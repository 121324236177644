import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';

import { translate } from '../../../../common/language/translations';
import { ClausePayment } from '../../../types';
import { useEffect, useState } from 'react';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { getDateLocaleFormat } from '../../../utils/playerUtils';
import { ClauseState } from '../../documents/clause/clauseState';
import { getDisplayPaymentAmount, getPaymentCurrency } from '../../../utils/clauseUtils';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { DateInputField } from '../../controls/input/DateInputField';
import { InputField } from '../../controls/input/InputField';


export const getPaymentTitle = (payment: ClausePayment, language: string) => {
  if (payment.conditionType === 'sellOn') {
    return translate('sellOn', language);
  }

  if (payment.conditionType === 'season') {
    return translate('season', language) + ' ' + payment.seasonString;
  }

  if (payment.conditionType === 'total' && payment.totalCondition) {
    return payment.totalConditionThreshold + ' ' + translate(payment.totalCondition, language, true);
  }

  return translate('unconditioned', language);
};


interface PaymentModalProps {
  state: ClauseState;
  closeModal: () => void;
  payments: ClausePayment[];
  addOrEditPayments: (payments: ClausePayment[], deletedPayments: ClausePayment[]) => void;
  isEdit: boolean;
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: (hasUnsavedChanges: boolean) => void;
}


export const PaymentModal: React.FC<PaymentModalProps> = ({
  state,
  closeModal,
  payments,
  addOrEditPayments,
  isEdit,
  hasUnsavedChanges,
  setHasUnsavedChanges,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [newPayments, setNewPayments] = useState<ClausePayment[]>(payments);
  const [deletedPayments, setDeletedPayments] = useState<ClausePayment[]>([]);

  const [newPaymentAmount, setNewPaymentAmount] = useState('');
  const [newPaymentDate, setNewPaymentDate] = useState<Date | null>(null);

  const [paymentIndexToEdit, setPaymentIndexToEdit] = useState<number | undefined>(undefined);
  const [paymentIndexToEditIsConditional, setPaymentIndexToEditIsConditional] = useState<boolean>(false);

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);


  const handleSetNewPaymentDate = (date: Date | null) => {
    if (!date) {
      setNewPaymentDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setNewPaymentDate(adjustedDate);
  };


  const onChangeAmountInputField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageKey(undefined);
    setNewPaymentAmount(event.target.value.replaceAll('.', ''));
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      addOrEditPayment();
    }
  };


  const addOrEditPayment = () => {
    if (!newPaymentAmount) {
      setMessageKey('noAmountProvided');
      return;
    }

    const amount = Number(newPaymentAmount.replaceAll('.', ''));

    if (isNaN(amount) || amount <= 0) {
      setMessageKey('invalidAmount');
      return;
    }

    setMessageKey(undefined);

    if (paymentIndexToEdit !== undefined) {
      const newPaymentsToSet = [...newPayments];
      const editedPayment = newPaymentsToSet[paymentIndexToEdit];
      editedPayment.amount = amount;
      if (newPaymentDate) {
        editedPayment.date = newPaymentDate.toISOString().split('T')[0];
      }
      else {
        delete editedPayment.date;
      }
      newPaymentsToSet[paymentIndexToEdit] = editedPayment;

      newPaymentsToSet.sort((a, b) => {
        if (!a.date) return 1;
        if (!b.date) return -1;
        return a.date.localeCompare(b.date);
      });

      setNewPayments(newPaymentsToSet);
      setPaymentIndexToEdit(undefined);
      setPaymentIndexToEditIsConditional(false);
      setNewPaymentAmount('');
      setNewPaymentDate(null);
      return;
    }

    const newPayment: ClausePayment = {
      amount: amount,
    };

    if (newPaymentDate) {
      newPayment.date = newPaymentDate.toISOString().split('T')[0];
    }

    const newPaymentsToSet = [...newPayments, newPayment];
    newPaymentsToSet.sort((a, b) => {
      if (!a.date) return 1;
      if (!b.date) return -1;
      return a.date.localeCompare(b.date);
    });

    setNewPayments(newPaymentsToSet);
  };


  const handleEditIconClick = (index: number) => {
    setPaymentIndexToEdit(index);
    setPaymentIndexToEditIsConditional(newPayments[index].conditionId !== undefined);

    const paymentDate = newPayments[index].date;
    let date: Date | null = null;
    if (paymentDate) {
      date = new Date(paymentDate);
    }

    const displayPaymentAmount = getDisplayPaymentAmount(newPayments[index], state, true);

    setNewPaymentAmount(displayPaymentAmount);
    setNewPaymentDate(date);
  };


  const cancelEditPayment = () => {
    setPaymentIndexToEdit(undefined);
    setPaymentIndexToEditIsConditional(false);
    setMessageKey(undefined);
    setNewPaymentAmount('');
    setNewPaymentDate(null);
  };


  const deletePayment = (index: number) => {
    setDeletedPayments([...deletedPayments, newPayments[index]]);

    const newPaymentsToSet = [...newPayments];
    newPaymentsToSet.splice(index, 1);
    setNewPayments(newPaymentsToSet);
  };


  useEffect(() => {
    setHasUnsavedChanges(
      payments.length !== newPayments.length ||
      payments.some((payment, index) => {
        return payment.date !== newPayments[index].date || payment.amount !== newPayments[index].amount;
      })
    );
  }, [newPayments, payments]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='modal-root-container payment-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate(isEdit ? 'addOrEditPayments' : 'addUnconditionedPayments', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      {messageKey && (
        <div className='payment-modal-message'>
          {translate(messageKey, userConfig?.language)}
        </div>
      )}

      <div className='modal-content-section' style={{ height: 110, minHeight: 110, marginTop: 30, marginBottom: 10 }}>
        <div className='payment-modal-new-payment-input-row'>
          <DateInputField
            value={newPaymentDate}
            placeholder={translate('paymentDate', userConfig?.language) + '?'}
            onChange={handleSetNewPaymentDate}
            onKeyDown={onKeyDownInputField}
            height={22}
            width={120}
            minDate='today-10y'
            maxDate='today+10y'
            popperPlacement='bottom-start'
          />

          <InputField
            value={getDisplayEconomicValue(newPaymentAmount)}
            placeholder={translate('amount', userConfig?.language)}
            onChange={onChangeAmountInputField}
            onKeyDown={onKeyDownInputField}
            disabled={paymentIndexToEditIsConditional}
            showDisabled={true}
            isSquare={true}
            height={22}
            width={120}
          />
        </div>

        <div className='payment-modal-divider' />

        <TextButton
          onClick={() => addOrEditPayment()}
          text={translate(paymentIndexToEdit !== undefined ? 'editPayment' : 'addPayment', userConfig?.language)}
          icon={<CheckIcon style={{ fontSize: 20 }} />}
          buttonType={'transparent'}
          style={{ height: 26 }}
        />
      </div>

      <div className='modal-content-container' style={{ top: 200, bottom: 80 }}>

        <div className='modal-content-section'>
          {newPayments.map((payment, index) => {
            return (
              <div key={index} className='payment-modal-existing-payment'>

                <IconButton
                  onClick={() => deletePayment(index)}
                  icon={<DeleteOutlineIcon style={{ fontSize: 17, marginTop: 1 }} />}
                  size={24}
                  style={{ position: 'absolute', left: -32 }}
                  title={translate('deletePayment', userConfig?.language)}
                />

                <div className='payment-modal-existing-payment-value' style={{ width: 150 }}>
                  {getDateLocaleFormat(payment.date)}
                </div>

                <div className='payment-modal-existing-payment-value' style={{ alignItems: 'baseline', width: 150 }}>
                  {getDisplayPaymentAmount(payment, state)}
                  <div style={{ marginLeft: 3, fontSize: 11, color: '#ffffffdd' }}>
                    {getPaymentCurrency(payment, state)}
                  </div>
                </div>

                <div className='payment-modal-existing-payment-value' style={{ width: 150, fontSize: 12, color: '#ffffffbb' }}>
                  {getPaymentTitle(payment, userConfig?.language ?? 'en')}
                </div>

                <IconButton
                  onClick={() => index === paymentIndexToEdit ? cancelEditPayment() : handleEditIconClick(index)}
                  icon={<EditIcon style={{ fontSize: 17, marginTop: 1 }} />}
                  size={24}
                  isActive={index === paymentIndexToEdit}
                  style={{ position: 'absolute', right: -32 }}
                  title={translate('editPayment', userConfig?.language)}
                />

              </div>
            );
          })}
        </div>
      </div>

      <TextButton
        onClick={() => addOrEditPayments(newPayments, deletedPayments)}
        text={translate(isEdit ? 'confirmEdits' : 'addPayments', userConfig?.language)}
        icon={isEdit ? <CheckIcon style={{ fontSize: 22 }} /> : <AddIcon style={{ fontSize: 22 }} />}
        disabled={!hasUnsavedChanges}
        style={{ position: 'absolute', bottom: 30 }}
      />
    </div>
  );
};
