import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '../../../../common/language/translations';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { IconButton } from '../../controls/buttons/IconButton';
import { PositionGroupsTable } from '../../display/visualization/PositionGroupsTable';


interface PositionGroupsInfoModalProps {
  closeModal: () => void;
}

export const PositionGroupsInfoModal: React.FC<PositionGroupsInfoModalProps> = ({ closeModal }) => {

  const userConfig = useRecoilValue(userConfigState);
  const { openTextModal } = useOpenGlobalModal();


  return (
    <div className='modal-root-container position-groups-info-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('positionGroups', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container'>
        <div className='position-groups-info-modal-text'>
          {translate('positionGroupsInfo', userConfig?.language)}
        </div>

        <div className='position-groups-info-modal-position-groups-table-container'>
          <PositionGroupsTable />
        </div>

        <div className='position-groups-info-modal-index-and-confidence-container'>

          <div
            className='position-groups-info-modal-index-and-confidence-section'
            onClick={() => openTextModal(
              metricToDisplayInfo['skill_index'].name[userConfig?.language ?? 'en'],
              metricToDisplayInfo['skill_index'].description[userConfig?.language ?? 'en'],
              { infoType: 'metric', titleKey: 'skill_index' },
            )}
          >
            {metricToDisplayInfo['skill_index'].name[userConfig?.language ?? 'en']}
            <InfoOutlinedIcon style={{ fontSize: 14, marginTop: 1 }} />
          </div>

          <div
            className='position-groups-info-modal-index-and-confidence-section'
            onClick={() => openTextModal(
              metricToDisplayInfo['confidence'].name[userConfig?.language ?? 'en'],
              metricToDisplayInfo['confidence'].description[userConfig?.language ?? 'en'],
              { infoType: 'metric', titleKey: 'confidence' },
            )}
          >
            {metricToDisplayInfo['confidence'].name[userConfig?.language ?? 'en']}
            <InfoOutlinedIcon style={{ fontSize: 14, marginTop: 1 }} />
          </div>
        </div>
      </div>
    </div>
  );
};
