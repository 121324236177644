import { db, FirebaseUser } from '../../../firebase';

import {
  collection,
  addDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  orderBy,
} from 'firebase/firestore';

import { StringToAnyMap, PlayerClause } from '../../types';
import { trackEvent } from '../server/analytics/trackEvent';
import { EventPropertiesMap } from '../server/analytics/events';


// Get all clauses
export const getAllClauses = async (
  club: string,
  currentUser: FirebaseUser,
): Promise<PlayerClause[]> => {

  const clauseDocRef = collection(db, 'configs', club, 'clauses');

  try {
    const q = query(clauseDocRef, orderBy('date', 'desc'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() as PlayerClause }));
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'getAllClauses', errorDetails: { error } }, currentUser, 'system');
    return [];
  }
};


// Add a new clause and return the document id as the new clause id
export const addClause = async (
  clause: PlayerClause,
  club: string,
  currentUser: FirebaseUser,
): Promise<string | undefined> => {

  const clauseCollectionRef = collection(db, 'configs', club, 'clauses');

  try {
    const docRef = await addDoc(clauseCollectionRef, clause);

    trackEvent('ClauseAdded', { clauseType: clause.isSoldPlayer ? 'soldPlayer' : 'boughtPlayer' }, currentUser, 'user');

    return docRef.id;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addClause', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// Update a clause
export const updateClause = async (
  clauseId: string,
  newValues: StringToAnyMap,
  club: string,
  currentUser: FirebaseUser,
  eventProperties?: EventPropertiesMap['ClauseAction'],
): Promise<boolean> => {

  const clauseDocRef = doc(db, 'configs', club, 'clauses', clauseId);

  try {
    await updateDoc(clauseDocRef, newValues);

    if (eventProperties) trackEvent('ClauseAction', eventProperties, currentUser, 'user');

    return true;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateClause', errorDetails: { error } }, currentUser, 'system');
    return false;
  }
};
