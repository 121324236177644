import '../mobilePlatform.css';

import { ViewContainerBackgroundSvg } from '../../../assets/svg/ViewContainerBackgroundSvg';
import { MobileHeader } from '../mobileComponents/MobileHeader';


export const MobileReports: React.FC = () => {

  return (
    <div className='full-size-container'>

      <div className='mobile-home-view-background'>
        <ViewContainerBackgroundSvg />
      </div>

      <MobileHeader tab={'reports'} />

      <div className='mobile-platform-view-section'>
        <div className='mobile-platform-view-content-container'>
          &nbsp;
        </div>
      </div>
    </div>
  );
};
