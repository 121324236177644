import { FirebaseUser, db } from '../../../firebase';

import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  onSnapshot,
} from 'firebase/firestore';
import { UserConfig, UserConfigMap } from '../../types';
import { trackEvent } from '../server/analytics/trackEvent';


// Get user data for the given user
export const getUserConfig = (
  currentUser: FirebaseUser,
  handleSetUserConfig: (user: UserConfig | null) => void,
) => {
  if (!currentUser || !currentUser.email) {
    return () => null;
  }

  const userDocRef = doc(db, 'users', currentUser.email);

  const unsubscribe = onSnapshot(userDocRef, (docSnapshot) => {
    if (!docSnapshot.exists()) {
      handleSetUserConfig(null);
      return;
    }

    const userData = docSnapshot.data();
    if (!userData.club) {
      handleSetUserConfig(null);
      return;
    }

    if (!currentUser.email) {
      handleSetUserConfig(null);
      return;
    }

    const userConfig: UserConfig = {
      email: currentUser.email,
      club: userData.club,
      name: userData.name ?? currentUser.email.split('@')[0],
      language: userData.language ?? 'en',
      isNavigationExpanded: userData.isNavigationExpanded ?? true,
      isColorBlind: userData.isColorBlind ?? false,
      access: userData.access ?? 'scout',
      hasMaintenanceAccess: userData.hasMaintenanceAccess ?? false,
    };

    handleSetUserConfig(userConfig);
  });

  return unsubscribe;
};


// Update user config
export const updateUserConfig = async (
  userEmail: string,
  property: string,
  value: string | boolean,
  eventName: 'AccessEdited' | 'UserNameEdited' | 'NewLanguageSelected' | 'NavigationExpansionToggled',
  currentUser: FirebaseUser,
) => {

  const userDocRef = doc(db, 'users', userEmail);

  try {
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      await updateDoc(userDocRef, { [property]: value });
      trackEvent(eventName, { newValue: value }, currentUser, 'user');
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateUserConfig', errorDetails: { error } }, currentUser, 'system');
  }
};


// Get all users with the same club as the current user
export const getAllUsersWithSameClub = (
  setAllUsersWithSameClub: (allUsersWithSameClub: UserConfigMap) => void,
  club: string,
) => {

  const usersRef = collection(db, 'users');

  const q = query(usersRef, where('club', '==', club));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const users: UserConfigMap = {};
    querySnapshot.forEach((doc) => {
      users[doc.id] = doc.data() as UserConfig;

      if (!users[doc.id].access) {
        users[doc.id].access = 'scout';
      }
    });

    setAllUsersWithSameClub(users);
  });

  return unsubscribe;
};
