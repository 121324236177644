import './dropDowns.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { translate } from '../../../../common/language/translations';
import { ConditionSeasonString } from '../../../types';


interface SeasonDropDownProps {
  id: string;

  seasonOptions: string[];
  selectedSeasons: string[];
  setSelectedSeasons: (value: string[]) => void;
  selectedSeasonString: string;
  setSelectedSeasonString: (value: ConditionSeasonString) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string;
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string;
}

export const SeasonDropDown: React.FC<SeasonDropDownProps> = ({
  id,

  seasonOptions,
  selectedSeasons,
  setSelectedSeasons,
  selectedSeasonString,
  setSelectedSeasonString,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [topOptionWasJustClicked, setTopOptionWasJustClicked] = useState(false);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean) => {

    if (option === 'allSeasons') {
      setSelectedSeasons(isOptionAlreadySelected ? [] : seasonOptions);
      setTopOptionWasJustClicked(true);
      setTimeout(() => {
        setTopOptionWasJustClicked(false);
      }, 25);
      return;
    }

    const newSelectedOptions = selectedSeasons.slice();

    if (isOptionAlreadySelected) {
      const i = newSelectedOptions.indexOf(option);
      newSelectedOptions.splice(i, 1);
    }
    else {
      newSelectedOptions.push(option);
    }

    setSelectedSeasons(newSelectedOptions);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '26px';
      element.style.zIndex = '1';
    }

    setIsDropDownExpanded(false);
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (seasonOptions.length > 0) {
      if (selectedSeasons.length === seasonOptions.length) {
        setSelectedSeasonString('allSeasons');
      }
      else if (selectedSeasons.length > 0) {
        setSelectedSeasonString('someSeasons');
      }
    }
  }, [selectedSeasons, setSelectedSeasonString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ height: 26, maxHeight: maxHeight }}
    >

      <div
        className='drop-down-button'
        style={{ height: 26 }}
        onClick={() => handleDropDownClick()}>

        {selectedSeasons.length > 0 && <div className='drop-down-button-overlay' />}

        <div className='drop-down-button-text'>
          {selectedSeasons.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div style={{ color: '#000000' }} className={selectedSeasonString !== 'someSeasons' ? 'fade-in' : undefined}>
              {!topOptionWasJustClicked ? translate(selectedSeasonString, userConfig?.language) : ''}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon' style={{ marginTop: 1 }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon' style={{ marginTop: 1 }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-space'>&nbsp;</div>

      {seasonOptions.length === 0 && (
        <div className='drop-down-message'>
          {translate('noOptionsAvailable', userConfig?.language)}
        </div>
      )}

      {seasonOptions.length > 0 && (
        <div>
          <div
            className={'drop-down-option' + (selectedSeasons.length === seasonOptions.length ? ' drop-down-option-selected' : '')}
            style={{ marginTop: marginBetweenOptions }}
            onClick={() => handleOptionSelect('allSeasons', selectedSeasons.length === seasonOptions.length)}
          >
            {translate('allSeasons', userConfig?.language)}
          </div>

          <div
            className={'drop-down-options' + (maxHeight ? ' drop-down-options-scroll' : '')}
            style={{ maxHeight: maxHeight }}>
            {seasonOptions.map(season => {

              const isSelected = selectedSeasons.includes(season);

              return (
                <div
                  className={'drop-down-option' + (isSelected ? ' drop-down-option-selected' : '')}
                  style={{ marginTop: marginBetweenOptions }}
                  key={season}
                  onClick={() => handleOptionSelect(season, isSelected)}
                >
                  {season}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
