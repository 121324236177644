import { useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from './common/contexts/AuthContext';
import { RecoilRoot } from 'recoil';

import { LandingPage } from './site/landingPage/LandingPage';
import { Login } from './site/login/Login';
import { Platform } from './platform/Platform';


function App() {


  const isPWA = useMemo(
    () =>
      window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone === true,
    []
  );

  useEffect(() => {
    if (isPWA) {
      document.body.classList.add('pwa-mode');
    }
    else {
      document.body.classList.remove('pwa-mode');
    }
  }, [isPWA]);


  return (
    <div>
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>

            <Route
              path="/"
              element={
                isPWA ? <Login /> : <LandingPage />
              }
            />

            <Route
              path="/login"
              element={
                <Login />
              }
            />

            <Route
              path="/platform"
              element={(
                <RecoilRoot>
                  <Platform />
                </RecoilRoot>
              )}
            />

          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
