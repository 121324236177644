import './dropDowns.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { translate } from '../../../../common/language/translations';


interface LogisticTeamDropDownProps {
  id: string;

  selectedOptions: string[];
  setSelectedOptions: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  height?: number;
  maxHeight?: string; // controls scrolling
}

export const LogisticTeamDropDown: React.FC<LogisticTeamDropDownProps> = ({
  id,

  selectedOptions,
  setSelectedOptions,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  height,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const teams = useRecoilValue(teamsState);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean) => {
    let newSelectedOptions = selectedOptions.slice();

    if (isOptionAlreadySelected) {
      const i = newSelectedOptions.indexOf(option);
      newSelectedOptions.splice(i, 1);
    }
    else {
      if (option === 'allTeams') {
        newSelectedOptions = ['allTeams'];
      }
      else {
        const i = newSelectedOptions.indexOf('allTeams');
        if (i !== -1) newSelectedOptions.splice(i, 1);
        newSelectedOptions.push(option);
      }
    }

    setSelectedOptions(newSelectedOptions);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = height ? height + 'px' : '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const getDisplayOption = (option: string) => {
    const translation = translate(option, userConfig?.language, undefined, undefined, true);
    if (translation) return translation;
    if (teams && option in teams) return teams[option].name;
    return option;
  };


  const showSelectedOptions = () => {
    let firstSelection = getDisplayOption(selectedOptions[0]);
    for (let i = 1; i < selectedOptions.length; i++) {
      firstSelection += ', ' + getDisplayOption(selectedOptions[i]);
    }
    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  const dropDownOptions = Object.keys(teams ?? {}).filter(teamKey => (teamKey !== 'ownTeam' && teamKey !== 'academyTeam'));
  const allTeamsIsSelected = selectedOptions.length === 1 && selectedOptions[0] === 'allTeams';

  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ height: height, maxHeight: maxHeight }}
    >

      <div
        className='drop-down-button'
        style={{ height: height }}
        onClick={() => handleDropDownClick()}
      >

        {selectedOptions.length > 0 && <div className='drop-down-button-overlay' />}

        <div className='drop-down-button-text'>
          {selectedOptions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-space'>&nbsp;</div>

      <div
        className={'drop-down-options' + (maxHeight ? ' drop-down-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}
      >
        <div
          className={'drop-down-option' + (allTeamsIsSelected ? ' drop-down-option-selected' : '')}
          style={{ marginTop: marginBetweenOptions }}
          onClick={() => handleOptionSelect('allTeams', allTeamsIsSelected)}
        >
          {getDisplayOption('allTeams')}
        </div>

        <div className='drop-down-divider' />

        <div className='drop-down-options'>
          {dropDownOptions.map(option => {
            const isSelected = selectedOptions.includes(option);
            return (
              <div
                className={'drop-down-option' + (isSelected ? ' drop-down-option-selected' : '')}
                style={{ marginTop: marginBetweenOptions }}
                key={option}
                onClick={() => handleOptionSelect(option, isSelected)}
              >
                {getDisplayOption(option)}
              </div>
            );
          })}
        </div>
      </div>

    </div>
  );
};
