import { SearchPlayerOverviewsResponse } from '../../../services/server/application/playerOverviews';
import { StringToAnyMap } from '../../../types';
import { playerTableChapterSize } from '../Scouting';
import { initialPlayerScoutingState, PlayerScoutingFilter, PlayerScoutingState } from './playerScoutingState';


export type PlayerScoutingAction =
  | {
    type: 'SET_SEARCH_RESULT';
    payload: {
      result: SearchPlayerOverviewsResponse,
      isNewSearch: boolean,
      nextChapter: number | undefined,
      newOverriddenOrderBy: string | undefined
    }
  }
  | { type: 'RESET_STATE'; }
  | { type: 'RESET_SEARCH_STATE'; }
  | { type: 'SET_IS_LOADING'; }
  | { type: 'SET_EMPTY_TABLE_DATA'; }

  | { type: 'SET_FILTER'; payload: Partial<PlayerScoutingState> }
  | { type: 'SET_FILTER_EXPANSION'; payload: PlayerScoutingFilter; }
  | { type: 'RESET_FILTER_EXPANSION'; }
  | { type: 'SET_ADVANCED_FILTERS_EXPANSION'; }
  | { type: 'SET_SHOW_ADVANCED_FILTERS'; payload: boolean; }
  | { type: 'SET_SELECTED_ORDER_BY'; payload: string[]; }

  | { type: 'SET_MODAL_TYPE_OPEN'; payload: 'saveSearch' | 'loadSearch' | undefined; }
  | { type: 'LOAD_SEARCH'; payload: StringToAnyMap; };


const playerScoutingReducer = (state: PlayerScoutingState, action: PlayerScoutingAction): PlayerScoutingState => {

  switch (action.type) {

    case 'SET_SEARCH_RESULT':
      return getState_SET_SEARCH_RESULT(action.payload, state);

    case 'RESET_STATE':
      return getState_RESET_STATE(state);

    case 'RESET_SEARCH_STATE':
      return getState_RESET_SEARCH_STATE(state);

    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };

    case 'SET_EMPTY_TABLE_DATA':
      return { ...state, tableData: [] };


    case 'SET_FILTER':
      return { ...state, ...action.payload };

    case 'SET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: action.payload, previousFilterExpanded: action.payload };

    case 'RESET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: undefined };

    case 'SET_ADVANCED_FILTERS_EXPANSION':
      return getState_SET_ADVANCED_FILTERS_EXPANSION(state);

    case 'SET_SHOW_ADVANCED_FILTERS':
      return { ...state, showAdvancedFilters: action.payload };

    case 'SET_SELECTED_ORDER_BY':
      return { ...state, selectedOrderBy: action.payload };


    case 'SET_MODAL_TYPE_OPEN':
      return { ...state, modalTypeOpen: action.payload };

    case 'LOAD_SEARCH':
      return getState_LOAD_SEARCH(action.payload, state);


    default:
      return state;
  }
};


const getState_SET_SEARCH_RESULT = (
  payload: {
    result: SearchPlayerOverviewsResponse,
    isNewSearch: boolean,
    nextChapter: number | undefined,
    newOverriddenOrderBy: string | undefined,
  },
  state: PlayerScoutingState
) => {

  const isEmptySearchResult = payload.result.total_hits === 0 || (payload.isNewSearch && payload.result.players.length === 0);
  const isFirstPageOfChapter = payload.isNewSearch || payload.nextChapter !== undefined;

  return {
    ...state,

    tableData: isEmptySearchResult
      ? []
      : isFirstPageOfChapter ? payload.result.players : [...state.tableData, ...payload.result.players],

    totalHits: isEmptySearchResult
      ? 0
      : payload.result.total_hits,

    currentModuloPage: isEmptySearchResult
      ? 0
      : isFirstPageOfChapter ? 1 : (payload.result.current_page - (state.currentChapter * playerTableChapterSize)),

    currentChapter: isEmptySearchResult
      ? 0
      : payload.isNewSearch ? 0 : (payload.nextChapter !== undefined ? payload.nextChapter : state.currentChapter),

    isEmptySearchResult: isEmptySearchResult,
    isLoading: false,
    searchWasLoaded: false,

    // set current filters applied to the search result to the ones currenlty selected
    currentMinConfidence: state.selectedMinConfidence,
    currentMaxAge: state.selectedMaxAge,
    currentPositions: state.selectedPositions,
    currentRoles: state.selectedRoles,
    currentLeagues: state.selectedLeagues,
    currentOrderBy: payload.newOverriddenOrderBy ? [payload.newOverriddenOrderBy] : state.selectedOrderBy,
    currentClubs: state.selectedClubs,
    currentForm: state.selectedForm,
    currentPlayingTime: state.selectedPlayingTime,
    currentNationalities: state.selectedNationalities,
    currentContractStatus: state.selectedContractStatus,
    currentIsContractStatusToggled: state.isContractStatusToggled
  };
};


const getState_RESET_STATE = (state: PlayerScoutingState) => {
  return {
    ...initialPlayerScoutingState,
    isAdvancedFiltersExpanded: state.isAdvancedFiltersExpanded,
    showAdvancedFilters: state.showAdvancedFilters,
    scoutingViewResultContainerTop: state.scoutingViewResultContainerTop,
  };
};


const getState_RESET_SEARCH_STATE = (state: PlayerScoutingState) => {
  return {
    ...state,
    tableData: [],
    currentChapter: 0,
    totalHits: 0,
    currentModuloPage: 0,
    isEmptySearchResult: false,
    isLoading: false,
    searchWasLoaded: false,
  };
};


const getState_SET_ADVANCED_FILTERS_EXPANSION = (state: PlayerScoutingState) => {
  return {
    ...state,
    scoutingViewResultContainerTop: state.isAdvancedFiltersExpanded ? 85 : 155,
    isAdvancedFiltersExpanded: !state.isAdvancedFiltersExpanded,
  };
};


const getState_LOAD_SEARCH = (payload: StringToAnyMap, state: PlayerScoutingState) => {
  return {
    ...state,

    selectedMinConfidence: payload.minConfidence ?? [],
    selectedMaxAge: payload.maxAge ?? [],
    selectedPositions: payload.positions ?? [],
    selectedRoles: payload.roles ?? [],
    selectedLeagues: payload.leagues ?? [],
    selectedOrderBy: payload.orderBy ?? [],

    selectedClubs: payload.clubs ?? [],
    selectedForm: payload.form ?? [],
    selectedPlayingTime: payload.playingTime ?? [],
    selectedNationalities: payload.nationalities ?? [],
    selectedContractStatus: payload.contractStatus ?? [],
    isContractStatusToggled: payload.isContractStatusToggled ?? false,

    searchWasLoaded: true,
  };
};


export default playerScoutingReducer;
