import './navigation.css';

import { useState, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import LockIcon from '@mui/icons-material/Lock';
import BlockIcon from '@mui/icons-material/Block';

import { translate } from '../../../../common/language/translations';
import { getClubColor } from '../../../static/clubConfigs';
import { StringToStringMap } from '../../../types';
import { trackEvent } from '../../../services/server/analytics/trackEvent';


interface FixedTabsProps {
  tabOptions: string[] | JSX.Element[];
  tabIcons?: JSX.Element[];
  tabTitles?: string[];

  activeTab: number,
  setActiveTab: (tab: number, updateTabLineCallback?: (tab: number) => void) => void;
  parentTab: string;
  initialActiveTab?: number;

  marginTop?: string;
  marginTopLine?: string;

  isPlayerView?: boolean;
  playerViewTabOptionToStatus?: StringToStringMap;

  withoutTabLine?: boolean;
  useUpdateTabLineCallback?: boolean;

  numberOfNewPlayersSinceLastViewed?: number; // used for alerts tab to show notification
}


export const FixedTabs: React.FC<FixedTabsProps> = ({
  tabOptions,
  tabIcons,
  tabTitles,

  activeTab,
  setActiveTab,
  parentTab,
  initialActiveTab,

  marginTop,
  marginTopLine,

  isPlayerView,
  playerViewTabOptionToStatus,

  withoutTabLine,
  useUpdateTabLineCallback,

  numberOfNewPlayersSinceLastViewed,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const [tabLineLeft, setTabLineLeft] = useState('0%');

  const lineWidth = 14 - (tabOptions.length / 2);


  const updateTabLine = (tab: number) => {
    const line = document.getElementById('fixed-tab-line');
    if (line && tabOptions.length > 0) {
      const tabWidth = 100 / tabOptions.length;
      const tabOffset = (tabWidth - lineWidth) / 2;
      setTabLineLeft((tabOffset + (tabWidth * tab)) + '%');
    }
  };


  const handleTrackSubTabClicked = (tab: number) => {
    if (tab !== activeTab) {
      let stringTab = typeof tabOptions[tab] === 'string' ? tabOptions[tab].toString() : tabTitles ? tabTitles[tab] : '';

      // table tab
      if (parentTab === 'home' && !translate(stringTab, undefined, undefined, undefined, true)) {
        stringTab = 'leagueTable';
      }

      trackEvent('SubTabClicked', { tab: stringTab, parentTab: parentTab }, currentUser, 'user');
    }
  };


  const handleTabClick = (tab: number, isInitialTabClick?: boolean) => {
    handleTrackSubTabClicked(tab);
    if (useUpdateTabLineCallback && !isInitialTabClick) {
      setActiveTab(tab, updateTabLine);
    }
    else {
      setActiveTab(tab);
      if (!withoutTabLine) {
        updateTabLine(tab);
      }
    }
  };


  useEffect(() => {
    if (!withoutTabLine) {
      handleTabClick(initialActiveTab ?? 0, true);
    }
  }, [withoutTabLine, initialActiveTab]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='tabs-tab-section'>

      {!withoutTabLine && (
        <div
          id='fixed-tab-line'
          className='tabs-tab-line fixed-tab-line'
          style={{
            backgroundColor: clubColor,
            left: tabLineLeft,
            marginTop: marginTopLine,
            width: lineWidth + '%'
          }}
        />
      )}

      {tabOptions.map((tabOption, index) => {

        // the tab is either 1) enabled, 2) blocked (and disabled), or 3) locked (and disabled)

        const isStringTab = typeof tabOption === 'string';

        const tabIsEnabled = !playerViewTabOptionToStatus || !isStringTab || playerViewTabOptionToStatus[tabOption] === 'enabled';
        const tabIsBlocked = playerViewTabOptionToStatus && isStringTab && playerViewTabOptionToStatus[tabOption] === 'blocked';
        const tabIsLocked = playerViewTabOptionToStatus && isStringTab && playerViewTabOptionToStatus[tabOption] === 'locked';

        const showNotificationForAlertsTab: boolean = tabOption === 'alerts'
          && numberOfNewPlayersSinceLastViewed !== undefined
          && numberOfNewPlayersSinceLastViewed > 0;

        return (
          <div
            key={index}
            title={tabTitles ? translate(tabTitles[index], userConfig?.language) : undefined}
            className={
              'tabs-tab fixed-tabs-tab' + (activeTab === index
                ? isPlayerView ? ' fixed-tabs-player-view-tab-active' : ' tabs-tab-active'
                : isPlayerView ? ' fixed-tabs-player-view-tab' : ''
              ) + (!tabIsEnabled ? ' fixed-tabs-player-view-tab-disabled' : '')}
            style={{ marginTop: marginTop }}
            onClick={() => tabIsEnabled ? handleTabClick(index) : null}
          >

            {!tabIcons && isStringTab && (
              <div className='fixed-tabs-tab-title'>
                {translate(tabOption, userConfig?.language)}
              </div>
            )}

            {!isStringTab && (
              <div className='fixed-tabs-single-icon-tab'>
                {tabOption}
              </div>
            )}

            {tabIcons && isStringTab && (
              <div className='fixed-tabs-tab-row'>
                <div className='fixed-tabs-tab-icon'>
                  {tabIsLocked && <LockIcon style={{ fontSize: 21 }} />}
                  {tabIsBlocked && <BlockIcon style={{ fontSize: 21 }} />}
                  {tabIsEnabled && tabIcons[index]}
                </div>
                <div className='fixed-tabs-tab-title'>
                  {translate(tabOption, userConfig?.language)}
                </div>

                {showNotificationForAlertsTab && (
                  <div className='fixed-tabs-notification-container fade-in'>
                    <div className='fixed-tabs-notification'>
                      {numberOfNewPlayersSinceLastViewed}
                    </div>
                  </div>
                )}
              </div>
            )}

          </div>
        );
      })}

    </div>
  );
};
