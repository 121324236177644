import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';

import { translate } from '../../../../common/language/translations';
import { getConditionStatusIcon } from '../../../utils/iconUtils';
import { IconButton } from '../../controls/buttons/IconButton';
import { OptionButton } from '../../controls/buttons/OptionButton';
import { SeasonSubConditionStatus } from '../../../types';


interface SeasonSubConditionStatusModalProps {
  currentStatus: string | null;
  newStatusSuggestion: string | undefined;
  conditionDescriptions: string[];
  handleUpdateSeasonSubConditionStatus: (newStatus: SeasonSubConditionStatus) => void;
  closeModal: () => void;
}

export const SeasonSubConditionStatusModal: React.FC<SeasonSubConditionStatusModalProps> = ({
  currentStatus,
  newStatusSuggestion,
  conditionDescriptions,
  handleUpdateSeasonSubConditionStatus,
  closeModal
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const statusOptions: SeasonSubConditionStatus[] = ['achieved', 'notAchieved'];

  return (
    <div className='modal-root-container' style={{ width: 400, height: 320 + (conditionDescriptions.length * 20) }}>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate(currentStatus ? 'editConditionStatus' : 'provideConditionStatus', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container' style={{ padding: '10px' }}>
        <div className='modal-content-section' style={{ padding: '10px 0px', height: 'fit-content' }}>
          {conditionDescriptions.map((description: string, index: number) => {
            return (
              <div key={index} style={{ marginTop: index > 0 ? 12 : undefined }}>
                {description}
              </div>
            );
          })}
        </div>

        {newStatusSuggestion && (
          <div className='modal-content-section' style={{ padding: '10px 0px', height: 'fit-content' }}>
            {translate('suggestedStatusInfo', userConfig?.language)}
            {' ' + translate(newStatusSuggestion, userConfig?.language, true)}
          </div>
        )}

        <div className='modal-content-section' style={{ padding: '10px 0px' }}>
          <div className='modal-options-column' style={{ margin: 'auto' }}>
            {statusOptions.map((status, index) => {

              const icon = getConditionStatusIcon(status, 22);

              return (
                <div
                  key={status}
                  style={{ marginTop: index === 0 ? 2 : 18 }}
                >
                  <OptionButton
                    onClick={() => handleUpdateSeasonSubConditionStatus(status === currentStatus ? null : status)}
                    option={status as string}
                    language={userConfig?.language}
                    optionIsSelected={status === currentStatus}
                    icons={{ leftIcon: icon }}
                    style={{ width: 180 }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>

    </div>
  );
};
