import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '../../../../common/language/translations';
import { newAccessOptions } from '../../../static/propertyValues';
import { getAccessIcon } from '../../../utils/iconUtils';
import { useState } from 'react';
import { InputField } from '../../controls/input/InputField';

import { getFunctions, httpsCallable } from 'firebase/functions';
import { trackEvent } from '../../../services/server/analytics/trackEvent';
import { IconButton } from '../../controls/buttons/IconButton';
import { OptionButton } from '../../controls/buttons/OptionButton';
import { TextButton } from '../../controls/buttons/TextButton';


const functions = getFunctions(undefined, 'europe-west1');


interface NewUserData {
  email: string;
  club: string;
  name: string;
  language: string;
  access: string;
}


interface AddAccountModalProps {
  closeModal: () => void;
}


export const AddAccountModal: React.FC<AddAccountModalProps> = ({ closeModal }) => {

  const createUser = httpsCallable(functions, 'createUser');

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const { openConfirmModal, openTextModal } = useOpenGlobalModal();

  const [isLoading, setIsLoading] = useState(false);

  const [messageKey, setMessageKey] = useState<string | undefined>('');

  const [emailInputString, setEmailInputString] = useState('');
  const [nameInputString, setNameInputString] = useState('');
  const [selectedAccess, setSelectedAccess] = useState<string | undefined>('scout');

  const isButtonDisabled = !emailInputString || !nameInputString || !selectedAccess;

  const [newAccountWasAdded, setNewAccountWasAdded] = useState(false);


  const isValidEmail = (email: string) => {

    // check if format is valid
    if (!(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))) {
      setMessageKey('invalidEmailProvided');
      return false;
    }

    // fokus emails (fokus@something.something) are restricted
    if (email.split('@')[0] === 'fokus') {
      setMessageKey('fokusEmailRestricted');
      return false;
    }

    return true;
  };


  const handleAddAccountClick = () => {
    if (isValidEmail(emailInputString)) {
      openConfirmModal(
        confirmAddAccount,
        'createAccount',
        'youWillNotBeAbleToDeleteAccount'
      );
    }
  };


  const confirmAddAccount = async () => {
    if (!userConfig || isButtonDisabled) return;

    setIsLoading(true);

    const newUserConfig: NewUserData = {
      email: emailInputString.toLocaleLowerCase(),
      club: userConfig.club,
      name: nameInputString,
      language: userConfig.language,
      access: selectedAccess,
    };

    try {
      trackEvent('AccountCreated', { newAccess: selectedAccess }, currentUser, 'user');
      const success = await createUser(newUserConfig);

      if (!success) {
        setMessageKey('somethingWentWrong');
        setIsLoading(false);
        return;
      }

      setMessageKey(undefined);
      setNewAccountWasAdded(true);
      setIsLoading(false);
    }
    catch (error) {
      trackEvent('UnexpectedBehavior', { file: 'AddAccountModal', behavior: 'createUser (somethingWentWrong)' }, currentUser, 'system');
      setMessageKey('somethingWentWrong');
      setIsLoading(false);
    }
  };


  return (
    <div className='modal-root-container add-account-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('addNewAccount', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />


      <div className='modal-content-container'>
        {!newAccountWasAdded && !isLoading && (
          <div
            className='modal-content-section'
            style={{ paddingTop: 50 }}
          >
            {messageKey && !newAccountWasAdded && !isLoading && (
              <div className='add-account-modal-message' style={{ color: '#ff8888' }}>
                {translate(messageKey, userConfig?.language)}
              </div>
            )}

            <div className='add-account-modal-input-entry'>
              {translate('email', userConfig?.language)}
              <InputField
                value={emailInputString}
                placeholder={translate('provideEmail', userConfig?.language)}
                onChange={(event) => setEmailInputString(event.target.value)}
                resetValue={() => setEmailInputString('')}
                height={26}
                isSquare={true}
              />
            </div>

            <div className='add-account-modal-input-entry'>
              {translate('name', userConfig?.language)}
              <InputField
                value={nameInputString}
                placeholder={translate('provideName', userConfig?.language)}
                onChange={(event) => setNameInputString(event.target.value)}
                resetValue={() => setNameInputString('')}
                height={26}
                isSquare={true}
              />
            </div>
          </div>
        )}

        {!newAccountWasAdded && !isLoading && (
          <div
            className='modal-content-section'
            style={{ minHeight: 200 }}
          >
            <div className='modal-option-column' style={{ margin: '2vh' }}>
              {newAccessOptions.map(access => {

                const icon = getAccessIcon(access, 22);

                return (
                  <div
                    key={access}
                    style={{ position: 'relative' }}
                  >
                    <IconButton
                      icon={<InfoOutlinedIcon style={{ fontSize: 16 }} />}
                      onClick={() => openTextModal(
                        translate(access + 'Access', userConfig?.language),
                        translate(access + 'AccessInfo', userConfig?.language),
                        { infoType: 'infoIcon', titleKey: access + 'Access' },
                      )}
                      size={24}
                      style={{ position: 'absolute', left: -40 }}
                    />

                    <OptionButton
                      onClick={() => setSelectedAccess(access)}
                      option={access + 'Access'}
                      language={userConfig?.language}
                      optionIsSelected={access === selectedAccess}
                      icons={{ leftIcon: icon }}
                      style={{ width: 200 }}
                    />
                  </div>
                );
              })}
            </div>

            <TextButton
              onClick={handleAddAccountClick}
              text={translate('createAccount', userConfig?.language)}
              icon={<PersonAddIcon style={{ fontSize: 22, marginTop: -1 }} />}
              disabled={isButtonDisabled}
            />
          </div>
        )}

        {newAccountWasAdded && !isLoading && (
          <div className='modal-content-section' style={{ padding: '5vh 30px', minHeight: 270 }}>
            <div className='add-account-modal-success-message'>
              <CheckIcon style={{ fontSize: 28, marginRight: 12, marginTop: -2 }} />
              {translate('accountCreated', userConfig?.language)}
            </div>

            <div className='add-account-modal-success-info'>
              {translate('accountCreatedInfo', userConfig?.language)}
            </div>

            <div className='add-account-modal-input-entry' style={{ margin: 'auto' }}>
              {translate('email', userConfig?.language)}
              <div style={{ fontSize: 16, userSelect: 'text' }}>
                {emailInputString}
              </div>
            </div>
          </div>
        )}

        {isLoading && <div className='loading-spinner' />}
      </div>

    </div>
  );
};
