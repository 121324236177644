import './dropDowns.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import LockIcon from '@mui/icons-material/Lock';

import { translate } from '../../../../common/language/translations';
import { PlayerClubIteration } from '../../../types';
import { ClubLogo } from '../../display/images/ClubLogo';
import { CompetitionFlag } from '../../display/images/CompetitionFlag';


interface PlayerIterationDropDownProps {
  id: string;

  dropDownOptions: PlayerClubIteration[];
  selectedOption: number | undefined;
  handleIterationClick: (value: number | undefined) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string;
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight: string;
}

export const PlayerIterationDropDown: React.FC<PlayerIterationDropDownProps> = ({
  id,

  dropDownOptions,
  selectedOption,
  handleIterationClick,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
}) => {

  const userConfig = useRecoilValue(userConfigState);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (index: number) => {
    if (index === selectedOption) {
      handleIterationClick(undefined);
    }
    else {
      handleIterationClick(index);
      setIsDropDownExpanded(false);
    }
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-button' onClick={() => handleDropDownClick()}>

        {selectedOption !== undefined && <div className='drop-down-button-overlay' />}

        <div className='drop-down-button-text'>
          {selectedOption !== undefined && (
            <CompetitionFlag
              competitionId={dropDownOptions[selectedOption].competition_id}
              width={16}
              seasonToShow={dropDownOptions[selectedOption].season}
              containerStyle={{ marginLeft: -3, gap: 3, color: '#000000' }}
              isWhiteBackground={true}
            />
          )}

          {selectedOption === undefined && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-space'>&nbsp;</div>

      <div className='drop-down-options drop-down-options-scroll'>
        {dropDownOptions.map((option: PlayerClubIteration, index: number) => {
          const isSelected = selectedOption === index;
          const isAvailable = option.event_data_available;

          const positionGroupAccessor = option.position_group_stats.overall
            ? 'overall'
            : option.position_groups && option.position_groups.length > 0
              ? option.position_groups[0]
              : undefined;

          const minutesPlayed = positionGroupAccessor !== undefined ? option.position_group_stats[positionGroupAccessor]?.minutes_played : undefined;

          return (
            <div
              className={
                'drop-down-option'
                + (isSelected ? ' drop-down-option-selected' : '')
                + (isAvailable ? '' : ' player-iteration-drop-down-option-not-available')
              }
              style={{ marginTop: marginBetweenOptions }}
              key={index}
              onClick={() => isAvailable ? handleOptionSelect(index) : null}
            >
              <CompetitionFlag
                competitionId={option.competition_id}
                width={16}
                seasonToShow={option.season}
                containerStyle={{
                  marginLeft: 3,
                  justifyContent: 'left',
                }}
                isWhiteBackground={true}
                smallFontSize={11}
                smallFontSizeThreshold={20}
              />

              <ClubLogo
                club={option.club}
                size={14}
                showClubName={true}
                minutesPlayedToShow={minutesPlayed}
                containerStyle={{
                  marginLeft: 4,
                  marginTop: 2,
                  justifyContent: 'left',
                  fontSize: 11,
                  gap: 5,
                }}
              />

              {!isAvailable && (
                <div className='player-iteration-drop-down-lock'>
                  <LockIcon style={{ fontSize: 18 }} />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
