import '../../platform.css';
import './settings.css';

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { updateUserConfig } from '../../services/firestore/users';
import { trackEvent } from '../../services/server/analytics/trackEvent';
import { supportedLanguages, translate } from '../../../common/language/translations';

import EditNoteIcon from '@mui/icons-material/EditNote';
import ReplayIcon from '@mui/icons-material/Replay';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { InputField } from '../../components/controls/input/InputField';
import { ViewContainerBackgroundSvg } from '../../../assets/svg/ViewContainerBackgroundSvg';
import { Dialog } from '@mui/material';
import { AccountModal } from '../../components/modals/accounModals/AccountModal';
import { TextButton } from '../../components/controls/buttons/TextButton';
import { IconButton } from '../../components/controls/buttons/IconButton';
import { modalPaperProps } from '../../components/modals/globalModals/GlobalModals';
import { CountryFlagButton } from '../../components/display/images/CountryFlagButton';


interface AccountProps {
  noAccessView?: boolean;
  maintenanceMessage?: string; // if provided, the platform is under maintenance
}

export const Account: React.FC<AccountProps> = ({ noAccessView, maintenanceMessage }) => {

  const { currentUser, logout, resetPassword } = useAuthContext() as AuthContextType;

  const navigate = useNavigate();

  const userConfig = useRecoilValue(userConfigState);

  const [nameString, setNameString] = useState('');
  const [isEditName, setIsEditName] = useState(false);
  const [message, setMessage] = useState<string | undefined>('');

  const isFokusAccount = userConfig?.email.split('@')[0] === 'fokus';
  const [isEditFokusAccessModalOpen, setIsEditFokusAccessModalOpen] = useState(false);


  const handleSignOut = async () => {
    trackEvent('UserLoggedOut', {}, currentUser, 'user');
    await logout();
    navigate('/login');
  };


  const handleResetPassword = async () => {
    if (userConfig) {
      setMessage(undefined);

      trackEvent('PasswordResetRequested', {}, currentUser, 'user');
      await resetPassword(userConfig.email);

      setMessage('emailSent');
    }
  };


  const handleFlagClick = (language: string) => {
    if (userConfig && language !== userConfig.language) {
      updateUserConfig(userConfig.email, 'language', language, 'NewLanguageSelected', currentUser);
    }
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length < 20) {
      setNameString(event.target.value);
    }
  };


  const submitNameEdit = () => {
    if (nameString.length > 0 && userConfig) {
      updateUserConfig(userConfig.email, 'name', nameString, 'UserNameEdited', currentUser);
      setIsEditName(false);
      setNameString('');
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      submitNameEdit();
    }
  };


  const cancelNameEdit = () => {
    setIsEditName(false);
    setNameString('');
  };


  const isNoAccessOrMaintenanceView = noAccessView || maintenanceMessage;


  return (
    <div className='full-size-container'>

      <Dialog
        open={isEditFokusAccessModalOpen}
        onClose={() => setIsEditFokusAccessModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <AccountModal
          user={userConfig ?? undefined}
          closeModal={() => setIsEditFokusAccessModalOpen(false)}
          isFokusAccount={true}
        />
      </Dialog>

      <div className='full-size-container'>
        <ViewContainerBackgroundSvg />
      </div>

      <div className='full-size-container'>
        <div className='account-view-space' />
        <div className='account-view-space' />

        <div className='account-view-name-row'>
          {isFokusAccount && (
            <IconButton
              onClick={() => setIsEditFokusAccessModalOpen(true)}
              icon={<MoreVertIcon style={{ fontSize: 26 }} />}
              size={34}
              style={{ position: 'absolute', left: -60 }}
            />
          )}

          {userConfig?.name ?? ''}

          {!isEditName && !isNoAccessOrMaintenanceView && (
            <IconButton
              onClick={() => setIsEditName(true)}
              icon={<EditNoteIcon style={{ fontSize: 20, marginLeft: 1 }} />}
              size={24}
              style={{ position: 'absolute', right: -46, marginTop: 1 }}
              hoverText={translate('editName', userConfig?.language)}
            />
          )}
        </div>

        <div className='account-view-email-row'>
          {userConfig?.email ?? ''}
        </div>

        <div className='account-view-edit-name-row'>
          {isEditName && (
            <div className='account-view-edit-name-input'>
              <InputField
                value={nameString}
                placeholder={translate('provideName', userConfig?.language)}
                onChange={onChangeSearchField}
                onKeyDown={onKeyDownSearchField}
                resetValue={() => setNameString('')}
              />

              <IconButton
                onClick={cancelNameEdit}
                icon={<CloseIcon style={{ fontSize: 22, marginTop: 1 }} />}
                size={28}
                style={{ position: 'absolute', left: -40, top: 0 }}
                hoverText={translate('cancel', userConfig?.language)}
                hoverStyle={{ right: 34 }}
              />

              {nameString.length > 0 && nameString !== userConfig?.name && (
                <IconButton
                  onClick={submitNameEdit}
                  icon={<CheckCircleOutlineIcon style={{ fontSize: 22, marginTop: 1 }} />}
                  size={28}
                  style={{ position: 'absolute', right: -40, top: 0 }}
                  hoverText={translate('confirmNewName', userConfig?.language)}
                />
              )}
            </div>
          )}
          &nbsp;
        </div>

        {noAccessView && (
          <div className='account-no-access-row'>
            {translate('noAccessInfo', userConfig?.language)}
          </div>
        )}

        {maintenanceMessage && (
          <div className='account-no-access-row'>
            {maintenanceMessage}
          </div>
        )}

        <div className='account-view-space' />

        <div className='account-view-language-section'>
          <div className='account-view-language-title'>
            {translate('language', userConfig?.language)}
          </div>
          <div className='account-view-language-row'>
            {supportedLanguages.map((language) => (
              <CountryFlagButton
                key={language}
                language={language}
                isSelected={userConfig?.language === language}
                onClick={() => handleFlagClick(language)}
                width={32}
              />
            ))}
          </div>
        </div>

        {!isNoAccessOrMaintenanceView && <div className='account-view-space' />}

        <div className='account-view-message-row'>
          {message && <CheckCircleIcon style={{ fontSize: 20, marginTop: -1, marginRight: 7 }} />}
          {message && translate(message, userConfig?.language)}
        </div>

        {!isNoAccessOrMaintenanceView && (
          <TextButton
            onClick={() => handleResetPassword()}
            text={translate('resetPassword', userConfig?.language)}
            icon={<ReplayIcon style={{ fontSize: 22 }} />}
            style={{ width: 220 }}
          />
        )}

        <div className='account-view-space' />

        <TextButton
          onClick={() => handleSignOut()}
          text={translate('logout', userConfig?.language)}
          icon={<LogoutIcon style={{ fontSize: 22, marginRight: -1 }} />}
          style={{ width: 220 }}
        />

        <div className='account-view-space' />
        <div className='account-view-space' />
      </div>

    </div>
  );
};
