import './dropDowns.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';

import Fuse from 'fuse.js';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import KeyIcon from '@mui/icons-material/Key';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../input/InputField';
import { competitionsState } from '../../../recoil/atoms/competitionsState';
import { Competition, Iteration } from '../../../types';

import skill_corner_logo from '../../../../assets/images/skillcorner_logo_dark.png';
import { IconButton } from '../buttons/IconButton';
import { CountryFlag } from '../../display/images/CountryFlag';
import { CompetitionFlag } from '../../display/images/CompetitionFlag';


interface IterationDropDownProps {
  id: string;

  selectedIterations: Iteration[];
  setSelectedIterations: (value: Iteration[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string; // controls scrolling

  anySkillcornerFiltersSelected: boolean;
  setAnyIterationWithoutSkillcornerSelected: (value: boolean) => void;
}


export const IterationDropDown: React.FC<IterationDropDownProps> = ({
  id,

  selectedIterations,
  setSelectedIterations,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,

  anySkillcornerFiltersSelected,
  setAnyIterationWithoutSkillcornerSelected,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);
  const competitions = useRecoilValue(competitionsState);

  const [searchString, setSearchString] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const [fuse, setFuse] = useState<Fuse<Competition> | null>(null);
  const [sortedStaticCompetitions, setSortedStaticCompetitions] = useState<Competition[]>([]);
  const [competitionsToShow, setCompetitionsToShow] = useState<Competition[]>([]);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setCompetitionsToShow(sortedStaticCompetitions);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForCompetitions(searchString);
    }
  };


  const handleSearchForCompetitions = useCallback((currentSearchString: string) => {
    if (!fuse) return [];

    const searchResult = fuse.search(currentSearchString).map(result => result.item);
    setCompetitionsToShow(searchResult);
  }, [fuse]);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (iteration: Iteration, isOptionAlreadySelected: boolean) => {
    let newSelectedIterations = selectedIterations.slice();

    if (isOptionAlreadySelected) {
      newSelectedIterations = newSelectedIterations.filter(item => item.iterationId !== iteration.iterationId);
    }
    else {
      newSelectedIterations.push(iteration);
    }

    setSelectedIterations(newSelectedIterations);

    const anyIterationWithoutSkillcornerSelected = newSelectedIterations.some(selectedIteration => {
      const iterationHasSkillcorner = clubScopes !== null && clubScopes.skillcornerIterationIds.includes(selectedIteration.iterationId.toString());
      return !iterationHasSkillcorner;
    });
    setAnyIterationWithoutSkillcornerSelected(anyIterationWithoutSkillcornerSelected);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const showSelectedCompetitions = () => {
    if (selectedIterations.length === 0 || !(Number(selectedIterations[0].competitionId) in competitions)) return '';

    let display = competitions[Number(selectedIterations[0].competitionId)].name + ' ' + selectedIterations[0].season;

    for (let i = 1; i < selectedIterations.length; i++) {
      display += ', ' + competitions[Number(selectedIterations[i].competitionId)].name + ' ' + selectedIterations[i].season;
    }

    return display;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
      setSearchString('');
      setCompetitionsToShow(sortedStaticCompetitions);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString.length === 0) {
      setCompetitionsToShow(sortedStaticCompetitions);
    }
    else {
      handleSearchForCompetitions(searchString);
    }
  }, [handleSearchForCompetitions, searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const searchKeys = [
      'name',
      'countryName_' + (userConfig?.language ?? 'en'),
    ];

    const options = {
      keys: searchKeys,
      threshold: 0.2,
    };

    const fuseInstance = new Fuse(sortedStaticCompetitions, options);
    setFuse(fuseInstance);
  }, [sortedStaticCompetitions, userConfig?.language]);


  useEffect(() => {
    if (!clubScopes) return;

    const competitionList: Competition[] = Object.values(competitions).filter(
      competition =>
        competition.iterationsWithEventData &&
        competition.iterationsWithEventData.length > 0 &&
        (clubScopes.hasFullCoverage || clubScopes.competitionIds.includes(competition.id))
    );

    // sort leagues based on orderIndex
    competitionList.sort((a, b) => a.orderIndex - b.orderIndex);

    setSortedStaticCompetitions(competitionList);
    setCompetitionsToShow(competitionList);

  }, [clubScopes, competitions]);


  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-button' onClick={() => handleDropDownClick()}>

        {selectedIterations.length > 0 && <div className='drop-down-button-overlay fast-fade-in' />}

        <div className='drop-down-button-text'>

          {selectedIterations.length === 0 && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          )}

          {selectedIterations.length > 0 && isDropDownExpanded && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate('selectedIterations', userConfig?.language)}
            </div>
          )}

          {selectedIterations.length > 0 && !isDropDownExpanded && (
            <div style={{ color: '#000000' }}>
              {showSelectedCompetitions()}
            </div>
          )}

        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-section-with-search' style={{ maxHeight: maxHeight }}>

        <div className='drop-down-with-search-selected-options'>
          {selectedIterations.map((selectedIteration: Iteration) => {
            const competition = competitions[Number(selectedIteration.competitionId)];
            return (
              <div
                className='drop-down-with-search-selected-option'
                key={competition.id + '-' + selectedIteration.iterationId + '-selected'}
              >
                <IconButton
                  icon={<CloseIcon style={{ fontSize: 16 }} />}
                  onClick={() => handleOptionSelect(selectedIteration, true)}
                  size={18}
                  backgroundColorBehindIcon='white'
                  style={{ marginLeft: -2 }}
                />

                <CompetitionFlag
                  competitionId={competition.id}
                  width={16}
                  seasonToShow={selectedIteration.season}
                  containerStyle={{ marginLeft: 3, fontSize: 11 }}
                  isWhiteBackground={true}
                />
              </div>
            );
          })}
        </div>

        <div
          className='drop-down-with-search-input-container'
          style={{ marginTop: 12 + (22 * Math.max(selectedIterations.length, 2)) }}
        >
          <InputField
            ref={inputRef}
            value={searchString}
            placeholder={translate('searchForIteration', userConfig?.language)}
            onChange={onChangeSearchField}
            onKeyDown={onKeyDownSearchField}
            resetValue={() => setSearchString('')}
            isWhiteBackground={true}
          />
        </div>

        {competitionsToShow.length > 0 &&
          competitionsToShow.map((competition: Competition) => {
            return (
              <div
                className='drop-down-option iteration-drop-down-option'
                style={{ marginTop: marginBetweenOptions }}
                key={competition.id}
              >

                {competition.name}

                <CountryFlag
                  countryCode={competition.countryCode}
                  width={15}
                  showCountryName={true}
                  tierToShow={competition.tier}
                  iconToShow={
                    clubScopes && (clubScopes.hasFullCoverage || (clubScopes.competitionIds && clubScopes.competitionIds.includes(competition.id)))
                      ? <KeyIcon style={{ fontSize: 15, color: '#444b57b9' }} />
                      : undefined
                  }
                  containerStyle={{ marginTop: 2, marginLeft: 3, fontSize: 12, gap: 6 }}
                  isWhiteBackground={true}
                />

                {competition.iterationsWithEventData && competition.iterationsWithEventData.map((iteration: Iteration) => {

                  const isSelected = selectedIterations.some(selectedIteration => selectedIteration.iterationId === iteration.iterationId);
                  const selectedIteration: Iteration = { competitionId: Number(competition.id), iterationId: iteration.iterationId, season: iteration.season };

                  const iterationHasSkillcorner = clubScopes !== null
                    && clubScopes.hasSkillcorner
                    && clubScopes.skillcornerIterationIds.includes(iteration.iterationId.toString());

                  const isDisabled = anySkillcornerFiltersSelected && !iterationHasSkillcorner;

                  return (
                    <div
                      className={
                        'drop-down-option'
                        + (isSelected ? ' drop-down-option-selected' : '')
                        + (isDisabled ? ' drop-down-option-disabled' : '')
                      }
                      style={{ marginTop: marginBetweenOptions }}
                      key={competition.id + '-' + iteration.iterationId}
                      onClick={() => !isDisabled ? handleOptionSelect(selectedIteration, isSelected) : null}
                    >
                      <div style={{ position: 'relative', color: isDisabled ? '#00000075' : undefined }}>
                        {iteration.season}
                        {iterationHasSkillcorner && (
                          <div style={{ position: 'absolute', top: 0, width: 15, right: 'calc(50% - ' + (iteration.season.includes('/') ? 39 : 37) + 'px' }}>
                            <img src={skill_corner_logo} alt='SkillCorner' style={{ width: 10, marginTop: 2 }} />
                          </div>
                        )}
                        {isDisabled && (
                          <div style={{ position: 'absolute', top: 1, width: 15, right: 'calc(50% - ' + (iteration.season.includes('/') ? 39 : 37) + 'px' }}>
                            <BlockIcon style={{ fontSize: 13, color: '#00000075' }} />
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}

              </div>
            );
          })}

        {competitionsToShow.length === 0 && (
          <div className='drop-down-with-search-no-search-result'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

      </div>

    </div>
  );
};
