import '../tables.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';
import { useAuthContext, AuthContextType } from '../../../../common/contexts/AuthContext';

import { useMemo } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { PlayerClubIteration, StringToAnyMap } from '../../../types';
import { getSeasonColumns } from './seasonColumns';


interface SeasonTableProps {
  data: PlayerClubIteration[];
  selectedClubIterationId: string | undefined;
  selectedPositionGroupKey: string;
  setSelectedClubIterationId: (index: string) => void;
}


export const SeasonTable: React.FC<SeasonTableProps> = ({
  data,
  selectedClubIterationId,
  selectedPositionGroupKey,
  setSelectedClubIterationId,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const clubScopes = useRecoilValue(clubScopesState);

  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getSeasonColumns(
      selectedPositionGroupKey,
      clubSettings,
      userConfig,
      currentUser,
      userSettings?.seasonStatsToggles ?? {},
      clubScopes?.hasSkillcorner ?? false,
      openTextModal,
      openRoleInfoModal,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentUser,
      selectedPositionGroupKey,
      clubSettings,
      userConfig,
      userSettings,
      clubScopes,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );


  const getExtendedRowClassName = (clubIteration: PlayerClubIteration, index: number) => {
    if (!clubIteration.event_data_available) {
      return 'empty';
    }

    if (clubIteration.club_iteration_id === selectedClubIterationId) {
      return 'selected';
    }

    const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
      ? clubIteration.position_groups[0]
      : selectedPositionGroupKey;

    const playerClubIterationPositionGroupData = clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration];
    if (!playerClubIterationPositionGroupData || !playerClubIterationPositionGroupData.minutes_played) {
      return 'empty';
    }

    return index % 2 === 0 ? 'even' : 'odd';
  };


  const getExtendedCellClassName = (cell: StringToAnyMap) => {
    if (cell.column.isFinalSubMetric) {
      return ' season-table-cell-with-border';
    }
    return '';
  };


  return (
    <div
      {...getTableProps()}
      className='table-container season-table'
    >

      <div className='table-top-level-header-group'>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <div
              key={key}
              {...restHeaderGroupProps}
            >
              {headerGroup.headers.map((column: StringToAnyMap) => {
                const { key: columnKey, ...restColumnProps } = column.getHeaderProps();
                return (
                  <div
                    key={columnKey}
                    {...restColumnProps}
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div
        {...getTableBodyProps()}
        className='season-table-body'>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={'season-table-body-row season-table-body-row-' + getExtendedRowClassName(row.original, i)}
              onClick={() => getExtendedRowClassName(row.original, i) !== 'empty' && row.original.club_iteration_id
                ? setSelectedClubIterationId(row.original.club_iteration_id)
                : null
              }
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={'season-table-body-cell season-table-body-cell-' + getExtendedRowClassName(row.original, i) + getExtendedCellClassName(cell)}
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
