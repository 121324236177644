import './input.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, nb } from 'date-fns/locale';


interface DateInputFieldProps {
  value: Date | null;
  placeholder?: string;

  onChange: (date: Date | null) => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  disabled?: boolean;
  showDisabled?: boolean;

  height?: number;
  width?: number;
  isWhiteBackground?: boolean;

  minDate?: Date | 'today-10y' | 'today-40y';
  maxDate?: 'minDate+10y' | 'today+10y' | 'today-10y';

  popperPlacement?:
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end'
  | undefined;
}

export const DateInputField: React.FC<DateInputFieldProps> = ({
  value,
  placeholder,

  onChange,
  onKeyDown,
  disabled,
  showDisabled,

  height = 24,
  width = 150,
  isWhiteBackground = false,

  minDate,
  maxDate,

  popperPlacement = 'bottom',
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const dateFormat = 'dd.MM.yyyy';
  const locale = userConfig && userConfig.language === 'no'
    ? nb
    : enGB;


  const today = new Date();

  const minPossibleDate = minDate === 'today-10y'
    ? new Date(today.getFullYear() - 10, today.getMonth(), today.getDate())
    : minDate === 'today-40y'
      ? new Date(today.getFullYear() - 40, today.getMonth(), today.getDate())
      : minDate;

  const maxPossibleDate = maxDate === 'minDate+10y' && minPossibleDate
    ? new Date(minPossibleDate.getFullYear() + 10, minPossibleDate.getMonth(), minPossibleDate.getDate())
    : maxDate === 'today+10y'
      ? new Date(today.getFullYear() + 10, today.getMonth(), today.getDate())
      : maxDate === 'today-10y'
        ? new Date(today.getFullYear() - 10, today.getMonth(), today.getDate())
        : undefined;


  const whiteBackgroundBoxShadow = '0px 0px 2px 1px #15171e35';
  const darkBackgroundBoxShadow = '0px 0px 2px 1px #15171e70';

  document.documentElement.style.setProperty('--date-input-field-height', height + 'px');


  return (
    <div
      className='date-input-field-container'
      style={{
        height: height,
        width: width ?? '100%',
        boxShadow: isWhiteBackground ? whiteBackgroundBoxShadow : darkBackgroundBoxShadow,
      }}
    >
      <DatePicker
        className={'date-input-field' + (disabled && showDisabled ? ' date-input-field-disabled' : '')}
        selected={value ? new Date(value) : null}
        placeholderText={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        locale={locale}
        dateFormat={dateFormat}
        popperPlacement={popperPlacement}
        disabled={disabled}

        minDate={minPossibleDate}
        maxDate={maxPossibleDate}
        showYearDropdown={minPossibleDate !== undefined && maxPossibleDate !== undefined}
        scrollableYearDropdown={minPossibleDate !== undefined && maxPossibleDate !== undefined}
        yearDropdownItemNumber={minPossibleDate !== undefined && maxPossibleDate !== undefined ? 100 : undefined}
        showMonthDropdown={minPossibleDate !== undefined && maxPossibleDate !== undefined}
      />
    </div>
  );
};
