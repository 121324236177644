import { StringToAnyMap } from '../types';


export const getFiltersUsed = (
  queryOptions: StringToAnyMap,
): string[] => {

  const filtersUsed: string[] = [];

  // common filters
  if (queryOptions.maxAge) { filtersUsed.push('maxAge'); }
  if (queryOptions.positionGroup) { filtersUsed.push('roles'); }
  if (queryOptions.primaryPositions) { filtersUsed.push('primaryPositions'); }
  if (queryOptions.countryCodes) { filtersUsed.push('nationalities'); }
  if (queryOptions.maxContractLength) { filtersUsed.push('maxContract'); }

  // player overview and alert filters
  if (queryOptions.minConfidence) { filtersUsed.push('minConfidence'); }
  if (queryOptions.competitions) { filtersUsed.push('leagues'); }
  if (queryOptions.form) { filtersUsed.push('form'); }
  if (queryOptions.playingTime) { filtersUsed.push('playingTime'); }

  // player overview and season scouting filters
  if (queryOptions.clubs) { filtersUsed.push('clubs'); }

  // alert filters
  if (queryOptions.indexRequirements) { filtersUsed.push('indexRequirements'); }
  if (queryOptions.isDebutToggled) { filtersUsed.push('isDebutToggled'); }
  if (queryOptions.logisticTeamIds) { filtersUsed.push('logisticTeams'); }

  // season scouting filters
  if (queryOptions.minMinutesPlayed || queryOptions.minMinutesPlayedPercentage) { filtersUsed.push('minMinutes'); }
  if (queryOptions.iterations) { filtersUsed.push('iterations'); }
  if (queryOptions.ratingRequirements) { filtersUsed.push('ratings'); }

  if (filtersUsed.length === 0) { filtersUsed.push('noFilters'); }

  return filtersUsed;
};
