import { monthsToContractKeys } from '../../../static/propertyValues';
import { Alert, RatingRequirement } from '../../../types';
import { PlayerOverviewWithAlertProperties } from './Alerts';
import { AlertsFilter, AlertsState, initialAlertsState } from './alertsState';


export type AlertsAction =
  | { type: 'RESET_ALERT_STATE'; }
  | {
    type: 'SET_SEARCH_RESULT';
    payload: {
      tableData: PlayerOverviewWithAlertProperties[],
      totalHits: number,
      currentPage: number,
      isEmptySearchResult: boolean,
    }
  }
  | { type: 'SET_EMPTY_TABLE_DATA'; }
  | { type: 'SET_IS_EMPTY_SEARCH_RESULT'; payload: boolean; }
  | { type: 'SET_FILTER'; payload: Partial<AlertsState> }
  | { type: 'SET_FILTER_EXPANSION'; payload: AlertsFilter; }
  | { type: 'RESET_FILTER_EXPANSION'; }
  | { type: 'SET_SELECTED_ORDER_BY'; payload: string[]; }
  | { type: 'SET_SELECTED_INDEXES'; payload: RatingRequirement[]; }
  | { type: 'SET_IS_LOADING'; payload: boolean; }
  | { type: 'SET_SUBMIT_BUTTON_DISABLED'; payload: boolean; }
  | { type: 'SET_ERROR_MESSAGE'; payload: string | undefined; }

  | { type: 'SET_ALERT_NAME'; payload: string; }
  | { type: 'SET_SHOW_ALERT_NAME_REQUIRED'; payload: boolean; }
  | { type: 'SET_NEW_ALERT_SELECTED'; payload: boolean; }
  | { type: 'SET_EXISTING_ALERT_SELECTED'; payload: Alert | undefined; }
  | { type: 'SET_FILTERS'; payload: Alert | undefined; }
  | { type: 'SET_EXISTING_ALERT_WAS_JUST_CLICKED'; payload: boolean; }
  | { type: 'SET_IS_EDIT_ALERT_VIEW'; payload: boolean; }
  | { type: 'SET_SHOW_EDIT_ALERT_VIEW'; payload: boolean; }
  | { type: 'SET_IS_EXCLUDED_PLAYERS_VIEW'; payload: boolean; }
  | { type: 'SET_SHOW_EXCLUDED_PLAYERS_VIEW'; payload: boolean; }
  | { type: 'SET_PLAYER_WAS_JUST_EXCLUDED'; payload: boolean; }
  | { type: 'SET_PLAYER_EXCLUSION_WAS_JUST_UNDONE'; payload: boolean; }
  | { type: 'SET_IS_RESET_EXCLUDED_PLAYERS_TOGGLED'; payload: boolean; }


const alertsReducer = (state: AlertsState, action: AlertsAction): AlertsState => {

  switch (action.type) {

    case 'RESET_ALERT_STATE':
      return initialAlertsState;

    case 'SET_SEARCH_RESULT':
      return { ...state, ...action.payload };

    case 'SET_EMPTY_TABLE_DATA':
      return { ...state, tableData: [] };

    case 'SET_IS_EMPTY_SEARCH_RESULT':
      return { ...state, isEmptySearchResult: action.payload };

    case 'SET_FILTER':
      return { ...state, ...action.payload };

    case 'SET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: action.payload, previousFilterExpanded: action.payload };

    case 'RESET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: undefined };

    case 'SET_SELECTED_ORDER_BY':
      return { ...state, selectedOrderBy: action.payload };

    case 'SET_SELECTED_INDEXES':
      return { ...state, selectedIndexes: action.payload };

    case 'SET_IS_LOADING':
      return { ...state, isLoading: action.payload };

    case 'SET_SUBMIT_BUTTON_DISABLED':
      return { ...state, submitButtonDisabled: action.payload };

    case 'SET_ERROR_MESSAGE':
      return { ...state, errorMessage: action.payload };


    case 'SET_ALERT_NAME':
      return { ...state, alertName: action.payload };

    case 'SET_SHOW_ALERT_NAME_REQUIRED':
      return { ...state, showAlertNameRequired: action.payload };

    case 'SET_NEW_ALERT_SELECTED':
      return { ...state, newAlertSelected: action.payload };

    case 'SET_EXISTING_ALERT_SELECTED':
      return { ...state, existingAlertSelected: action.payload };

    case 'SET_FILTERS':
      return getState_SET_FILTERS(action.payload, state);

    case 'SET_EXISTING_ALERT_WAS_JUST_CLICKED':
      return { ...state, existingAlertSelectedWasJustClicked: action.payload };

    case 'SET_IS_EDIT_ALERT_VIEW':
      return { ...state, isEditAlertView: action.payload };

    case 'SET_SHOW_EDIT_ALERT_VIEW':
      return { ...state, showEditAlertView: action.payload };

    case 'SET_IS_EXCLUDED_PLAYERS_VIEW':
      return { ...state, isExcludedPlayersView: action.payload };

    case 'SET_SHOW_EXCLUDED_PLAYERS_VIEW':
      return { ...state, showExcludedPlayersView: action.payload };

    case 'SET_PLAYER_WAS_JUST_EXCLUDED':
      return { ...state, playerWasJustExcluded: action.payload };

    case 'SET_PLAYER_EXCLUSION_WAS_JUST_UNDONE':
      return { ...state, playerExclusionWasJustUndone: action.payload };

    case 'SET_IS_RESET_EXCLUDED_PLAYERS_TOGGLED':
      return { ...state, isResetExcludedPlayersToggled: action.payload };


    default:
      return state;
  }
};


export const getState_SET_FILTERS = (alert: Alert | undefined, state: AlertsState): AlertsState => {
  return {
    ...state,
    alertName: alert?.name ?? '',
    selectedRoles: alert?.selectedRoles ?? [],
    selectedLogisticTeams: alert?.filters.logisticTeamIds ?? [],
    selectedMinConfidence: alert?.filters.minConfidence ? [(alert.filters.minConfidence * 100).toString()] : [],
    selectedMaxAge: alert?.filters.maxAge ? [alert.filters.maxAge.toString()] : [],
    selectedPositions: alert?.filters.primaryPositions ?? [],
    selectedLeagues: alert?.filters.competitions?.map(id => id.toString()) ?? [],
    selectedOrderBy: alert?.filters.sortBy ? [alert.filters.sortBy] : ['skillIndex'],
    selectedIndexes: alert?.filters.indexRequirements ?? [],
    selectedForm: alert?.filters.form ?? [],
    selectedPlayingTime: alert?.filters.playingTime ?? [],
    selectedNationalities: alert?.filters.countryCodes ?? [],
    selectedContractStatus: alert?.filters.maxContractLength ? [monthsToContractKeys[alert.filters.maxContractLength]] : [],
    isContractStatusToggled: alert?.filters.includeUnknownContracts ?? false,
    errorMessage: undefined,
    showAlertNameRequired: false,
    isResetExcludedPlayersToggled: false,
  };
};


export default alertsReducer;
