import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { playerTeamDataSelector } from '../selectors/playerTeamDataSelector';
import { playerSquadDataSelector } from '../selectors/playerSquadDataSelector';
import { playerOverviewsState } from '../atoms/playerOverviewsState';
import { playerCareersState } from '../atoms/playerCareersState';
import { playerDataLastRefreshState } from '../atoms/playerDataLastRefreshState';
import { getPlayerOverviews } from '../../services/server/application/playerOverviews';
import { getPlayerCareers } from '../../services/server/application/playerCareers';


export const useSyncPlayerData = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const playerTeamData = useRecoilValue(playerTeamDataSelector);
  const playerSquadData = useRecoilValue(playerSquadDataSelector);

  const [playerOverviews, setPlayerOverviews] = useRecoilState(playerOverviewsState);
  const [playerCareers, setPlayerCareers] = useRecoilState(playerCareersState);

  const [playerDataLastRefresh, setPlayerDataLastRefresh] = useRecoilState(playerDataLastRefreshState);


  useEffect(() => {

    const syncPlayerData = async () => {

      if (!currentUser || !playerTeamData || !playerSquadData) return;

      const playerIdsToFetchOverviews = new Set<number>();
      const playerIdsToFetchCareers = new Set<number>();

      Object.keys(playerTeamData).forEach((playerId) => {
        const numberId = Number(playerId);
        if (!isNaN(numberId)) {
          if (!(playerId in playerOverviews)) {
            playerIdsToFetchOverviews.add(numberId);
          }
          if (!(playerId in playerCareers) && playerTeamData[playerId].currentTeam === 'ownTeam') {
            playerIdsToFetchCareers.add(numberId);
          }
        }
      });

      Object.keys(playerSquadData).forEach((playerId) => {
        const numberId = Number(playerId);
        if (!isNaN(numberId) && !(playerId in playerOverviews)) {
          playerIdsToFetchOverviews.add(numberId);
        }
      });

      if (playerIdsToFetchOverviews.size > 0) {
        const newPlayerOverviews = await getPlayerOverviews(Array.from(playerIdsToFetchOverviews), currentUser);
        if (newPlayerOverviews) {
          setPlayerOverviews((currentPlayerOverviews) => ({ ...currentPlayerOverviews, ...newPlayerOverviews }));
        }
      }

      if (playerIdsToFetchCareers.size > 0) {
        const newPlayerCareer = await getPlayerCareers(Array.from(playerIdsToFetchCareers), currentUser);
        if (newPlayerCareer) {
          setPlayerCareers((currentPlayerCareers) => ({ ...currentPlayerCareers, ...newPlayerCareer }));
        }
      }

      if (playerDataLastRefresh === null) {
        setPlayerDataLastRefresh(new Date().toISOString().split('T')[0]);
      }
    };

    syncPlayerData();

  }, [currentUser, playerTeamData, playerSquadData]); // eslint-disable-line react-hooks/exhaustive-deps

};
