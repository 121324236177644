import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';

import { countryCodeToCountryInfo } from '../../../static/countries';
import { Club } from '../../../types';


interface ClubLogoProps {
  club?: Club | undefined;
  clubLogoUrl?: string;
  clubName?: string;
  size: number;

  showClubName?: boolean;
  competitionIdToShow?: number | string;
  minutesPlayedToShow?: number;
  showHyphenIfEmpty?: boolean;

  containerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  isWhiteBackground?: boolean;
  smallFontSize?: number;
  smallFontSizeThreshold?: number; // if club/competition is longer than this, the font size will be smallFontSize
  smallFontSizeStepSize?: number; // if club/competition is smallFontSizeStepSize longer than smallFontSizeThreshold, the font size will be smallFontSize - 1
  maxInfoWidth?: number;
}


export const ClubLogo: React.FC<ClubLogoProps> = ({
  club,
  clubLogoUrl,
  clubName,
  size,

  showClubName,
  competitionIdToShow,
  minutesPlayedToShow,
  showHyphenIfEmpty,

  containerStyle,
  imageStyle,
  isWhiteBackground,
  smallFontSize,
  smallFontSizeThreshold,
  smallFontSizeStepSize,
  maxInfoWidth,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);


  const logoIsFlag = club && club.is_national_team && club.country_code in countryCodeToCountryInfo;

  const clubLogoUrlToUse = clubLogoUrl ?? (
    logoIsFlag
      ? countryCodeToCountryInfo[club.country_code].flagUrl
      : club?.logo_url
  );

  const clubNameToUse = clubName ?? (
    logoIsFlag
      ? countryCodeToCountryInfo[club.country_code].name[userConfig?.language ?? 'en'] + (club?.name ? ' ' + club.name : '')
      : club?.name
  );

  const shouldOverrideFontSize = smallFontSize !== undefined && smallFontSizeThreshold !== undefined;

  const clubNameFontSize = shouldOverrideFontSize && clubNameToUse && clubNameToUse.length > smallFontSizeThreshold
    ? (smallFontSizeStepSize !== undefined && clubNameToUse.length > smallFontSizeThreshold + smallFontSizeStepSize ? smallFontSize - 1 : smallFontSize)
    : undefined;

  const competitionName = competitionIdToShow !== undefined && competitionIdToShow !== null ? competitions[Number(competitionIdToShow)].name : undefined;
  const competitionNameFontSize = shouldOverrideFontSize && competitionName && competitionName.length > smallFontSizeThreshold
    ? (smallFontSizeStepSize !== undefined && competitionName.length > smallFontSizeThreshold + smallFontSizeStepSize ? smallFontSize - 1 : smallFontSize)
    : undefined;


  const imageClassName = 'club-logo' + (logoIsFlag ? (' flag-image' + (isWhiteBackground ? '-whiteBackground' : '')) : '');

  const showInfo = (showClubName && clubNameToUse) || competitionName || minutesPlayedToShow !== undefined;

  return (
    <div
      className='image-row-container'
      style={{
        ...containerStyle,
      }}
    >
      {clubLogoUrlToUse && (
        <div
          className='club-logo-image-container'
          style={{
            width: size,
            height: size,
          }}
        >
          <img
            src={clubLogoUrlToUse}
            alt={''}
            className={imageClassName}
            style={{
              ...imageStyle,
            }}
            draggable={false}
          />
        </div>
      )}

      {showInfo && (
        <div className='club-logo-info-column' style={{ maxWidth: maxInfoWidth }}>
          <div
            className='club-logo-info-row'
            style={{ fontSize: clubNameFontSize }}
          >
            {showClubName && clubNameToUse && <div>{clubNameToUse}</div>}
            {club && minutesPlayedToShow !== undefined && <div style={{ fontSize: 10 }}>{minutesPlayedToShow + ' min'}</div>}
          </div>

          {competitionName && (
            <div
              className='club-logo-info-row'
              style={{ fontSize: competitionNameFontSize }}
            >
              {competitionName}
            </div>
          )}
        </div>
      )}

      {showHyphenIfEmpty && !club && (competitionIdToShow === undefined || competitionIdToShow === null) && <div>-</div>}
    </div>
  );
};
