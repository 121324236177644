import './../mobileTables.css';

import { CellProps } from 'react-table';
import { StringToAnyMap, RoleConfigMap, PlayerOverview, RoleConfig } from '../../../../types';
import { getDateLocaleFormat, getDisplayPlayerName, getDisplayPositions } from '../../../../utils/playerUtils';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { translate } from '../../../../../common/language/translations';
import { getFormIcon, getPlayingTimeIcon } from '../../../../utils/iconUtils';
import { ReactNode } from 'react';
import { getIndexColor } from '../../../../utils/colorUtils';
import { metricToDisplayInfo } from '../../../../static/playerMetrics';
import { EventPropertiesMap } from '../../../../services/server/analytics/events';
import { PlayerImage } from '../../../../components/display/images/PlayerImage';
import { CountryFlag } from '../../../../components/display/images/CountryFlag';
import { ClubLogo } from '../../../../components/display/images/ClubLogo';
import { ProgressCircle } from '../../../../components/display/visualization/ProgressCircle';
import { IconButton } from '../../../../components/controls/buttons/IconButton';


const renderInfoCell = (positionGroupKey: string) => {
  const InfoCellRenderer = ({ row }: StringToAnyMap) => {
    return <InfoCell playerOverview={row.original} positionGroupKey={positionGroupKey} />;
  };

  InfoCellRenderer.displayName = 'InfoCellRenderer';
  return InfoCellRenderer;
};


const InfoCell: React.FC<{ playerOverview: PlayerOverview, positionGroupKey: string }> = ({ playerOverview, positionGroupKey }) => {

  const confidence = playerOverview.position_group_stats?.[positionGroupKey]?.confidence;

  const displayPlayerName = getDisplayPlayerName(playerOverview.fullname, 16);

  return (
    <div className='mobile-player-table-info-cell'>

      <PlayerImage
        src={playerOverview.image_url}
        size={28}
        containerStyle={{ marginLeft: 3, marginRight: 7, marginTop: 1 }}
        isSearchTableComponent={true}
      />

      <div className='mobile-player-table-info-cell-column'>
        <div className='mobile-player-table-info-cell-row' style={{ fontSize: displayPlayerName.length > 16 ? 10 : undefined }}>
          {displayPlayerName}

          <CountryFlag
            countryCode={playerOverview.country_code}
            width={12}
            containerStyle={{ marginLeft: 5, marginBottom: -1 }}
          />
        </div>

        {playerOverview.club && (
          <ClubLogo
            club={playerOverview.club}
            size={12}
            showClubName={true}
            containerStyle={{ justifyContent: 'left', color: '#ffffffdd', fontSize: 10, gap: 3, marginLeft: -1 }}
            smallFontSize={8}
            smallFontSizeThreshold={16}
            smallFontSizeStepSize={6}
          />
        )}
      </div>

      {confidence !== undefined && confidence !== null && (
        <div className='mobile-player-table-info-cell-precision-container'>
          <ProgressCircle
            size={18}
            progress={confidence * 100}
            value={Math.round(confidence * 100)}
            trailColor='#ffffff44'
            color={getIndexColor(confidence * 10, 53)}
            fontSize={8}
          />
        </div>
      )}
    </div>
  );
};


const TextCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='mobile-player-table-cell-centered'>{value ?? '-'}</div>;
};


const renderIndexCell = (property: string, positionGroup: string) => {
  const IndexCellRenderer = ({ row }: StringToAnyMap) => {
    return <IndexCell playerOverview={row.original} property={property} positionGroup={positionGroup} />;
  };

  IndexCellRenderer.displayName = 'IndexCellRenderer';
  return IndexCellRenderer;
};

const IndexCell: React.FC<{ playerOverview: PlayerOverview, property: string, positionGroup: string }> = ({
  playerOverview,
  property,
  positionGroup,
}) => {

  let value: number | string | undefined = property === 'skill_index' || property === 'confidence'
    ? playerOverview.position_group_stats?.[positionGroup]?.[property]
    : playerOverview.position_group_stats?.[positionGroup]?.custom_indexes[property + '_index'];

  // not entirely sure if both checks are necessary
  value = value !== undefined && value !== null
    ? property === 'confidence'
      ? (Math.round(value * 100))
      : (Math.round(value * 10) / 10)
    : '-';

  return (
    <div className='mobile-player-table-cell-centered'>
      {value}
    </div>
  );
};


const renderContractCell = () => {
  const ContractCellRenderer = ({ row }: StringToAnyMap) => {
    return <ContractCell playerOverview={row.original} />;
  };

  ContractCellRenderer.displayName = 'ContractCellRenderer';
  return ContractCellRenderer;
};

const ContractCell: React.FC<{ playerOverview: PlayerOverview }> = ({ playerOverview }) => {
  return (
    <div className='mobile-player-table-cell-centered'>
      {getDateLocaleFormat(playerOverview.contract_expiration)}
    </div>
  );
};


const renderPositionCell = (language: string) => {
  const PositionCellRenderer = ({ row }: StringToAnyMap) => {
    return <PositionCell playerOverview={row.original} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};

const PositionCell: React.FC<{
  playerOverview: PlayerOverview;
  language: string;
}> = ({ playerOverview, language }) => {

  let primaryPositions = [];
  let secondaryPositions = [];

  if (playerOverview.event_data_exist) {
    primaryPositions = playerOverview.primary_positions ?? [];
    secondaryPositions = playerOverview.secondary_positions ?? [];
  }

  else {
    // if FAPI positions, we use the first position as primary and the second position as secondary (whether it's primary or secondary)
    const allPositions = (playerOverview.primary_positions ?? []).concat(playerOverview.secondary_positions ?? []);
    primaryPositions = allPositions.slice(0, 1);
    secondaryPositions = allPositions.slice(1);
  }

  const primaryPositionString = getDisplayPositions(primaryPositions, language);
  const secondaryPositionString = getDisplayPositions(secondaryPositions, language);

  const primaryFontSize = primaryPositions.length > 2 ? 9 : undefined;
  const secondaryFontSize = secondaryPositions.length > 2 ? 9 : 10;

  return (
    <div className='mobile-player-table-position-cell' style={{ fontSize: primaryFontSize }}>
      {primaryPositionString ?? '-'}

      {secondaryPositionString && (
        <div style={{ fontSize: secondaryFontSize, color: '#ffffffdd' }}>
          {secondaryPositionString}
        </div>
      )}
    </div>
  );
};


const FormCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const formIcon = row.original.event_data_available
    ? getFormIcon(row.original.form_status, 16, 'light', 53)
    : undefined;

  return (
    <div className='mobile-player-table-cell-centered'>
      {formIcon}
    </div>
  );
};


const PlayingTimeCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const playingTimeIcon = row.original.event_data_exist
    ? getPlayingTimeIcon(row.original.availability_status, 16, 'light')
    : undefined;

  return (
    <div className='mobile-player-table-cell-centered'>
      {playingTimeIcon}
    </div>
  );
};


export const getMobilePlayerColumns = (
  widthUnit: number,
  language: string,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  selectedRoles: string[],
  roleConfigs: RoleConfigMap,
  selectedOrderBy: string | undefined,
  secondaryClubColor: string,
  openTextModal: (title: string, text: string, eventProperties: EventPropertiesMap['InfoModalOpened']) => void,
  openRoleInfoModal: (roleConfig: RoleConfig) => void,
  handleChangeCurrentChapter: (isIncrement: boolean) => void,
) => {

  const positionGroupKey = selectedRoles.length > 0 ? roleConfigs[selectedRoles[0]].positionGroup : 'overall';

  const columns: StringToAnyMap[] = [
    {
      Header:
        <div className='mobile-player-table-header-cell-sticky'>

          {totalHits + (totalHits >= 10000 ? '+ ' : ' ') + translate(totalHits === 1 ? 'player' : 'players', language, true)}

          {totalHits > hitsPerChapter && (
            <div className='mobile-player-table-cell'>
              <div className='mobile-player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter + 1) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              <div className='mobile-player-table-pagination-section'>
                <IconButton
                  icon={(
                    <KeyboardArrowLeftIcon
                      style={{ fontSize: 16, color: currentChapter > 0 ? '#ffffffcc' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => currentChapter > 0 ? handleChangeCurrentChapter(false) : null}
                  size={20}
                  isMobile={true}
                />

                <div className='mobile-player-table-current-chapter'>
                  {currentChapter + 1}
                </div>

                <IconButton
                  icon={(
                    <KeyboardArrowRightIcon
                      style={{ fontSize: 16, color: currentChapter < Math.floor(totalHits / hitsPerChapter) ? '#ffffffcc' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => currentChapter < Math.floor(totalHits / hitsPerChapter) ? handleChangeCurrentChapter(true) : null}
                  size={20}
                  isMobile={true}
                />
              </div>
            </div>
          )}
        </div>,
      id: 'player',
      accessor: 'id',
      sticky: 'left',
      Cell: renderInfoCell(positionGroupKey),
      width: 200,
    },
    {
      Header:
        <div className='mobile-player-table-header-cell-normal'>
          {translate('age', language)}
        </div>,
      id: 'age',
      accessor: 'age',
      Cell: TextCell,
      width: 50 * widthUnit,
      minWidth: 50,
    },
    {
      Header:
        <div className='mobile-player-table-header-cell-normal'>
          {translate('contract', language)}
        </div>,
      id: 'contract',
      accessor: 'contract_expiration',
      Cell: renderContractCell(),
      width: 80 * widthUnit,
      minWidth: 80,
    },
    {
      Header:
        <div className='mobile-player-table-header-cell-normal'>
          {translate('position', language)}
        </div>,
      id: 'position',
      accessor: 'position',
      Cell: renderPositionCell(language),
      width: 80 * widthUnit,
      minWidth: 80,
    },
    {
      Header:
        <div
          className='mobile-player-table-header-cell-normal'
          onClick={() => openTextModal(
            translate('form', language),
            translate('formInfo', language),
            { infoType: 'metric', titleKey: 'form' }
          )}
        >
          {translate('form', language)}
        </div>,
      id: 'form',
      accessor: 'form',
      Cell: FormCell,
      width: 60 * widthUnit,
      minWidth: 60,
    },
    {
      Header:
        <div
          className='mobile-player-table-header-cell-normal mobile-player-table-cell-with-border'
          style={{ fontSize: language === 'en' ? 10 : undefined }}
          onClick={() => openTextModal(
            translate('playingTime', language),
            translate('playingTimeInfo', language),
            { infoType: 'metric', titleKey: 'playingTime' }
          )}
        >
          {translate('playingTime', language)}
        </div>,
      id: 'playingTime',
      accessor: 'playingTime',
      Cell: PlayingTimeCell,
      width: 60 * widthUnit,
      minWidth: 60,
      isFinalSubMetric: true,
    },
    {
      Header:
        <div
          className={'mobile-player-table-header-cell-normal' + (selectedRoles.length === 0 ? ' mobile-player-table-cell-with-border' : '')}
          style={{
            color: selectedOrderBy === 'skillIndex' ? secondaryClubColor : undefined,
            fontWeight: selectedOrderBy === 'skillIndex' ? 600 : undefined,
          }}
        >
          <div
            onClick={() => openTextModal(
              metricToDisplayInfo['skill_index'].name[language],
              metricToDisplayInfo['skill_index'].description[language],
              { infoType: 'metric', titleKey: 'skill_index' }
            )}
          >
            {metricToDisplayInfo['skill_index'].name[language]}
          </div>
        </div>,
      id: 'skill_index',
      accessor: 'skill_index',
      Cell: renderIndexCell('skill_index', positionGroupKey),
      width: 80 * widthUnit,
      minWidth: 80,
      isFinalSubMetric: selectedRoles.length === 0,
    },
  ];


  selectedRoles.forEach((roleId, index) => {

    const roleName = roleConfigs[roleId].name;
    const width = getRoleColumnWidth(roleName);

    columns.push(
      {
        Header:
          <div
            className={'mobile-player-table-header-cell-normal' + (index === selectedRoles.length - 1 ? ' mobile-player-table-cell-with-border' : '')}
            style={{
              color: selectedOrderBy === roleId ? secondaryClubColor : undefined,
              fontWeight: selectedOrderBy === roleId ? 600 : undefined,
            }}
          >
            <div
              onClick={() => openRoleInfoModal(roleConfigs[roleId])}
            >
              {roleName}
            </div>
          </div>,
        accessor: roleId,
        Cell: renderIndexCell(roleId, positionGroupKey),
        width: width * widthUnit,
        minWidth: width,
        isFinalSubMetric: index === selectedRoles.length - 1,
      }
    );
  });

  return columns;
};


const getRoleColumnWidth = (roleName: string) => {
  return Math.min(Math.max(55, roleName.length * 7), 90);
};


export const getMobilePlayerTableDynamicColumnsMinWidth = (
  selectedRoles: string[] | undefined,
  roleConfigs: RoleConfigMap | undefined,
) => {

  const baseColumnsMinWIdth = 410 + 5;

  const roleColumnsMinWidth = selectedRoles && roleConfigs
    ? selectedRoles.reduce((acc, role) => acc + getRoleColumnWidth(roleConfigs[role].name), 0)
    : 0;

  return baseColumnsMinWIdth + roleColumnsMinWidth;
};
