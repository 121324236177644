import { SearchPlayerOverviewsResponse } from '../../../services/server/application/playerOverviews';
import { playerTableChapterSize } from '../Scouting';
import { initialPlayerFindingState, PlayerFindingFilter, PlayerFindingState } from './playerFindingState';


export type PlayerFindingAction =
  | {
    type: 'SET_SEARCH_RESULT';
    payload: {
      result: SearchPlayerOverviewsResponse,
      isNewSearch: boolean,
      nextChapter: number | undefined,
    }
  }
  | { type: 'RESET_STATE'; }
  | { type: 'SET_IS_LOADING'; }
  | { type: 'SET_EMPTY_TABLE_DATA'; }

  | { type: 'SET_FILTER'; payload: Partial<PlayerFindingState> }
  | { type: 'SET_FILTER_EXPANSION'; payload: PlayerFindingFilter; }
  | { type: 'RESET_FILTER_EXPANSION'; }


const playerFindingReducer = (state: PlayerFindingState, action: PlayerFindingAction): PlayerFindingState => {

  switch (action.type) {

    case 'SET_SEARCH_RESULT':
      return getState_SET_SEARCH_RESULT(action.payload, state);

    case 'RESET_STATE':
      return initialPlayerFindingState;

    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };

    case 'SET_EMPTY_TABLE_DATA':
      return { ...state, tableData: [] };


    case 'SET_FILTER':
      return { ...state, ...action.payload };

    case 'SET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: action.payload, previousFilterExpanded: action.payload };

    case 'RESET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: undefined };


    default:
      return state;
  }
};


const getState_SET_SEARCH_RESULT = (
  payload: {
    result: SearchPlayerOverviewsResponse,
    isNewSearch: boolean,
    nextChapter: number | undefined,
  },
  state: PlayerFindingState
) => {

  const isEmptySearchResult = payload.result.total_hits === 0 || (payload.isNewSearch && payload.result.players.length === 0);
  const isFirstPageOfChapter = payload.isNewSearch || payload.nextChapter !== undefined;

  return {
    ...state,

    tableData: isEmptySearchResult
      ? []
      : isFirstPageOfChapter ? payload.result.players : [...state.tableData, ...payload.result.players],

    totalHits: isEmptySearchResult
      ? 0
      : payload.result.total_hits,

    currentModuloPage: isEmptySearchResult
      ? 0
      : isFirstPageOfChapter ? 1 : (payload.result.current_page - (state.currentChapter * playerTableChapterSize)),

    currentChapter: isEmptySearchResult
      ? 0
      : payload.isNewSearch ? 0 : (payload.nextChapter !== undefined ? payload.nextChapter : state.currentChapter),

    isEmptySearchResult: isEmptySearchResult,
    isLoading: false,

    // set current filters applied to the search result to the ones currenlty selected
    currentMinAge: state.selectedMinAge,
    currentMaxAge: state.selectedMaxAge,
    currentNationalities: state.selectedNationalities,
    currentLeagues: state.selectedLeagues,
    currentClubs: state.selectedClubs,
  };
};


export default playerFindingReducer;
