import { countryCodeToCurrencyEuroMultiplier } from '../static/currencies';


// Get display value of values representing economic values
export const getDisplayEconomicValue = (value: number | string, includeSuffix?: boolean) => {

  value = String(value);
  value = value.replaceAll('.', '');
  value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  const suffix = includeSuffix ? ',-' : '';

  return value + suffix;
};


// Euro to NOK
export const getEuroToCurrency = (value: number | string | undefined, clubCountryCode: string) => {
  if (value === undefined || value === null) return undefined;

  const numberValue = Number(value);
  const multiplier = countryCodeToCurrencyEuroMultiplier[clubCountryCode] ?? 1;

  const currencyValue = numberValue * multiplier;

  return getRoundedEconomicValue(currencyValue);
};


// Get a rounded value of an economic value
export const getRoundedEconomicValue = (value: number | string | undefined) => {
  if (value === undefined) return undefined;

  const numberValue = Number(value);
  if (isNaN(numberValue)) return undefined;

  let nearest = 1;
  if (numberValue >= 1e9) {
    nearest = 1e7;
  }
  else if (numberValue >= 5e8) {
    nearest = 5e6;
  }
  else if (numberValue >= 1e8) {
    nearest = 1e6;
  }
  else if (numberValue >= 5e7) {
    nearest = 5e5;
  }
  else if (numberValue >= 1e7) {
    nearest = 1e5;
  }
  else if (numberValue >= 5e6) {
    nearest = 5e4;
  }
  else if (numberValue >= 1e6) {
    nearest = 1e4;
  }
  else if (numberValue >= 5e5) {
    nearest = 5e3;
  }
  else if (numberValue >= 1e5) {
    nearest = 1e3;
  }
  else if (numberValue >= 5e4) {
    nearest = 5e2;
  }
  else if (numberValue >= 1e4) {
    nearest = 1e2;
  }
  else if (numberValue >= 5e3) {
    nearest = 5e1;
  }
  else if (numberValue >= 1e3) {
    nearest = 1e1;
  }

  return Math.round(numberValue / nearest) * nearest;
};


// Get value where higher values are lowered
export const getLogairthmicValue = (value: number | string | undefined) => {
  if (value === undefined || value === null) return undefined;

  const numberValue = Number(value);
  let logarithmicValue = Math.min(5000000, numberValue);

  if (numberValue > 5000000) {

    let valueInThisRange = Math.min(numberValue - 5000000, 5000000);
    logarithmicValue += valueInThisRange * 0.9;

    if (numberValue > 10000000) {

      valueInThisRange = Math.min(numberValue - 10000000, 5000000);
      logarithmicValue += valueInThisRange * 0.8;

      if (numberValue > 15000000) {

        valueInThisRange = Math.min(numberValue - 15000000, 10000000);
        logarithmicValue += valueInThisRange * 0.6;

        if (numberValue > 25000000) {

          valueInThisRange = Math.min(numberValue - 25000000, 25000000);
          logarithmicValue += valueInThisRange * 0.4;

          if (numberValue > 50000000) {

            valueInThisRange = Math.min(numberValue - 50000000, 50000000);
            logarithmicValue += valueInThisRange * 0.2;

            if (numberValue > 100000000) {

              valueInThisRange = numberValue - 100000000;
              logarithmicValue += valueInThisRange * 0.1;
            }
          }
        }
      }
    }
  }

  return logarithmicValue;
};
