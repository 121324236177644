

import { StringToStringMap } from '../../../types';


const supportedLanguagesToFlagUrl: StringToStringMap = {
  'no': 'flags/no.svg',
  'se': 'flags/se.svg',
  'dk': 'flags/dk.svg',
  'en': 'flags/gb.svg',
};


interface CountryFlagButtonProps {
  language: string;
  isSelected: boolean;
  onClick: (language: string) => void;
  width: number;
  isWhiteBackground?: boolean;
}


export const CountryFlagButton: React.FC<CountryFlagButtonProps> = ({
  language,
  isSelected,
  onClick,
  width,
  isWhiteBackground,
}) => {


  return (
    <div
      className={
        'country-flag-button-container' +
        (isSelected
          ? (' country-flag-button-container-selected' + (isWhiteBackground ? '-whiteBackground' : ''))
          : (' country-flag-button-container-not-selected' + (isWhiteBackground ? '-whiteBackground' : ''))
        )
      }
      style={{
        width: width,
        height: width * 0.75,
      }}
      onClick={() => onClick(language)}
    >
      <img
        src={supportedLanguagesToFlagUrl[language]}
        alt={''}
        style={{
          borderRadius: 2,
          width: width,
          height: width * 0.75,
        }}
        draggable={false}
      />

      <div
        className={
          'country-flag-button-overlay' +
          (isSelected
            ? ''
            : (' country-flag-button-overlay-not-selected' + (isWhiteBackground ? '-whiteBackground' : ''))
          )
        }
      />
    </div>
  );
};
