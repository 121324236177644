import '../../platform.css';
import './scouting.css';

import { useReducer, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { alertsConfigSelector } from '../../recoil/selectors/scoutingConfigSelectors';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { FixedTabs } from '../../components/controls/navigation/FixedTabs';
import { PlayerScouting } from './playerScouting/PlayerScouting';
import { SeasonScouting } from './seasonScouting/SeasonScouting';
import { Alerts } from './alerts/Alerts';
import { PlayerOverviews } from '../../types';
import playerScoutingReducer from './playerScouting/playerScoutingReducer';
import { initialPlayerScoutingState } from './playerScouting/playerScoutingState';
import seasonScoutingReducer from './seasonScouting/seasonScoutingReducer';
import { initialSeasonScoutingState } from './seasonScouting/seasonScoutingState';


export const playerTablePageSize = 25;
export const playerTableChapterSize = 4;

export const playerSeasonTablePageSize = 50;
export const playerSeasonTableChapterSize = 1;

export const alertTablePageSize = 50;

export const playerSimpleTablePageSize = 50;
export const playerSimpleTableChapterSize = 4;


export const Scouting = () => {

  const alertsConfig = useRecoilValue(alertsConfigSelector);

  // Player scouting state
  // name strings are not part of the state due to debounce logic
  const [playerScoutingNameSearchString, setPlayerScoutingNameSearchString] = useState('');
  const [playerScoutingCurrentNameSearchString, setPlayerScoutingCurrentNameSearchString] = useState('');
  const [playerScoutingDebouncedNameSearchString, setPlayerScoutingDebouncedNameSearchString] = useState('');
  const [playerScoutingState, playerScoutingDispatch] = useReducer(
    playerScoutingReducer,
    initialPlayerScoutingState,
  );


  // Season scouting state
  const [additionalPlayerOverviews, setAdditionalPlayerOverviews] = useState<PlayerOverviews>({});
  const [seasonScoutingState, seasonScoutingDispatch] = useReducer(
    seasonScoutingReducer,
    initialSeasonScoutingState,
  );


  const handleSetActiveTab = (tab: number) => {
    setActiveTab(tab);

    // Reset search result states, but keep filter state
    seasonScoutingDispatch({ type: 'RESET_SEARCH_STATE' });
    playerScoutingDispatch({ type: 'RESET_SEARCH_STATE' });
  };


  const [activeTab, setActiveTab] = useState(0);
  const tabOptions = [
    'playerSearch',
    'seasonSearch',
    'alerts'
  ];
  const tabIcons = [
    <PersonSearchIcon key={'person-search-icon'} style={{ fontSize: 21 }} />,
    <QueryStatsIcon key={'query-stats-icon'} style={{ fontSize: 21 }} />,
    <NotificationsIcon key={'notifications-icon'} style={{ fontSize: 21 }} />
  ];


  const numberOfNewPlayersSinceLastViewed = alertsConfig && alertsConfig.alerts
    ? alertsConfig.alerts.reduce((acc, alert) => acc + alert.numberOfNewPlayersSinceLastViewed, 0)
    : 0;


  return (
    <div className='platform-view-section'>

      <FixedTabs
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={handleSetActiveTab}
        parentTab={'scouting'}
        tabIcons={tabIcons}
        numberOfNewPlayersSinceLastViewed={numberOfNewPlayersSinceLastViewed}
      />

      <div className='platform-view-content-container-with-tabs scouting-view-content-container'>
        {activeTab === 0 && (
          <PlayerScouting
            state={playerScoutingState}
            dispatch={playerScoutingDispatch}
            nameSearchString={playerScoutingNameSearchString}
            setNameSearchString={setPlayerScoutingNameSearchString}
            currentNameSearchString={playerScoutingCurrentNameSearchString}
            setCurrentNameSearchString={setPlayerScoutingCurrentNameSearchString}
            debouncedNameSearchString={playerScoutingDebouncedNameSearchString}
            setDebouncedNameSearchString={setPlayerScoutingDebouncedNameSearchString}
          />
        )}

        {activeTab === 1 && (
          <SeasonScouting
            state={seasonScoutingState}
            dispatch={seasonScoutingDispatch}
            additionalPlayerOverviews={additionalPlayerOverviews}
            setAdditionalPlayerOverviews={setAdditionalPlayerOverviews}
          />
        )}

        {activeTab === 2 && <Alerts />}
      </div>

    </div >
  );
};
