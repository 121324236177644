import './dropDowns.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BlockIcon from '@mui/icons-material/Block';

import { translate } from '../../../../common/language/translations';
import { goalkeeperSeasonTableMetricGroups, metricToDisplayInfo, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../../static/playerMetrics';
import { indexKeys } from '../../../static/propertyValues';
import { RatingRequirement } from '../../../types';


interface RatingDropDownProps {
  id: string;

  selectedOptions: RatingRequirement[];
  setSelectedOptions: (value: RatingRequirement[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  selectedRoles: string[];
  showIndexes?: boolean;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string; // controls scrolling

  isGoalkeeperSelected?: boolean;
  anyIterationWithoutSkillcornerSelected?: boolean;
  setAnySkillcornerFiltersSelected?: (value: boolean) => void;
}

export const RatingDropDown: React.FC<RatingDropDownProps> = ({
  id,

  selectedOptions,
  setSelectedOptions,
  isDropDownExpanded,
  setIsDropDownExpanded,

  selectedRoles,
  showIndexes,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,

  isGoalkeeperSelected,
  anyIterationWithoutSkillcornerSelected,
  setAnySkillcornerFiltersSelected,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const clubScopes = useRecoilValue(clubScopesState);

  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (metric: string, value: string, valueIsSelected: boolean, metricIsSelected: boolean) => {
    let newRatings: RatingRequirement[] = [];
    if (valueIsSelected) {
      newRatings = selectedOptions.filter(rating => rating.metric !== metric);
      setSelectedOptions(newRatings);
    }
    else if (metricIsSelected) {
      newRatings = selectedOptions.map(rating => rating.metric === metric ? { metric: metric, value: value } : rating);
      setSelectedOptions(newRatings);
    }
    else {
      newRatings = [...selectedOptions, { metric: metric, value: value }];
      setSelectedOptions(newRatings);
    }

    if (setAnySkillcornerFiltersSelected) {
      const anySkillcornerFiltersSelected = newRatings.some(rating => skillcornerStats.includes(rating.metric));
      setAnySkillcornerFiltersSelected(anySkillcornerFiltersSelected);
    }
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const getMetricDescription = (metric: string) => {
    if (!userConfig) return '';

    return metricToDisplayInfo[metric]
      ? metricToDisplayInfo[metric].description[userConfig.language]
      : showIndexes
        ? metricToDisplayInfo.role_index.description[userConfig.language]
        : metricToDisplayInfo.role_rating.description[userConfig.language];
  };


  const getDisplayOption = (metric: string) => {
    if (!userConfig || !clubSettings) return '';

    return metricToDisplayInfo[metric]
      ? metricToDisplayInfo[metric].name[userConfig.language]
      : clubSettings.roleConfigs[metric].name;
  };


  const showSelectedOptions = () => {
    if (!userConfig || selectedOptions.length === 0) return '';

    let firstSelection = getDisplayOption(selectedOptions[0].metric) + ': ' + selectedOptions[0].value;

    selectedOptions.slice(1).forEach(rating => {
      firstSelection += ', ' + getDisplayOption(rating.metric) + ': ' + rating.value;
    });

    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  const ratingMetricGroup = { ratings: [...selectedRoles, 'skill_rating'] };
  const normalMetricGroups = isGoalkeeperSelected ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const metricGroups = { ...ratingMetricGroup, ...normalMetricGroups };

  const indexGroup = { indexes: [...selectedRoles, 'skill_index'] };

  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-button' onClick={() => handleDropDownClick()}>

        {selectedOptions.length > 0 && <div className='drop-down-button-overlay' />}

        <div className='drop-down-button-text'>
          {selectedOptions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-space'>&nbsp;</div>

      <div
        ref={dropDownRef}
        className={'drop-down-options' + (maxHeight ? ' drop-down-options-scroll' : '')}
        style={{ maxHeight: maxHeight, paddingBottom: 10 }}
      >
        {Object.entries(showIndexes ? indexGroup : metricGroups).map(([metricGroupKey, metrics], index) => {
          return (
            <div key={metricGroupKey}>
              <div className='rating-drop-down-divider' style={{ marginTop: index === 0 ? 20 : undefined }} />

              <div className='metric-drop-down-group-title-container'>
                <div className='rating-drop-down-group-title'>
                  {metricToDisplayInfo[metricGroupKey].name[userConfig?.language ?? 'en']}
                </div>
              </div>

              {metrics.map((metric, index) => {
                if (!userConfig) return null;

                const requirement = selectedOptions.find(rating => rating.metric === metric)?.value;
                const roleConfig = clubSettings?.roleConfigs[metric];

                return (
                  <div
                    className={'rating-drop-down-option' + (index > 0 ? ' rating-drop-down-option-border-top' : '')}
                    style={{ marginTop: marginBetweenOptions }}
                    key={metric}
                  >
                    <div
                      className='rating-drop-down-metric-container'
                      style={{ width: showIndexes ? 110 : undefined }}
                    >
                      <div
                        className='rating-drop-down-metric'
                        title={translate('showDescription', userConfig.language)}
                        onClick={() => roleConfig
                          ? openRoleInfoModal(roleConfig)
                          : openTextModal(getDisplayOption(metric), getMetricDescription(metric), { infoType: 'metric', titleKey: metric })
                        }
                      >
                        {getDisplayOption(metric)}
                      </div>
                    </div>

                    <div
                      className='rating-drop-down-metric-values'
                      style={{ width: showIndexes ? 'calc(100% - 110px)' : undefined }}
                    >
                      {indexKeys.map(value => {
                        const metricIsSelected: boolean = requirement !== undefined;
                        const valueIsSelected: boolean = metricIsSelected && value === requirement;
                        return (
                          <div
                            key={value}
                            className={'rating-drop-down-metric-value' + (valueIsSelected ? ' rating-drop-down-metric-value-selected' : '')}
                            onClick={() => handleOptionSelect(metric, value, valueIsSelected, metricIsSelected)}>
                            {value}
                          </div>
                        );
                      })
                      }
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}

        {userConfig && clubScopes && clubScopes.hasSkillcorner && !isGoalkeeperSelected && !showIndexes && (
          <div>
            <div className='rating-drop-down-divider' />

            <div className='metric-drop-down-group-title-container'>
              <div className='rating-drop-down-group-title' style={{ paddingRight: anyIterationWithoutSkillcornerSelected ? 24 : undefined }}>
                {metricToDisplayInfo.skillcorner.name[userConfig.language]}
                {anyIterationWithoutSkillcornerSelected &&
                  <BlockIcon className='fast-fade-in' style={{ fontSize: 17, color: '#00000088', position: 'absolute', marginTop: -2, marginLeft: 3 }} />
                }
              </div>
            </div>

            {skillcornerStats.map((metric, index) => {

              const isDisabled = anyIterationWithoutSkillcornerSelected;
              const requirement = selectedOptions.find(rating => rating.metric === metric)?.value;

              return (
                <div
                  className={'rating-drop-down-option' + (index > 0 ? ' rating-drop-down-option-border-top' : '')}
                  style={{ marginTop: marginBetweenOptions, color: isDisabled ? '#00000088' : '#000000' }}
                  key={metric}
                >
                  <div className='rating-drop-down-metric-container'>
                    <div
                      className='rating-drop-down-metric'
                      onClick={() => openTextModal(getDisplayOption(metric), getMetricDescription(metric), { infoType: 'metric', titleKey: metric })}
                    >
                      {getDisplayOption(metric)}
                    </div>
                  </div>
                  <div className='rating-drop-down-metric-values'>
                    {indexKeys.map(value => {
                      const metricIsSelected: boolean = requirement !== undefined;
                      const valueIsSelected: boolean = metricIsSelected && value === requirement;
                      return (
                        <div
                          key={value}
                          style={{ color: isDisabled ? '#00000088' : '#000000' }}
                          className={
                            'rating-drop-down-metric-value'
                            + (valueIsSelected ? ' rating-drop-down-metric-value-selected' : '')
                            + (isDisabled ? ' rating-drop-down-metric-value-disabled' : '')
                          }
                          onClick={() => !isDisabled ? handleOptionSelect(metric, value, valueIsSelected, metricIsSelected) : null}
                        >
                          {value}
                        </div>
                      );
                    })
                    }
                  </div>
                </div>
              );
            })}
          </div>
        )}

      </div>

    </div>
  );
};
