import './navigation.css';

import React, { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import { Dialog } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { getClubColor } from '../../../static/clubConfigs';
import { TeamOrSquadModal } from '../../modals/TeamOrSquadModal';
import { StringToAnyMap } from '../../../types';
import { getNavigationWidth } from './Navigation';
import { reorderTeamsOrSquads } from '../../../services/firestore/teamsOrSquads';
import { trackEvent } from '../../../services/server/analytics/trackEvent';
import { IconButton } from '../buttons/IconButton';
import { modalPaperProps } from '../../modals/globalModals/GlobalModals';


interface DynamicTabsProps {
  teamsOrSquads: StringToAnyMap | null;
  activeTab: string | undefined,
  setActiveTab: (tab: string | undefined) => void;
  isSquad: boolean;
}

export const DynamicTabs: React.FC<DynamicTabsProps> = ({ teamsOrSquads, activeTab, setActiveTab, isSquad }) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const { width } = useWindowSize();

  const [tabLineLeft, setTabLineLeft] = useState('0px');
  const [tabLineWidth, setTabLineWidth] = useState('0px');

  const [isTeamOrSquadModalOpen, setIsTeamOrSquadModalOpen] = useState(false);
  const [tabIndexToEdit, setTabIndexToEdit] = useState<number | undefined>(undefined);

  const [tabOptions, setTabOptions] = useState<string[]>(teamsOrSquads ? Object.keys(teamsOrSquads) : []);

  const [draggedTab, setDraggedTab] = useState<string | undefined>(undefined);
  const [dragActiveDelayed, setDragActiveDelayed] = useState<boolean>(false);
  const [originalDraggedTabIndex, setOriginalDraggedTabIndex] = useState<number | undefined>(undefined);
  const [currentDraggedTabIndex, setCurrentDraggedTabIndex] = useState<number | undefined>(undefined);
  const [droppedTab, setDroppedTab] = useState<string | undefined>(undefined);

  const [disableHover, setDisableHover] = useState(false);

  useEffect(() => {
    const handleUserInteraction = () => {
      if (disableHover) {
        setDisableHover(false);
      }
    };

    window.addEventListener('mousemove', handleUserInteraction);
    window.addEventListener('click', handleUserInteraction);

    return () => {
      window.removeEventListener('mousemove', handleUserInteraction);
      window.removeEventListener('click', handleUserInteraction);
    };
  }, [disableHover]);


  const handleDragStart = (tab: string) => {
    setDraggedTab(tab);
    setOriginalDraggedTabIndex(tabOptions.indexOf(tab));
    setCurrentDraggedTabIndex(tabOptions.indexOf(tab));

    setTimeout(() => {
      setDragActiveDelayed(true);
      setDisableHover(true);
    }, 50);
  };


  const resetDrag = () => {
    setDraggedTab(undefined);
    setCurrentDraggedTabIndex(undefined);
    setDragActiveDelayed(false);
  };


  const handleDragEnd = () => {
    resetDrag();
    if (userConfig) reorderTeamsOrSquads(tabOptions, isSquad, userConfig.club, currentUser);
  };


  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };


  const handleDropZoneDrageEnter = (dropZoneIndex: number) => {
    if (currentDraggedTabIndex === undefined) return;

    let newIndex = dropZoneIndex;
    if (dropZoneIndex > currentDraggedTabIndex) {
      newIndex--;
    }
    setCurrentDraggedTabIndex(newIndex);

    // update tabs in real time while dragging
    const newTabOptions = [...tabOptions];
    const draggedTab = newTabOptions.splice(currentDraggedTabIndex, 1)[0];
    newTabOptions.splice(newIndex, 0, draggedTab);
    setTabOptions(newTabOptions);
  };


  const handleDragDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    if (!teamsOrSquads || !userConfig) return;

    event.preventDefault();

    if (currentDraggedTabIndex !== originalDraggedTabIndex) {
      if (userConfig) reorderTeamsOrSquads(tabOptions, isSquad, userConfig.club, currentUser);

      setDroppedTab(draggedTab);
      setTimeout(() => {
        setDroppedTab(undefined);
      }, 400);
    }

    resetDrag();
  };


  const handleTabClick = (tab: string) => {
    if (tab !== activeTab) {
      trackEvent(isSquad ? 'SquadClicked' : 'ScoutTeamClicked', {}, currentUser, 'user');
    }
    setActiveTab(tab);
    updateTabLine(tab);
  };

  const updateTabLine = useCallback((tab: string) => {
    const tabIndex = tabOptions.indexOf(tab);

    const tabContainer = document.getElementById('tab-' + tabIndex);
    const line = document.getElementById('dynamic-tab-line');

    if (tabContainer && line) {
      const tabLeft = tabContainer.getBoundingClientRect().left;
      const tabRight = tabContainer.getBoundingClientRect().right;
      const tabWidth = tabRight - tabLeft;

      // depends on navigation widths and platform-view-section margins
      const offsetFromNavigation = getNavigationWidth(userConfig) + 12;

      const lineWidth = tabWidth * 0.5 + 'px';
      const lineLeft = tabLeft - offsetFromNavigation + (tabWidth * 0.25) + 'px';

      setTabLineLeft(lineLeft);
      setTabLineWidth(lineWidth);
    }
  }, [tabOptions, userConfig]);


  const handleIconClick = (tabToEdit: number | undefined = undefined) => {
    setTabIndexToEdit(tabToEdit);
    setIsTeamOrSquadModalOpen(true);
  };


  const getDynamicTabWidth = (tabOption: string) => {
    const numberOfTabs = teamsOrSquads ? Object.keys(teamsOrSquads).length : 0;
    if (numberOfTabs <= 5) return '20%';

    const activeTabOffset = Math.ceil(numberOfTabs / 1.5);

    const base = (100 - activeTabOffset) / numberOfTabs;
    if (activeTab === tabOption) return base + activeTabOffset + '%';
    return base + '%';
  };


  const getTabLineStyle = (tab: string): React.CSSProperties => {

    const isActive = activeTab === tab;
    const isDragged = draggedTab === tab;
    const isDropped = droppedTab === tab;

    let fontSize = undefined;
    if (isActive || !teamsOrSquads || Object.keys(teamsOrSquads).length <= 8) {
      fontSize = 13;
    }
    else if (Object.keys(teamsOrSquads).length <= 14) {
      fontSize = 12;
    }
    else {
      fontSize = 11;
    }

    let color = undefined;
    let backgroundColor = undefined;
    let transition = undefined;
    if (isDragged && !isActive) {
      color = '#00000063';
      backgroundColor = '#ededf4';
    }
    if (isDropped) {
      fontSize++;
      transition = '300ms ease-out';

      if (!isActive) {
        color = '#000000';
      }
    }

    return {
      fontSize: fontSize + 'px',
      color: color,
      backgroundColor: backgroundColor,
      transition: transition
    };
  };


  const getTabLineTitleClassName = (tab: string) => {
    const base = 'dynamic-tabs-tab-title';
    if (tab === activeTab || !teamsOrSquads || Object.keys(teamsOrSquads).length <= 8) return base;
    if (Object.keys(teamsOrSquads).length <= 14) return base + ' dynamic-tabs-tab-title-small';
    return base + ' dynamic-tabs-tab-title-smaller';
  };


  const setActiveTabIndex = (index: number) => {
    if (index >= 0 && index < tabOptions.length) {
      setActiveTab(tabOptions[index]);
    }
  };


  useEffect(() => {
    if (teamsOrSquads && activeTab !== undefined && Object.keys(teamsOrSquads).length > 0) {
      const timer = setTimeout(() => {
        updateTabLine(activeTab);
      }, 100);

      return () => clearTimeout(timer);
    }
  }, [activeTab, updateTabLine]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (teamsOrSquads && activeTab !== undefined && Object.keys(teamsOrSquads).length > 0) {
      const timer = setTimeout(() => {
        updateTabLine(activeTab);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [activeTab, updateTabLine, width, userConfig?.isNavigationExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (teamsOrSquads) {

      if (Object.keys(teamsOrSquads).length > 0) {
        const currentActiveTab = activeTab ?? Object.keys(teamsOrSquads)[0];
        let newActiveTab: string | undefined = currentActiveTab;

        const newTabOptions = Object.keys(teamsOrSquads);

        if (newTabOptions.length > tabOptions.length) {
          newActiveTab = newTabOptions[newTabOptions.length - 1];
          setActiveTab(newActiveTab);
        }

        else if (newTabOptions.length < tabOptions.length || newTabOptions.length === 1) {
          newActiveTab = newTabOptions[0];
          setActiveTab(newActiveTab);
        }

        setTabOptions(newTabOptions);

        if (newActiveTab) updateTabLine(newActiveTab);
      }

      else {
        setTabOptions([]);
        setActiveTab(undefined);
      }

    }
  }, [teamsOrSquads]); // eslint-disable-line react-hooks/exhaustive-deps


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className='dynamic-tabs-container'>

      <Dialog
        open={isTeamOrSquadModalOpen}
        onClose={() => setIsTeamOrSquadModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <TeamOrSquadModal
          teamsOrSquads={teamsOrSquads}
          isSquad={isSquad}
          setIsTeamOrSquadModalOpen={setIsTeamOrSquadModalOpen}
          activeTabIndex={activeTab ? tabOptions.indexOf(activeTab) : undefined}
          setActiveTabIndex={setActiveTabIndex}
          tabIndexToEdit={tabIndexToEdit}
          setTabIndexToEdit={setTabIndexToEdit}
        />
      </Dialog>

      {teamsOrSquads && Object.keys(teamsOrSquads).length > 0 && (
        <div
          id='dynamic-tab-line'
          className='tabs-tab-line'
          style={{ backgroundColor: clubColor, left: tabLineLeft, width: tabLineWidth, opacity: draggedTab ? 0 : undefined }}
        />
      )}

      <div className='tabs-tab-section'>
        {tabOptions.map((tabOption, index) => {

          if (!teamsOrSquads || !(tabOption in teamsOrSquads)) return null;

          const dividerIsNextToActiveTab = activeTab === tabOption || (index < tabOptions.length - 1 && activeTab === tabOptions[index + 1]);

          return (
            <React.Fragment key={tabOption}>
              <div
                id={'tab-container-' + index}
                draggable={tabOptions.length > 1}
                onDragStart={() => handleDragStart(tabOption)}
                onDragEnd={() => handleDragEnd()}
                className={
                  'dynamic-tabs-tab-container' +
                  (activeTab === tabOption ? ' dynamic-tabs-tab-container-active' : '') +
                  (draggedTab === tabOption ? ' dynamic-tabs-tab-container-dragged' : '') +
                  (draggedTab ? ' dynamic-tabs-tab-container-drag-active' : '') +
                  (disableHover ? ' no-hover' : '')
                }
                style={{ width: getDynamicTabWidth(tabOption) }}
              >
                <div
                  id={'tab-' + index}
                  className={'tabs-tab' + (activeTab === tabOption ? ' tabs-tab-active' : '')}
                  onClick={() => handleTabClick(tabOption)}
                  style={getTabLineStyle(tabOption)}
                >
                  <div className={getTabLineTitleClassName(tabOption)}>
                    {teamsOrSquads ? teamsOrSquads[tabOption]['name'] : ''}
                  </div>
                </div>
                {draggedTab !== tabOption && activeTab === tabOption && (
                  <IconButton
                    onClick={() => handleIconClick(index)}
                    icon={<MoreVertIcon style={{ fontSize: 20 }} />}
                    size={23}
                    style={{ margin: 'auto', marginLeft: -26, zIndex: 5 }}
                  />
                )}
                <div
                  className={
                    'dynamic-tabs-overlay' +
                    (activeTab === tabOption ? ' dynamic-tabs-overlay-active' : '') +
                    (draggedTab === tabOption && activeTab !== tabOption ? ' dynamic-tabs-overlay-dragged' : '')
                  }
                  onClick={() => handleTabClick(tabOption)}
                />
              </div>

              {index < tabOptions.length - 1 &&
                <div className={'dynamic-tabs-divider' + (dividerIsNextToActiveTab ? ' dynamic-tabs-divider-active' : '')} />
              }

            </React.Fragment>
          );
        })}

        {(teamsOrSquads === null || (teamsOrSquads && Object.keys(teamsOrSquads).length < 20)) && (
          <div className='dynamic-tabs-add-icon-section' onClick={() => handleIconClick()}>
            <div className='dynamic-tabs-add-icon'>
              <AddIcon style={{ fontSize: 22 }} />
            </div>
          </div>
        )}

        {draggedTab !== undefined && teamsOrSquads && (
          <div
            className='dynamic-tabs-drop-zone-container'
            onDragOver={handleDragOver}
            onDrop={handleDragDrop}
          >
            {Array.from({ length: Object.keys(teamsOrSquads).length + 1 }, (_, index) => index).map((index) => {

              // there are 3 possible drop zones
              // if index === 0, the drop zone is the left half of the first tab
              // if index === Object.keys(teamsOrSquads).length, the drop zone is from the middle of the last tab to the right end
              // otherwise, the drop zone is between the middles of each tab

              const dropZoneIsAdjacentToOriginalDraggedTabIndex = originalDraggedTabIndex !== index && originalDraggedTabIndex !== index - 1;
              // var draggedTabHasMoved = currentDraggedTabIndex !== originalDraggedTabIndex;
              // var isValidDropZone = draggedTabHasMoved || !dropZoneIsAdjacentToOriginalDraggedTabIndex;

              const tabElement = document.getElementById('tab-container-' + index);
              const leftTabElement = document.getElementById('tab-container-' + (index - 1));

              const offsetFromNavigation = getNavigationWidth(userConfig) + 12;

              const style: React.CSSProperties = {};

              if (index === 0) {
                if (!tabElement) return null;
                const tabRect = tabElement.getBoundingClientRect();

                style.left = tabRect.left - offsetFromNavigation;
                style.width = tabRect.width / 2;
              }
              else if (index === Object.keys(teamsOrSquads).length) {
                if (!leftTabElement) return null;
                const tabRect = leftTabElement.getBoundingClientRect();

                style.left = tabRect.left + (tabRect.width / 2) - offsetFromNavigation;
                style.right = 0;
              }
              else {
                if (!tabElement || !leftTabElement) return null;
                const tabRect = tabElement.getBoundingClientRect();
                const leftTabRect = leftTabElement.getBoundingClientRect();

                const left = (leftTabRect.left + leftTabRect.width / 2) - offsetFromNavigation + 5;
                style.width = (leftTabRect.width / 2) + (tabRect.width / 2) - 10;
                style.left = left;
              }

              if (!dropZoneIsAdjacentToOriginalDraggedTabIndex && !dragActiveDelayed) return null;

              return (
                <div
                  key={'drop-zone-' + index}
                  style={style}
                  className='dynamic-tabs-drop-zone'
                  onDragEnter={() => handleDropZoneDrageEnter(index)}
                />
              );
            })
            }
          </div>
        )}

      </div>

    </div>
  );
};
