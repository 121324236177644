import { db, FirebaseUser } from '../../../firebase';

import {
  doc,
  setDoc,
  onSnapshot,
} from 'firebase/firestore';

import { StringToAnyMap, UserSettings } from '../../types';
import { trackEvent } from '../server/analytics/trackEvent';


// get and set settings for club and
export const getUserSettings = (setUserSettings: (userSettings: UserSettings) => void, userEmail: string, club: string) => {

  const userSettingsDocRef = doc(db, 'configs', club, 'settings', userEmail);

  const unsubscribe = onSnapshot(userSettingsDocRef, (doc) => {
    const userSettingsObject = doc.data();

    const userSettings: UserSettings = {
      academyTeamMenu: userSettingsObject?.academyTeamMenu ?? {},
      ownTeamMenu: userSettingsObject?.ownTeamMenu ?? {},
      teamMenu: userSettingsObject?.teamMenu ?? {},
      seasonStatsToggles: userSettingsObject?.seasonStatsToggles ?? {}
    };

    setUserSettings(userSettings);
  });

  return unsubscribe;
};


// Update teamMenu or ownTeamMenu settings
export const updateTeamMenu = async (
  menu: StringToAnyMap,
  teamOrSquadId: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser
) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  const menuField = teamOrSquadId === 'ownTeam'
    ? 'ownTeamMenu'
    : teamOrSquadId === 'academyTeam'
      ? 'academyTeamMenu'
      : 'teamMenu';

  try {
    await setDoc(userDocRef, {
      [menuField]: menu
    }, { merge: true });
    trackEvent('TeamOrSquadMenuUpdated', {}, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateTeamMenu', errorDetails: { error } }, currentUser, 'system');
  }
};


// Update season stats toggles
export const updateSeasonStatsToggles = async (
  newSeasonStatsToggles: StringToAnyMap,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser
) => {

  const userDocRef = doc(db, 'configs', club, 'settings', userEmail);

  try {
    await setDoc(userDocRef, {
      ['seasonStatsToggles']: newSeasonStatsToggles
    }, { merge: true });
    trackEvent('SeasonStatsTogglesUpdated', {}, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateSeasonStatsToggles', errorDetails: { error } }, currentUser, 'system');
  }
};
