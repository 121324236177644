import './playerView.css';

import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerCareersState } from '../../recoil/atoms/playerCareersState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { playersState } from '../../recoil/atoms/playersState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';

import CloseIcon from '@mui/icons-material/Close';

import {
  getAge,
  getDateLocaleFormat,
  getDisplayCompetition,
  getDisplayPositions,
  getPlayerPropertyValue,
  getDisplayPlayerName
} from '../../utils/playerUtils';

import {
  PlayerOverview,
  PlayerClubIteration,
  StringToAnyMap,
  PlayerCareer,
  PositionData
} from '../../types';

import { translate } from '../../../common/language/translations';
import { getClubColor, getClubContrastColor } from '../../static/clubConfigs';
import { SelectPlayerView } from '../searchPlayers/SelectPlayerView';
import { PlayerIterationDropDown } from '../controls/dropDowns/PlayerIterationDropDown';
import { useGetAndAddPlayerCareer } from '../../recoil/hooks/useGetAndAddPlayerCareer';
import { CompareRadarPlot } from '../plots/CompareRadarPlot';
import { metricToDisplayInfo } from '../../static/playerMetrics';
import { getIndexColor } from '../../utils/colorUtils';
import { IconButton } from '../controls/buttons/IconButton';
import { ProgressCircle } from '../display/visualization/ProgressCircle';
import { PlayerImage } from '../display/images/PlayerImage';
import { ClubLogo } from '../display/images/ClubLogo';
import { CountryFlag } from '../display/images/CountryFlag';


interface PlayerViewCompareProps {
  playerOverview: PlayerOverview;
}

export const PlayerViewCompare: React.FC<PlayerViewCompareProps> = ({ playerOverview }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);
  const players = useRecoilValue(playersState);
  const playerCareers = useRecoilValue(playerCareersState);
  const getAndAddPlayerCareer = useGetAndAddPlayerCareer();

  const { openTextModal } = useOpenGlobalModal();
  const handleOpenMetricModal = (metric: string) => openTextModal(
    metricToDisplayInfo[metric].name[userConfig?.language ?? 'en'],
    metricToDisplayInfo[metric].description[userConfig?.language ?? 'en'],
    { infoType: 'metric', titleKey: metric }
  );


  // selected player
  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const [playerClubIterationArray, setPlayerClubIterationArray] = useState<PlayerClubIteration[]>([]);
  const [isIterationDropDownExpanded, setIsIterationDropDownExpanded] = useState<boolean>(false);
  const [selectedClubIterationIndex, setSelectedClubIterationIndex] = useState<number | undefined>(undefined);
  const [positionGroupsOfSelectedIteration, setPositionGroupsOfSelectedIteration] = useState<string[]>([]);
  const [selectedPositionGroupKey, setSelectedPositionGroupKey] = useState<string | undefined>(undefined);


  // other player
  const [otherPlayerOverview, setOtherPlayerOverview] = useState<PlayerOverview | undefined>(undefined);
  const [otherPlayerConfidence, setOtherPlayerConfidence] = useState<number>(0);
  const [otherPlayerClubIterationArray, setOtherPlayerClubIterationArray] = useState<PlayerClubIteration[]>([]);
  const [isOtherIterationDropDownExpanded, setIsOtherIterationDropDownExpanded] = useState<boolean>(false);
  const [otherSelectedClubIterationIndex, setOtherSelectedClubIterationIndex] = useState<number | undefined>(undefined);
  const [otherPositionGroupsOfSelectedIteration, setOtherPositionGroupsOfSelectedIteration] = useState<string[]>([]);
  const [otherSelectedPositionGroupKey, setOtherSelectedPositionGroupKey] = useState<string | undefined>(undefined);


  const removeDropDownExpansions = () => {
    setIsIterationDropDownExpanded(false);
    setIsOtherIterationDropDownExpanded(false);
  };


  const handleSelectOtherPlayer = (player: StringToAnyMap) => {
    if (!player.event_data_available) return;
    if (playerOverview.primary_positions?.includes('GK') !== player.primary_positions?.includes('GK')) return;
    setOtherPlayerOverview(player as PlayerOverview);
  };


  const handleOtherPlayerCloseClick = () => {
    setOtherPlayerOverview(undefined);
    setOtherSelectedClubIterationIndex(undefined);
    setOtherSelectedPositionGroupKey(undefined);
    setOtherPlayerClubIterationArray([]);
  };


  const setPositionGroupData = (
    positionGroups: string[],
    setPositionGroups: (value: string[]) => void,
    setPositionGroup: (value: string) => void,
  ) => {
    setPositionGroups(
      positionGroups.length === 1
        ? positionGroups
        : ['overall', ...positionGroups]
    );
    setPositionGroup(
      positionGroups.length === 1
        ? positionGroups[0]
        : 'overall'
    );
  };


  const setClubIterationArrayAndSelectedIndex = (
    playerCareer: PlayerCareer,
    setArray: (array: PlayerClubIteration[]) => void,
    setSelectedIndex: (value: number) => void,
    setPositionGroups: (value: string[]) => void,
    setPositionGroup: (value: string) => void,
  ) => {

    const clubIterationArray: PlayerClubIteration[] = [];

    Object.keys(playerCareer).forEach((clubIterationId) => {
      if (playerCareer[clubIterationId].event_data_exist) {
        clubIterationArray.push(playerCareer[clubIterationId]);
      }
    });

    clubIterationArray.sort(
      (a, b) => b.basic_stats?.plot_date && a.basic_stats?.plot_date
        ? b.basic_stats.plot_date.localeCompare(a.basic_stats.plot_date)
        : 0
    );

    let anyLeagueWithMinutes = false;
    let initialIndexToSelect = 0;
    clubIterationArray.forEach((clubIteration, index) => {
      if (clubIteration.event_data_available && competitions) {

        const positionGroupAccessor = clubIteration.position_group_stats.overall
          ? 'overall'
          : clubIteration.position_groups && clubIteration.position_groups.length > 0
            ? clubIteration.position_groups[0]
            : undefined;

        const minutesPlayed = positionGroupAccessor !== undefined ? clubIteration.position_group_stats[positionGroupAccessor]?.minutes_played : undefined;

        if (
          !anyLeagueWithMinutes &&
          clubIteration.competition_id in competitions &&
          competitions[clubIteration.competition_id].type === 'League' &&
          minutesPlayed
        ) {
          initialIndexToSelect = index;
          anyLeagueWithMinutes = true;
        }
        else if (
          !anyLeagueWithMinutes &&
          clubIteration.competition_id in competitions &&
          competitions[clubIteration.competition_id].type === 'Cup' &&
          minutesPlayed
        ) {
          initialIndexToSelect = index;
        }
      }
    });

    setArray(clubIterationArray);
    setSelectedIndex(initialIndexToSelect);

    const selectedClubIteration = clubIterationArray[initialIndexToSelect];
    setPositionGroupData(selectedClubIteration.position_groups, setPositionGroups, setPositionGroup);
  };


  const handleIterationDropDownClick = (iterationIndex: number | undefined) => {
    setSelectedClubIterationIndex(iterationIndex);
    if (iterationIndex === undefined) {
      setPositionGroupsOfSelectedIteration([]);
      setSelectedPositionGroupKey(undefined);
      return;
    }
    const clubIteration = playerClubIterationArray[iterationIndex];
    setPositionGroupData(clubIteration.position_groups, setPositionGroupsOfSelectedIteration, setSelectedPositionGroupKey);
  };


  const handleOtherIterationDropDownClick = (iterationIndex: number | undefined) => {
    setOtherSelectedClubIterationIndex(iterationIndex);
    if (iterationIndex === undefined) {
      setOtherPositionGroupsOfSelectedIteration([]);
      setOtherSelectedPositionGroupKey(undefined);
      return;
    }
    const clubIteration = otherPlayerClubIterationArray[iterationIndex];
    setPositionGroupData(clubIteration.position_groups, setOtherPositionGroupsOfSelectedIteration, setOtherSelectedPositionGroupKey);
  };


  useEffect(() => {
    if (playerClubIterationArray.length === 0 && playerCareers && selectedPlayer && selectedPlayer.id in playerCareers) {
      const career = playerCareers[Number(selectedPlayer.id)];
      setClubIterationArrayAndSelectedIndex(
        career,
        setPlayerClubIterationArray,
        setSelectedClubIterationIndex,
        setPositionGroupsOfSelectedIteration,
        setSelectedPositionGroupKey
      );
    }

    if (otherPlayerClubIterationArray.length === 0 && playerCareers && otherPlayerOverview && otherPlayerOverview.id in playerCareers) {
      const career = playerCareers[otherPlayerOverview.id];
      setClubIterationArrayAndSelectedIndex(
        career,
        setOtherPlayerClubIterationArray,
        setOtherSelectedClubIterationIndex,
        setOtherPositionGroupsOfSelectedIteration,
        setOtherSelectedPositionGroupKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    playerCareers,
    playerClubIterationArray.length,
    selectedPlayer,
    otherPlayerClubIterationArray.length,
    otherPlayerOverview
  ]);


  useEffect(() => {
    if (otherPlayerOverview && playerCareers && !playerCareers[otherPlayerOverview.id]) {
      getAndAddPlayerCareer(otherPlayerOverview.id, currentUser);
    }
  }, [currentUser, otherPlayerOverview, playerCareers]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const timer = setTimeout(() => {
      setOtherPlayerConfidence(otherPlayerOverview?.position_group_stats?.overall?.confidence ?? 0);
    }, 100);

    return () => clearTimeout(timer);
  }, [otherPlayerOverview]);


  const clubColor = getClubColor(userConfig?.club ?? '');
  const clubContrastColor = getClubContrastColor(userConfig?.club ?? '');


  const minutesPlayedIndex = selectedClubIterationIndex !== undefined && selectedPositionGroupKey !== undefined
    ? Math.min(
      (playerClubIterationArray[selectedClubIterationIndex]?.position_group_stats[selectedPositionGroupKey]?.minutes_played ?? 0) / 150,
      10
    )
    : 0;

  const otherMinutesPlayedIndex = otherSelectedClubIterationIndex !== undefined && otherSelectedPositionGroupKey !== undefined
    ? Math.min(
      (otherPlayerClubIterationArray[otherSelectedClubIterationIndex]?.position_group_stats[otherSelectedPositionGroupKey]?.minutes_played ?? 0) / 150,
      10
    )
    : 0;


  return (
    <div className='full-size-container'>

      {(isIterationDropDownExpanded || isOtherIterationDropDownExpanded) &&
        <div className='filter-section-empty-background' onClick={removeDropDownExpansions} />
      }

      <div className='player-view-widget-container player-view-compare-iteration-section'>

        <div
          className='player-view-compare-iteration-section-line'
          style={{ backgroundColor: clubColor }}
        />

        <div
          className='player-view-compare-iteration-section-line player-view-compare-iteration-section-line-other-player'
          style={{ backgroundColor: clubContrastColor }}
        />

        <div className='player-view-compare-iteration-player-section'>
          <div className='player-view-compare-iteration-player-section-title'>
            {selectedPlayer ? getDisplayPlayerName(selectedPlayer.fullname, 20) : ''}
          </div>

          <div className='player-view-compare-iteration-drop-down'>
            <PlayerIterationDropDown
              id={'player-view-compare-iteration-drop-down'}
              dropDownOptions={playerClubIterationArray}
              selectedOption={selectedClubIterationIndex}
              handleIterationClick={handleIterationDropDownClick}
              isDropDownExpanded={isIterationDropDownExpanded}
              setIsDropDownExpanded={setIsIterationDropDownExpanded}
              defaultDropDownText={'selectSeason'}
              defaultDropDownTextColor={'#00000080'}
              marginBetweenOptions={3}
              maxHeight={'70vh'}
            />
          </div>

          <div className='player-view-compare-positions-groups-row'>
            {selectedClubIterationIndex !== undefined && positionGroupsOfSelectedIteration.map(positionGroup => {

              const isSelected = selectedPositionGroupKey === positionGroup;
              const isMultiplePositionGroups = positionGroupsOfSelectedIteration.length > 1;
              const positions: PositionData[] = playerClubIterationArray[selectedClubIterationIndex]
                .position_group_stats[positionGroup]?.positions ?? [];

              return (
                <div
                  key={positionGroup + selectedClubIterationIndex}
                  className={
                    'player-view-compare-position-group'
                    + (isSelected ? ' player-view-compare-position-group-selected' : '')
                    + (isMultiplePositionGroups ? ' player-view-compare-position-group-hover' : '')
                  }
                  style={{
                    border: '1px solid ' + (isSelected ? clubColor : 'transparent'),
                    fontSize: positions.length > 2 ? 11 : 12
                  }}
                  onClick={() => isMultiplePositionGroups ? setSelectedPositionGroupKey(isSelected ? 'overall' : positionGroup) : 0}
                >
                  {positionGroup === 'overall' && (
                    <div>
                      {translate('all', userConfig?.language)}
                    </div>
                  )}

                  {positionGroup !== 'overall' && positions.map((position: PositionData) => {
                    return (
                      <div key={position.position}>
                        {translate(position.position, userConfig?.language)}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>

        </div>

        <div className='player-view-compare-iteration-section-divider' />

        {otherPlayerOverview && (
          <div className='player-view-compare-iteration-player-section'>
            <div className='player-view-compare-iteration-player-section-title-container-other-player'>
              <div className='player-view-compare-iteration-player-section-title-other-player'>
                {getDisplayPlayerName(otherPlayerOverview.fullname, 20)}
              </div>
            </div>

            <div className='player-view-compare-iteration-drop-down-other-player'>
              <PlayerIterationDropDown
                id={'player-view-compare-iteration-drop-down-other-player'}
                dropDownOptions={otherPlayerClubIterationArray}
                selectedOption={otherSelectedClubIterationIndex}
                handleIterationClick={handleOtherIterationDropDownClick}
                isDropDownExpanded={isOtherIterationDropDownExpanded}
                setIsDropDownExpanded={setIsOtherIterationDropDownExpanded}
                defaultDropDownText={'selectSeason'}
                defaultDropDownTextColor={'#00000080'}
                marginBetweenOptions={3}
                maxHeight={'70vh'}
              />
            </div>

            <div className='player-view-compare-positions-groups-row'>
              {otherSelectedClubIterationIndex !== undefined && otherPositionGroupsOfSelectedIteration.map(positionGroup => {

                const isSelected = otherSelectedPositionGroupKey === positionGroup;
                const isMultiplePositionGroups = otherPositionGroupsOfSelectedIteration.length > 1;
                const positions: PositionData[] = otherPlayerClubIterationArray[otherSelectedClubIterationIndex]
                  .position_group_stats[positionGroup]?.positions ?? [];

                return (
                  <div
                    key={positionGroup + otherSelectedClubIterationIndex}
                    className={
                      'player-view-compare-position-group'
                      + (isSelected ? ' player-view-compare-position-group-selected' : '')
                      + (isMultiplePositionGroups ? ' player-view-compare-position-group-hover' : '')
                    }
                    style={{
                      border: '1px solid ' + (isSelected ? clubContrastColor : 'transparent'),
                      fontSize: positions.length > 2 ? 11 : 12
                    }}
                    onClick={() => isMultiplePositionGroups ? setOtherSelectedPositionGroupKey(isSelected ? 'overall' : positionGroup) : null}
                  >
                    {positionGroup === 'overall' && (
                      <div>
                        {translate('all', userConfig?.language)}
                      </div>
                    )}

                    {positionGroup !== 'overall' && positions.map((position: PositionData) => {
                      return (
                        <div key={position.position}>
                          {translate(position.position, userConfig?.language)}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {!otherPlayerOverview && (
          <div className='player-view-compare-iteration-player-section'>
            <div className='player-view-compare-iteration-player-section-no-player-selected'>
              {translate('noPlayerSelected', userConfig?.language)}
            </div>
          </div>
        )}
      </div>

      <div className='player-view-widget-container player-view-compare-data-section'>
        <div
          className='player-view-compare-iteration-section-line'
          style={{ backgroundColor: clubColor }}
        />

        <div
          className='player-view-compare-iteration-section-line player-view-compare-iteration-section-line-other-player'
          style={{ backgroundColor: clubContrastColor }}
        />

        {playerClubIterationArray.length > 0 && selectedClubIterationIndex !== undefined && selectedPositionGroupKey !== undefined && (
          <div className='player-view-compare-data-info-column'>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {translate('team', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value' style={{ marginTop: 4 }}>
                <ClubLogo
                  club={playerClubIterationArray[selectedClubIterationIndex].club}
                  size={18}
                  showClubName={true}
                  showHyphenIfEmpty={true}
                  containerStyle={{ gap: 3 }}
                />
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {translate('minutes', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value' style={{ marginTop: 4 }}>
                {playerClubIterationArray[selectedClubIterationIndex]
                  .position_group_stats[selectedPositionGroupKey]?.minutes_played !== undefined
                  ? Math.round(playerClubIterationArray[selectedClubIterationIndex]
                    .position_group_stats[selectedPositionGroupKey].minutes_played)
                  : '-'}

                <div
                  style={{
                    marginTop: -4,
                    marginLeft: 7,
                  }}
                >
                  <ProgressCircle
                    size={16}
                    progress={minutesPlayedIndex * 10}
                    color={getIndexColor(minutesPlayedIndex, 65)}
                    strokeWidth={9}
                    trailColor='#ffffff55'
                  />
                </div>
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                <div
                  className='player-view-metric-label'
                  title={translate('showDescription', userConfig?.language)}
                  onClick={() => handleOpenMetricModal('skill_rating')}
                >
                  {translate('skillRating', userConfig?.language)}
                </div>
              </div>
              <div className='player-view-compare-data-info-column-value' style={{ fontSize: 16, fontWeight: 500, marginTop: 3 }}>
                {playerClubIterationArray[selectedClubIterationIndex]
                  .position_group_stats[selectedPositionGroupKey]?.skill_rating !== undefined
                  ? Math.round(playerClubIterationArray[selectedClubIterationIndex]
                    .position_group_stats[selectedPositionGroupKey].skill_rating * 10) / 10
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {translate('goals', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value'>
                {playerClubIterationArray[selectedClubIterationIndex].position_group_stats[selectedPositionGroupKey]?.metrics.n_goals ?? '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry'>
              <div className='player-view-compare-data-info-column-label'>
                {translate('assists', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value'>
                {playerClubIterationArray[selectedClubIterationIndex].position_group_stats[selectedPositionGroupKey]?.metrics.n_assists ?? '-'}
              </div>
            </div>
          </div>
        )}

        <div className='player-view-compare-radar-section'>
          {userConfig && (
            <CompareRadarPlot
              playerClubIterations={playerClubIterationArray}
              selectedClubIterationIndex={selectedClubIterationIndex}
              selectedPositionGroupKey={selectedPositionGroupKey}
              clubColor={clubColor}

              otherPlayerClubIterations={otherPlayerClubIterationArray}
              otherSelectedClubIterationIndex={otherSelectedClubIterationIndex}
              otherSelectedPositionGroupKey={otherSelectedPositionGroupKey}
              clubContrastColor={clubContrastColor}

              isGoalkeeper={playerOverview.primary_positions?.includes('GK') ?? false}
              language={userConfig.language} />
          )}
        </div>

        {otherPlayerClubIterationArray.length > 0 && otherSelectedClubIterationIndex !== undefined && otherSelectedPositionGroupKey !== undefined && (
          <div className='player-view-compare-data-info-column player-view-compare-data-info-column-other-player'>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {translate('team', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player' style={{ marginTop: 4 }}>
                <ClubLogo
                  club={otherPlayerClubIterationArray[otherSelectedClubIterationIndex].club}
                  size={18}
                  showClubName={true}
                  showHyphenIfEmpty={true}
                  containerStyle={{ gap: 3 }}
                />
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {translate('minutes', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player' style={{ marginTop: 4 }}>
                <div
                  style={{
                    marginTop: -4,
                    marginRight: 7,
                  }}
                >
                  <ProgressCircle
                    size={16}
                    progress={otherMinutesPlayedIndex * 10}
                    color={getIndexColor(otherMinutesPlayedIndex, 65)}
                    strokeWidth={9}
                    trailColor='#ffffff55'
                  />
                </div>
                {otherPlayerClubIterationArray[otherSelectedClubIterationIndex]
                  .position_group_stats[otherSelectedPositionGroupKey]?.minutes_played !== undefined
                  ? Math.round(otherPlayerClubIterationArray[otherSelectedClubIterationIndex]
                    .position_group_stats[otherSelectedPositionGroupKey].minutes_played)
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-skill-rating-label-other-player'>
                <div
                  className='player-view-metric-label'
                  title={translate('showDescription', userConfig?.language)}
                  onClick={() => handleOpenMetricModal('skill_rating')}
                >
                  {translate('skillRating', userConfig?.language)}
                </div>
              </div>
              <div
                className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'
                style={{ fontSize: 16, fontWeight: 500, marginTop: 3 }}
              >
                {otherPlayerClubIterationArray[otherSelectedClubIterationIndex]
                  .position_group_stats[otherSelectedPositionGroupKey]?.skill_rating !== undefined
                  ? Math.round(otherPlayerClubIterationArray[otherSelectedClubIterationIndex]
                    .position_group_stats[otherSelectedPositionGroupKey].skill_rating * 10) / 10
                  : '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {translate('goals', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'>
                {otherPlayerClubIterationArray[otherSelectedClubIterationIndex].position_group_stats[otherSelectedPositionGroupKey]?.metrics.n_goals ?? '-'}
              </div>
            </div>
            <div className='player-view-compare-data-info-column-entry-other-player'>
              <div className='player-view-compare-data-info-column-label player-view-compare-data-info-column-label-other-player'>
                {translate('assists', userConfig?.language)}
              </div>
              <div className='player-view-compare-data-info-column-value player-view-compare-data-info-column-value-other-player'>
                {otherPlayerClubIterationArray[otherSelectedClubIterationIndex].position_group_stats[otherSelectedPositionGroupKey]?.metrics.n_assists ?? '-'}
              </div>
            </div>
          </div>
        )}


      </div>

      <div className='player-view-widget-container player-view-compare-other-player-section'>

        {!otherPlayerOverview && (
          <div>
            <SelectPlayerView
              onPlayerClick={handleSelectOtherPlayer}
              titleKey={'selectPlayerForComparison'}
              maxTableHeight={'62vh'}
              isGoalkeeper={playerOverview.primary_positions?.includes('GK') ?? false}
              disablePlayersWithoutEventData={true}
            />
          </div>
        )}

        {otherPlayerOverview && (
          <div>

            <IconButton
              onClick={handleOtherPlayerCloseClick}
              icon={<CloseIcon style={{ fontSize: 26 }} />}
              size={30}
              style={{ position: 'absolute', top: 16, right: 16 }}
              startWhite={true}
            />

            <PlayerImage
              src={otherPlayerOverview.image_url}
              isSingleImage={true}
              containerStyle={{ margin: 'auto', marginTop: '3vh', width: '13vh', height: 'auto', minWidth: 80 }}
              imageStyle={{ width: '80%', height: 'auto' }}
            />

            <div className='player-view-compare-other-name'>
              {otherPlayerOverview['fullname']}
            </div>

            <div className='player-view-compare-other-player-rating-section'>

              <div className='player-view-compare-other-player-rating-entry'>
                <div
                  className='player-view-compare-other-player-rating-label player-view-metric-label'
                  title={translate('showDescription', userConfig?.language)}
                  onClick={() => handleOpenMetricModal('confidence')}
                >
                  {translate('confidence', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-rating-value'>
                  <ProgressCircle
                    size={30}
                    progress={otherPlayerConfidence * 100}
                    color={getIndexColor(otherPlayerConfidence * 10, 65)}
                    value={Math.round(otherPlayerConfidence * 100)}
                    fontSize={13}
                  />
                </div>
              </div>

              <div className='player-view-compare-other-player-rating-entry'>
                <div
                  className='player-view-compare-other-player-rating-label player-view-metric-label'
                  title={translate('showDescription', userConfig?.language)}
                  onClick={() => handleOpenMetricModal('skill_index')}
                >
                  {translate('skillIndex', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-rating-value'>
                  {Math.round((otherPlayerOverview.position_group_stats.overall?.skill_index ?? 0) * 10) / 10}
                </div>
              </div>

            </div>

            <div className='player-view-compare-other-player-info-section'>
              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {translate('age', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getPlayerPropertyValue('birth_date', otherPlayerOverview, otherPlayerOverview.id, players, getAge, '-')}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {translate('position', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getDisplayPositions(otherPlayerOverview?.primary_positions, userConfig?.language) ?? '-'}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {translate('nationality', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  <CountryFlag
                    countryCode={getPlayerPropertyValue('country_code', otherPlayerOverview, otherPlayerOverview.id, players)}
                    width={16}
                    showCountryName={true}
                    showHyphenIfEmpty={true}
                    imageStyle={{ marginBottom: -1 }}
                  />
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {translate('league', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getDisplayCompetition(otherPlayerOverview.club, competitions)}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {translate('team', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  <ClubLogo
                    club={otherPlayerOverview.club}
                    size={18}
                    showClubName={true}
                    showHyphenIfEmpty={true}
                    containerStyle={{ gap: 3 }}
                  />
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {translate('height', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getPlayerPropertyValue('height', otherPlayerOverview) ?? '-'}
                </div>
              </div>

              <div className='player-view-compare-other-player-info-row'>
                <div className='player-view-compare-other-player-info-label'>
                  {translate('contract', userConfig?.language)}
                </div>
                <div className='player-view-compare-other-player-info-value'>
                  {getPlayerPropertyValue('contract_expiration', otherPlayerOverview, otherPlayerOverview.id, players, getDateLocaleFormat, '-')}
                </div>
              </div>
            </div>

          </div>
        )}

      </div>
    </div>
  );
};
