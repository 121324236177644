import { StringToAnyMap, HomePageData, StringToStringMap } from '../../types';
import { db, FirebaseUser } from '../../../firebase';

import {
  collection,
  doc,
  getDocs,
  getDoc
} from 'firebase/firestore';
import { trackEvent } from '../server/analytics/trackEvent';


// Get home page data
export const getHomePageData = async (
  clubId: number,
  sortedIterationIdsWithTableData: string[],
  iterationIdToTableOptions: StringToStringMap,
  competitionIdsToCompareWith: Set<string>,
  currentUser: FirebaseUser,
): Promise<HomePageData> => {

  // Get table data in the same order as tableIterationIds
  const tableCollectionRef = collection(db, 'iteration_tables');

  try {
    const tables = await getDocs(tableCollectionRef);

    const tableMap: StringToAnyMap = {};
    tables.docs.forEach(doc => {
      if (doc.id in iterationIdToTableOptions) {
        tableMap[doc.id] = doc.data().table;
      }
    });

    const tableOptions: string[] = [];
    const tableData: StringToAnyMap[][] = [];

    sortedIterationIdsWithTableData.forEach(iterationId => {
      if (tableMap[iterationId]) {
        tableOptions.push(iterationIdToTableOptions[iterationId]);
        tableData.push(tableMap[iterationId]);
      }
    });

    // Get line data
    const clubDataDocRef = doc(db, 'club_stats', String(clubId));
    const clubData = (await getDoc(clubDataDocRef)).data();

    if (!clubData) return { tableOptions: tableOptions, tableData: tableData, lineData: undefined };

    const lineData: StringToAnyMap = {
      'xGData': {
        'xGCreated': clubData['xGCreated'],
        'xGConceded': clubData['xGConceded']
      },
      'averageAge': {
        'club': clubData['averageAge']
      },
      'u21Share': {
        'club': clubData['u21Share']
      }
    };

    competitionIdsToCompareWith.forEach(async (competitionId) => {
      const competitionDataDocRef = doc(db, 'competition_stats', String(competitionId));
      const competitionData = (await getDoc(competitionDataDocRef)).data();

      if (competitionData) {

        // we must assure the number of points is the same for the average data as for the club data
        const clubAverageAgeMinDate = clubData['averageAge'][0]['date'];
        const clubAverageU21ShareMinDate = clubData['u21Share'][0]['date'];

        const clubAverageAgeMaxDate = clubData['averageAge'][clubData['averageAge'].length - 1]['date'];
        const clubAverageU21ShareMaxDate = clubData['u21Share'][clubData['u21Share'].length - 1]['date'];

        const competitionAverageAge = competitionData['averageAge'].filter((data: StringToAnyMap) => {
          return data['date'] >= clubAverageAgeMinDate && data['date'] <= clubAverageAgeMaxDate;
        });

        const competitionU21Share = competitionData['u21Share'].filter((data: StringToAnyMap) => {
          return data['date'] >= clubAverageU21ShareMinDate && data['date'] <= clubAverageU21ShareMaxDate;
        });

        competitionAverageAge[0]['date'] = clubAverageAgeMinDate;
        competitionU21Share[0]['date'] = clubAverageU21ShareMinDate;

        competitionAverageAge[competitionAverageAge.length - 1]['date'] = clubAverageAgeMaxDate;
        competitionU21Share[competitionU21Share.length - 1]['date'] = clubAverageU21ShareMaxDate;

        lineData['averageAge'][competitionId] = competitionAverageAge;
        lineData['u21Share'][competitionId] = competitionU21Share;
      }
    });

    return { tableOptions: tableOptions, tableData: tableData, lineData: lineData };
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'getHomePageData', errorDetails: { error } }, currentUser, 'system');
    return { tableOptions: [], tableData: [], lineData: undefined };
  }
};
