import './dropDowns.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { teamsState } from '../../../recoil/atoms/teamsState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BlockIcon from '@mui/icons-material/Block';

import { translate } from '../../../../common/language/translations';
import { Toggle } from '../input/Toggle';


interface DropDownProps {
  id: string;

  dropDownOptions: string[]; // static language keys
  dropDownSecondCategoryOptions?: string[];
  selectedOptions: string[];
  setSelectedOptions: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  height?: number;
  maxHeight?: string; // controls scrolling
  multiSelect?: boolean;

  toggleText?: string; // static language key
  isToggled?: boolean;
  setIsToggled?: (value: boolean) => void;

  forceSelection?: boolean; // if true, at least one option must be selected
  disabledOptions?: string[]; // options that are disabled

  optionsWithIcon?: string[];
  icon?: JSX.Element;
}

export const DropDown: React.FC<DropDownProps> = ({
  id,

  dropDownOptions,
  dropDownSecondCategoryOptions,
  selectedOptions,
  setSelectedOptions,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  height,
  maxHeight,
  multiSelect,

  toggleText,
  isToggled,
  setIsToggled,

  forceSelection,
  disabledOptions,

  optionsWithIcon,
  icon
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const teams = useRecoilValue(teamsState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean) => {

    let newSelectedOptions: string[] = [];

    // single-select
    if (!multiSelect) {
      if (!isOptionAlreadySelected && !setIsToggled) {
        removeDropDownExpansion();
      }
      newSelectedOptions = (isOptionAlreadySelected && !forceSelection) ? [] : [option];
    }

    // multi-select
    else {
      newSelectedOptions = selectedOptions.slice();
      if (isOptionAlreadySelected) {
        const i = newSelectedOptions.indexOf(option);
        newSelectedOptions.splice(i, 1);
      }
      else {
        newSelectedOptions.push(option);
      }
    }

    setSelectedOptions(newSelectedOptions);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = height ? height + 'px' : '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const getDisplayOption = (option: string) => {
    if (!userConfig) return '';

    // we want to map every option that is present in the language map
    const translation = translate(option, userConfig.language, undefined, undefined, true);
    if (translation) return translation;

    if (teams && option in teams) return teams[option].name;

    if (clubSettings && option in clubSettings.roleConfigs) return clubSettings.roleConfigs[option].name;

    return option;
  };


  const showSelectedOptions = () => {

    // single-select
    let firstSelection = getDisplayOption(selectedOptions[0]);

    if (!multiSelect) {
      return firstSelection;
    }

    // multi-select
    for (let i = 1; i < selectedOptions.length; i++) {
      firstSelection += ', ' + getDisplayOption(selectedOptions[i]);
    }
    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ height: height, maxHeight: maxHeight }}
    >

      <div
        className='drop-down-button'
        style={{ height: height }}
        onClick={() => handleDropDownClick()}
      >

        {selectedOptions.length > 0 && <div className='drop-down-button-overlay' />}

        <div className='drop-down-button-text'>
          {selectedOptions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon' style={{ marginTop: height ? (height - 24) / 2 : undefined }}>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-space'>&nbsp;</div>

      <div
        ref={dropDownRef}
        className={'drop-down-options' + (maxHeight ? ' drop-down-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}
      >
        {dropDownOptions.map(option => {
          const isSelected = selectedOptions.includes(option);
          const showIcon = optionsWithIcon && optionsWithIcon.includes(option) && icon;

          // option is disabled if it's in the incompatibleOptions list and an option outside of this list is selected,
          // or if it's not in the incompatibleOptions list and an option in this list is selected
          const isDisabled = (disabledOptions && disabledOptions.includes(option));
          // || (incompatibleOptions && (
          //   (incompatibleOptions.includes(option) && selectedOptions.some(selectedOption => !incompatibleOptions.includes(selectedOption))) ||
          //   (!incompatibleOptions.includes(option) && selectedOptions.some(selectedOption => incompatibleOptions.includes(selectedOption)))
          // ));

          return (
            <div
              className={
                'drop-down-option'
                + (isSelected ? ' drop-down-option-selected' : '')
                + (isDisabled ? ' drop-down-option-disabled' : '')
              }
              style={{ marginTop: marginBetweenOptions, paddingLeft: showIcon ? 16 : undefined }}
              key={option}
              onClick={() => !isDisabled && handleOptionSelect(option, isSelected)}
            >
              {showIcon && icon}
              {isDisabled &&
                <BlockIcon className='fast-fade-in' style={{ fontSize: 18, color: '#00000088', position: 'absolute', marginLeft: -24, marginTop: -2 }} />
              }
              {getDisplayOption(option)}
            </div>
          );
        })}

        {dropDownSecondCategoryOptions && (
          <div>
            <div className='drop-down-divider' />

            <div className='drop-down-options'>
              {dropDownSecondCategoryOptions.map(option => {
                const isSelected = selectedOptions.includes(option);
                return (
                  <div
                    className={'drop-down-option' + (isSelected ? ' drop-down-option-selected' : '')}
                    style={{ marginTop: marginBetweenOptions }}
                    key={option}
                    onClick={() => handleOptionSelect(option, isSelected)}
                  >
                    {getDisplayOption(option)}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {toggleText && isToggled !== undefined && setIsToggled !== undefined && (
          <div className='drop-down-toggle-section'>
            <div className='drop-down-toggle-text'>
              {translate(toggleText, userConfig?.language)}
            </div>
            <div className='drop-down-toggle'>
              <Toggle isToggled={isToggled} setIsToggled={setIsToggled} isWhiteBackground={true} />
            </div>
          </div>
        )}
      </div>

    </div>
  );
};
