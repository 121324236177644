import './../modals.css';

import CloseIcon from '@mui/icons-material/Close';

import { IconButton } from '../../controls/buttons/IconButton';
import { FormOrPlayingTimeTable } from '../../display/visualization/FormOrPlayingTimeTable';


interface TextModalProps {
  closeModal: () => void;
  textTitle: string;
  text: string;
  isMobileView: boolean;
}

export const TextModal: React.FC<TextModalProps> = ({
  closeModal,
  textTitle,
  text,
  isMobileView,
}) => {

  const textArray = text.split('\n');
  const textAlign = textArray.length === 1 ? 'center' : 'left';
  const alignItems = textAlign === 'center' ? 'center' : 'flex-start';

  const fontSize = 14;

  const paragraphMargin = 16;

  return (
    <div
      className='modal-root-container text-modal-root'
      style={{
        maxWidth: 650,
        minWidth: isMobileView ? undefined : 420,
      }}
    >

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
        isMobile={isMobileView}
      />

      <div className='modal-root-title'>
        {textTitle}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div
        className='modal-content-container text-modal-content-container'
        style={{
          fontSize: fontSize,
          textAlign: textAlign,
          alignItems: alignItems,
        }}
      >
        {textArray.map((textLine: string, index: number) => {

          const isEmptySpace = textLine === '&nbsp;';
          if (isEmptySpace) {
            return (
              <div
                key={index}
                style={{ height: paragraphMargin }}
              />
            );
          }

          const isFormTable = textLine === '<FormTable/>';
          const isPlayingTimeTable = textLine === '<PlayingTimeTable/>';
          if (isFormTable || isPlayingTimeTable) {
            return (
              <div
                key={index}
                style={{
                  marginTop: index > 0 ? paragraphMargin : undefined,
                  width: '100%',
                }}
              >
                <FormOrPlayingTimeTable isFormTable={isFormTable} />
              </div>
            );
          }

          return (
            <div
              key={index}
              style={{
                marginTop: index > 0 ? paragraphMargin : undefined,
              }}
            >
              {textLine}
            </div>
          );
        })}
      </div>

    </div>
  );
};
