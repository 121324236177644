import './../mobileTables.css';

import { useEffect, useMemo, useRef } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../../../recoil/atoms/clubSettingsState';
import { useSetAndTrackSelectedPlayerState } from '../../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../../../recoil/hooks/useOpenGlobalModal';

import { StringToAnyMap, PlayerOverview } from '../../../../types';
import { useWindowSize } from '../../../../../common/hooks/WindowSize';
import { debounce } from '../../../../utils/utils';
import { playerTableChapterSize, playerTablePageSize } from '../../../../views/scouting/Scouting';
import { getClubSecondaryColor } from '../../../../static/clubConfigs';
import { getMobilePlayerColumns, getMobilePlayerTableDynamicColumnsMinWidth } from './mobilePlayerColumns';


interface MobilePlayerTableProps {
  data: PlayerOverview[];

  isLoading?: boolean;
  handleSearchButtonPressed?: (isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  handleChangeCurrentChapter: (isIncrement: boolean) => void;

  selectedOrderBy?: string | undefined;
  selectedRoles?: string[];
}

export const MobilePlayerTable: React.FC<MobilePlayerTableProps> = ({
  data,

  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  handleChangeCurrentChapter,

  selectedOrderBy,
  selectedRoles,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize();
  const remainingWidth = width - 200;
  const dynamicColumnsMinWidth = getMobilePlayerTableDynamicColumnsMinWidth(selectedRoles, clubSettings?.roleConfigs);
  const widthUnit = remainingWidth / dynamicColumnsMinWidth;


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getMobilePlayerColumns(
      widthUnit,
      userConfig?.language ?? 'en',
      currentChapter,
      playerTablePageSize * playerTableChapterSize,
      totalHits,
      selectedRoles ?? [],
      clubSettings?.roleConfigs ?? {},
      selectedOrderBy,
      getClubSecondaryColor(userConfig?.club ?? ''),
      openTextModal,
      openRoleInfoModal,
      handleChangeCurrentChapter,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      width,
      userConfig,
      currentChapter,
      totalHits,
      clubSettings?.roleConfigs,
      selectedOrderBy,
      isLoading,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );


  const handleRowClick = (playerOverview: PlayerOverview) => {
    const initialPositionGroup = selectedRoles && selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[selectedRoles[0]]
      ? clubSettings.roleConfigs[selectedRoles[0]].positionGroup
      : undefined;

    setAndTrackSelectedPlayerState({
      id: playerOverview.id,
      fullname: playerOverview.fullname,
      playerOverview: playerOverview,
      initialPositionGroup: initialPositionGroup,
    });
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !handleSearchButtonPressed) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    const isMoreDataToFetch = ((currentChapter * playerTablePageSize * playerTableChapterSize) + data.length) < totalHits;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && isMoreDataToFetch) {
      await handleSearchButtonPressed(false);
    }
  };


  const getExtendedCellClassName = (cell: StringToAnyMap) => {
    if (cell.column.isFinalSubMetric) {
      return ' mobile-player-table-cell-with-border';
    }
    return '';
  };


  useEffect(() => {
    if (playerTableChapterSize > 1) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 150);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      ref={tableContainerRef}
      {...getTableProps()}
      className='mobile-player-table-container'
    >

      {headerGroups.map(headerGroup => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div
            key={key}
            {...restHeaderGroupProps}
            className='mobile-player-table-header-group'
          >
            {headerGroup.headers.map((column: StringToAnyMap) => {
              const { key: columnKey, ...restColumnProps } = column.getHeaderProps();
              return (
                <div
                  key={columnKey}
                  {...restColumnProps}
                  className='mobile-player-table-header-cell'
                >
                  {column.render('Header')}
                </div>
              );
            })}
          </div>
        );
      })}

      <div {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={i % 2 === 0 ? 'mobile-player-table-body-row-even' : 'mobile-player-table-body-row-odd'}
              onClick={() => handleRowClick(row.original)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      'mobile-player-table-body-cell'
                      + (i % 2 === 0 ? ' mobile-player-table-body-cell-even' : ' mobile-player-table-body-cell-odd')
                      + getExtendedCellClassName(cell)
                    }
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
