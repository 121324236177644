import './dropDowns.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../input/InputField';
import { Club, StringToAnyMap } from '../../../types';
import { debounce } from '../../../utils/utils';
import { searchClubs } from '../../../services/server/application/clubs';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { IconButton } from '../buttons/IconButton';
import { ClubLogo } from '../../display/images/ClubLogo';


interface ClubDropDownProps {
  id: string;

  selectedClubs: Club[];
  setSelectedClubs: (value: Club[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string | number; // controls scrolling
  singleSelect?: boolean;
}


export const ClubDropDown: React.FC<ClubDropDownProps> = ({
  id,

  selectedClubs,
  setSelectedClubs,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
  singleSelect,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);

  const { width } = useWindowSize();

  const inputRef = useRef<HTMLInputElement>(null);

  const [clubsToShow, setClubsToShow] = useState<Club[]>([]);

  const [searchString, setSearchString] = useState('');
  const [debouncedSearchString, setDebouncedSearchString] = useState<string>('');
  const clearDebounceRef = useRef<() => void>(() => null);

  const [isSearchWithoutResult, setIsSearchWithoutResult] = useState(false);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setClubsToShow([]);
      setDebouncedSearchString('');
      setIsSearchWithoutResult(false);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForClubs(true);
    }
  };


  const handleSearchForClubs = async (isUserInitiated: boolean) => {
    if (isUserInitiated) {
      clearDebounceRef.current();
    }

    const nextPageToQuery = 1; // only considering the first page for now

    if (searchString !== '') {
      const query = {
        name: searchString.toLowerCase(),
        page: nextPageToQuery,
      };

      const result: StringToAnyMap | undefined = await searchClubs(query, currentUser);

      if (!result) {
        // todo: handle error?
        setClubsToShow([]);
      }
      else if (result.total_hits === 0 || result.clubs.length === 0) {
        setClubsToShow([]);
        setIsSearchWithoutResult(true);
      }
      else {
        setClubsToShow(result.clubs);
        setIsSearchWithoutResult(false);
      }
    }
  };


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (club: Club, isOptionAlreadySelected: boolean) => {
    if (singleSelect) {
      setSelectedClubs(isOptionAlreadySelected ? [] : [club]);
      if (!isOptionAlreadySelected) removeDropDownExpansion();
      return;
    }

    let newSelectedClubs = selectedClubs.slice();

    if (isOptionAlreadySelected) {
      newSelectedClubs = newSelectedClubs.filter(item => item.id !== club.id);
    }
    else {
      newSelectedClubs.push(club);
    }

    setSelectedClubs(newSelectedClubs);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const showSelectedCountries = () => {

    if (!userConfig || selectedClubs.length === 0) return '';

    let display = selectedClubs[0].name;

    for (let i = 1; i < selectedClubs.length; i++) {
      display += ', ' + selectedClubs[i].name;
    }

    return display;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
      setSearchString('');
      setDebouncedSearchString('');
      setClubsToShow([]);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString.length === 0) {
      setClubsToShow([]);
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (searchString !== debouncedSearchString) {
        setDebouncedSearchString(searchString);
      }
    }, 600);

    debounceFilter();

    clearDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedSearchString !== '') {
      handleSearchForClubs(false);
    }
  }, [debouncedSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-button' onClick={() => handleDropDownClick()}>

        {selectedClubs.length > 0 && <div className='drop-down-button-overlay fast-fade-in' />}

        <div className='drop-down-button-text'>

          {selectedClubs.length === 0 && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          )}

          {selectedClubs.length > 0 && isDropDownExpanded && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(singleSelect ? 'selectedClub' : 'selectedClubs', userConfig?.language)}
            </div>
          )}

          {selectedClubs.length > 0 && !isDropDownExpanded && (
            <div style={{ color: '#000000' }}>
              {showSelectedCountries()}
            </div>
          )}

        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-section-with-search' style={{ maxHeight: maxHeight }}>

        <div className='drop-down-with-search-selected-options'>
          {selectedClubs.map((club: Club) => {
            return (
              <div
                className='drop-down-with-search-selected-option'
                key={club.id + '-selected'}
              >
                <IconButton
                  icon={<CloseIcon style={{ fontSize: 16 }} />}
                  onClick={() => handleOptionSelect(club, true)}
                  size={18}
                  backgroundColorBehindIcon='white'
                  style={{ marginLeft: -2 }}
                />

                <ClubLogo
                  club={club}
                  size={13}
                  showClubName={true}
                  containerStyle={{ marginLeft: 2, fontSize: 11, gap: 3 }}
                />
              </div>
            );
          })}
        </div>

        <div
          className='drop-down-with-search-input-container'
          style={{ marginTop: singleSelect ? (34) : (12 + (22 * Math.max(selectedClubs.length, 2))) }}
        >
          <InputField
            ref={inputRef}
            value={searchString}
            onChange={onChangeSearchField}
            onKeyDown={onKeyDownSearchField}
            resetValue={() => setSearchString('')}
            placeholder={translate(width > 1350 ? 'searchForClub' : 'search', userConfig?.language)}
            isWhiteBackground={true}
          />
        </div>

        {clubsToShow.length > 0 &&
          clubsToShow.map((club: Club) => {
            const isSelected = selectedClubs.some(item => item.id === club.id);
            return (
              <div
                className={'drop-down-option' + (isSelected ? ' drop-down-option-selected' : '')}
                style={{ marginTop: marginBetweenOptions }}
                key={club.id}
                onClick={() => handleOptionSelect(club, isSelected)}
              >
                <ClubLogo
                  club={club}
                  size={14}
                  showClubName={true}
                  containerStyle={{ marginLeft: 2, gap: 3 }}
                />
              </div>
            );
          })}

        {isSearchWithoutResult && (
          <div className='drop-down-with-search-no-search-result'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

      </div>

    </div>
  );
};
