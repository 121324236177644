import axios from 'axios';

import { FirebaseUser } from '../../../../firebase';
import { PositionGroupClubIteration, PositionGroupClubIterationsQueryOptions } from '../../../types';

import { APPLICATION_API_URL } from './../constants';
import { getBearerToken } from './../token';

import { getSortByMetricName, getNormalizedMetricName } from '../../../static/playerMetrics';
import { getCountryCodeSearchString } from './../utils';
import { trackEvent } from '../analytics/trackEvent';


export interface SearchPositionGroupClubIterationsResponse {
  total_hits: number;
  current_page: number;
  players: PositionGroupClubIteration[];
}


// Search for position group club iterations
export const searchPositionGroupClubIterations = async (
  queryOptions: PositionGroupClubIterationsQueryOptions,
  currentUser: FirebaseUser,
): Promise<SearchPositionGroupClubIterationsResponse | undefined> => {

  // if the array is undefined, we don't concern with any player ids, but if the array is empty, we attempt to search for an empty set of players
  if (queryOptions.playerIds && queryOptions.playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  try {
    const token = await getBearerToken(currentUser);

    const primaryPositionsString = queryOptions.primaryPositions ? queryOptions.primaryPositions.join(',') : undefined;

    const iterationsString = queryOptions.iterations ? queryOptions.iterations.join(',') : undefined;

    const countryCodesString = queryOptions.countryCodes ? getCountryCodeSearchString(queryOptions.countryCodes) : undefined;

    const clubsString = queryOptions.clubs ? queryOptions.clubs.join(',') : undefined;

    const ratingRequirementsString = queryOptions.ratingRequirements
      ? queryOptions.ratingRequirements.map(({ metric, value }) => `${getNormalizedMetricName(metric)}:${value}`).join(',')
      : undefined;

    const correctSortBy = getSortByMetricName(queryOptions.sortBy, queryOptions.sortByActualValues);

    const playerIdsString = queryOptions.playerIds ? queryOptions.playerIds.join(',') : undefined;

    const result = await axios.get(`${APPLICATION_API_URL}/search/positionGroupClubIterations/`, {
      headers: { Authorization: token },
      params: {
        min_age: queryOptions.minAge,
        max_age: queryOptions.maxAge,
        max_contract_length: queryOptions.maxContractLength,
        include_unknown_contracts: queryOptions.includeUnknownContracts,
        primary_positions: primaryPositionsString,
        position_group: queryOptions.positionGroup,
        iterations: iterationsString,
        min_minutes_played: queryOptions.minMinutesPlayed,
        max_minutes_played: queryOptions.maxMinutesPlayed,
        min_minutes_played_percentage: queryOptions.minMinutesPlayedPercentage,
        max_minutes_played_percentage: queryOptions.maxMinutesPlayedPercentage,
        country_codes: countryCodesString,
        player_ids: playerIdsString,
        club_ids: clubsString,
        rating_requirements: ratingRequirementsString,
        sort_by: correctSortBy,
        page: queryOptions.page,
        page_size: queryOptions.pageSize,
      },
    });

    if (!result) return undefined;

    return result.data;
  }

  catch (error) {
    trackEvent('Error', { api: 'server', function: 'searchPositionGroupClubIterations', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};
