import { PlayerOverview } from '../../../types';


export type MobileScoutingFilter =
  | 'minConfidence'
  | 'maxAge'
  | 'positionsAndRoles'
  | 'leagues'
  | 'orderBy'


// name strings are handled separately due to debounce logic
export interface MobileScoutingState {

  // selected filters
  nameSearchString: string;
  selectedMinConfidence: string[];
  selectedMaxAge: string[];
  selectedPositions: string[];
  selectedRoles: string[];
  selectedLeagues: string[];
  selectedOrderBy: string[];

  // filters applied to current search result
  currentNameSearchString: string;
  currentMinConfidence: string[];
  currentMaxAge: string[];
  currentPositions: string[];
  currentRoles: string[];
  currentLeagues: string[];
  currentOrderBy: string[];

  // filters
  isFilterSectionExpanded: boolean;
  showFilterSection: boolean;
  currentFilterExpanded: MobileScoutingFilter | undefined,
  previousFilterExpanded: MobileScoutingFilter | undefined;

  // search state
  tableData: PlayerOverview[];
  currentModuloPage: number; // currentModuloPage (page % pagesPerChapter) is 1-indexed
  currentChapter: number; // chapters are 0-indexed
  totalHits: number;
  isLoading: boolean;
  isEmptySearchResult: boolean;
}


export const initialMobileScoutingState: MobileScoutingState = {

  // selected filters
  nameSearchString: '',
  selectedMinConfidence: [],
  selectedMaxAge: [],
  selectedPositions: [],
  selectedRoles: [],
  selectedLeagues: [],
  selectedOrderBy: ['skillIndex'],

  // filters applied to current search result
  currentNameSearchString: '',
  currentMinConfidence: [],
  currentMaxAge: [],
  currentPositions: [],
  currentRoles: [],
  currentLeagues: [],
  currentOrderBy: ['skillIndex'],

  // filters
  isFilterSectionExpanded: false,
  showFilterSection: false,
  currentFilterExpanded: undefined,
  previousFilterExpanded: undefined,

  // search state
  tableData: [],
  currentModuloPage: 0,
  currentChapter: 0,
  totalHits: 0,
  isLoading: false,
  isEmptySearchResult: false,
};


export const anyMobileFilterExceptNameAndDefaultOrderBySelected = (state: MobileScoutingState) => {

  const otherOrderByThanDefaultSelected = state.selectedOrderBy.length > 0 && state.selectedOrderBy[0] !== 'skillIndex';

  return (
    state.selectedMinConfidence.length > 0
    || state.selectedMaxAge.length > 0
    || state.selectedPositions.length > 0
    || state.selectedRoles.length > 0
    || state.selectedLeagues.length > 0
    || otherOrderByThanDefaultSelected
  );
};
