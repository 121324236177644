import './input.css';

import { forwardRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { nameSearchHistorySelector } from '../../../recoil/selectors/scoutingConfigSelectors';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import CloseIcon from '@mui/icons-material/Close';

import { deleteNameFromNameSearchHistory } from '../../../services/firestore/scouting';
import { IconButton } from '../buttons/IconButton';


interface InputFieldProps {
  value: string;
  placeholder?: string;
  placeholderFontSize?: number;

  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  resetValue?: () => void;
  disabled?: boolean;
  showDisabled?: boolean;

  onClick?: () => void;

  showNameSearchHistory?: boolean;
  onNameSearchHistoryClick?: (value: string) => void;
  maxHeight?: string;

  height?: number;
  width?: number;
  isSquare?: boolean;
  isWhiteBackground?: boolean;
  isWhiteBackgroundWhenExpanded?: boolean;
  isMobile?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>((
  {
    value,
    placeholder,
    placeholderFontSize = 13,

    onChange,
    onKeyDown,
    resetValue,
    disabled,
    showDisabled,

    onClick,

    showNameSearchHistory,
    onNameSearchHistoryClick,
    maxHeight,

    height = 28,
    width = '100%',
    isSquare,
    isWhiteBackground,
    isWhiteBackgroundWhenExpanded,
    isMobile,
  },
  ref,
) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const userConfig = useRecoilValue(userConfigState);
  const nameSearchHistory = useRecoilValue(nameSearchHistorySelector);


  const [isFocused, setIsFocused] = useState(false);


  const handleOnNameSearchHistoryClick = (name: string, event: React.MouseEvent) => {
    if (onNameSearchHistoryClick && event.button === 0) {
      onNameSearchHistoryClick(name);
    }
  };


  const handleDeleteNameFromNameSearchHistory = async (name: string, event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();

    if (!userConfig) return;

    await deleteNameFromNameSearchHistory(name, nameSearchHistory, userConfig?.email, userConfig?.club, currentUser);
  };


  const isExpandable = showNameSearchHistory;
  const isExpanded = showNameSearchHistory && isFocused && nameSearchHistory.length > 0 && value.length === 0;

  const containerHeight = isExpanded ? 'auto' : height;

  const whiteBackgroundBoxShadow = '0px 0px 2px 1px #15171e35';
  const darkBackgroundBoxShadow = '0px 0px 2px 1px #15171e70';

  const defaultBoxShadow = isWhiteBackground
    ? whiteBackgroundBoxShadow
    : darkBackgroundBoxShadow;

  const focusedBoxShadow = isExpandable
    ? (isWhiteBackgroundWhenExpanded ? whiteBackgroundBoxShadow : defaultBoxShadow)
    : '0px 0px 0px 1.5px #6a92cf';

  const borderRadius = isSquare ? 2 : 14;
  const paddingLeft = isSquare ? 8 : 14;


  document.documentElement.style.setProperty('--input-field-placeholder-font-size', placeholderFontSize + 'px');


  return (
    <div
      className={
        'input-field-container'
        + (isExpanded ? ' input-field-container-expanded' : '')
        + (disabled && showDisabled ? ' input-field-container-disabled' : '')
      }
      style={{
        height: containerHeight,
        width: width ?? '100%',
        boxShadow: isFocused ? focusedBoxShadow : defaultBoxShadow,
        borderRadius: borderRadius,
      }}
    >

      <div
        className='input-field-input-container'
        style={{
          height: height,
          borderRadius: borderRadius,
        }}
      >

        <input
          ref={ref}
          className={'input-field-input' + (disabled && showDisabled ? ' input-field-input-disabled' : '')}
          style={{ paddingLeft: paddingLeft }}
          name='input-field'
          type='text'
          autoComplete='off'
          spellCheck='false'
          value={value}
          placeholder={placeholder}
          onChange={(event) => onChange(event)}
          onKeyDown={(event) => onKeyDown ? onKeyDown(event) : undefined}
          disabled={disabled}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onClick={onClick}
        />

        {value.length > 0 && resetValue && (
          <IconButton
            icon={<CloseIcon style={{ fontSize: 16 }} />}
            onClick={() => resetValue()}
            size={20}
            style={{ position: 'absolute', right: 4, zIndex: 1 }}
            backgroundColorBehindIcon='white'
          />
        )}
      </div>

      {isExpanded && (
        <div
          className='name-search-history'
          style={{ maxHeight: maxHeight }}
        >
          {nameSearchHistory.map((name, index) => (
            <div
              key={index}
              className={'name-search-history-item' + (!isMobile ? ' name-search-history-item-hover' : '')}
              onMouseDown={(event) => handleOnNameSearchHistoryClick(name, event)}
            >
              {name}

              {!isMobile && <div className='name-search-history-item-overlay' />}

              <div
                className={'name-search-history-close-icon' + (isMobile ? '-mobile' : '')}
                onMouseDown={(event) => handleDeleteNameFromNameSearchHistory(name, event)}
              >
                <CloseIcon style={{ fontSize: 15 }} />
              </div>
            </div>
          ))}
        </div>
      )}

    </div>
  );
});

InputField.displayName = 'InputField';
