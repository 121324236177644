import { selector } from 'recoil';
import { scoutingConfigState } from '../atoms/scoutingConfigState';


// Selector for savedSearches
export const savedSearchesSelector = selector({
  key: 'savedSearchesSelector',
  get: ({ get }) => get(scoutingConfigState).savedSearches,
});


// Selector for nameSearchHistory
export const nameSearchHistorySelector = selector({
  key: 'nameSearchHistorySelector',
  get: ({ get }) => get(scoutingConfigState).nameSearchHistory,
});


// Selector for alerts
export const alertsConfigSelector = selector({
  key: 'alertsConfigSelector',
  get: ({ get }) => {
    const scoutingConfig = get(scoutingConfigState);
    return {
      alerts: scoutingConfig.alerts,
      playerIdsToExcludeFromAllAlerts: scoutingConfig.playerIdsToExcludeFromAllAlerts,
    };
  },
});
