import '../mobilePlatform.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import HomeIcon from '@mui/icons-material/Home';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import FolderIcon from '@mui/icons-material/Folder';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { translate } from '../../../common/language/translations';
import { getClubSecondaryColor } from '../../static/clubConfigs';
import { useWindowSize } from '../../../common/hooks/WindowSize';


export type MobileNavigationTab = 'home' | 'players' | 'scouting' | 'reports' | 'account';

const tabOptions: MobileNavigationTab[] = [
  'home',
  'players',
  'scouting',
  'reports',
  'account'
];

const tabIcons: JSX.Element[] = [
  <HomeIcon key={'home'} style={{ fontSize: 23 }} />,
  <GroupsIcon key={'players'} style={{ fontSize: 25, marginBottom: -1 }} />,
  <PersonSearchIcon key={'scouting'} style={{ fontSize: 24, marginRight: -3 }} />,
  <FolderIcon key={'reports'} style={{ fontSize: 22 }} />,
  <AccountCircleIcon key={'account'} style={{ fontSize: 22 }} />,
];

interface MobileNavigationProps {
  activeTab: MobileNavigationTab;
  setActiveTab: (tab: MobileNavigationTab) => void;
}

export const MobileNavigation: React.FC<MobileNavigationProps> = ({ activeTab, setActiveTab }) => {

  const userConfig = useRecoilValue(userConfigState);
  const { width } = useWindowSize();

  const clubSecondaryColor = getClubSecondaryColor(userConfig?.club ?? '');


  const [tabLineLeft, setTabLineLeft] = useState<number | string>(5); // must match padding of mobile-navigation-container


  const updateTabLine = (tab: MobileNavigationTab) => {
    const tabElement = document.getElementById(tab);
    if (tabElement) {
      setTabLineLeft(tabElement.offsetLeft);
    }
  };


  const handleNavigation = (tab: MobileNavigationTab) => {
    setActiveTab(tab);
    updateTabLine(tab);
  };


  useEffect(() => {
    let count = 0;
    const maxCount = 10;
    const interval = 10;

    const intervalId = setInterval(() => {
      updateTabLine(activeTab);
      count++;
      if (count >= maxCount) {
        clearInterval(intervalId);
      }
    }, interval);

    return () => clearInterval(intervalId);
  }, [width]); // eslint-disable-line react-hooks/exhaustive-deps


  return (

    <div className='mobile-navigation-container'>

      <div
        className='mobile-navigation-tab-line-container'
        style={{ left: tabLineLeft }}
      >
        <div
          className='mobile-navigation-tab-line'
          style={{ backgroundColor: clubSecondaryColor }}
        >
          &nbsp;
        </div>
      </div>

      {tabOptions.map((tab, index) => (
        <div
          key={tab}
          id={tab}
          className='mobile-navigation-tab'
          onClick={() => handleNavigation(tab)}
          style={{ color: activeTab === tab ? clubSecondaryColor : '#ffffff88' }}
        >
          <div className='mobile-navigation-tab-icon'>
            {tabIcons[index]}
          </div>
          <div className='mobile-navigation-tab-text'>
            {translate(tab, userConfig?.language)}
          </div>
        </div>
      ))}

    </div>

  );
};
