import { useCallback, useEffect, useState } from 'react';

import { CompetitionMap, PlayerClubIteration, StringToAnyMap } from '../../types';


interface PlayerLinePlotProps {
  playerClubIterations: PlayerClubIteration[];
  selectedClubIterationId: string | undefined,
  selectedPositionGroupKey: string;
  clubColor: string;
  competitions: CompetitionMap;
}


// x, y, r, minutes, team, xTextOffset, yTextOffset, isSelectedClubIteration
type PlayerLinePoint = [number, number, number, number, string, number, number, boolean];


// todo: this should eventually be dynamic once we have even more seasons with event data
const startDate = new Date(2020, 1, 0);


export const PlayerLinePlot: React.FC<PlayerLinePlotProps> = ({
  playerClubIterations,
  selectedClubIterationId,
  selectedPositionGroupKey,
  clubColor,
  competitions,
}) => {


  // points = [[x, y, r, 'team'], ...]
  const [linePoints, setLinePoints] = useState<PlayerLinePoint[]>([]);
  const [linePath, setLinePath] = useState('');
  // const [areaPath, setAreaPath] = useState('');

  const [yearToXCoordinates, setYearToXCoordinates] = useState<{ [key: number]: number }>({});


  const getDaysSinceStartDate = useCallback((date: Date) => {
    const diffInMs = date.getTime() - startDate.getTime();
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

    return Math.floor(diffInDays);
  }, []);


  const createPathData = (points: PlayerLinePoint[]): string => {

    // need at least two points to draw a curve
    if (points.length < 2) {
      return '';
    }

    function catmullRomToBezier(p0: PlayerLinePoint, p1: PlayerLinePoint, p2: PlayerLinePoint, p3: PlayerLinePoint, tension: number): string {
      const cp1x = p1[0] + (p2[0] - p0[0]) / 6 * tension;
      const cp1y = p1[1] + (p2[1] - p0[1]) / 6 * tension;
      const cp2x = p2[0] - (p3[0] - p1[0]) / 6 * tension;
      const cp2y = p2[1] - (p3[1] - p1[1]) / 6 * tension;

      return ` C ${cp1x},${cp1y}, ${cp2x},${cp2y}, ${p2[0]},${p2[1]}`;
    }

    let line = `M ${points[0][0]},${points[0][1]}`;

    for (let i = 0; i < points.length - 1; i++) {
      const p0 = i === 0 ? points[0] : points[i - 1];
      const p1 = points[i];
      const p2 = points[i + 1];
      const p3 = i === points.length - 2 ? points[points.length - 1] : points[i + 2];

      line += catmullRomToBezier(p0, p1, p2, p3, 1);
    }

    // let area = line + ` L ${points[points.length - 1][0]},470 L ${points[0][0]},470 Z`;

    return line;
  };


  useEffect(() => {

    // only consider iterations that are leagues and have enough minutes played
    let relevantClubIterations: StringToAnyMap[] = playerClubIterations.map((iteration, index) => ({ ...iteration, clubIterationIndex: index }));
    relevantClubIterations = relevantClubIterations.filter(clubIteration => {

      const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
        ? clubIteration.position_groups[0]
        : selectedPositionGroupKey;

      if (!clubIteration.event_data_available || !clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration]) return false;

      const isLeague = clubIteration.competition_id in competitions && competitions[clubIteration.competition_id].type === 'League';

      const minutesPlayed = clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].minutes_played;

      return isLeague && minutesPlayed >= 180;
    });

    // calculate units on x-axis
    const currentDate = new Date();
    const totalDaysSinceStartDate = getDaysSinceStartDate(currentDate);
    const xUnitPerDay = 750 / totalDaysSinceStartDate;

    // calculate x-coordinates of year-labels
    const xCoordinates: { [key: number]: number } = {};
    const currentYear = currentDate.getFullYear();
    const startYear = startDate.getFullYear();
    const numberOfYears = currentYear - startYear + 1;
    for (let i = 1; i < numberOfYears; i++) {
      const year = startYear + i;

      const yearStartDate = new Date(year, 0, 1);
      const daysSinceStartDate = getDaysSinceStartDate(yearStartDate);
      const xCoordinate = 115 + (daysSinceStartDate * xUnitPerDay);

      xCoordinates[year] = xCoordinate;
    }

    // calculate y-coordinates of iteration points
    const ys: number[] = [];
    for (let i = 0; i < relevantClubIterations.length; i++) {
      const clubIteration = relevantClubIterations[i];

      const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
        ? clubIteration.position_groups[0]
        : selectedPositionGroupKey;

      const skillRating = clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].skill_rating;
      const y = 500 - (skillRating * 40);
      ys.push(y);
    }

    // calculate points
    const points: PlayerLinePoint[] = [];
    for (let i = 0; i < relevantClubIterations.length; i++) {
      const clubIteration = relevantClubIterations[i];

      const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
        ? clubIteration.position_groups[0]
        : selectedPositionGroupKey;

      const skillRating = clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].skill_rating;
      const isSpaceBelowPoint = skillRating > 1.8;

      // x
      const date = new Date(clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].plot_date);
      const daysSinceStartDate = getDaysSinceStartDate(date);
      const x = 115 + (daysSinceStartDate * xUnitPerDay);

      // r & minutesPlayed
      const minutesPlayed = clubIteration.position_group_stats[selectedPositionGroupKeyForIteration].minutes_played;
      const r = 6 + Math.min(minutesPlayed / 400, 6);

      // team
      const team = clubIteration.club?.name ?? '';

      // text offsets ()
      let isNextPointAbove: boolean | undefined = undefined;
      let isPreviousPointAbove: boolean | undefined = undefined;
      if (i > 0) {
        isNextPointAbove = ys[i - 1] < ys[i];
      }
      if (i < relevantClubIterations.length - 1) {
        isPreviousPointAbove = ys[i + 1] < ys[i];
      }

      let xTextOffset = 0;
      let yTextOffset = 0;

      // only point
      if (isPreviousPointAbove === undefined && isNextPointAbove === undefined) {
        yTextOffset = -40;
      }

      // first point
      else if (isPreviousPointAbove === undefined) {
        yTextOffset = isNextPointAbove
          ? isSpaceBelowPoint
            ? 34
            : -44
          : -40;

        xTextOffset = isNextPointAbove && !isSpaceBelowPoint
          ? -30
          : -6;
      }

      // last point
      else if (isNextPointAbove === undefined) {
        yTextOffset = isPreviousPointAbove
          ? isSpaceBelowPoint
            ? 34
            : -44
          : -40;

        xTextOffset = isPreviousPointAbove && yTextOffset < 0
          ? 30
          : 6;
      }

      // points below both neighbors
      else if (isPreviousPointAbove && isNextPointAbove) {
        if (isSpaceBelowPoint) {
          yTextOffset = 34;
        }
        else {
          yTextOffset = -50;
        }
      }

      // points above both neighbors
      else if (!isPreviousPointAbove && !isNextPointAbove) {
        yTextOffset = -40;
      }

      // points between neighbors (downhill)
      else if (isPreviousPointAbove && !isNextPointAbove) {
        xTextOffset = 30;
        yTextOffset = -44;
      }

      // points between neighbors (uphill)
      else if (!isPreviousPointAbove && isNextPointAbove) {
        xTextOffset = -30;
        yTextOffset = -44;
      }

      points.push([x, ys[i], r, minutesPlayed, team, xTextOffset, yTextOffset, clubIteration.club_iteration_id === selectedClubIterationId]);
    }

    const line = createPathData(points);

    setLinePath(line);
    // setAreaPath(area);

    setLinePoints(points);
    setYearToXCoordinates(xCoordinates);
  }, [selectedPositionGroupKey, selectedClubIterationId, playerClubIterations, getDaysSinceStartDate, competitions]);


  // const svgRef = useRef<SVGSVGElement>(null);
  // const [shouldPreserveAspectRatio, setShouldPreserveAspectRatio] = useState(false);

  // useEffect(() => {
  //   const observer = new ResizeObserver(entries => {
  //     for (let entry of entries) {
  //       const { width, height } = entry.contentRect;
  //       var aspectRatio = width / height;
  //       const shouldPreserve = aspectRatio < 1 || aspectRatio > 2.5;
  //       setShouldPreserveAspectRatio(shouldPreserve);
  //     }
  //   });

  //   if (svgRef.current) {
  //     observer.observe(svgRef.current.parentElement!);
  //   }

  //   return () => observer.disconnect();
  // }, [shouldPreserveAspectRatio]);


  // x-axis ranges from 115 (January 1st, 2019) to 815 (current date)  ->  700 units
  // y-axis ranges from 100 (skill_index = 10) to 500 (skill_index = 0)  ->  400 units

  return (
    // <svg ref={svgRef} className='player-view-svg-plot' viewBox={'0 0 1000 580'} preserveAspectRatio={shouldPreserveAspectRatio ? 'xMidYMid meet' : 'none'}>
    <svg className='player-view-svg-plot' viewBox={'0 0 1000 580'} preserveAspectRatio={'xMidYMid meet'}>

      <defs>
        <linearGradient id='player-line-plot-area' x1='0%' y1='0%' x2='0%' y2='100%'>
          <stop offset='0%' style={{ stopColor: clubColor, stopOpacity: 0.12 }} />
          <stop offset='100%' style={{ stopColor: clubColor, stopOpacity: 0 }} />
        </linearGradient>
      </defs>

      {/* <rect width='1000' height='580' style={{ fill: 'none', stroke: '#ffffff88', strokeWidth: 4 }} /> */}

      {/* x-axis */}
      <line x1='114.6' y1='500' x2='900' y2='500' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />

      {/* y-axis */}
      <line x1='115' y1='100' x2='115' y2='500' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />

      {/* x-axis labels */}
      {Object.entries(yearToXCoordinates).map(([year, x]) => (
        <g key={year}>
          <line x1={x} y1='500' x2={x} y2='510' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
          <text x={x} y='544' textAnchor='middle' fill='#ffffffaa' fontSize={23} fontFamily=''>{year}</text>
        </g>
      ))}

      {/* y-axis labels and value lines */}
      <line x1='115' y1='100' x2='900' y2='100' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='102' y1='100' textAnchor='middle' x2='115' y2='100' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='69' y='108' textAnchor='middle' fill='#ffffffaa' fontSize={24} fontFamily=''>10</text>

      <line x1='115' y1='180' x2='900' y2='180' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='102' y1='180' x2='115' y2='180' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='69' y='188' textAnchor='middle' fill='#ffffffaa' fontSize={24} fontFamily=''>8</text>

      <line x1='115' y1='260' x2='900' y2='260' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='102' y1='260' x2='115' y2='260' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='69' y='268' textAnchor='middle' fill='#ffffffaa' fontSize={24} fontFamily=''>6</text>

      <line x1='115' y1='340' x2='900' y2='340' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='102' y1='340' x2='115' y2='340' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='69' y='348' textAnchor='middle' fill='#ffffffaa' fontSize={24} fontFamily=''>4</text>

      <line x1='115' y1='420' x2='900' y2='420' style={{ stroke: '#ffffff22', strokeWidth: 1 }} />
      <line x1='102' y1='420' x2='115' y2='420' style={{ stroke: '#8c90a1', strokeWidth: 1 }} />
      <text x='69' y='428' textAnchor='middle' fill='#ffffffaa' fontSize={24} fontFamily=''>2</text>

      {/* iteration points */}
      {linePoints.map(([x, y, r, minutes, team, xTextOffset, yTextOffset, isSelectedClubIteration]) => (
        <g key={x + ',' + y}>
          <circle cx={x} cy={y} r={r} style={{ fill: clubColor, stroke: 'none' }} />

          <text
            x={x + xTextOffset}
            y={y + yTextOffset}
            textAnchor='middle'
            fill={'#ffffff' + (isSelectedClubIteration ? 'bb' : '77')}
            fontSize={isSelectedClubIteration ? 17 : 16}
            fontFamily=''>
            {team}
          </text>

          <text
            x={x + xTextOffset}
            y={y + yTextOffset + 18}
            textAnchor='middle'
            fill={'#ffffff' + (isSelectedClubIteration ? 'bb' : '77')}
            fontSize={isSelectedClubIteration ? 16 : 15}
            fontFamily=''>
            {Math.round(minutes) + ' min'}
          </text>
        </g>
      ))}

      {/* line path */}
      <path d={linePath} style={{ fill: 'none', stroke: clubColor, strokeWidth: 4 }} />

      {/* line area path */}
      {/* <path d={areaPath} fill='url(#player-line-plot-area)' /> */}

    </svg>
  );
};
