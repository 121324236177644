
import StarsIcon from '@mui/icons-material/Stars';
import DiamondIcon from '@mui/icons-material/Diamond';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import HealingIcon from '@mui/icons-material/Healing';
import AddIcon from '@mui/icons-material/Add';
import RocketIcon from '@mui/icons-material/Rocket';
import EventSeatIcon from '@mui/icons-material/EventSeat';

import HomeIcon from '@mui/icons-material/Home';
import FlagIcon from '@mui/icons-material/Flag';
import LanguageIcon from '@mui/icons-material/Language';

import ConstructionIcon from '@mui/icons-material/Construction';
import StarIcon from '@mui/icons-material/Star';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SchoolIcon from '@mui/icons-material/School';
import GroupIcon from '@mui/icons-material/Group';
import PublicIcon from '@mui/icons-material/Public';

import SpaIcon from '@mui/icons-material/Spa';
import HdrAutoIcon from '@mui/icons-material/HdrAuto';

import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import WorkIcon from '@mui/icons-material/Work';
import PaidIcon from '@mui/icons-material/Paid';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import BlockIcon from '@mui/icons-material/Block';

import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import KeyIcon from '@mui/icons-material/Key';
import SyncIcon from '@mui/icons-material/Sync';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import SortIcon from '@mui/icons-material/Sort';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined';
import AlignVerticalCenterOutlinedIcon from '@mui/icons-material/AlignVerticalCenterOutlined';

import { getIndexColor } from './colorUtils';
import { AccessOption, AggregationRule, AvailabilityStatus, FormStatus, PlayerLocality, SeasonSubConditionStatus, StringToStringMap, TeamRole } from '../types';


const defaultIconColorMap: StringToStringMap = {
  'dark': '#2f3441',
  'light': '#ffffffdd'
};


export const getRoleIcon = (role: TeamRole, fontSize: number, iconColor?: string, clubLightColor?: string, returnAddIfNoRole?: boolean) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (role === 'keyPlayer') {
    if (clubLightColor) {
      return (
        <div
          style={{
            position: 'relative',
            height: fontSize,
            width: fontSize,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <StarsIcon style={{ fontSize: fontSize, color: iconColor, zIndex: 1 }} />
          <div
            style={{
              position: 'absolute',
              height: fontSize - 6,
              width: fontSize - 6,
              borderRadius: 100,
              backgroundColor: clubLightColor,
            }}
          />
        </div>
      );
    }
    return <StarsIcon style={{ fontSize: fontSize, color: iconColor, zIndex: 1 }} />;
  }

  if (role === 'valuePlayer') return <DiamondIcon style={{ fontSize: fontSize, color: iconColor, marginBottom: -1 }} />;
  if (role === 'rotationPlayer') return <GroupIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (role === 'loanedIn') return <CreditCardIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (role === 'developmentPlayer') return <RocketIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (role === 'reserve') return <EventSeatIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (role === 'longTermInjured') return <HealingIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (role === 'loanedOut') return <PersonOffIcon style={{ fontSize: fontSize, color: iconColor }} />;

  if (role === 'trialist') return <HdrAutoIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (role === 'talent') return <StarIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (role === 'potential') return <SpaIcon style={{ fontSize: fontSize, color: iconColor }} />;

  if (returnAddIfNoRole) return <AddIcon style={{ fontSize: fontSize, color: iconColor }} />;
};


export const getLocalityIcon = (locality: PlayerLocality, fontSize: number, iconColor?: string) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (locality === 'local') return <HomeIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (locality === 'national') return <FlagIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (locality === 'international') return <LanguageIcon style={{ fontSize: fontSize, color: iconColor }} />;
};


export const getAccessIcon = (access: AccessOption, fontSize: number, iconColor?: string) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (access === 'admin') return <AdminPanelSettingsIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (access === 'full') return <WorkIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (access === 'economy') return <PaidIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (access === 'scout') return <TravelExploreIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (access === 'none') return <BlockIcon style={{ fontSize: fontSize, color: iconColor }} />;
};


export const getTeamIcon = (currentTeam: string | undefined, fontSize: number, iconColor?: string, isSquad?: boolean) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (isSquad) return <ConstructionIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (currentTeam === 'ownTeam') return <GroupIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (currentTeam === 'shadowTeam') return <SportsSoccerIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (currentTeam === 'masterTeam') return <StarIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (currentTeam === 'academyTeam') return <SchoolIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (currentTeam) return <PublicIcon style={{ fontSize: fontSize, color: iconColor }} />;
};


export const getPlayingTimeIcon = (playingTimeKey: AvailabilityStatus, fontSize: number, iconColor?: string) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (playingTimeKey === 'KEY') return <KeyIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (playingTimeKey === 'ROTATION') return <SyncIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (playingTimeKey === 'RESERVE') return <AcUnitIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (playingTimeKey === 'UNKNOWN') return <RemoveOutlinedIcon style={{ fontSize: fontSize, color: iconColor }} />;
};


export const getFormIcon = (formKey: FormStatus, fontSize: number, iconColor?: string, lightness?: number) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (formKey === 'IN_FORM') {
    return (
      <div
        style={{
          position: 'relative',
          height: fontSize,
          width: fontSize,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ArrowDropUpIcon
          style={{
            position: 'absolute',
            fontSize: fontSize + 12,
            color: lightness ? getIndexColor(10, lightness) : iconColor,
          }}
        />
      </div>
    );
  }

  if (formKey === 'NORMAL_FORM') return <TrendingFlatIcon style={{ fontSize: fontSize, color: iconColor }} />;

  if (formKey === 'OUT_OF_FORM') {
    return (
      <div
        style={{
          position: 'relative',
          height: fontSize,
          width: fontSize,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <ArrowDropDownIcon
          style={{
            position: 'absolute',
            fontSize: fontSize + 12,
            color: lightness ? getIndexColor(0, lightness) : iconColor,
          }} />
      </div>
    );
  }

  if (formKey === 'NO_FORM') return <RemoveOutlinedIcon style={{ fontSize: fontSize, color: iconColor }} />;
};


export const getConditionStatusIcon = (status: SeasonSubConditionStatus, fontSize: number, iconColor?: string) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (status === 'achieved') return <TaskAltIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (status === 'notAchieved') return <CancelOutlinedIcon style={{ fontSize: fontSize, color: iconColor }} />;
};


export const getAggregationRuleIcon = (aggregationRule: AggregationRule, fontSize: number, iconColor?: string) => {

  iconColor = iconColor ? defaultIconColorMap[iconColor] ?? iconColor : undefined;

  if (aggregationRule === 'priority') return <SortIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (aggregationRule === 'max') return <LandscapeOutlinedIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (aggregationRule === 'average') return <AlignVerticalCenterOutlinedIcon style={{ fontSize: fontSize, color: iconColor }} />;
  if (aggregationRule === 'confidence') return <CircleOutlinedIcon style={{ fontSize: fontSize, color: iconColor }} />;
};
