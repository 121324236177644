import './dropDowns.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { competitionsState } from '../../../recoil/atoms/competitionsState';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import { translate } from '../../../../common/language/translations';
import { Toggle } from '../input/Toggle';
import { Iteration } from '../../../types';
import { CompetitionFlag } from '../../display/images/CompetitionFlag';


interface ClubIterationDropDownProps {
  id: string;

  dropDownOptions: Iteration[];
  selectedIterations: number[];
  setSelectedIterations: (value: number[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string;
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight: string;

  isToggled: boolean;
  setIsToggled: (value: boolean) => void;
  toggleText: string;
}

export const ClubIterationDropDown: React.FC<ClubIterationDropDownProps> = ({
  id,

  dropDownOptions,
  selectedIterations,
  setSelectedIterations,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,

  isToggled,
  setIsToggled,
  toggleText
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const competitions = useRecoilValue(competitionsState);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }
    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (iterationId: number, isOptionAlreadySelected: boolean) => {
    const newSelectedOptions = selectedIterations.slice();
    if (isOptionAlreadySelected) {
      const i = newSelectedOptions.indexOf(iterationId);
      newSelectedOptions.splice(i, 1);
    }
    else {
      newSelectedOptions.push(iterationId);
    }
    setSelectedIterations(newSelectedOptions);
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const getDisplayOption = (index: number) => {
    const selectedIteration: Iteration | undefined = dropDownOptions.find((option: Iteration) => option.iterationId === selectedIterations[index]);
    if (selectedIteration) {
      const competitionId = Number(selectedIteration.competitionId);
      const competitionName = competitions[competitionId].name;
      return competitionName + ' ' + selectedIteration.season;
    }
  };


  const showSelectedOptions = () => {
    if (selectedIterations.length === 0) return;

    let firstSelection = getDisplayOption(0);

    for (let i = 1; i < selectedIterations.length; i++) {
      firstSelection += ', ' + getDisplayOption(i);
    }
    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-button' onClick={() => handleDropDownClick()}>

        {selectedIterations.length > 0 && <div className='drop-down-button-overlay' />}

        <div className='drop-down-button-text'>
          {selectedIterations.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div className='player-iteration-drop-down-selected-option-display' style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-space'>&nbsp;</div>

      <div className='drop-down-options drop-down-options-scroll'>
        {dropDownOptions.map((option: Iteration, index: number) => {

          const isSelected = selectedIterations.includes(option.iterationId);

          return (
            <div
              className={'drop-down-option' + (isSelected ? ' drop-down-option-selected' : '')}
              style={{ marginTop: marginBetweenOptions }}
              key={index}
              onClick={() => handleOptionSelect(option.iterationId, isSelected)}
            >
              <CompetitionFlag
                competitionId={option.competitionId}
                width={16}
                seasonToShow={option.season}
                containerStyle={{ justifyContent: 'left', marginLeft: 2 }}
                isWhiteBackground={true}
              />
            </div>
          );
        })}
      </div>

      <div className='drop-down-toggle-section'>
        <div className='drop-down-toggle-text'>
          {translate(toggleText, userConfig?.language)}
        </div>
        <div className='drop-down-toggle'>
          <Toggle isToggled={isToggled} setIsToggled={setIsToggled} isWhiteBackground={true} />
        </div>
      </div>
    </div>
  );
};
