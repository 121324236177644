import { db, FirebaseUser } from '../../../firebase';

import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  setDoc,
  addDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  writeBatch,
  query,
  orderBy,
} from 'firebase/firestore';

import { StringToAnyMap, PlayerActivity, PlayerEntry, PlayerId } from '../../types';
import { getTeamWithNewFormation } from '../../utils/formationUtils';
import { getSortDistanceRole, getStrictPlayerId } from '../../utils/playerUtils';
import { positionOptionsPlatform } from '../../static/propertyValues';
import { deepCloneObject } from '../../utils/utils';
import { addActivity } from './activities';
import { trackEvent } from '../server/analytics/trackEvent';


// Get all teams or squads
export const getTeamsOrSquads = (
  setTeamsOrSquads: (teamsOrSquads: StringToAnyMap) => void,
  isSquad: boolean,
  club: string,
) => {

  const collectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);

  const unsubscribe = onSnapshot(collectionRef, snapshot => {
    let teamsOrSquads: StringToAnyMap = {};

    snapshot.forEach((doc) => {
      const teamOrSquad = doc.data();
      teamsOrSquads[doc.id] = teamOrSquad;
    });

    teamsOrSquads = Object.fromEntries(Object.entries(teamsOrSquads).sort((a, b) => a[1].orderIndex - b[1].orderIndex));

    setTeamsOrSquads(teamsOrSquads);
  });

  return unsubscribe;
};


// Add the given player to the given team, or change the position or position index of the player within the team
// The player is either
// (1) added to a new team (if player already exists in another team, this will also remove the player from the other team)
// (2) moved to another position within the same team
// (3) moved to a new position index within the same position
export const addPlayerToTeam = async (
  playerEntry: PlayerEntry,
  newPosiitonKey: string,
  newPositionIndex: number | undefined,
  teamId: string,
  team: StringToAnyMap,
  currentTeamData: { teamId: string, teamData: StringToAnyMap, currentPosition: string } | undefined,
  userEmail: string,
  club: string,
  actionSource: 'dragged' | 'modal',
  currentUser: FirebaseUser,
) => {

  const playerId = getStrictPlayerId(playerEntry.id);
  const teamDocRef = getTeamDocRef(teamId, club);

  // temporary tracking of potential issue
  if (playerEntry.id.toString().endsWith('copy')) {
    trackEvent(
      'UnexpectedBehavior',
      { file: 'teamsOrSquads.ts', behavior: 'Dragged player copy detected (addPlayerToTeam)' },
      currentUser,
      'user'
    );
  }

  try {
    let action: 'added' | 'moved' | 'positionChanged' | 'positionIndexChanged' = 'added';

    // If newPositionIndex is not provided, the player is added to the end of the position array
    const updatedPositionArray = team[newPosiitonKey] ? [...team[newPosiitonKey]].filter(player => player.id !== playerId) : [];
    updatedPositionArray.splice(newPositionIndex ?? updatedPositionArray.length, 0, playerEntry);

    // (1) the player is added to a new team (and potentially removed from another team)
    if (!currentTeamData || currentTeamData.teamId !== teamId) {

      // Remove player from current team if player exists in another team
      if (currentTeamData) {
        action = 'moved';
        const currentTeamDocRef = getTeamDocRef(currentTeamData.teamId, club);

        for (const positionKey of positionOptionsPlatform) {
          const positionList = currentTeamData.teamData[positionKey];
          if (positionList && Array.isArray(positionList)) {
            const player = positionList.find(player => player.id === playerId);
            if (player) {
              updateDoc(currentTeamDocRef, {
                [positionKey]: arrayRemove(player),
              });
              break;
            }
          }
        }
      }

      // Add player to new team
      await updateDoc(teamDocRef, {
        [newPosiitonKey]: updatedPositionArray
      });

      // Log activity
      const playerActivity: PlayerActivity = {
        playerId: playerId,
        playerName: playerEntry.fullname,
        userEmail: userEmail,
        date: new Date().toISOString(),
        fromTeamId: currentTeamData?.teamId ?? null,
        fromTeamName: currentTeamData?.teamData.name ?? null,
        toTeamId: teamId,
        toTeamName: team.name,
      };
      addActivity(playerActivity, club, currentUser);
    }

    else {
      // (2) the player is moved to another position within the same team
      if (currentTeamData.currentPosition !== newPosiitonKey) {
        action = 'positionChanged';
        await updateDoc(teamDocRef, {
          [newPosiitonKey]: updatedPositionArray,
          [currentTeamData.currentPosition]: arrayRemove(playerEntry),
        });
      }

      // (3) the player is moved to a new position index within the same position
      else {
        action = 'positionIndexChanged';
        await updateDoc(teamDocRef, {
          [newPosiitonKey]: updatedPositionArray,
        });
      }
    }

    trackEvent('PlayerMoved', { action, actionSource, context: 'team' }, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addPlayerToTeam', errorDetails: { error } }, currentUser, 'system');
  }
};


// Add the given player to the given squad, or change the position or position index of the player within the squad
// The player is either
// (1) added to a new squad
// (2) moved to another position within the same squad
// (3) moved to a new position index within the same position
export const addPlayerToSquad = async (
  playerEntry: PlayerEntry,
  newPositionKey: string,
  newPositionIndex: number | undefined,
  squadId: string,
  squad: StringToAnyMap,
  userEmail: string,
  club: string,
  actionSource: 'dragged' | 'modal',
  currentUser: FirebaseUser,
) => {

  const playerId = getStrictPlayerId(playerEntry.id);
  const squadDocRef = getSquadDocRef(squadId, club);

  // temporary tracking of potential issue
  if (playerEntry.id.toString().endsWith('copy')) {
    trackEvent(
      'UnexpectedBehavior',
      { file: 'teamsOrSquads.ts', behavior: 'Dragged player copy detected (addPlayerToSquad)' },
      currentUser,
      'user'
    );
  }

  try {
    let action: 'added' | 'positionChanged' | 'positionIndexChanged' = 'added';

    // If newPositionIndex is not provided, the player is added to the end of the position array
    const updatedPositionArray = squad[newPositionKey] ? [...squad[newPositionKey]].filter(player => player.id !== playerId) : [];
    updatedPositionArray.splice(newPositionIndex ?? updatedPositionArray.length, 0, playerEntry);

    // Check if player already exists in squad
    const currentPositionKey = positionOptionsPlatform.find(position => {
      if (squad[position]) {
        return squad[position].some((player: PlayerEntry) => player.id === playerId);
      }
    });

    // (1) the player is added to a new squad
    if (!currentPositionKey) {
      const squadHistoryAction: StringToAnyMap = {
        playerId: playerId,
        playerName: playerEntry.fullname,
        userEmail: userEmail,
        date: new Date().toISOString(),
        toTeamId: squadId,
      };

      await updateDoc(squadDocRef, {
        [newPositionKey]: updatedPositionArray,
        history: arrayUnion(squadHistoryAction),
      });
    }

    else {
      // (2) the player is moved to another position in the same squad
      if (currentPositionKey !== newPositionKey) {
        action = 'positionChanged';
        await updateDoc(squadDocRef, {
          [newPositionKey]: updatedPositionArray,
          [currentPositionKey]: arrayRemove(playerEntry),
        });
      }

      // (3) the player is moved to a new position index within the same position
      else {
        action = 'positionIndexChanged';
        await updateDoc(squadDocRef, {
          [newPositionKey]: updatedPositionArray,
        });
      }
    }

    trackEvent('PlayerMoved', { action, actionSource, context: 'squad' }, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addPlayerToSquad', errorDetails: { error } }, currentUser, 'system');
  }
};


// Remove the player with the given playerId from all teams and squads - this only happens when a player without data is deleted
export const removePlayerFromAllTeamsAndSquads = async (
  playerId: PlayerId,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {
  try {
    const teamSnapshots = await getDocs(getTeamsCollectionRef(club));
    teamSnapshots.forEach((doc) => {
      removePlayerFromTeamOrSquad(playerId, doc.id, false, userEmail, club, currentUser);
    });

    const squadSnapshots = await getDocs(getSquadsCollectionRef(club));
    squadSnapshots.forEach((doc) => {
      removePlayerFromTeamOrSquad(playerId, doc.id, true, userEmail, club, currentUser);
    });
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'removePlayerFromAllTeamsAndSquads', errorDetails: { error } }, currentUser, 'system');
  }
};


// Remove a player from a given team or squad
export const removePlayerFromTeamOrSquad = async (
  playerId: PlayerId,
  teamOrSquadId: string,
  isSquad: boolean,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
  actionSource?: 'dragged' | 'modal', // controls whether the action is tracked
) => {

  playerId = getStrictPlayerId(playerId);

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  // temporary tracking of potential issue
  if (playerId.toString().endsWith('copy')) {
    trackEvent(
      'UnexpectedBehavior',
      { file: 'teamsOrSquads.ts', behavior: 'Dragged player copy detected (removePlayerFromTeamOrSquad)' },
      currentUser,
      'user'
    );
  }

  try {
    const teamOrSquadDoc = await getDoc(teamOrSquadDocRef);
    const teamOrSquad = teamOrSquadDoc.data();

    const position = positionOptionsPlatform.find(position => {
      if (teamOrSquad && teamOrSquad[position]) {
        return teamOrSquad[position].some((player: PlayerEntry) => player.id === playerId);
      }
    });

    if (teamOrSquad && position) {
      // Get player data of given player
      teamOrSquad[position].forEach((player: PlayerEntry) => {
        if (player['id'] === playerId) {

          if (isSquad) {
            const squadHistoryAction: StringToAnyMap = {
              playerId: playerId,
              playerName: player.fullname,
              userEmail: userEmail,
              date: new Date().toISOString(),
              fromTeamId: teamOrSquadId,
            };

            updateDoc(teamOrSquadDocRef, {
              [position]: arrayRemove(player),
              history: arrayUnion(squadHistoryAction)
            });
          }

          else {
            updateDoc(teamOrSquadDocRef, {
              [position]: arrayRemove(player)
            });

            const playerActivity: PlayerActivity = {
              playerId: playerId,
              playerName: player.fullname,
              userEmail: userEmail,
              date: new Date().toISOString(),
              fromTeamId: teamOrSquadId,
              fromTeamName: teamOrSquad['name'],
              toTeamId: null,
              toTeamName: null,
            };

            addActivity(playerActivity, club, currentUser);
          }

          return;
        }
      });
    }

    if (actionSource) {
      trackEvent('PlayerMoved', { action: 'removed', actionSource, context: isSquad ? 'squad' : 'team' }, currentUser, 'user');
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'removePlayerFromTeamOrSquad', errorDetails: { error } }, currentUser, 'system');
  }
};


// Removes all players from the given team or squad
export const resetTeamOrSquad = async (
  teamOrSquadId: string,
  teamOrSquad: StringToAnyMap,
  isSquad: boolean,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);
  const batch = writeBatch(db);

  try {
    const fieldsToUpdate: Record<string, unknown[]> = positionOptionsPlatform.reduce<Record<string, unknown[]>>((acc, fieldName) => {
      acc[fieldName] = [];
      return acc;
    }, {});

    const action: StringToAnyMap = {
      actionType: 'reset',
    };
    addTeamHistoryAction(action, teamOrSquadId, isSquad, userEmail, club, currentUser);

    if (isSquad) {
      const extendedSquadHistory: StringToAnyMap[] = getRemovedPlayersToAddToSquadHistory(teamOrSquadId, teamOrSquad, userEmail);

      fieldsToUpdate['history'] = [...(teamOrSquad.history || []), ...extendedSquadHistory];
    }
    else {
      addPlayerActivitiesForReset(teamOrSquadId, teamOrSquad, userEmail, club, currentUser);
    }

    batch.update(teamOrSquadDocRef, fieldsToUpdate);

    await batch.commit();

    trackEvent(isSquad ? 'SquadAction' : 'ScoutTeamAction', { action: 'reset' }, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'resetTeamOrSquad', errorDetails: { error } }, currentUser, 'system');
  }
};


// Add activity for each player in the given team or squad when resetting
const addPlayerActivitiesForReset = async (
  teamId: string,
  team: StringToAnyMap,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  positionOptionsPlatform.forEach(positionKey => {
    const positionList = team[positionKey];

    if (positionList && Array.isArray(positionList) && positionList.length > 0) {
      positionList.forEach((player: PlayerEntry) => {

        const playerActivity: PlayerActivity = {
          playerId: getStrictPlayerId(player.id),
          playerName: player.fullname,
          userEmail: userEmail,
          date: new Date().toISOString(),
          fromTeamId: teamId,
          fromTeamName: team['name'],
          toTeamId: null,
          toTeamName: null,
        };

        addActivity(playerActivity, club, currentUser);

      });
    }
  });
};


// Add the removed players to the squad history
const getRemovedPlayersToAddToSquadHistory = (
  squadId: string,
  squad: StringToAnyMap,
  userEmail: string
) => {

  const extendedSquadHistory: StringToAnyMap[] = [];

  positionOptionsPlatform.forEach(positionKey => {
    const positionList = squad[positionKey];

    if (positionList && Array.isArray(positionList) && positionList.length > 0) {
      positionList.forEach((player: PlayerEntry) => {

        const squadHistoryAction: StringToAnyMap = {
          playerId: getStrictPlayerId(player.id),
          playerName: player.fullname,
          userEmail: userEmail,
          date: new Date().toISOString(),
          fromTeamId: squadId,
        };

        extendedSquadHistory.push(squadHistoryAction);

      });
    }
  });

  return extendedSquadHistory;
};


// Delete the given comment from team history
export const deleteCommentFromTeamHistory = async (
  teamOrSquadId: string,
  isSquad: boolean,
  comment: StringToAnyMap,
  club: string,
  currentUser: FirebaseUser,
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  try {
    await updateDoc(teamOrSquadDocRef, {
      history: arrayRemove(comment)
    });
    trackEvent(isSquad ? 'SquadAction' : 'ScoutTeamAction', { action: 'commentDeleted' }, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deleteCommentFromTeamHistory', errorDetails: { error } }, currentUser, 'system');
  }
};


// Add a new squad
export const addTeamOrSquad = async (
  teamOrSquadName: string,
  formation: string,
  orderIndex: number,
  clubFormation: string,
  isSquad: boolean,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
  teamIdToCopy?: string | undefined,
): Promise<string | undefined> => {

  const teamOrSquadCollectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);
  let newTeamOrSquad: StringToAnyMap | undefined = undefined;

  // add players that potentially are added to the squad to the squad history
  const now = new Date().toISOString();
  const initialHistory: StringToAnyMap[] = [];

  // teamIdToCopy may only be provided if squad
  if (teamIdToCopy) {

    const teamRef = getTeamDocRef(teamIdToCopy, club);
    const teamDoc = getDoc(teamRef);
    newTeamOrSquad = (await teamDoc).data() as StringToAnyMap;

    // sort players based on role if copying from Eget lag
    if (teamIdToCopy === 'ownTeam') {
      positionOptionsPlatform.forEach(positionKey => {
        if (newTeamOrSquad && newTeamOrSquad[positionKey] && Array.isArray(newTeamOrSquad[positionKey])) {
          newTeamOrSquad[positionKey].sort((a: PlayerEntry, b: PlayerEntry) => getSortDistanceRole(a, b));
        }
      });
    }

    // add players to squad history
    positionOptionsPlatform.forEach(positionKey => {
      if (newTeamOrSquad && newTeamOrSquad[positionKey] && Array.isArray(newTeamOrSquad[positionKey])) {
        newTeamOrSquad[positionKey].forEach((player: PlayerEntry) => {
          const squadHistoryAction: StringToAnyMap = {
            playerId: getStrictPlayerId(player.id),
            playerName: player.fullname,
            userEmail: userEmail,
            date: new Date().toISOString(),
          };
          initialHistory.push(squadHistoryAction);
        });
      }
    });

    if (formation !== clubFormation) {
      newTeamOrSquad = getTeamWithNewFormation(newTeamOrSquad, formation, clubFormation);
    }

    newTeamOrSquad['name'] = teamOrSquadName;
    newTeamOrSquad['formation'] = formation;
    newTeamOrSquad['orderIndex'] = orderIndex;
    newTeamOrSquad['history'] = [];
  }
  else {
    newTeamOrSquad = getEmptyTeamOrSquad(teamOrSquadName, formation, orderIndex);
  }

  try {
    const docRef = await addDoc(teamOrSquadCollectionRef, newTeamOrSquad);

    const action: StringToAnyMap = {
      actionType: 'create',
    };

    if (initialHistory.length > 0) {
      initialHistory.map((action: StringToAnyMap) => {
        action['toTeamId'] = docRef.id;
        return action;
      });

      const teamHistoryAction: StringToAnyMap = {
        action: action,
        userEmail: userEmail,
        date: now,
      };

      await updateDoc(docRef, {
        history: arrayUnion(teamHistoryAction, ...initialHistory)
      });
    }
    else {
      await addTeamHistoryAction(action, docRef.id, isSquad, userEmail, club, currentUser);
    }

    trackEvent(isSquad ? 'SquadCreated' : 'ScoutTeamCreated', {}, currentUser, 'user');
    return docRef.id;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addTeamOrSquad', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// Edit a squad
export const editTeamOrSquad = async (
  teamOrSquadId: string,
  newTeamOrSquadName: string,
  newFormation: string,
  currentTeamOrSquad: StringToAnyMap,
  isSquad: boolean,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  const currentFormation = currentTeamOrSquad['formation'];
  const history = [...currentTeamOrSquad['history'] as StringToAnyMap[]];

  let newTeamOrSquad: StringToAnyMap = { ...currentTeamOrSquad };
  if (newFormation !== currentFormation) {
    newTeamOrSquad = getTeamWithNewFormation(newTeamOrSquad, newFormation, currentFormation);
    newTeamOrSquad['formation'] = newFormation;

    const action: StringToAnyMap = {
      actionType: 'editFormation',
      newFormation: newFormation,
    };
    const teamHistoryAction: StringToAnyMap = {
      action: action,
      userEmail: userEmail,
      date: new Date().toISOString(),
    };
    history.push(teamHistoryAction);
  }

  if (newTeamOrSquadName !== currentTeamOrSquad.name) {
    newTeamOrSquad['name'] = newTeamOrSquadName;

    const action: StringToAnyMap = {
      actionType: 'editName',
      newName: newTeamOrSquadName,
    };
    const teamHistoryAction: StringToAnyMap = {
      action: action,
      userEmail: userEmail,
      date: new Date().toISOString(),
    };
    history.push(teamHistoryAction);
  }

  newTeamOrSquad['history'] = history;

  try {
    await setDoc(teamOrSquadDocRef, newTeamOrSquad);
    trackEvent(isSquad ? 'SquadAction' : 'ScoutTeamAction', { action: 'edited' }, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'editTeamOrSquad', errorDetails: { error } }, currentUser, 'system');
  }
};


// Delete a team or squad
export const deleteTeamOrSquad = async (
  teamOrSquadId: string,
  isSquad: boolean,
  orderIndex: number,
  club: string,
  currentUser: FirebaseUser,
) => {

  const teamsOrSquadsCollectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);
  const batch = writeBatch(db);

  try {
    const snapshot = await getDocs(teamsOrSquadsCollectionRef);

    snapshot.forEach(doc => {
      if (doc.id === teamOrSquadId) {
        batch.delete(doc.ref);
      }
      else {
        // must decrement the orderIndex of all squads with an orderIndex greater than the provided orderIndex
        const docOrderIndex = doc.data()['orderIndex'];
        if (docOrderIndex > orderIndex) {
          batch.update(doc.ref, { orderIndex: docOrderIndex - 1 });
        }
      }
    });

    await batch.commit();
    trackEvent(isSquad ? 'SquadDeleted' : 'ScoutTeamDeleted', {}, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deleteTeamOrSquad', errorDetails: { error } }, currentUser, 'system');
  }
};


// re-order teams or squads
export const reorderTeamsOrSquads = async (
  tabOptions: string[],
  isSquad: boolean,
  club: string,
  currentUser: FirebaseUser,
) => {

  const collectionRef = isSquad ? getSquadsCollectionRef(club) : getTeamsCollectionRef(club);
  const batch = writeBatch(db);

  try {
    tabOptions.forEach((teamOrSquadId, index) => {
      const docRef = doc(collectionRef, teamOrSquadId);
      batch.update(docRef, { orderIndex: index });
    });

    await batch.commit();
    trackEvent(isSquad ? 'SquadAction' : 'ScoutTeamAction', { action: 'dragged' }, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'reorderTeamsOrSquads', errorDetails: { error } }, currentUser, 'system');
  }
};


// Archive team or squad, and add action to team history
export const archiveTeamOrSquad = async (
  teamOrSquadId: string,
  teamOrSquad: StringToAnyMap,
  isSquad: boolean,
  formation: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
): Promise<string | undefined> => {

  const archivedTeamsCollectionRef = collection(db, 'configs', club, 'archivedTeams');

  let newTeamOrSquad: StringToAnyMap = {};

  newTeamOrSquad = deepCloneObject(teamOrSquad);

  delete newTeamOrSquad['history'];
  delete newTeamOrSquad['orderIndex'];

  newTeamOrSquad['teamOrSquadId'] = teamOrSquadId;
  newTeamOrSquad['name'] = teamOrSquad['name'];
  newTeamOrSquad['date'] = new Date().toISOString();
  newTeamOrSquad['isSquad'] = isSquad;
  newTeamOrSquad['formation'] = formation;

  try {
    const action: StringToAnyMap = {
      actionType: 'archive',
    };
    await addTeamHistoryAction(action, teamOrSquadId, isSquad, userEmail, club, currentUser);

    const docRef = await addDoc(archivedTeamsCollectionRef, newTeamOrSquad);

    trackEvent(isSquad ? 'SquadAction' : 'ScoutTeamAction', { action: 'archived' }, currentUser, 'user');

    return docRef.id;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'archiveTeamOrSquad', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// get all archived teams
export const getAllArchivedTeams = async (
  club: string,
  currentUser: FirebaseUser,
): Promise<StringToAnyMap[]> => {

  const archivedTeamsCollectionRef = collection(db, 'configs', club, 'archivedTeams');

  const q = query(archivedTeamsCollectionRef, orderBy('date', 'desc'));

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'getAllArchivedTeams', errorDetails: { error } }, currentUser, 'system');
    return [];
  }
};


// update delete status of given archived team
export const updateArchivedTeamIsDeletedStatus = async (
  archivedTeamId: string,
  isDeleted: boolean,
  club: string,
  currentUser: FirebaseUser,
): Promise<boolean> => {

  const archivedTeamDocRef = doc(db, 'configs', club, 'archivedTeams', archivedTeamId);

  try {
    await updateDoc(archivedTeamDocRef, {
      isDeleted: isDeleted
    });
    trackEvent(isDeleted ? 'ArchivedTeamOrSquadDeleted' : 'ArchivedTeamOrSquadRestored', {}, currentUser, 'user');
    return true;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateArchivedTeamIsDeletedStatus', errorDetails: { error } }, currentUser, 'system');
    return false;
  }
};


// Add action to team history (action can be 'create', 'editName', editFormation', 'archive' or 'reset')
export const addTeamHistoryAction = async (
  action: StringToAnyMap,
  teamOrSquadId: string,
  isSquad: boolean,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const teamOrSquadDocRef = isSquad ? getSquadDocRef(teamOrSquadId, club) : getTeamDocRef(teamOrSquadId, club);

  const teamHistoryAction: StringToAnyMap = {
    action: action,
    userEmail: userEmail,
    date: new Date().toISOString(),
  };

  try {
    await updateDoc(teamOrSquadDocRef, {
      history: arrayUnion(teamHistoryAction)
    });
    if (action.actionType === 'comment') {
      trackEvent(isSquad ? 'SquadAction' : 'ScoutTeamAction', { action: 'commentAdded' }, currentUser, 'user');
    }
    return true;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addTeamHistoryAction', errorDetails: { error } }, currentUser, 'system');
    return false;
  }
};


// Updates all teams with new formation
export const updateTeamsWithNewFormation = async (
  newFormation: string,
  currentFormation: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  try {
    const teamSnapshots = await getDocs(getTeamsCollectionRef(club));

    teamSnapshots.forEach(async (doc) => {
      const teamId = doc.id;
      const team = doc.data();
      if (team['formation'] === currentFormation) {
        await updateTeamDocWithNewFormation(teamId, team, newFormation, currentFormation, userEmail, club, currentUser);
      }
    });
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateTeamsWithNewFormation', errorDetails: { error } }, currentUser, 'system');
  }
};


// Updates a given team with new formation
const updateTeamDocWithNewFormation = async (
  teamId: string,
  teamData: StringToAnyMap,
  newFormation: string,
  currentFormation: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const newTeam: StringToAnyMap = getTeamWithNewFormation(teamData, newFormation, currentFormation);

  const action: StringToAnyMap = {
    actionType: 'editFormation',
    newFormation: newFormation,
  };
  const teamHistoryAction: StringToAnyMap = {
    action: action,
    userEmail: userEmail,
    date: new Date().toISOString(),
  };

  newTeam['history'].push(teamHistoryAction);

  try {
    await setDoc(doc(db, 'configs', club, 'teams', teamId), newTeam);
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateTeamDocWithNewFormation', errorDetails: { error } }, currentUser, 'system');
  }
};


const getSquadsCollectionRef = (club: string) => {
  return collection(db, 'configs', club, 'squads');
};


const getSquadDocRef = (squadId: string, club: string) => {
  return doc(db, 'configs', club, 'squads', squadId);
};


const getTeamsCollectionRef = (club: string) => {
  return collection(db, 'configs', club, 'teams');
};


const getTeamDocRef = (teamId: string, club: string) => {
  return doc(db, 'configs', club, 'teams', teamId);
};


const getEmptyTeamOrSquad = (name: string, formation: string, orderIndex: number): StringToAnyMap => {
  return {
    GK: [],
    LWB: [],
    LB: [],
    LCB: [],
    CB: [],
    RCB: [],
    RB: [],
    RWB: [],
    CDM: [],
    LCM: [],
    RCM: [],
    CAM: [],
    LM: [],
    LW: [],
    RM: [],
    RW: [],
    LS: [],
    S: [],
    RS: [],

    name: name,
    formation: formation,
    orderIndex: orderIndex,
    history: [],
  };
};
