import {
  CompetitionMap,
  StringToAnyMap,
  PlayerId,
  PlayerMap,
  PlayerOverview,
  PlayerAlertsQueryOptions,
  UserConfig,
  TeamRole,
  PlayerLocality
} from '../types';

import {
  accessOptionToSortOrder,
  playerLocalityToSortOrder,
  positionOptionsPlatform,
  teamRoleToSortOrder,
} from '../static/propertyValues';

import { translate } from '../../common/language/translations';


// get player name to display, where the first name pattern that is not longer than maxLength is returned
// the patterns are ordered <fullname>, <firstName><initials><lastName>, <firstName><lastName>, <initials><lastName>
// if fullname does not contain a space, the fullname is returned regardless of length
// if none of the three first patterns are short enough, the shortest pattern is returned unless shortestNameIsNotAnOption is true
export const getDisplayPlayerName = (
  fullname: string,
  maxLength?: number,
  shortestNameIsNotAnOption?: boolean,
) => {
  if (fullname === undefined) return '';

  if (maxLength === undefined || fullname.length <= maxLength) {
    return fullname;
  }

  const playerName = fullname.trim().replace('"', '');
  const nameList = playerName.split(' ');

  if (nameList.length === 1) {
    return fullname;
  }

  const firstName = nameList[0];
  const lastName = nameList[nameList.length - 1];
  const initials = nameList.slice(1, nameList.length - 1).map(name => name[0]).join('. ') + '.';

  const nameWithInitials = `${firstName} ${initials} ${lastName}`;
  if (nameWithInitials.length <= maxLength) {
    return nameWithInitials;
  }

  const nameWithoutInitials = `${firstName} ${lastName}`;
  if (shortestNameIsNotAnOption || nameWithoutInitials.length <= maxLength) {
    return nameWithoutInitials;
  }

  const shortestName = `${firstName[0]}. ${lastName}`;
  if (shortestName.length <= maxLength) {
    return shortestName;
  }

  if (lastName.includes('-')) {
    if (lastName.length <= maxLength) {
      return lastName;
    }

    const lastNameList = lastName.split('-');
    return `${firstName[0]}. ${lastNameList[lastNameList.length - 1]}`;
  }

  return shortestName;
};


// Get playerId, where a number is returned if possible, else string
export const getStrictPlayerId = (playerId: PlayerId) => {
  const numberId = Number(playerId);
  if (isNaN(numberId)) {
    return playerId;
  }
  return numberId;
};


// Gets the year of a date
export const getYear = (date: string) => {
  if (date) {
    return date.split('-')[0];
  }
  return '';
};


// Gets last two digits of the year of a date
export const getYearShort = (date: string) => {
  if (date) {
    return date.split('-')[0].substring(2);
  }
  return '';
};


// Gets the date in locale format (dd/mm/yyyy, yyyy//mm/dd, etc.)
export const getDateLocaleFormat = (date: string | undefined) => {
  if (!date) {
    return '-';
  }

  const dateList = date.split('-');

  return dateList[2] + '.' + dateList[1] + '.' + dateList[0];
};


// Get number of months left of a contract
export const getMonthsLeftOfContract = (contractExpiration: string): number | undefined => {
  if (!contractExpiration) return undefined;

  const expirationDate = new Date(contractExpiration);
  const currentDate = new Date();

  const startYear = currentDate.getFullYear();
  const startMonth = currentDate.getMonth();

  const endYear = expirationDate.getFullYear();
  const endMonth = expirationDate.getMonth();

  const monthsLeft = (endYear - startYear) * 12 + (endMonth - startMonth);

  return Math.max(0, monthsLeft);
};


// Get age based on birth date
export const getAge = (birthDate: string) => {
  if (!birthDate || birthDate === '') return undefined;

  const date = new Date(birthDate);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - date.getTime();

  const diffInYears = diffInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);

  return Number(diffInYears.toFixed(1));
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidPlayerLocality = (playerLocality: any): playerLocality is PlayerLocality => {
  return playerLocality in playerLocalityToSortOrder;
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isValidTeamRole = (role: any): role is TeamRole => {
  return role in teamRoleToSortOrder;
};


// Gets sort distance between two players based on role (descending, where order of roles defined above)
export const getSortDistanceRole = (a: StringToAnyMap, b: StringToAnyMap) => {
  if (!b.role || !isValidTeamRole(b.role)) {
    return -1;
  }
  if (!a.role || !isValidTeamRole(a.role)) {
    return 1;
  }
  return teamRoleToSortOrder[b.role] - teamRoleToSortOrder[a.role];
};


// Gets sort distance between two access categories
export const getSortDistanceAccess = (a: UserConfig, b: UserConfig) => {
  const aHasAccess = a.access && accessOptionToSortOrder[a.access];
  const bHasAccess = b.access && accessOptionToSortOrder[b.access];

  if (!bHasAccess && aHasAccess) return -1;
  if (!aHasAccess && bHasAccess) return 1;

  if (aHasAccess && bHasAccess) {
    return accessOptionToSortOrder[b.access] - accessOptionToSortOrder[a.access];
  }

  return a.name.localeCompare(b.name);
};


// Gets sort distance between two players based on a number property (descending)
export const getSortDistanceNumberProperty = (a: StringToAnyMap, b: StringToAnyMap, property: string) => {
  if (!a[property] && !b[property]) {
    return getSortDistanceRole(a, b);
  }
  if (!b[property]) {
    return -1;
  }
  if (!a[property]) {
    return 1;
  }
  return b[property] - a[property];
};


// Gets sort distance between two players based on a date property (descending)
export const getSortDistanceDateProperty = (a: StringToAnyMap, b: StringToAnyMap, property: string) => {
  if (!a[property] && !b[property]) {
    return getSortDistanceRole(a, b);
  }
  if (!a[property]) {
    return -1;
  }
  if (!b[property]) {
    return 1;
  }
  const a_time = new Date(a[property]).getTime();
  const b_time = new Date(b[property]).getTime();
  return b_time - a_time;
};


// Player property getters

export const getPlayerPropertyValue = (
  property: string,
  playerOverview?: StringToAnyMap | undefined,
  playerId?: PlayerId | undefined,
  players?: PlayerMap | undefined,
  valueTransformer?: (value: any) => any, // eslint-disable-line @typescript-eslint/no-explicit-any
  resultIfUndefined?: string,
) => {

  const playerDocument = playerId !== undefined && players ? players[playerId] : undefined;

  // user provided values have presedence
  if (playerDocument && property in playerDocument) {
    const value = playerDocument[property];
    if (valueTransformer) {
      return valueTransformer(value);
    }
    return value;
  }

  if (playerOverview && property in playerOverview) {
    const value = playerOverview[property];
    if (valueTransformer) {
      return valueTransformer(value);
    }
    return value;
  }

  return resultIfUndefined;
};


export const getDisplayPositions = (
  positionList: string[] | undefined,
  language: string | undefined,
) => {
  if (positionList && positionList.length > 0) {
    const positions = positionList.map((position: string) => {
      return translate(position, language);
    });

    return positions.join(' / ');
  }
};


export const getDisplayCompetition = (
  club: StringToAnyMap | undefined,
  competitions: CompetitionMap
) => {
  if (club && club.current_competition_id) {
    return competitions[club.current_competition_id]?.name ?? '-';
  }
  return '-';
};


// returns true if given players matches all given alert filters
export const playerMatchesAlertsFilters = (
  playerOverview: PlayerOverview,
  filters: PlayerAlertsQueryOptions,
  teams: StringToAnyMap | null,
) => {

  // maxAge?: number;
  // maxContractLength?: number;
  // includeUnknownContracts?: boolean;
  // primaryPositions?: string[];
  // positionGroup?: string | null;
  // competitions?: number[];
  // minConfidence?: number;
  // form?: string[];
  // playingTime?: string[];
  // countryCodes?: string[];
  // indexRequirements?: RatingRequirement[];
  // isDebutToggled?: boolean;
  // logisticTeamIds?: string[]; // either 'allTeams' or list of team ids

  if (filters.maxAge) {
    const playerAge = getAge(playerOverview.birth_date);
    if (playerAge === undefined || playerAge > filters['maxAge']) {
      return false;
    }
  }

  if (filters.maxContractLength) {
    const monthsLeft = getMonthsLeftOfContract(playerOverview.contract_expiration);
    if (monthsLeft !== undefined && monthsLeft > filters.maxContractLength) {
      return false;
    }
    if (monthsLeft === undefined && !filters.includeUnknownContracts) {
      return false;
    }
  }

  if (filters.primaryPositions) {
    if (filters.primaryPositions.every((position: string) => !playerOverview.primary_positions.includes(position))) {
      return false;
    }
  }

  if (filters.positionGroup) {
    if (!playerOverview.position_group_stats[filters.positionGroup]?.confidence) {
      return false;
    }
  }

  if (filters.competitions) {
    if (!filters.competitions.includes(playerOverview.club.current_competition_id)) {
      return false;
    }
  }

  if (filters.minConfidence) {
    const playerConfidence = filters.positionGroup
      ? playerOverview.position_group_stats[filters.positionGroup]?.confidence
      : playerOverview.position_group_stats?.overall?.confidence;
    if (!playerConfidence || playerConfidence < filters.minConfidence) {
      return false;
    }
  }

  if (filters.form) {
    if (!filters.form.includes(playerOverview.form_status)) {
      return false;
    }
  }

  if (filters.playingTime) {
    if (!filters.playingTime.includes(playerOverview.availability_status)) {
      return false;
    }
  }

  if (filters.countryCodes) {
    if (!filters.countryCodes.includes(playerOverview.country_code)) {
      return false;
    }
  }

  if (filters.indexRequirements) {
    const positionGroupStats = playerOverview.position_group_stats[filters.positionGroup ?? 'overall'];
    if (!positionGroupStats || filters.indexRequirements.some(
      requirement => {
        const value = requirement.metric === 'skill_index'
          ? positionGroupStats.skill_index
          : positionGroupStats.custom_indexes?.[requirement.metric + '_index'];
        return value && value < parseInt(requirement.value);
      }
    )) {
      return false;
    }
  }

  if (filters.isDebutToggled) {
    if (!playerOverview.is_data_debutant) {
      return false;
    }
  }

  if (filters.logisticTeamIds && teams) {
    const playerIdsOnSelectedTeams = new Set<number>();
    positionOptionsPlatform.forEach(positionKey => {

      // iterate over all teams
      Object.keys(teams).forEach(teamKey => {

        // check if team is selected
        if (filters.logisticTeamIds && (filters.logisticTeamIds[0] === 'allTeams' || filters.logisticTeamIds.includes(teamKey))) {
          const teamData = teams[teamKey];

          // check if team has players in the position
          if (positionKey in teamData) {
            const positionArray = teamData[positionKey];

            // add player ids to set
            positionArray.forEach((player: PlayerId) => {
              const numberId = Number(player);
              if (!isNaN(numberId)) {
                playerIdsOnSelectedTeams.add(numberId);
              }
            });
          }
        }
      });
    });

    if (!playerIdsOnSelectedTeams.has(playerOverview.id)) {
      return false;
    }
  }

  return true;
};
