import '../../platform.css';
import './settings.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { navigationPropsState } from '../../recoil/atoms/navigationPropsState';

import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

import { Account } from './Account';
import { ClubSettings } from './ClubSettings';
import { Support } from './Support';
import { RoleConfigs } from './RoleConfigs';
import { FixedTabs } from '../../components/controls/navigation/FixedTabs';


export const Settings: React.FC = () => {

  const navigationProps = useRecoilValue(navigationPropsState);

  const initialActiveTab = navigationProps?.activeSubTab ?? 0;
  const [activeTab, setActiveTab] = useState(initialActiveTab);

  const tabOptions = [
    'account',
    'club',
    'roles',
    'help',
  ];

  const tabIcons = [
    <AccountCircle key={'account-icon'} style={{ fontSize: 22 }} />,
    <GroupsIcon key={'groups-icon'} style={{ fontSize: 22 }} />,
    <InsertChartOutlinedIcon key={'location-icon'} style={{ fontSize: 22 }} />,
    <HelpIcon key={'help-icon'} style={{ fontSize: 22 }} />
  ];


  return (
    <div className='platform-view-section'>

      <FixedTabs
        tabOptions={tabOptions}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        parentTab={'settings'}
        initialActiveTab={initialActiveTab}
        tabIcons={tabIcons}
      />

      <div className='platform-view-content-container-with-tabs'>
        {activeTab === 0 && <Account />}

        {activeTab === 1 && <ClubSettings />}

        {activeTab === 2 && <RoleConfigs />}

        {activeTab === 3 && <Support />}
      </div>

    </div>
  );
};
