import '../tables.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { playerTeamDataSelector } from '../../../recoil/selectors/playerTeamDataSelector';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import RestoreIcon from '@mui/icons-material/Restore';

import { CellProps } from 'react-table';
import { StringToAnyMap, RoleConfigMap, PlayerOverview, RoleConfig } from '../../../types';
import { getDateLocaleFormat, getDisplayPlayerName, getDisplayPositions } from '../../../utils/playerUtils';

import { translate } from '../../../../common/language/translations';
import { getFormIcon, getPlayingTimeIcon, getTeamIcon } from '../../../utils/iconUtils';
import { ReactNode } from 'react';
import { metricToDisplayInfo } from '../../../static/playerMetrics';
import { alertTablePageSize } from '../../../views/scouting/Scouting';
import { positionGroupToPositions } from '../../../static/propertyValues';
import { getIndexColor } from '../../../utils/colorUtils';
import { EventPropertiesMap } from '../../../services/server/analytics/events';
import { IconButton } from '../../controls/buttons/IconButton';
import { ProgressCircle } from '../../display/visualization/ProgressCircle';
import { PlayerImage } from '../../display/images/PlayerImage';
import { CountryFlag } from '../../display/images/CountryFlag';
import { ClubLogo } from '../../display/images/ClubLogo';


const InfoCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const playerOverview: PlayerOverview = row.original;

  const userConfig = useRecoilValue(userConfigState);
  const playerTeamData = useRecoilValue(playerTeamDataSelector) ?? {};
  const { openPlayerViewModal } = useOpenGlobalModal();

  const currentTeam = playerTeamData[playerOverview.id]?.currentTeam;
  let currentTeamIcon = getTeamIcon(currentTeam, 18, 'dark');
  if (!currentTeamIcon) {
    currentTeamIcon = <AddIcon style={{ fontSize: 13 }} />;
  }

  return (
    <div className='player-table-info-cell'>

      <PlayerImage
        src={playerOverview.image_url}
        size={40}
        containerStyle={{ marginLeft: 0, marginRight: 10 }}
        isSearchTableComponent={true}
      />

      <div className='player-table-info-cell-column' style={{ width: 210 }}>
        <div className='player-table-info-cell-row'>
          {getDisplayPlayerName(playerOverview.fullname, 20)}

          <CountryFlag
            countryCode={playerOverview.country_code}
            width={16}
            containerStyle={{ marginLeft: 9 }}
            isWhiteBackground={true}
          />
        </div>

        <ClubLogo
          club={playerOverview.club}
          size={14}
          showClubName={true}
          competitionIdToShow={playerOverview.club?.current_competition_id}
          containerStyle={{ justifyContent: 'left', color: '#444444', fontSize: 12 }}
          smallFontSize={11}
          smallFontSizeThreshold={25}
        />
      </div>

      <IconButton
        onClick={(event) => {
          {
            if (event) event.stopPropagation();
            openPlayerViewModal(
              playerOverview.id,
              playerOverview.fullname,
              undefined,
              undefined,
              true
            );
          }
        }}
        icon={currentTeamIcon}
        size={24}
        title={translate(currentTeam ? 'editTeamStatus' : 'addToTeam', userConfig?.language)}
        backgroundColorBehindIcon='gray'
      />
    </div>
  );
};


const AgeCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return (
    <div className='table-cell-centered' style={{ fontSize: 12 }}>
      {value ?? '-'}
    </div>
  );
};


const renderIndexCell = (property: string, positionGroup: string) => {
  const IndexCellRenderer = ({ row }: StringToAnyMap) => {
    return <IndexCell playerOverview={row.original} property={property} positionGroup={positionGroup} />;
  };

  IndexCellRenderer.displayName = 'IndexCellRenderer';
  return IndexCellRenderer;
};

const IndexCell: React.FC<{ playerOverview: PlayerOverview, property: string, positionGroup: string }> = ({ playerOverview, property, positionGroup }) => {

  let value: number | string | undefined = property === 'skill_index' || property === 'confidence'
    ? playerOverview.position_group_stats?.[positionGroup]?.[property]
    : playerOverview.position_group_stats?.[positionGroup]?.custom_indexes[property + '_index'];

  // not entirely sure if both checks are necessary
  value = value !== undefined && value !== null
    ? property === 'confidence'
      ? (Math.round(value * 100))
      : (Math.round(value * 10) / 10)
    : '-';

  return (
    <div className='table-cell-centered'>
      {value}
    </div>
  );
};


const renderContractCell = () => {
  const ContractCellRenderer = ({ row }: StringToAnyMap) => {
    return <ContractCell row={row} />;
  };

  ContractCellRenderer.displayName = 'ContractCellRenderer';
  return ContractCellRenderer;
};

const ContractCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {
  return (
    <div className='table-cell-centered' style={{ fontSize: 12 }}>
      {getDateLocaleFormat(row.original.contract_expiration)}
    </div>
  );
};


const renderPositionCell = (language: string) => {
  const PositionCellRenderer = ({ row }: StringToAnyMap) => {
    return <PositionCell row={row} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};

const PositionCell: React.FC<{
  row: StringToAnyMap;
  language: string;
}> = ({ row, language }) => {

  const playerOverview = row.original as PlayerOverview;

  let primaryPositions = playerOverview.primary_positions ?? [];
  let secondaryPositions = playerOverview.secondary_positions ?? [];

  // we only allow maximum 2 primary positions and 2 secondary positions - if more than 2 primary positions, we shift the other positions to secondary
  const allPositions = primaryPositions.concat(secondaryPositions);
  primaryPositions = allPositions.slice(0, 2);
  secondaryPositions = allPositions.slice(2, 4);

  const primaryPositionString = getDisplayPositions(primaryPositions, language);
  const secondaryPositionString = getDisplayPositions(secondaryPositions, language);

  return (
    <div className='player-table-position-cell' style={{ fontSize: 12 }}>
      {primaryPositionString ?? '-'}

      {secondaryPositionString && (
        <div style={{ fontSize: 10, color: '#000000dd' }}>
          {'(' + secondaryPositionString + ')'}
        </div>
      )}
    </div>
  );
};


const renderConfidenceCell = (positionGroupKey: string) => {
  const ConfidenceCellRenderer = ({ row }: StringToAnyMap) => {
    return <ConfidenceCell playerOverview={row.original} positionGroupKey={positionGroupKey} />;
  };

  ConfidenceCellRenderer.displayName = 'ConfidenceCellRenderer';
  return ConfidenceCellRenderer;
};


const ConfidenceCell: React.FC<{ playerOverview: PlayerOverview, positionGroupKey: string }> = ({ playerOverview, positionGroupKey }) => {

  const confidence = playerOverview.position_group_stats?.[positionGroupKey]?.confidence;

  return (
    <div className='table-cell-centered'>
      {confidence !== undefined && confidence !== null && (
        <ProgressCircle
          size={28}
          progress={confidence * 100}
          value={Math.round(confidence * 100)}
          trailColor='#00000022'
          color={getIndexColor(confidence * 10, 47)}
          fontSize={12}
        />
      )}
    </div>
  );
};


const renderExcludeCell = (language: string, excludePlayer: (playerId: number, allAlerts: boolean, monthsDiscarded: number) => void) => {
  const ExcludeCellRenderer = ({ row }: StringToAnyMap) => {
    return <ExcludeCell row={row} language={language} excludePlayer={excludePlayer} />;
  };

  ExcludeCellRenderer.displayName = 'DiscardCellRenderer';
  return ExcludeCellRenderer;
};


const ExcludeCell: React.FC<{
  row: StringToAnyMap,
  language: string,
  excludePlayer: (playerId: number, allAlerts: boolean, monthsDiscarded: number) => void
}> = ({ row, language, excludePlayer }) => {

  const excludePlayerInternal = (event: React.MouseEvent, allAlerts: boolean, monthsDiscarded: number) => {
    event.stopPropagation();
    excludePlayer(row.original.id, allAlerts, monthsDiscarded);
  };

  return (
    <div className='alert-table-exclude-cell' style={{ paddingRight: lastColumnPadding }}>

      <div className='alert-table-exclude-container' onClick={(event) => excludePlayerInternal(event, false, 3)}>
        <div className='alert-table-exclude-row'>
          <BlockIcon style={{ fontSize: 16 }} />
          {'3 '}
          {translate('monthsShort', language, true)}
        </div>
        <div className='alert-table-exclude-row'>
          {translate('thisSearch', language)}
        </div>
      </div>

      {/* <div className='alert-table-exclude-container' onClick={(event) => excludePlayerInternal(event, false, 12)}>
        <div className='alert-table-exclude-row'>
          <BlockIcon style={{ fontSize: 16 }} />
          {'1 '}
          {translate('year', language, true)}
        </div>
        <div className='alert-table-exclude-row'>
          {translate('thisSearch', language)}
        </div>
      </div> */}

      <div className='alert-table-exclude-container' onClick={(event) => excludePlayerInternal(event, true, 12)}>
        <div className='alert-table-exclude-row'>
          <BlockIcon style={{ fontSize: 16 }} />
          {'1 '}
          {translate('year', language, true)}
        </div>
        <div className='alert-table-exclude-row'>
          {translate('allSearches', language)}
        </div>
      </div>

    </div >
  );
};


const renderExcludedCell = (
  language: string,
  undoExcludePlayer: (playerOverview: PlayerOverview, allAlerts: boolean) => void
) => {
  const ExcludedCellRenderer = ({ row }: StringToAnyMap) => {
    return <ExcludedCell row={row} language={language} undoExcludePlayer={undoExcludePlayer} />;
  };

  ExcludedCellRenderer.displayName = 'DiscardCellRenderer';
  return ExcludedCellRenderer;
};


const ExcludedCell: React.FC<{
  row: StringToAnyMap,
  language: string,
  undoExcludePlayer: (playerOverview: PlayerOverview, allAlerts: boolean) => void
}> = ({ row, language, undoExcludePlayer }) => {

  const undoExcludePlayerInternal = (event: React.MouseEvent, allAlerts: boolean) => {
    event.stopPropagation();
    undoExcludePlayer(row.original, allAlerts);
  };

  const isExcludedFromAllAlerts = row.original.isExcludedFromAllAlerts;

  return (
    <div className='alert-table-exclude-cell' style={{ paddingRight: lastColumnPadding, paddingLeft: 5 }}>

      <div className='alert-table-exclude-column' style={{ width: 80, fontSize: 10, textWrap: 'nowrap' }}>
        <BlockIcon style={{ fontSize: 16 }} />
        {translate(isExcludedFromAllAlerts ? 'allSearches' : 'thisSearch', language)}
      </div>

      <div
        className='alert-table-excluded-container'
        onClick={(event) => undoExcludePlayerInternal(event, isExcludedFromAllAlerts ?? false)}
      >
        <div className='alert-table-exclude-column'>
          <RestoreIcon style={{ fontSize: 16, marginRight: 2 }} />
          {translate('undo', language)}?
        </div>
      </div>

    </div >
  );
};


const FormCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const formIcon = row.original.event_data_available
    ? getFormIcon(row.original.form_status, 18, 'dark', 43)
    : undefined;

  return (
    <div className='table-cell-centered'>
      {formIcon}
    </div>
  );
};


const PlayingTimeCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const playingTimeIcon = row.original.event_data_exist
    ? getPlayingTimeIcon(row.original.availability_status, 18, 'dark')
    : undefined;

  return (
    <div className='table-cell-centered'>
      {playingTimeIcon}
    </div>
  );
};


export const playerInfoCellWidth = 300;
const lastColumnPadding = 16;


export const getAlertColumns = (
  widthUnit: number,
  language: string,
  excludePlayer: (playerId: number, allAlerts: boolean, monthsExcluded: number) => void,
  undoExcludePlayer: (playerOverview: PlayerOverview, allAlerts: boolean) => void,
  excludeAllPlayers: () => void,
  undoAllExcludedPlayers: () => void,
  isExcludedPlayersView: boolean,
  currentPage: number,
  totalHits: number,
  handleChangeCurrentPage: (isIncrement: boolean) => void,
  selectedRoles: string[],
  roleConfigs: RoleConfigMap,
  selectedOrderBy: string | undefined,
  secondaryClubColor: string,
  openTextModal: (title: string, text: string, eventProperties: EventPropertiesMap['InfoModalOpened']) => void,
  openConfirmModal: (confirm: () => void, confirmTitleKey?: string, confirmInfoKey?: string) => void,
  openRoleInfoModal: (roleConfig: RoleConfig) => void,
) => {

  const positionGroupKey = selectedRoles.length > 0 ? roleConfigs[selectedRoles[0]].positionGroup : 'overall';

  const columns: StringToAnyMap[] = [
    {
      Header:
        <div className='player-table-top-level-header-cell-sticky' style={{ width: playerInfoCellWidth }}>

          {totalHits
            + (totalHits >= 10000 ? '+ ' : ' ')
            + (translate(totalHits === 1 ? 'player' : 'players', language, true))
          }

          {totalHits > alertTablePageSize && (
            <div className='table-cell'>
              <div className='player-table-current-chapter-range'>
                {'(' + (alertTablePageSize * (currentPage - 1) + 1) + '-' + Math.min(alertTablePageSize * currentPage, totalHits) + ')'}
              </div>

              <div className='player-table-pagination-section' style={{ left: 235 }}>
                <IconButton
                  icon={(
                    <KeyboardArrowLeftIcon
                      style={{ fontSize: 17, color: currentPage > 1 ? '#ffffffdd' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => currentPage > 1 ? handleChangeCurrentPage(false) : null}
                  size={20}
                  isMobile={true}
                  style={{ cursor: currentPage > 1 ? 'pointer' : 'default' }}
                />

                <div className='player-table-current-chapter'>
                  {currentPage}
                </div>

                <IconButton
                  icon={(
                    <KeyboardArrowRightIcon
                      style={{ fontSize: 17, color: (currentPage - 1) < Math.floor(totalHits / alertTablePageSize) ? '#ffffffdd' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => (currentPage - 1) < Math.floor(totalHits / alertTablePageSize) ? handleChangeCurrentPage(true) : null}
                  size={20}
                  isMobile={true}
                  style={{ cursor: (currentPage - 1) < Math.floor(totalHits / alertTablePageSize) ? 'pointer' : 'default' }}
                />
              </div>
            </div>
          )}
        </div>,

      id: 'player',
      accessor: 'id',
      sticky: 'left',
      Cell: InfoCell,
      width: playerInfoCellWidth,
      minWidth: playerInfoCellWidth,
    },
  ];


  const infoColumns = [
    {
      Header:
        <div className='player-table-sub-level-header-cell'>
          {translate('age', language)}
        </div>,
      id: 'age',
      accessor: 'age',
      Cell: AgeCell,
      width: widthUnit * 50,
      minWidth: 50,
    },
    {
      Header:
        <div className='player-table-sub-level-header-cell'>
          {translate('contract', language)}
        </div>,
      id: 'contract',
      accessor: 'contract_expiration',
      Cell: renderContractCell(),
      width: widthUnit * 90,
      minWidth: 90,
    },
    {
      Header:
        <div className='player-table-sub-level-header-cell'>
          {translate('position', language)}
        </div>,
      id: 'position',
      accessor: 'position',
      Cell: renderPositionCell(language),
      width: widthUnit * 85,
      minWidth: 85,
    },
    {
      Header:
        <div className='player-table-sub-level-header-cell'>
          <div
            className='player-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            onClick={() => openTextModal(
              translate('form', language),
              translate('formInfo', language),
              { infoType: 'metric', titleKey: 'form' }
            )}
          >
            {translate('form', language)}
          </div>
        </div>,
      id: 'form',
      accessor: 'form',
      Cell: FormCell,
      width: widthUnit * 60,
      minWidth: 60,
    },
    {
      Header:
        <div
          className='player-table-sub-level-header-cell player-table-header-cell-with-border'
          style={{ textAlign: 'center', fontSize: 11 }}
        >
          <div
            className='player-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            style={{ textAlign: 'center', fontSize: language === 'en' ? 12.4 : undefined }}
            onClick={() => openTextModal(
              translate('playingTime', language),
              translate('playingTimeInfo', language),
              { infoType: 'metric', titleKey: 'playingTime' }
            )}
          >
            {translate('playingTime', language)}
          </div>
        </div>,
      id: 'playingTime',
      accessor: 'playingTime',
      Cell: PlayingTimeCell,
      width: widthUnit * 80,
      minWidth: 80,
      isFinalSubMetric: true,
    },
  ];

  columns.push({
    Header:
      <div
        className='player-table-top-level-header-cell player-table-header-cell-with-border'
        style={{ fontSize: 12 }}
      >
        Info
      </div>,
    id: 'playerInfo',
    columns: infoColumns,
  });


  const dataColumns: StringToAnyMap[] = [
    {
      Header:
        <div
          className='player-table-sub-level-header-cell'
          style={{
            color: selectedOrderBy === 'skillIndex' ? secondaryClubColor : undefined,
            fontWeight: selectedOrderBy === 'skillIndex' ? 600 : undefined,
          }}
        >
          <div
            className='player-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            onClick={() => openTextModal(
              metricToDisplayInfo['skill_index'].name[language],
              metricToDisplayInfo['skill_index'].description[language],
              { infoType: 'metric', titleKey: 'skill_index' }
            )}
          >
            {metricToDisplayInfo['skill_index'].name[language]}
          </div>
        </div>,
      id: 'skill_index',
      accessor: 'skill_index',
      Cell: renderIndexCell('skill_index', positionGroupKey),
      width: widthUnit * 90,
      minWidth: 90,
    },
  ];


  selectedRoles.forEach(roleId => {

    const roleName = roleConfigs[roleId].name;
    const width = getRoleColumnWidth(roleName);

    dataColumns.push(
      {
        Header:
          <div
            className='player-table-sub-level-header-cell'
            style={{
              color: selectedOrderBy === roleId ? secondaryClubColor : undefined,
              fontWeight: selectedOrderBy === roleId ? 600 : undefined,
            }}
          >
            <div
              className='player-table-sub-level-header-cell-text'
              title={translate('showDescription', language)}
              onClick={() => openRoleInfoModal(roleConfigs[roleId])}
            >
              {roleName}
            </div>
          </div>,
        accessor: roleId,
        Cell: renderIndexCell(roleId, positionGroupKey),
        width: widthUnit * width,
        minWidth: width,
      }
    );
  });

  dataColumns.push(
    {
      Header:
        <div className='player-table-sub-level-header-cell player-table-header-cell-with-border'>
          <div
            className='player-table-sub-level-header-cell-text'
            title={translate('showDescription', language)}
            onClick={() => openTextModal(
              metricToDisplayInfo['confidence'].name[language],
              metricToDisplayInfo['confidence'].description[language],
              { infoType: 'metric', titleKey: 'confidence' }
            )}
          >
            {metricToDisplayInfo['confidence'].name[language]}
          </div>
        </div>,
      id: 'confidence',
      accessor: 'confidence',
      Cell: renderConfidenceCell(positionGroupKey),
      width: widthUnit * 80,
      minWidth: 80,
      isFinalSubMetric: true,
    },
  );


  columns.push({
    Header:
      <div
        className='player-table-top-level-header-cell player-table-header-cell-with-border'
        style={{ fontSize: 12 }}
      >
        {translate('basedOn<positions>', language) + ' '}
        {positionGroupKey !== 'overall'
          ? getDisplayPositions(positionGroupToPositions[positionGroupKey], language)
          : translate('allPositions', language, true)}
      </div>,
    id: 'playerData',
    columns: dataColumns,
  });


  if (isExcludedPlayersView) {
    columns.push({
      Header:
        <div
          className='player-table-top-level-header-cell player-table-header-cell-with-border'
          style={{ paddingRight: lastColumnPadding, fontSize: 12 }}
        >
          {translate('undo', language)}
        </div>,
      id: 'actions',
      columns: [{
        Header:
          <div
            className='player-table-sub-level-header-cell player-table-header-cell-with-border'
            style={{ paddingRight: lastColumnPadding }}
          >
            <div
              className='player-table-sub-level-header-cell-text'
              onClick={() => openConfirmModal(
                undoAllExcludedPlayers,
                'undoAll',
                'undoAllInfo'
              )}
            >
              {translate('undoAll', language)}?
            </div>
          </div>,
        accessor: 'excluded',
        Cell: renderExcludedCell(language, undoExcludePlayer),
        width: widthUnit * (140 + lastColumnPadding),
        minWidth: 140 + lastColumnPadding,
      }],
    });
  }
  else {
    columns.push({
      Header:
        <div
          className='player-table-top-level-header-cell player-table-header-cell-with-border'
          style={{ paddingRight: lastColumnPadding, fontSize: 12 }}
        >
          {translate('removeFromSearch', language)}
        </div>,
      id: 'actions',
      columns: [{
        Header:
          <div
            className='player-table-sub-level-header-cell player-table-header-cell-with-border'
            style={{ paddingRight: lastColumnPadding }}
          >
            <div
              className='player-table-sub-level-header-cell-text'
              onClick={() => openConfirmModal(
                excludeAllPlayers,
                'removeAll',
                'removeAllInfo'
              )}
            >
              {translate('removeAll', language)}?
            </div>
          </div>,
        accessor: 'exclude',
        Cell: renderExcludeCell(language, excludePlayer),
        width: widthUnit * (140 + lastColumnPadding),
        minWidth: 140 + lastColumnPadding,
      }],
    });
  }

  return columns;
};


const getRoleColumnWidth = (roleName: string) => {
  return Math.min(Math.max(60, roleName.length * 8), 100);
};


export const getAlertTableTotalColumnsMinWidth = (
  selectedRoles: string[],
  roleConfigs: RoleConfigMap,
) => {
  const baseColumnsMinWIdth = 675 + lastColumnPadding;

  const roleColumnsMinWidth = selectedRoles && roleConfigs
    ? selectedRoles.reduce((acc, role) => acc + getRoleColumnWidth(roleConfigs[role].name), 0)
    : 0;

  return baseColumnsMinWIdth + roleColumnsMinWidth;
};
