import './input.css';


interface TextAreaProps {
  value: string;
  placeholder?: string;
  placeholderFontSize?: number;

  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  disabled?: boolean;

  height?: number;
  width?: number;
  isWhiteBackground?: boolean;
}

export const TextArea: React.FC<TextAreaProps> = ({
  value,
  placeholder,
  placeholderFontSize = 14,

  onChange,
  disabled,

  height = '100%',
  width = '100%',
  isWhiteBackground = false,
}) => {

  const whiteBackgroundBoxShadow = '0px 0px 2px 1px #15171e35';
  const darkBackgroundBoxShadow = '0px 0px 2px 1px #15171e70';


  document.documentElement.style.setProperty('--text-area-placeholder-font-size', placeholderFontSize + 'px');


  return (
    <div
      className='text-area-container'
      style={{
        height: height,
        width: width,
        boxShadow: isWhiteBackground ? whiteBackgroundBoxShadow : darkBackgroundBoxShadow,
      }}
    >
      <textarea
        className='text-area'
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
      />

    </div>
  );
};
