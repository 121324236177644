import { db, FirebaseUser } from '../../../firebase';

import {
  collection,
  addDoc,
  doc,
  getDocs,
  updateDoc,
  query,
  orderBy,
  where
} from 'firebase/firestore';
import { StringToAnyMap, PlayerId, PlayerReport } from '../../types';
import { trackEvent } from '../server/analytics/trackEvent';
import { EventPropertiesMap } from '../server/analytics/events';


// Get all reports for all players
export const getAllReports = async (
  club: string,
  currentUser: FirebaseUser,
): Promise<PlayerReport[]> => {

  const reportsCollectionRef = collection(db, 'configs', club, 'reports');

  try {
    const q = query(reportsCollectionRef, orderBy('date', 'desc'));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() as PlayerReport }));
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'getAllReports', errorDetails: { error } }, currentUser, 'system');
    return [];
  }
};


// Get all reports for a player
export const getReportsForPlayer = async (
  playerId: PlayerId,
  club: string,
  currentUser: FirebaseUser,
  specificReportId?: string
): Promise<{ reports: PlayerReport[], specificReport: PlayerReport | undefined }> => {

  const reportsRef = collection(db, 'configs', club, 'reports');

  try {
    const q = query(reportsRef, where('playerId', '==', playerId));

    let specificReport: PlayerReport | undefined = undefined;

    const querySnapshot = await getDocs(q);
    const docs = querySnapshot.docs.map(doc => {

      const report = { id: doc.id, ...doc.data() as PlayerReport };

      if (specificReportId && doc.id === specificReportId) {
        specificReport = report;
      }

      return report;
    });

    // todo: could add composite index in firestore to sort
    docs.sort((a, b) => b.date.localeCompare(a.date));

    return {
      reports: docs,
      specificReport: specificReport
    };
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'getReportsForPlayer', errorDetails: { error } }, currentUser, 'system');
    return { reports: [], specificReport: undefined };
  }
};


// Add a new report and return the document id as the new report id
export const addReport = async (
  report: PlayerReport,
  club: string,
  currentUser: FirebaseUser,
): Promise<string | undefined> => {

  const reportsCollectionRef = collection(db, 'configs', club, 'reports');

  try {
    const docRef = await addDoc(reportsCollectionRef, report);

    trackEvent('ReportAdded', { reportType: report.reportType }, currentUser, 'user');

    return docRef.id;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addReport', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// Update a clause
export const updateReport = async (
  reportId: string,
  newValues: StringToAnyMap,
  club: string,
  currentUser: FirebaseUser,
  eventProperties?: EventPropertiesMap['ReportAction'],
): Promise<boolean> => {

  const reportDocRef = doc(db, 'configs', club, 'reports', reportId);

  try {
    await updateDoc(reportDocRef, newValues);

    if (eventProperties) trackEvent('ReportAction', eventProperties, currentUser, 'user');

    return true;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateReport', errorDetails: { error } }, currentUser, 'system');
    return false;
  }
};
