import './dropDowns.css';

import { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubScopesState } from '../../../recoil/atoms/clubScopesState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import BlockIcon from '@mui/icons-material/Block';

import { translate } from '../../../../common/language/translations';
import { goalkeeperSeasonTableMetricGroups, metricToDisplayInfo, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../../static/playerMetrics';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';
import { Toggle } from '../input/Toggle';


interface MetricDropDownProps {
  id: string;

  selectedOptions: string[];
  setSelectedOptions: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;
  selectedRoles: string[];

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string; // controls scrolling
  multiSelect?: boolean;
  forceSelection?: boolean; // if true, at least one option must be selected

  isGoalkeeperSelected?: boolean;
  anyIterationWithoutSkillcornerSelected: boolean;
  setAnySkillcornerFiltersSelected: (value: boolean) => void;
}

export const MetricDropDown: React.FC<MetricDropDownProps> = ({
  id,

  selectedOptions,
  setSelectedOptions,
  isDropDownExpanded,
  setIsDropDownExpanded,
  selectedRoles,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
  multiSelect,
  forceSelection,

  isGoalkeeperSelected,
  anyIterationWithoutSkillcornerSelected,
  setAnySkillcornerFiltersSelected,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const clubScopes = useRecoilValue(clubScopesState);
  const userSettings = useRecoilValue(userSettingsState);
  const clubSettings = useRecoilValue(clubSettingsState);

  const dropDownRef = useRef<HTMLDivElement>(null);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (option: string, isOptionAlreadySelected: boolean, isSkillcornerMetric?: boolean) => {

    let newSelectedOptions: string[] = [];

    // single-select
    if (!multiSelect) {
      if (!isOptionAlreadySelected) {
        removeDropDownExpansion();
      }
      newSelectedOptions = (isOptionAlreadySelected && !forceSelection) ? [] : [option];
    }

    // multi-select
    else {
      newSelectedOptions = selectedOptions.slice();
      if (isOptionAlreadySelected) {
        const i = newSelectedOptions.indexOf(option);
        newSelectedOptions.splice(i, 1);
      }
      else {
        newSelectedOptions.push(option);
      }
    }

    setSelectedOptions(newSelectedOptions);

    if (!isOptionAlreadySelected) {
      setAnySkillcornerFiltersSelected(isSkillcornerMetric ?? false);
    }
  };


  const expandDropDown = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
    if (dropDownRef.current) {
      dropDownRef.current.scrollTop = 0;
    }
  };


  const getDisplayOption = (metric: string) => {
    if (!userConfig || !clubSettings) return '';

    return metricToDisplayInfo[metric]
      ? metricToDisplayInfo[metric].name[userConfig.language]
      : clubSettings.roleConfigs[metric].name;
  };


  const showSelectedOptions = () => {

    // single-select
    let firstSelection = getDisplayOption(selectedOptions[0]);

    if (!multiSelect) {
      return firstSelection;
    }

    // multi-select
    for (let i = 1; i < selectedOptions.length; i++) {
      firstSelection += ', ' + getDisplayOption(selectedOptions[i]);
    }
    return firstSelection;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  const ratingMetricGroup = { ratings: [...selectedRoles, 'skill_rating'] };
  const normalMetricGroups = isGoalkeeperSelected ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const metricGroups = { ...ratingMetricGroup, ...normalMetricGroups };

  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-button' onClick={() => handleDropDownClick()}>

        {selectedOptions.length > 0 && <div className='drop-down-button-overlay' />}

        <div className='drop-down-button-text'>
          {selectedOptions.length === 0 ? (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          ) : (
            <div style={{ color: '#000000' }}>
              {showSelectedOptions()}
            </div>
          )}
        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-space'>&nbsp;</div>

      <div className='drop-down-toggle-section'>
        <div className='drop-down-toggle-text'>
          {translate('orderByActualValues', userConfig?.language)}
        </div>
        {userConfig && (
          <div
            className='drop-down-toggle'
            title={translate(userSettings?.seasonStatsToggles?.orderBy ? 'orderByNormalizedValuesTitle' : 'orderByActualValuesTitle', userConfig.language)}
          >
            <Toggle
              isToggled={userSettings?.seasonStatsToggles?.orderBy ?? false}
              setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                { 'orderBy': value },
                userConfig.email,
                userConfig.club,
                currentUser,
              )}
              isWhiteBackground={true} />
          </div>
        )}
      </div>

      <div
        ref={dropDownRef}
        className={'drop-down-options' + (maxHeight ? ' drop-down-options-scroll' : '')}
        style={{ maxHeight: maxHeight }}
      >
        {Object.entries(metricGroups).map(([metricGroupKey, metrics], index) => {

          return (
            <div key={metricGroupKey + '-' + index}>
              <div className='metric-drop-down-divider' style={{ marginTop: index === 0 ? 20 : undefined }}>&nbsp;</div>

              <div className='metric-drop-down-group-title-container'>
                <div className='metric-drop-down-group-title'>
                  {metricToDisplayInfo[metricGroupKey].name[userConfig?.language ?? 'en']}
                </div>
              </div>

              {metrics.map(metric => {
                const isSelected = selectedOptions.includes(metric);
                return (
                  <div
                    className={'drop-down-option' + (isSelected ? ' drop-down-option-selected' : '')}
                    style={{ marginTop: marginBetweenOptions }}
                    key={metric}
                    onClick={() => handleOptionSelect(metric, isSelected)}
                  >
                    {getDisplayOption(metric)}
                  </div>
                );
              })}
            </div>
          );
        })}

        {userConfig && clubScopes && clubScopes.hasSkillcorner && !isGoalkeeperSelected && (
          <div>
            <div className='metric-drop-down-divider'>&nbsp;</div>

            <div className='metric-drop-down-group-title-container'>
              <div className='metric-drop-down-group-title' style={{ paddingRight: anyIterationWithoutSkillcornerSelected ? 24 : undefined }}>
                {metricToDisplayInfo.skillcorner.name[userConfig.language]}
                {anyIterationWithoutSkillcornerSelected &&
                  <BlockIcon className='fast-fade-in' style={{ fontSize: 17, color: '#00000088', position: 'absolute', marginTop: -2, marginLeft: 3 }} />
                }
              </div>

            </div>

            {skillcornerStats.map(metric => {
              const isSelected = selectedOptions.includes(metric);
              const isDisabled = anyIterationWithoutSkillcornerSelected;
              return (
                <div
                  className={
                    'drop-down-option'
                    + (isSelected ? ' drop-down-option-selected' : '')
                    + (isDisabled ? ' drop-down-option-disabled' : '')
                  }
                  style={{ marginTop: marginBetweenOptions }}
                  key={metric}
                  onClick={() => !isDisabled ? handleOptionSelect(metric, isSelected, true) : isDisabled}
                >
                  {getDisplayOption(metric)}
                </div>
              );
            })}
          </div>
        )}
      </div>

    </div>
  );
};
