import { svgColors } from './colors';


export const NavigationBackgroundSvg = () => {

  return (
    <div className='full-size'>
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none'>

        <defs>
          <linearGradient id='navigation-background-1' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%' stopColor={svgColors.svgBackgroundLightColor} stopOpacity='0.2' />
            <stop offset='100%' stopColor={svgColors.svgBackgroundLightColor} stopOpacity='0' />
          </linearGradient>

          <linearGradient id='navigation-background-2' x1='0%' y1='0%' x2='100%' y2='100%'>
            <stop offset='0%' stopColor={svgColors.svgBackgroundLightColor} stopOpacity='0.1' />
            <stop offset='100%' stopColor={svgColors.platformDarkColor} stopOpacity='0.1' />
          </linearGradient>

          <linearGradient id='navigation-background-3' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor={svgColors.svgBackgroundLightColor} stopOpacity='0.1' />
            <stop offset='100%' stopColor={svgColors.platformDarkColor} stopOpacity='0.1' />
          </linearGradient>

          <linearGradient id='navigation-background-4' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor={svgColors.svgBackgroundLightColor} stopOpacity='0.1' />
            <stop offset='100%' stopColor={svgColors.platformDarkColor} stopOpacity='0.1' />
          </linearGradient>

          <linearGradient id='navigation-background-5' x1='0%' y1='100%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor={svgColors.svgBackgroundLightColor} stopOpacity='0.1' />
            <stop offset='100%' stopColor={svgColors.platformDarkColor} stopOpacity='0.1' />
          </linearGradient>

        </defs>

        <path d='M 0 0 L 100 0 L 100 100 L 0 100 Z' stroke='none' fill='url(#navigation-background-1)' />

        <path d='M 0 2 L 100 0 L 100 10 L 0 10 Z' stroke='none' fill='url(#navigation-background-2)' />

        <path d='M 0 94 L 0 90 L 100 50 L 100 100 Z' stroke='none' fill='url(#navigation-background-3)' />

        <path d='M 12 100 L 100 77 L 100 100 Z' stroke='none' fill='url(#navigation-background-4)' />

        <path d='M 40 100 L 100 92 L 100 100 Z' stroke='none' fill='url(#navigation-background-5)' />

      </svg>
    </div>
  );
};
