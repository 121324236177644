import { FirebaseUser, db } from '../../../firebase';

import {
  doc,
  setDoc,
  arrayUnion,
  getDoc,
  updateDoc,
  onSnapshot,
} from 'firebase/firestore';

import {
  Alert,
  StringToAnyMap,
  NumberToStringToStringMap,
  ScoutingConfig,
  PlayerOverview,
  PlayerAlertsQueryOptions
} from '../../types';

import { searchPlayerAlerts, SearchPlayerAlertsResponse } from '../server/application/playerAlerts';
import { playerMatchesAlertsFilters } from '../../utils/playerUtils';
import { trackEvent } from '../server/analytics/trackEvent';
import { getFiltersUsed } from '../../utils/scoutingUtils';
import { getPlayerOverviews } from '../server/application/playerOverviews';


// Get user scouting data
export const getScoutingConfig = (
  setScoutingConfig: (scoutingConfig: ScoutingConfig) => void,
  userEmail: string,
  club: string,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  const unsubscribe = onSnapshot(scoutingConfigDocRef, (doc) => {
    if (doc.exists()) {
      const clubSettingsObject = doc.data();

      const scoutingConfig: ScoutingConfig = {
        savedSearches: clubSettingsObject.savedSearches ?? [],
        nameSearchHistory: clubSettingsObject.nameSearchHistory ?? [],
        alerts: clubSettingsObject.alerts ?? [],
        playerIdsToExcludeFromAllAlerts: clubSettingsObject.playerIdsToExcludeFromAllAlerts ?? {},
      };

      setScoutingConfig(scoutingConfig);
    }
  });

  return unsubscribe;
};


// Save a new search
export const saveUserSearch = async (
  search: StringToAnyMap,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    await setDoc(scoutingConfigDocRef, {
      savedSearches: arrayUnion(search)
    }, { merge: true });
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'saveUserSearch', errorDetails: { error } }, currentUser, 'system');
  }
};


// Delete a saved search
export const deleteSearch = async (
  searchIndex: number,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();

      if (Array.isArray(data.savedSearches)) {
        data.savedSearches.splice(searchIndex, 1);

        await updateDoc(scoutingConfigDocRef, {
          savedSearches: data.savedSearches
        });
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deleteSearch', errorDetails: { error } }, currentUser, 'system');
  }
};


// Add searched name to history
export const addNameToNameSearchHistory = async (
  name: string,
  nameSearchHistory: string[],
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const updatedHistory = [name, ...nameSearchHistory.filter((item) => item !== name)];

    const trimmedHistory = updatedHistory.slice(0, 30);

    await setDoc(
      scoutingConfigDocRef,
      { nameSearchHistory: trimmedHistory },
      { merge: true }
    );
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'addNameToHistory', errorDetails: { error } }, currentUser, 'system');
  }
};


// Delete a name from the name search history
export const deleteNameFromNameSearchHistory = async (
  name: string,
  nameSearchHistory: string[],
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const updatedHistory = nameSearchHistory.filter((item) => item !== name);

    await setDoc(
      scoutingConfigDocRef,
      { nameSearchHistory: updatedHistory },
      { merge: true }
    );
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deleteNameFromHistory', errorDetails: { error } }, currentUser, 'system');
  }
};


// Create a new alert
export const createAlert = async (
  alertName: string,
  filters: PlayerAlertsQueryOptions,
  selectedRoles: string[],
  playerIdsToExcludeFromAllAlerts: NumberToStringToStringMap,
  user: FirebaseUser,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
): Promise<Alert | string | null> => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);
  const playerIdsToExcludeArray = Object.keys(playerIdsToExcludeFromAllAlerts).map(Number);

  const playerAlertsResult: SearchPlayerAlertsResponse | undefined = await searchPlayerAlerts(
    { ...filters, playerIdsToExclude: playerIdsToExcludeArray }, user
  );

  if (!playerAlertsResult) return null;

  if (playerAlertsResult.total_hits > 1000) {
    trackEvent('TooManyPlayersReturnedForAlert', { totalHits: playerAlertsResult.total_hits }, currentUser, 'user');
    return 'tooManyPlayersForAlert';
  }

  const newAlert: Alert = {
    name: alertName,
    filters: filters,
    selectedRoles: selectedRoles,
    playerIdsToExclude: {},
    playerIdsToAlert: playerAlertsResult.player_alerts,
    lastViewedDate: playerAlertsResult.date,
    numberOfNewPlayersSinceLastViewed: 0,
  };

  try {
    await setDoc(scoutingConfigDocRef, {
      alerts: arrayUnion(newAlert)
    }, { merge: true });

    trackEvent('AlertCreated', { filtersUsed: getFiltersUsed(filters) }, currentUser, 'user');

    return newAlert;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'createAlert', errorDetails: { error } }, currentUser, 'system');
    return null;
  }
};


// Update alert
export const updateAlert = async (
  currentAlertName: string,
  newAlertName: string,
  filters: PlayerAlertsQueryOptions,
  selectedRoles: string[],
  resetExcludedPlayers: boolean,
  user: FirebaseUser,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
): Promise<string | boolean> => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (Array.isArray(data.alerts)) {

        let tooManyPlayers = false;
        let tooManyPlayersTotalHits = 0;

        const updatedAlerts = await Promise.all(data.alerts.map(async (alert: Alert) => {
          if (alert.name === currentAlertName) {

            const newPlayerIdsToExclude = resetExcludedPlayers ? {} : alert.playerIdsToExclude;
            const allExcludedPlayerIds = { ...data.playerIdsToExcludeFromAllAlerts, ...newPlayerIdsToExclude };
            const playerIdsToExcludeArray = Object.keys(allExcludedPlayerIds).map(Number);

            const playerAlertsResult: SearchPlayerAlertsResponse | undefined = await searchPlayerAlerts(
              { ...filters, playerIdsToExclude: playerIdsToExcludeArray }, user
            );

            if (!playerAlertsResult) return alert;

            if (playerAlertsResult.total_hits > 1000) {
              tooManyPlayers = true;
              tooManyPlayersTotalHits = playerAlertsResult.total_hits;
            }

            return {
              ...alert,
              name: newAlertName || alert.name,
              filters: filters,
              selectedRoles: selectedRoles,
              playerIdsToExclude: newPlayerIdsToExclude,
              playerIdsToAlert: playerAlertsResult.player_alerts,
              lastViewedDate: playerAlertsResult.date,
            };
          }

          return alert;
        }));

        if (tooManyPlayers) {
          trackEvent('TooManyPlayersReturnedForAlert', { totalHits: tooManyPlayersTotalHits }, currentUser, 'user');
          return 'tooManyPlayersForAlert';
        }

        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });

        trackEvent('AlertEdited', { filtersUsed: getFiltersUsed(filters), resetExcludedPlayers }, currentUser, 'user');

        return true;
      }
    }

    return false;
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateAlert', errorDetails: { error } }, currentUser, 'system');
    return false;
  }
};


// Update name of an alert
export const updateAlertName = async (
  currentAlertName: string,
  newAlertName: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (Array.isArray(data.alerts)) {
        const updatedAlerts = data.alerts.map((alert: Alert) =>
          alert.name === currentAlertName ? { ...alert, name: newAlertName } : alert
        );

        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });
        trackEvent('AlertNameEdited', {}, currentUser, 'user');
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateAlertName', errorDetails: { error } }, currentUser, 'system');
  }
};


// Update alert view data
export const updateAlertViewedData = async (
  alertName: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (Array.isArray(data.alerts)) {
        const updatedAlerts = data.alerts.map((alert: Alert) => {

          if (alert.name === alertName) {
            return {
              ...alert,
              lastViewedDate: new Date().toISOString(),
              numberOfNewPlayersSinceLastViewed: 0
            };
          }

          return alert;
        });

        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });

        trackEvent('AlertViewedUpdated', {}, currentUser, 'user');
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateAlertViewedData', errorDetails: { error } }, currentUser, 'system');
  }
};


// Delete an alert
export const deleteAlert = async (
  alertName: string,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (Array.isArray(data.alerts)) {
        const updatedAlerts = data.alerts.filter((alert: Alert) => alert.name !== alertName);
        await updateDoc(scoutingConfigDocRef, { alerts: updatedAlerts });
        trackEvent('AlertDeleted', {}, currentUser, 'user');
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deleteAlert', errorDetails: { error } }, currentUser, 'system');
  }
};


// Exclude the given player from either the alert with the given alertName or from all alerts
export const excludePlayerFromAlert = async (
  playerId: number,
  alertName: string | undefined,
  monthsExcluded: number,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);
    const todayString = new Date().toISOString();
    const excludedUntilString = new Date(new Date().setMonth(new Date().getMonth() + monthsExcluded)).toISOString();

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (Array.isArray(data.alerts)) {

        const newPlayerIdsToExcludeFromAllAlerts = { ...data.playerIdsToExcludeFromAllAlerts };

        if (!alertName) {
          newPlayerIdsToExcludeFromAllAlerts[playerId] = { dateAdded: todayString, excludedUntil: excludedUntilString };
        }

        const updatedAlerts = data.alerts.map((existingAlert: Alert) => {

          if (!alertName) {
            delete existingAlert.playerIdsToAlert[playerId];
          }

          else if (existingAlert.name === alertName) {
            delete existingAlert.playerIdsToAlert[playerId];
            existingAlert.playerIdsToExclude[playerId] = { dateAdded: todayString, excludedUntil: excludedUntilString };
          }

          return existingAlert;
        });

        await updateDoc(scoutingConfigDocRef, {
          alerts: updatedAlerts,
          playerIdsToExcludeFromAllAlerts: newPlayerIdsToExcludeFromAllAlerts
        });

        trackEvent('PlayerExcludedFromAlert', { monthsExcluded, excludedFromAllAlerts: alertName === undefined }, currentUser, 'user');
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'excludePlayerFromAlert', errorDetails: { error } }, currentUser, 'system');
  }
};


// Exclude all players in the given alert from the alert for 3 months
export const excludeAllPlayersFromAlert = async (
  alert: Alert,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);
    const todayString = new Date().toISOString();
    const excludedUntilString = new Date(new Date().setMonth(new Date().getMonth() + 3)).toISOString();

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (Array.isArray(data.alerts)) {

        let numberOfPlayersExcluded = 0;
        const updatedAlerts = data.alerts.map((existingAlert: Alert) => {

          if (existingAlert.name === alert.name) {
            Object.keys(alert.playerIdsToAlert).forEach((playerId) => {
              existingAlert.playerIdsToExclude[Number(playerId)] = { dateAdded: todayString, excludedUntil: excludedUntilString };
              numberOfPlayersExcluded++;
            });

            existingAlert.playerIdsToAlert = {};
          }

          return existingAlert;
        });

        await updateDoc(scoutingConfigDocRef, {
          alerts: updatedAlerts
        });

        trackEvent('AllPlayersExcludedFromAlert', { numberOfPlayers: numberOfPlayersExcluded }, currentUser, 'user');
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'excludePlayerFromAlert', errorDetails: { error } }, currentUser, 'system');
  }
};


// Undo the exclusion of the given player from either the alert with the given alertName or from all alerts
export const undoExcludePlayerFromAlert = async (
  playerOverview: PlayerOverview,
  filters: PlayerAlertsQueryOptions,
  teams: StringToAnyMap | null,
  alertName: string | undefined,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      if (Array.isArray(data.alerts)) {

        const newPlayerIdsToExcludeFromAllAlerts = { ...data.playerIdsToExcludeFromAllAlerts };

        if (!alertName) {
          delete newPlayerIdsToExcludeFromAllAlerts[playerOverview.id];
        }

        const updatedAlerts = data.alerts.map((existingAlert: Alert) => {

          if (!alertName) {
            if (playerMatchesAlertsFilters(playerOverview, filters, teams)) {
              existingAlert.playerIdsToAlert[playerOverview.id] = { dateAdded: new Date().toISOString() };
            }
          }

          else if (existingAlert.name === alertName) {
            delete existingAlert.playerIdsToExclude[playerOverview.id];
            if (playerMatchesAlertsFilters(playerOverview, filters, teams)) {
              existingAlert.playerIdsToAlert[playerOverview.id] = { dateAdded: new Date().toISOString() };
            }
          }

          return existingAlert;
        });

        await updateDoc(scoutingConfigDocRef, {
          alerts: updatedAlerts,
          playerIdsToExcludeFromAllAlerts: newPlayerIdsToExcludeFromAllAlerts
        });

        trackEvent('PlayerExclusionFromAlertRegretted', { wasExcludedFromAllAlerts: alertName === undefined }, currentUser, 'user');
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'undoExcludePlayerFromAlert', errorDetails: { error } }, currentUser, 'system');
  }
};


// Undo all exclusions from the given alert
export const undoAllExcludedPlayersFromAlert = async (
  alert: Alert,
  teams: StringToAnyMap | null,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  const scoutingConfigDocRef = doc(db, 'configs', club, 'scouting', userEmail);

  try {
    const docSnap = await getDoc(scoutingConfigDocRef);

    if (docSnap.exists()) {

      // we need to get the player overviews of all the excluded players to check if they still match the filters
      const playerIdsToUndo = Object.keys(alert.playerIdsToExclude).map(id => Number(id));
      const playerOverviewsToUndo = await getPlayerOverviews(playerIdsToUndo, currentUser);

      const data = docSnap.data();
      if (playerOverviewsToUndo && Array.isArray(data.alerts)) {

        let numberOfPlayerExclusionsRegretted = 0;
        const updatedAlerts = data.alerts.map((existingAlert: Alert) => {

          if (existingAlert.name === alert.name) {
            playerIdsToUndo.forEach((playerId) => {
              if (playerMatchesAlertsFilters(playerOverviewsToUndo[playerId], alert.filters, teams)) {
                existingAlert.playerIdsToAlert[playerId] = { dateAdded: new Date().toISOString() };
              }
              numberOfPlayerExclusionsRegretted++;
            });

            existingAlert.playerIdsToExclude = {};
          }

          return existingAlert;
        });

        await updateDoc(scoutingConfigDocRef, {
          alerts: updatedAlerts,
        });

        trackEvent('AllPlayerExclusionsFromAlertRegretted', { numberOfPlayers: numberOfPlayerExclusionsRegretted }, currentUser, 'user');
      }
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'undoExcludePlayerFromAlert', errorDetails: { error } }, currentUser, 'system');
  }
};
