import { SearchPositionGroupClubIterationsResponse } from '../../../services/server/application/positionGroupClubIterations';
import { RatingRequirement, StringToAnyMap } from '../../../types';
import { playerTableChapterSize } from '../Scouting';
import { initialSeasonScoutingState, SeasonScoutingFilter, SeasonScoutingState } from './seasonScoutingState';


export type SeasonScoutingAction =
  | {
    type: 'SET_SEARCH_RESULT';
    payload: {
      result: SearchPositionGroupClubIterationsResponse,
      isNewSearch: boolean,
      nextChapter: number | undefined,
      newOverriddenOrderBy: string | undefined,
      selectedOrderByToggle: boolean,
    }
  }
  | { type: 'RESET_STATE'; }
  | { type: 'RESET_SEARCH_STATE'; }
  | { type: 'SET_IS_LOADING'; }
  | { type: 'SET_EMPTY_TABLE_DATA'; }

  | { type: 'SET_FILTER'; payload: Partial<SeasonScoutingState> }
  | { type: 'SET_FILTER_EXPANSION'; payload: SeasonScoutingFilter; }
  | { type: 'RESET_FILTER_EXPANSION'; }
  | { type: 'SET_ADVANCED_FILTERS_EXPANSION'; }
  | { type: 'SET_SHOW_ADVANCED_FILTERS'; payload: boolean; }
  | { type: 'SET_SELECTED_ORDER_BY'; payload: string[]; }
  | { type: 'SET_SELECTED_RATINGS'; payload: RatingRequirement[]; }
  | { type: 'SET_ANY_SKILLCORNER_FILTERS_SELECTED'; payload: boolean; }
  | { type: 'SET_ANY_ITERATION_WITHOUT_SKILLCORNER_SELECTED'; payload: boolean; }

  | { type: 'SET_MODAL_TYPE_OPEN'; payload: 'saveSearch' | 'loadSearch' | undefined; }
  | { type: 'LOAD_SEARCH'; payload: StringToAnyMap; };


const seasonScoutingReducer = (state: SeasonScoutingState, action: SeasonScoutingAction): SeasonScoutingState => {

  switch (action.type) {

    case 'SET_SEARCH_RESULT':
      return getState_SET_SEARCH_RESULT(action.payload, state);

    case 'RESET_STATE':
      return getState_RESET_STATE(state);

    case 'RESET_SEARCH_STATE':
      return getState_RESET_SEARCH_STATE(state);

    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };

    case 'SET_EMPTY_TABLE_DATA':
      return { ...state, tableData: [] };


    case 'SET_FILTER':
      return { ...state, ...action.payload };

    case 'SET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: action.payload, previousFilterExpanded: action.payload };

    case 'RESET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: undefined };

    case 'SET_ADVANCED_FILTERS_EXPANSION':
      return getState_SET_ADVANCED_FILTERS_EXPANSION(state);

    case 'SET_SHOW_ADVANCED_FILTERS':
      return { ...state, showAdvancedFilters: action.payload };

    case 'SET_SELECTED_ORDER_BY':
      return { ...state, selectedOrderBy: action.payload };

    case 'SET_SELECTED_RATINGS':
      return { ...state, selectedRatings: action.payload };

    case 'SET_ANY_SKILLCORNER_FILTERS_SELECTED':
      return { ...state, anySkillcornerFiltersSelected: action.payload };

    case 'SET_ANY_ITERATION_WITHOUT_SKILLCORNER_SELECTED':
      return { ...state, anyIterationWithoutSkillcornerSelected: action.payload };


    case 'SET_MODAL_TYPE_OPEN':
      return { ...state, modalTypeOpen: action.payload };

    case 'LOAD_SEARCH':
      return getState_LOAD_SEARCH(action.payload, state);


    default:
      return state;
  }
};


const getState_SET_SEARCH_RESULT = (
  payload: {
    result: SearchPositionGroupClubIterationsResponse,
    isNewSearch: boolean,
    nextChapter: number | undefined,
    newOverriddenOrderBy: string | undefined,
    selectedOrderByToggle: boolean,
  },
  state: SeasonScoutingState
) => {

  const isEmptySearchResult = payload.result.total_hits === 0 || (payload.isNewSearch && payload.result.players.length === 0);
  const isFirstPageOfChapter = payload.isNewSearch || payload.nextChapter !== undefined;

  const tableData = isEmptySearchResult
    ? []
    : isFirstPageOfChapter ? payload.result.players : [...state.tableData, ...payload.result.players];

  return {
    ...state,

    tableData: tableData,
    playerIdsOnCurrentPage: tableData.map(player => player.player_id),

    totalHits: isEmptySearchResult
      ? 0
      : payload.result.total_hits,

    currentModuloPage: isEmptySearchResult
      ? 0
      : isFirstPageOfChapter ? 1 : (payload.result.current_page - (state.currentChapter * playerTableChapterSize)),

    currentChapter: isEmptySearchResult
      ? 0
      : payload.isNewSearch ? 0 : (payload.nextChapter !== undefined ? payload.nextChapter : state.currentChapter),

    isEmptySearchResult: isEmptySearchResult,
    isLoading: false,
    searchWasLoaded: false,

    // set current filters applied to the search result to the ones currenlty selected
    currentMinutes: state.selectedMinutes,
    currentMaxAge: state.selectedMaxAge,
    currentPositions: state.selectedPositions,
    currentRoles: state.selectedRoles,
    currentIterations: state.selectedIterations,
    currentOrderBy: payload.newOverriddenOrderBy ? [payload.newOverriddenOrderBy] : state.selectedOrderBy,
    currentOrderByToggle: payload.selectedOrderByToggle,
    currentRatings: state.selectedRatings,
    currentNationalities: state.selectedNationalities,
    currentContractStatus: state.selectedContractStatus,
    currentIsContractStatusToggled: state.isContractStatusToggled
  };
};


const getState_RESET_STATE = (state: SeasonScoutingState) => {
  return {
    ...initialSeasonScoutingState,
    isAdvancedFiltersExpanded: state.isAdvancedFiltersExpanded,
    showAdvancedFilters: state.showAdvancedFilters,
    scoutingViewResultContainerTop: state.scoutingViewResultContainerTop,
  };
};


const getState_RESET_SEARCH_STATE = (state: SeasonScoutingState) => {
  return {
    ...state,
    tableData: [],
    currentModuloPage: 0,
    currentChapter: 0,
    totalHits: 0,
    isLoading: false,
    isEmptySearchResult: false,
    searchWasLoaded: false,
  };
};


const getState_SET_ADVANCED_FILTERS_EXPANSION = (state: SeasonScoutingState) => {
  return {
    ...state,
    scoutingViewResultContainerTop: state.isAdvancedFiltersExpanded ? 85 : 155,
    isAdvancedFiltersExpanded: !state.isAdvancedFiltersExpanded,
  };
};


const getState_LOAD_SEARCH = (payload: StringToAnyMap, state: SeasonScoutingState) => {
  return {
    ...state,

    selectedMinutes: payload.minMinutes ?? [],
    selectedMaxAge: payload.maxAge ?? [],
    selectedPositions: payload.positions ?? [],
    selectedRoles: payload.roles ?? [],
    selectedIterations: payload.iterations ?? [],
    selectedOrderBy: payload.orderBy ?? ['skill_rating'],

    selectedRatings: payload.ratings ?? [],
    selectedNationalities: payload.nationalities ?? [],
    selectedContractStatus: payload.contractStatus ?? [],
    isContractStatusToggled: payload.isContractStatusToggled ?? false,

    searchWasLoaded: true,
  };
};


export default seasonScoutingReducer;
