import './modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { translate } from '../../../common/language/translations';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { AggregationRule } from '../../types';
import { IconButton } from '../controls/buttons/IconButton';
import { TextButton } from '../controls/buttons/TextButton';
import { OptionButton } from '../controls/buttons/OptionButton';
import { getAggregationRuleIcon } from '../../utils/iconUtils';


const aggregationOptions: AggregationRule[] = [
  'priority',
  'max',
  'average',
  'confidence',
];


interface RoleConfigAggregationModalProps {
  closeModal: () => void;
  currentAggregation: AggregationRule;
  confirmNewAggregation: (newAggregation: AggregationRule) => void;
}

export const RoleConfigAggregationModal: React.FC<RoleConfigAggregationModalProps> = ({
  closeModal,
  currentAggregation,
  confirmNewAggregation,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const { openTextModal } = useOpenGlobalModal();

  const [selectedAggregation, setSelectedAggregation] = useState<AggregationRule>(currentAggregation);

  return (
    <div className='modal-root-container role-config-aggregation-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('editRule', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container'>
        <div className='modal-option-column' style={{ marginTop: 30 }}>
          {aggregationOptions.map(aggregation => {

            const icon = getAggregationRuleIcon(aggregation, 22);

            return (
              <div
                key={aggregation}
                style={{ position: 'relative' }}
              >
                <IconButton
                  icon={<InfoOutlinedIcon style={{ fontSize: 16 }} />}
                  onClick={() => openTextModal(
                    translate(aggregation + 'Aggregation', userConfig?.language),
                    translate(aggregation + 'AggregationInfo', userConfig?.language),
                    { infoType: 'infoIcon', titleKey: aggregation + 'Aggregation' }
                  )}
                  size={24}
                  style={{ position: 'absolute', left: -40 }}
                />

                <OptionButton
                  onClick={() => setSelectedAggregation(aggregation)}
                  option={aggregation + 'Aggregation'}
                  language={userConfig?.language}
                  optionIsSelected={aggregation === selectedAggregation}
                  icons={{ leftIcon: icon }}
                  style={{ width: 200 }}
                />
              </div>
            );
          })}
        </div>

        <div style={{ margin: 'auto', padding: '20px 0px' }}>
          <TextButton
            onClick={() => confirmNewAggregation(selectedAggregation)}
            text={translate('confirmNewRule', userConfig?.language)}
            icon={<CheckIcon style={{ fontSize: 22 }} />}
            disabled={selectedAggregation === currentAggregation}
          />
        </div>
      </div>
    </div>
  );
};
