import { currencyToEuroMultiplier } from '../../../static/currencies';
import {
  ClausePayment,
  ClubMap,
  NumberToNumberMap,
  PlayerClause,
  PlayerSubClause,
  SeasonCondition,
  SellOnCondition,
  TotalCondition
} from '../../../types';


// the clause state holds a subset of the state of a clause + some practical addtional state that are not conceptually part of the clause
// playerData is excluded as it is handled one layer above the clause
// some immutable state (id, clauseVersion, userEmail, date, ...) are also exluded
// isDeleted and isResolved are also excluded as they will be updated directly and immediately in firestore
export interface ClauseState {
  id?: string;

  isSoldPlayer: boolean;
  totalTransferFee: number;

  sellingClubId: number | undefined;
  buyingClubId: number | undefined;
  transferDate: string | undefined;
  comment: string;
  fileUrls: string[];
  fileNames: string[];

  currency: string | null;
  payments: ClausePayment[];

  totalConditions: TotalCondition[];
  seasonConditions: SeasonCondition[];
  sellOnCondition: SellOnCondition | null;

  maxConditionalFees: number | null;
  conditionalFees?: number;

  nextClubId?: number;
  incorrectNextClubIds?: number[];

  subClauses: SubClauseState[] | null;

  additionalClauseState: AdditionalClauseState;
}


export interface SubClauseState extends PlayerSubClause {
  conditionalFees?: number;
}


// the additional state consists of either state tightly coupled to the clause (uploadedFiles, removedFileNames, ...) or
// state that will be passed to sub component of the clause to which the claue state is already passed (isConditionModalOpen, ...)
export interface AdditionalClauseState {
  uploadedFiles: File[];
  removedFileNames: string[];

  allClubs: ClubMap;

  isEditMode: boolean;
  isConditionModalOpen: boolean;
  isSubClauseModalOpen: boolean;
  activeSubClauseTab: number | undefined;
  paymentHighlightIndex: number | undefined;
  triggerClauseUpdate: boolean;
}


export const getPaymentAmountInClauseCurrency = (payment: ClausePayment, subClauses: PlayerSubClause[] | null, clauseCurrency: string | null) => {
  if (payment.subClauseIndex === undefined) {
    return payment.amount;
  }

  const discountFactor = subClauses && subClauses.length >= payment.subClauseIndex
    ? subClauses[payment.subClauseIndex].totalSellOnPercentage / 100
    : 1;
  const discountedAmount = Math.round(payment.amount * discountFactor);

  const currency = subClauses && subClauses.length >= payment.subClauseIndex
    ? subClauses[payment.subClauseIndex].currency
    : null;
  const currencyDiffersFromMainClause = currency && currency !== clauseCurrency;

  if (clauseCurrency && currencyDiffersFromMainClause) {
    // first get how many euros the payment is worth
    const currencyMultiplier = currencyToEuroMultiplier[currency]; // 12 ish for NOK
    const euroAmount = discountedAmount / currencyMultiplier;

    // then convert the euro amount to the main clause currency
    const clauseCurrencyMultiplier = currencyToEuroMultiplier[clauseCurrency];
    return Math.round(euroAmount * clauseCurrencyMultiplier);
  }

  return discountedAmount;
};


export const initializeState = (
  isSoldPlayer: boolean,
  userClubId: number,
  allClubs: ClubMap,
  existingClauseSelected?: PlayerClause,
  currentState?: ClauseState,
): ClauseState => {

  let totalTransferFee = 0;
  let conditionalFees = 0;
  const subClauseConditionalFees: NumberToNumberMap = {};

  if (existingClauseSelected) {
    existingClauseSelected.payments.forEach(payment => {
      totalTransferFee += getPaymentAmountInClauseCurrency(payment, existingClauseSelected.subClauses, existingClauseSelected.currency);

      if (payment.conditionType && payment.conditionType !== 'sellOn') {
        if (payment.subClauseIndex === undefined) {
          conditionalFees += payment.amount;
        }
        else {
          if (!subClauseConditionalFees[payment.subClauseIndex]) {
            subClauseConditionalFees[payment.subClauseIndex] = payment.amount;
          }
          else {
            subClauseConditionalFees[payment.subClauseIndex] += payment.amount;
          }
        }
      }
    });
  }

  return {
    id: existingClauseSelected?.id,

    isSoldPlayer,
    totalTransferFee: totalTransferFee,

    sellingClubId: isSoldPlayer ? userClubId : existingClauseSelected?.sellingClubId,
    buyingClubId: isSoldPlayer ? existingClauseSelected?.buyingClubId : userClubId,
    transferDate: existingClauseSelected?.transferDate,
    comment: existingClauseSelected?.comment ?? '',
    fileUrls: existingClauseSelected?.fileUrls ?? [],
    fileNames: existingClauseSelected?.fileNames ?? [],

    currency: existingClauseSelected?.currency ?? null,
    payments: existingClauseSelected?.payments ?? [],

    totalConditions: existingClauseSelected?.totalConditions ?? [],
    seasonConditions: existingClauseSelected?.seasonConditions ?? [],
    sellOnCondition: existingClauseSelected?.sellOnCondition ?? null,

    maxConditionalFees: existingClauseSelected?.maxConditionalFees ?? null,
    conditionalFees: conditionalFees,

    nextClubId: existingClauseSelected?.nextClubId,
    incorrectNextClubIds: existingClauseSelected?.incorrectNextClubIds ?? [],

    subClauses: existingClauseSelected?.subClauses
      ? existingClauseSelected.subClauses.map((subClause, index) => {
        return {
          ...subClause,
          conditionalFees: subClauseConditionalFees[index],
        };
      })
      : null,

    additionalClauseState: {
      uploadedFiles: [],
      removedFileNames: [],

      allClubs: allClubs,

      isEditMode: false,
      isConditionModalOpen: false,
      isSubClauseModalOpen: false,
      activeSubClauseTab: currentState && currentState?.id === existingClauseSelected?.id
        ? currentState.additionalClauseState.activeSubClauseTab
        : existingClauseSelected && existingClauseSelected.subClauses
          ? existingClauseSelected.subClauses.length - 1
          : undefined,
      paymentHighlightIndex: currentState ? currentState.additionalClauseState.paymentHighlightIndex : undefined,
      triggerClauseUpdate: false,
    },
  };
};
