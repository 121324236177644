import './input.css';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import { translate } from '../../../../common/language/translations';
import { IconButton } from '../buttons/IconButton';
import { TextButton } from '../buttons/TextButton';


interface FileUploadProps {
  handleFileUpload: (file: File) => void;
  language: string;
  buttonType: 'textButton' | 'iconButton';
}


export const FileUpload: React.FC<FileUploadProps> = ({
  handleFileUpload,
  language,
  buttonType,
}) => {


  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleFileUpload(event.target.files[0]);
      event.target.value = '';
    }
  };

  return (
    <div>
      <input
        id='file-upload'
        type='file'
        className='file-input'
        accept='application/pdf'
        placeholder='Upload file'
        onChange={onFileChange}
      />

      {buttonType === 'iconButton' && (
        <label htmlFor='file-upload'>
          <IconButton
            icon={<AddCircleOutlineIcon style={{ fontSize: 20 }} />}
            onClick={() => null}
            size={24}
            hoverText={translate('uploadPdf', language)}
          />
        </label>
      )}

      {buttonType === 'textButton' && (
        <label htmlFor='file-upload'>
          <TextButton
            onClick={() => null}
            text={translate('uploadPdf', language)}
            icon={<FileUploadOutlinedIcon style={{ fontSize: 20, marginBottom: 1 }} />}
            buttonType={'transparent'}
            isRound={true}
          />
        </label>
      )}
    </div>
  );
};
