import './mobileScouting.css';

import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../../../components/controls/input/InputField';
import { MobileScoutingState } from './mobileScoutingState';
import { MobileScoutingAction } from './mobileScoutingReducer';
import { filterSectionExpandedHeight, filterSectionHeight } from './MobileScouting';


interface MobileScoutingFiltersProps {
  state: MobileScoutingState;
  dispatch: React.Dispatch<MobileScoutingAction>;
  isMobileScoutingFilterSectionExpanded: boolean;
  onKeyDownNameSearchField: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onNameSearchHistoryClick: (name: string) => void;
}

export const MobileScoutingFilters: React.FC<MobileScoutingFiltersProps> = ({
  state,
  dispatch,
  isMobileScoutingFilterSectionExpanded,
  onKeyDownNameSearchField,
  onNameSearchHistoryClick,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  // const handleSetIsDropDownExpanded = (filter: MobileScoutingFilter, value: boolean) => {
  //   if (value) {
  //     dispatch({ type: 'SET_FILTER_EXPANSION', payload: filter });
  //   }
  //   else {
  //     dispatch({ type: 'RESET_FILTER_EXPANSION' });
  //   }
  // };


  useEffect(() => {
    if (!isMobileScoutingFilterSectionExpanded) {
      dispatch({ type: 'SET_SHOW_FILTER_SECTION', payload: false });
    }
    else {
      const timer = setTimeout(() => {
        dispatch({ type: 'SET_SHOW_FILTER_SECTION', payload: true });
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [isMobileScoutingFilterSectionExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      className='mobile-filter-section'
      style={{
        height: isMobileScoutingFilterSectionExpanded ? filterSectionExpandedHeight : filterSectionHeight,
      }}
    >

      <div
        className='mobile-filter-section-input-container'
        style={{
          top: (isMobileScoutingFilterSectionExpanded ? filterSectionExpandedHeight : filterSectionHeight) - 45,
        }}
      >
        <InputField
          value={state.nameSearchString}
          placeholder={translate('searchForName', userConfig?.language)}
          onChange={(event) => dispatch({ type: 'SET_FILTER', payload: { nameSearchString: event.target.value } })}
          onKeyDown={onKeyDownNameSearchField}
          resetValue={() => dispatch({ type: 'SET_FILTER', payload: { nameSearchString: '' } })}
          showNameSearchHistory={true}
          onNameSearchHistoryClick={onNameSearchHistoryClick}
          height={26}
          maxHeight={'calc(100vh - 160px - ' + (isMobileScoutingFilterSectionExpanded ? filterSectionExpandedHeight : filterSectionHeight) + 'px)'}
          isWhiteBackgroundWhenExpanded={true}
          isMobile={true}
        />
      </div>

      {/* <div className='filter-section-drop-down-title player-filter-section-confidence'>
        {translate('minConfidence', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-confidence'>
        <DropDown
          id={'player-filter-section-confidence-drop-down'}
          dropDownOptions={minConfidenceKeys}
          isDropDownExpanded={state.currentFilterExpanded === 'minConfidence'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('minConfidence', value)}
          selectedOptions={state.selectedMinConfidence}
          setSelectedOptions={(selectedMinConfidence: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMinConfidence } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'75vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-age'>
        {translate('maxAge', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-age'>
        <DropDown
          id={'player-filter-section-age-drop-down'}
          dropDownOptions={ageKeys}
          isDropDownExpanded={state.currentFilterExpanded === 'maxAge'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('maxAge', value)}
          selectedOptions={state.selectedMaxAge}
          setSelectedOptions={(selectedMaxAge: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMaxAge } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-positions-and-roles'>
        {translate('positionsAndRoles', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-positions-and-roles'>
        <PositionAndRoleDropDown
          id={'player-filter-section-positions-and-roles-drop-down'}
          selectedPositions={state.selectedPositions}
          setSelectedPositions={(selectedPositions: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedPositions } })}
          selectedRoles={state.selectedRoles}
          setSelectedRoles={(selectedRoles: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedRoles } })}
          isDropDownExpanded={state.currentFilterExpanded === 'positionsAndRoles'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('positionsAndRoles', value)}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-leagues'>
        {translate('leagues', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-leagues'>
        <LeagueDropDown
          id={'player-filter-section-leagues-drop-down'}
          isDropDownExpanded={state.currentFilterExpanded === 'leagues'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('leagues', value)}
          selectedLeagues={state.selectedLeagues}
          setSelectedLeagues={(selectedLeagues: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedLeagues } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div>

      <div className='filter-section-drop-down-title player-filter-section-order-by'>
        {translate('orderBy', userConfig?.language)}
      </div>
      <div className='filter-section-drop-down player-filter-section-order-by'>
        <DropDown
          id={'player-filter-section-order-by-drop-down'}
          dropDownOptions={[...state.selectedRoles, 'skillIndex', 'age']}
          isDropDownExpanded={state.currentFilterExpanded === 'orderBy'}
          setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('orderBy', value)}
          selectedOptions={state.selectedOrderBy}
          setSelectedOptions={(selectedOrderBy: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedOrderBy } })}
          defaultDropDownText={'select'}
          defaultDropDownTextColor={'#00000080'}
          marginBetweenOptions={3}
          maxHeight={'84vh'}
        />
      </div> */}

      {/*
      <IconButton
        onClick={() => { setNameSearchString(''); dispatch({ type: 'RESET_STATE' }); }}
        icon={<ReplayIcon style={{ fontSize: 20 }} />}
        size={24}
        style={{ position: 'absolute', marginTop: 43, marginLeft: 'calc(94% - 9px)' }}
        startWhite={true}
        title={translate('resetFilters', userConfig?.language)}
      />
      */}

    </div>
  );
};
