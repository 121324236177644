import './buttons.css';


interface TextButtonProps {
  onClick: () => void;
  text: string;
  icon: JSX.Element;
  style?: React.CSSProperties;
  buttonType?:
  | 'default' // white, fixed width, increased font weight on hover
  | 'transparent' // white, with border, transparent on hover
  | 'dark'; // dark, round
  isRound?: boolean;
  disabled?: boolean;
  iconIsNotificationIcon?: boolean;
  isMobile?: boolean;
}

export const TextButton: React.FC<TextButtonProps> = ({
  onClick,
  text,
  icon,
  style,
  buttonType = 'default',
  isRound,
  disabled,
  iconIsNotificationIcon,
  isMobile,
}) => {


  const containerClassName = (
    isMobile
      ? 'text-button-container-mobile'
      : (
        'text-button-container text-button-container-' + buttonType
        + (isRound ? ' text-button-container-round' : '')
      )
  ) + (disabled ? ' text-button-container-disabled' : '');


  return (
    <div
      className={containerClassName}
      onClick={() => !disabled ? onClick() : null}
      style={{
        gap: buttonType !== 'default' && !style?.width ? 25 : undefined, // whiteDefault requires fixed width due to increased font weight on hover
        ...style
      }}
    >

      {text}

      {!iconIsNotificationIcon && icon}

      {iconIsNotificationIcon && <div className='text-button-notification-icon'>{icon}</div>}

    </div>
  );
};
