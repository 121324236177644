import { svgColors } from './colors';


export const FootballFieldSvg = () => {


  return (
    <div className='full-size'>
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none'>

        <defs>
          <linearGradient id='football-field-background-gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%' stopColor={svgColors.platformLightColor} stopOpacity='1' />
            <stop offset='100%' stopColor={svgColors.platformDarkColor} stopOpacity='1' />
          </linearGradient>

          <linearGradient id='football-field-dark-grass' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor={svgColors.footballFieldDarkGrassLeft} stopOpacity='1' />
            <stop offset='100%' stopColor={svgColors.footballFieldDarkGrassRight} stopOpacity='1' />
          </linearGradient>

          <linearGradient id='football-field-light-grass' x1='0%' y1='0%' x2='100%' y2='0%'>
            <stop offset='0%' stopColor={svgColors.footballFieldLightGrassLeft} stopOpacity='1' />
            <stop offset='100%' stopColor={svgColors.footballFieldLightGrassRight} stopOpacity='1' />
          </linearGradient>
        </defs>

        {/* Grønne striper */}
        <rect x='0' y='7' width='100' height='7.23' stroke='none' fill='url(#football-field-light-grass)' />
        <rect x='0' y='14.2' width='100' height='7.53' stroke='none' fill='url(#football-field-dark-grass)' />
        <rect x='0' y='21.7' width='100' height='7.83' stroke='none' fill='url(#football-field-light-grass)' />
        <rect x='0' y='29.5' width='100' height='8.13' stroke='none' fill='url(#football-field-dark-grass)' />
        <rect x='0' y='37.6' width='100' height='8.43' stroke='none' fill='url(#football-field-light-grass)' />
        <rect x='0' y='46' width='100' height='8.73' stroke='none' fill='url(#football-field-dark-grass)' />
        <rect x='0' y='54.7' width='100' height='9.03' stroke='none' fill='url(#football-field-light-grass)' />
        <rect x='0' y='63.7' width='100' height='9.33' stroke='none' fill='url(#football-field-dark-grass)' />
        <rect x='0' y='73' width='100' height='9.63' stroke='none' fill='url(#football-field-light-grass)' />
        <rect x='0' y='82.6' width='100' height='9.9' stroke='none' fill='url(#football-field-dark-grass)' />

        {/* Banemerking */}
        <line x1='11' y1='7' x2='89' y2='7' stroke={svgColors.footballFieldLineColor} strokeWidth='0.5' />
        <line x1='89' y1='6.77' x2='96' y2='92.73' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' />
        <line x1='96' y1='92.5' x2='4' y2='92.5' stroke={svgColors.footballFieldLineColor} strokeWidth='0.5' />
        <line x1='4' y1='92.73' x2='11' y2='6.77' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' />

        {/* Midtsirkel */}
        <line x1='7.8' y1='46.1' x2='92.2' y2='46.1' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' />
        <ellipse cx='50' cy='46.1' rx='0.55' ry='0.4' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' fill={svgColors.footballFieldLineColor} />
        <ellipse cx='50' cy='46.1' rx='9.5' ry='6.9' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' fill='none' />

        {/* 16-meter nede */}
        <line x1='29' y1='92.73' x2='29.8' y2='73' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />
        <line x1='29.7' y1='73' x2='70.3' y2='73' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' />
        <line x1='70.2' y1='72.87' x2='71' y2='92.73' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        <line x1='39' y1='92.73' x2='39.2' y2='82.5' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />
        <line x1='39.1' y1='82.5' x2='60.9' y2='82.5' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' />
        <line x1='60.8' y1='82.5' x2='61' y2='92.73' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        <path d='M 43 73 A 8 10 0 0 1 57 73' fill='none' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        {/* 16-meter oppe */}
        <line x1='32.5' y1='6.77' x2='31.9' y2='21.7' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />
        <line x1='31.8' y1='21.7' x2='68.2' y2='21.7' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' />
        <line x1='68.1' y1='21.7' x2='67.5' y2='6.77' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        <line x1='40.5' y1='6.77' x2='40.4' y2='14' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />
        <line x1='40.3' y1='14' x2='59.7' y2='14' stroke={svgColors.footballFieldLineColor} strokeWidth='0.3' />
        <line x1='59.6' y1='14' x2='59.5' y2='6.77' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        <path d='M 43.5 21.7 A 8 10 0 0 0 56.5 21.7' fill='none' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        {/* Hjørnesirkler */}
        <ellipse cx='11.22' cy='7.36' rx='0.4' ry='0.6' stroke='none' fill={svgColors.footballFieldLineColorSemiTransparent} />
        <ellipse cx='88.78' cy='7.36' rx='0.4' ry='0.6' stroke='none' fill={svgColors.footballFieldLineColorSemiTransparent} />

        <ellipse cx='4.37' cy='92.1' rx='0.45' ry='0.65' stroke='none' fill={svgColors.footballFieldLineColorSemiTransparent} />
        <ellipse cx='95.63' cy='92.1' rx='0.45' ry='0.65' stroke='none' fill={svgColors.footballFieldLineColorSemiTransparent} />

        <path d='M 10.7 9 A 4 6 0 0 0 13 7' fill='none' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />
        <path d='M 89.3 9 A 4 6 0 0 1 87 7' fill='none' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        <path d='M 4.24 89.8 A 4 6 0 0 1 6.4 92.7' fill='none' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />
        <path d='M 93.6 92.7 A 4 6 0 0 1 95.76 89.8' fill='none' stroke={svgColors.footballFieldLineColor} strokeWidth='0.2' />

        {/* Bakgrunn */}
        <polyline points='4,92.75, 11,6.75, 0,6.75, 0,92.75' stroke='none' fill='url(#football-field-background-gradient)' />
        <polyline points='89,6.75, 96,92.75 100,92.75 100,6.75' stroke='none' fill='url(#football-field-background-gradient)' />

      </svg>
    </div>
  );
};
