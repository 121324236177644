import axios from 'axios';

import { FirebaseUser } from '../../../../firebase';

import { APPLICATION_API_URL } from '../constants';
import { getBearerToken } from '../token';
import { getCountryCodeSearchString } from '../utils';

import { NumberToStringToStringMap, PlayerAlertsQueryOptions } from '../../../types';
import { trackEvent } from '../analytics/trackEvent';


export interface SearchPlayerAlertsResponse {
  player_alerts: NumberToStringToStringMap;
  total_hits: number;
  date: string; // ISO 8601 date string generated by server at the time of the request (+500ms offset)
}


// Search for players ids
export const searchPlayerAlerts = async (
  options: PlayerAlertsQueryOptions,
  currentUser: FirebaseUser,
): Promise<SearchPlayerAlertsResponse | undefined> => {
  try {
    const token = await getBearerToken(currentUser);

    const logisticTeamString = options.logisticTeamIds ? options.logisticTeamIds.join(',') : undefined;

    const primaryPositionsString = options.primaryPositions ? options.primaryPositions.join(',') : undefined;

    const competitionsString = options.competitions ? options.competitions.join(',') : undefined;

    const formString = options.form ? options.form.join(',') : undefined;

    const playingTimeString = options.playingTime ? options.playingTime.join(',') : undefined;

    const countryCodesString = options.countryCodes ? getCountryCodeSearchString(options.countryCodes) : undefined;

    const playerIdsToExcludeString = options.playerIdsToExclude ? options.playerIdsToExclude.join(',') : undefined;

    const indexRequirementsString = options.indexRequirements
      ? options.indexRequirements.map(({ metric, value }) => `${metric}:${value}`).join(',')
      : undefined;

    const result = await axios.get(`${APPLICATION_API_URL}/search/playerAlerts/`, {
      headers: { Authorization: token },
      params: {
        logistic_team_ids: logisticTeamString,
        min_age: options.minAge,
        max_age: options.maxAge,
        max_contract_length: options.maxContractLength,
        include_unknown_contracts: options.includeUnknownContracts,
        primary_positions: primaryPositionsString,
        position_group: options.positionGroup,
        competitions: competitionsString,
        min_confidence: options.minConfidence,
        max_confidence: options.maxConfidence,
        form_status: formString,
        availability_status: playingTimeString,
        country_codes: countryCodesString,
        player_ids_to_exclude: playerIdsToExcludeString,
        index_requirements: indexRequirementsString,
      },
    });

    if (!result) return undefined;

    return result.data;
  }

  catch (error) {
    trackEvent('Error', { api: 'server', function: 'searchPlayerAlerts', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};
