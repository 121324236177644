

type ImageRequest = {
  url: string;
  resolve: (url: string) => void;
  reject: (error: Error) => void;
};


export const createPlayerImageLoader = (
  requestsPerSecond: number,
  maxConcurrent?: number,
  maxRetries?: number
) => {

  const requestQueue: ImageRequest[] = [];
  let isProcessing = false;

  const processQueue = async () => {
    if (isProcessing) return;
    isProcessing = true;

    while (requestQueue.length > 0) {
      const chunk = requestQueue.splice(0, maxConcurrent);

      await Promise.all(
        chunk.map(({ url, resolve, reject }) =>
          loadImageWithRetry(url, maxRetries)
            .then(resolve)
            .catch(reject)
        )
      );

      await new Promise(resolve => setTimeout(resolve, 1000 / requestsPerSecond));
    }

    isProcessing = false;
  };

  const addToQueue = (url: string, prioritize?: boolean): Promise<string> => {
    return new Promise((resolve, reject) => {
      const request = { url, resolve, reject };

      if (prioritize && requestQueue.length > 20) {
        requestQueue.unshift(request);
      } else {
        requestQueue.push(request);
      }

      processQueue();
    });
  };

  return { addToQueue };
};


const loadImageWithRetry = (
  url: string,
  maxRetries?: number,
): Promise<string> => {

  const baseDelay = 300;
  let attempts = 0;

  const attemptLoad = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      let isTimedOut = false;

      const timeoutId = setTimeout(() => {
        isTimedOut = true;
        img.src = '';
        reject(new Error(`Timeout loading image: ${url}`));
      }, 10000);

      img.onload = () => {
        clearTimeout(timeoutId);
        if (!isTimedOut) resolve(url);
      };

      img.onerror = () => {
        clearTimeout(timeoutId);
        attempts += 1;
        if (maxRetries !== undefined && attempts <= maxRetries) {
          const delay = baseDelay * Math.pow(2, attempts - 1);
          const jitter = Math.random() * 0.5 * delay;
          setTimeout(() => attemptLoad().then(resolve).catch(reject), delay + jitter);
        }
        else {
          reject(new Error(`Failed to load image after ${attempts} attempts: ${url}`));
        }
      };

      img.src = url;
    });
  };

  return attemptLoad();
};


export const playerImageLoader = createPlayerImageLoader(50, 10, 5);
