import '../tables.css';

import { useRecoilValue } from 'recoil';
import { updateSeasonStatsToggles } from '../../../services/firestore/userSettings';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { CellProps } from 'react-table';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { goalkeeperSeasonTableMetricGroups, metricToDisplayInfo, outfieldSeasonTableMetricGroups, skillcornerStats } from '../../../static/playerMetrics';
import { StringToAnyMap, RoleConfigMap, UserConfig, PositionGroupClubIteration, RoleConfig } from '../../../types';
import { getDateLocaleFormat, getDisplayPlayerName, getDisplayPositions } from '../../../utils/playerUtils';
import { translate } from '../../../../common/language/translations';
import { getMetricColumnWidth } from '../seasonTable/seasonColumns';
import { getColorBlindRatingColor, getIndexColor } from '../../../utils/colorUtils';
import { Toggle } from '../../controls/input/Toggle';
import { positionGroupToPositions } from '../../../static/propertyValues';
import { EventPropertiesMap } from '../../../services/server/analytics/events';
import { FirebaseUser } from '../../../../firebase';
import { ProgressCircle } from '../../display/visualization/ProgressCircle';
import { PlayerImage } from '../../display/images/PlayerImage';
import { CountryFlag } from '../../display/images/CountryFlag';
import { ClubLogo } from '../../display/images/ClubLogo';
import { IconButton } from '../../controls/buttons/IconButton';


const InfoCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const positionGroupClubIteration: PositionGroupClubIteration = row.original;

  const userConfig = useRecoilValue(userConfigState);

  // position info
  const primaryPositions = positionGroupClubIteration.overall_primary_positions ?? [];
  const secondaryPositions = positionGroupClubIteration.overall_secondary_positions ?? [];

  const primaryPositionString = getDisplayPositions(primaryPositions.slice(0, 3), userConfig?.language);
  let secondaryPositionString: string | undefined = undefined;

  if (primaryPositions.length === 1 && secondaryPositions.length > 0) {
    secondaryPositionString = getDisplayPositions(secondaryPositions.slice(0, 2), userConfig?.language);
  }

  return (
    <div className='player-table-info-cell'>

      <PlayerImage
        src={positionGroupClubIteration.image_url}
        size={40}
        containerStyle={{ marginLeft: 0, marginRight: 10 }}
        isSearchTableComponent={true}
      />

      <div className='player-table-info-cell-column player-season-table-info-cell-column'>
        <div className='player-table-info-cell-row'>
          {getDisplayPlayerName(positionGroupClubIteration.fullname, 22)}

          <CountryFlag
            countryCode={positionGroupClubIteration.country_code}
            width={16}
            isWhiteBackground={true}
            containerStyle={{ marginLeft: 9 }}
          />
        </div>

        <ClubLogo
          club={positionGroupClubIteration.club}
          size={14}
          showClubName={true}
          competitionIdToShow={positionGroupClubIteration.club?.current_competition_id}
          containerStyle={{ justifyContent: 'left', color: '#444444', fontSize: 12 }}
          smallFontSize={11}
          smallFontSizeThreshold={25}
        />
      </div>

      <div className='player-season-table-info-cell-position-column'>
        <div className='player-season-table-info-cell-position-row' style={{ fontSize: 13 }}>
          {positionGroupClubIteration.season}
        </div>
        <div className='player-season-table-info-cell-position-row' style={{ fontSize: 12 }}>
          {primaryPositionString ?? '-'}

          {secondaryPositionString && (
            <div style={{ fontSize: 11, color: '#000000bb' }}>
              {'(' + secondaryPositionString + ')'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};


const MinutesCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const positionGroupClubIteration: PositionGroupClubIteration = row.original;

  // total minutes info
  const minutesPlayed = positionGroupClubIteration.minutes_played ?? 0;
  const percentagePlayed = Math.round((positionGroupClubIteration.minutes_played_percentage ?? 0) * 100) + '%';
  const minutesColorIndex = Math.min(minutesPlayed / 150, 10);
  const minutesColor = minutesColorIndex ? getIndexColor(minutesColorIndex, 50) : 'transparent';

  return (
    <div className='player-season-table-minutes-cell'>
      <ProgressCircle
        size={18}
        progress={minutesColorIndex * 10}
        color={minutesColor}
        strokeWidth={12}
        trailColor='#00000022'
      />

      <div className='player-season-table-minutes-row'>
        {minutesPlayed} min

        <div style={{ fontSize: 11, color: '#000000bb' }}>
          {'(' + percentagePlayed + ')'}
        </div>
      </div>
    </div>
  );
};


const TextCell: React.FC<CellProps<StringToAnyMap, string>> = ({ value }) => {
  return <div className='table-cell-centered'>{value ?? '-'}</div>;
};


const ContractCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {
  return <div className='table-cell-centered'>{getDateLocaleFormat(row.original.contract_expiration)}</div>;
};


const renderMetricOrRatingCell = (
  property: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean
) => {
  const MetricOrRatingCellRenderer = ({ row }: StringToAnyMap) => {
    return (
      <MetricOrRatingCell
        positionGroupClubIteration={row.original}
        property={property}
        metricGroupKey={metricGroupKey}
        metricGroupIsToggled={metricGroupIsToggled}
        isColorBlind={isColorBlind}
      />
    );
  };

  MetricOrRatingCellRenderer.displayName = 'MetricOrRatingCellRenderer';
  return MetricOrRatingCellRenderer;
};

const MetricOrRatingCell: React.FC<{
  positionGroupClubIteration: PositionGroupClubIteration,
  property: string,
  metricGroupKey: string,
  metricGroupIsToggled: boolean,
  isColorBlind: boolean,
}> = ({ positionGroupClubIteration, property, metricGroupKey, metricGroupIsToggled, isColorBlind }) => {

  const normalizedValue = metricGroupKey === 'ratings'
    ? property === 'skill_rating'
      ? positionGroupClubIteration.skill_rating
      : (positionGroupClubIteration.custom_ratings ? positionGroupClubIteration.custom_ratings[property + '_rating'] : undefined)
    : positionGroupClubIteration.metrics[property + '_normalized'];

  let displayValue = normalizedValue !== undefined ? Math.round(normalizedValue * 10) / 10 : '-';

  if (metricGroupIsToggled) {
    let actualValue = positionGroupClubIteration.metrics[property];

    const isPercentage = property.endsWith('_percentage');
    if (actualValue && isPercentage) {
      actualValue = actualValue * 100;
    }

    const roundingFactor = (metricGroupKey === 'attacking' && property !== 'n_shots_per_90') || property === 'gk_postshot_xg_prevented_difference_per_shot'
      ? 100
      : 10;

    displayValue = actualValue !== undefined ? Math.round(actualValue * roundingFactor) / roundingFactor : '-';

    if (displayValue !== '-' && isPercentage) {
      displayValue = displayValue + '%';
    }

    if (displayValue !== '-' && ['skillcorner_distance_per_90', 'skillcorner_distance_tip_per_90', 'skillcorner_distance_otip_per_90'].includes(property)) {
      displayValue = Math.round(actualValue / 100) / 10 + ' km';
    }
    if (displayValue !== '-' && [
      'skillcorner_sprinting_distance_per_90',
      'skillcorner_sprinting_distance_tip_per_90',
      'skillcorner_sprinting_distance_otip_per_90',
      'skillcorner_hsr_distance_per_90',
      'skillcorner_hsr_distance_tip_per_90',
      'skillcorner_hsr_distance_otip_per_90',
    ].includes(property)) {
      displayValue = Math.round(actualValue) + ' m';
    }
    if (displayValue !== '-' && property === 'skillcorner_psv-99_average') {
      displayValue = displayValue + ' km/h';
    }
    if (displayValue !== '-' && property === 'gk_penalty_saves_percentage') {
      displayValue = positionGroupClubIteration.metrics['gk_n_penalties_saved'] + '/' + positionGroupClubIteration.metrics['gk_n_penalties_faced']
        + ' (' + displayValue + ')';
    }
  }

  const backgroundColor = isColorBlind
    ? getColorBlindRatingColor(normalizedValue ?? 0, 0.3)
    : getIndexColor(normalizedValue, 74);

  return (
    <div className='season-table-color-cell-container'>
      <div
        className='player-season-table-color-cell'
        style={{
          backgroundColor: backgroundColor,
          fontSize: isColorBlind ? 13 : 12,
          fontWeight: isColorBlind ? 700 : 500
        }}
      >
        {displayValue}
      </div>
    </div>
  );
};


export const getPlayerSeasonColumns = (
  userConfig: UserConfig | null,
  currentUser: FirebaseUser,
  isGoalkeeperSelected: boolean,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  handleChangeCurrentChapter: (isIncrement: boolean) => void,
  selectedOrderBy: string | undefined,
  selectedRoles: string[],
  roleConfigs: RoleConfigMap,
  secondaryClubColor: string,
  seasonStatsToggles: StringToAnyMap,
  hasSkillcorner: boolean,
  openTextModal: (text: string, title: string, eventProperties: EventPropertiesMap['InfoModalOpened']) => void,
  openRoleInfoModal: (roleConfig: RoleConfig) => void,
) => {

  if (!userConfig) return [];

  const seasonCellWidth = 375;

  const columns: StringToAnyMap[] = [
    {
      Header:
        <div className='player-table-top-level-header-cell-sticky' style={{ width: seasonCellWidth }}>

          {totalHits + (totalHits >= 10000 ? '+ ' : ' ') + translate(totalHits === 1 ? 'player' : 'players', userConfig.language, true)}

          {totalHits > hitsPerChapter && (
            <div className='table-cell'>
              <div className='player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter + 1) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              <div className='player-table-pagination-section' style={{ left: 290 }}>
                <IconButton
                  icon={(
                    <KeyboardArrowLeftIcon
                      style={{ fontSize: 17, color: currentChapter > 0 ? '#ffffffdd' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => currentChapter > 0 ? handleChangeCurrentChapter(false) : null}
                  size={20}
                  isMobile={true}
                  style={{ cursor: currentChapter > 0 ? 'pointer' : 'default' }}
                />

                <div className='player-table-current-chapter'>
                  {currentChapter + 1}
                </div>

                <IconButton
                  icon={(
                    <KeyboardArrowRightIcon
                      style={{ fontSize: 17, color: currentChapter < Math.floor(totalHits / hitsPerChapter) ? '#ffffffdd' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => currentChapter < Math.floor(totalHits / hitsPerChapter) ? handleChangeCurrentChapter(true) : null}
                  size={20}
                  isMobile={true}
                  style={{ cursor: currentChapter < Math.floor(totalHits / hitsPerChapter) ? 'pointer' : 'default' }}
                />
              </div>
            </div>
          )}

        </div>,
      id: 'season',
      sticky: 'left',
      accessor: 'season',
      Cell: InfoCell,
      width: seasonCellWidth,
      minWidth: seasonCellWidth,
    }
  ];

  const positionGroupKey = selectedRoles.length > 0 ? roleConfigs[selectedRoles[0]].positionGroup : 'overall';

  columns.push({
    Header:
      <div
        className='player-table-top-level-header-cell player-table-header-cell-with-border'
        style={{ fontSize: 12 }}
      >
        {translate('dataBasis', userConfig.language)}
      </div>,
    id: 'minutesInfo',
    sticky: 'left',
    columns: [
      {
        Header:
          <div
            className='player-table-sub-level-header-cell player-table-header-cell-with-border'
            style={{ fontSize: 10 }}
          >
            {translate('basedOn<positions>', userConfig.language) + ' '}
            {positionGroupKey !== 'overall'
              ? getDisplayPositions(positionGroupToPositions[positionGroupKey], userConfig.language)
              : translate('allPositions', userConfig.language, true)}
          </div>,
        id: 'minutes',
        accessor: 'minutes',
        Cell: MinutesCell,
        width: 140,
        isFinalSubMetric: true,
      },
    ],
  });

  columns.push({
    Header:
      <div
        className='player-table-top-level-header-cell player-table-header-cell-with-border'
        style={{ fontSize: 12 }}
      >
        Info
      </div>,
    id: 'playerInfo',
    columns: [
      {
        Header:
          <div className='player-table-sub-level-header-cell'>
            {translate('age', userConfig.language)}
          </div>,
        id: 'age',
        accessor: 'age',
        Cell: TextCell,
        width: 90,
      },
      {
        Header:
          <div className='player-table-sub-level-header-cell player-table-header-cell-with-border'>
            {translate('contract', userConfig.language)}
          </div>,
        id: 'contract',
        accessor: 'contract_expiration',
        Cell: ContractCell,
        width: 120,
        isFinalSubMetric: true,
      },
    ],
  });

  const ratingMetricGroup = { 'ratings': ['skill_rating', ...selectedRoles] };
  const normalMetricGroups = isGoalkeeperSelected ? goalkeeperSeasonTableMetricGroups : outfieldSeasonTableMetricGroups;
  const skillCornerMetricGroup = hasSkillcorner && !isGoalkeeperSelected ? { 'skillcorner': skillcornerStats } : {};

  const metricGroups = { ...ratingMetricGroup, ...normalMetricGroups, ...skillCornerMetricGroup };

  Object.entries(metricGroups).forEach(([metricGroupKey, metrics]: [string, string[]], index) => {
    const isFinalMetricGroup = index === Object.keys(metricGroups).length - 1;

    const metricGroupName = metricToDisplayInfo[metricGroupKey].name[userConfig.language];
    const metricGroupDescription = metricToDisplayInfo[metricGroupKey].description[userConfig.language];

    const topLevelCell = {
      Header:
        <div className={'player-table-top-level-header-cell' + (!isFinalMetricGroup ? ' player-table-header-cell-with-border' : '')}>
          <div
            className='player-table-sub-level-header-cell-text'
            title={translate('showDescription', userConfig.language)}
            onClick={() => openTextModal(metricGroupName, metricGroupDescription, { infoType: 'metricGroup', titleKey: metricGroupKey })}
            style={{ fontSize: 12 }}
          >
            {metricGroupName}
          </div>

          {metricGroupKey !== 'ratings' && metricGroupKey !== 'obv' && (
            <div
              className='season-table-top-level-header-cell-toggle'
              title={translate(seasonStatsToggles[metricGroupKey] ? 'showNormalizedValues' : 'showActualValues', userConfig.language)}
            >
              <Toggle
                isToggled={seasonStatsToggles[metricGroupKey]}
                setIsToggled={(value: boolean) => updateSeasonStatsToggles(
                  { [metricGroupKey]: value },
                  userConfig.email,
                  userConfig.club,
                  currentUser
                )}
                isSmall={true}
              />
            </div>
          )}
        </div>,
      id: metricGroupKey,

      columns: metrics.map((metric, index) => {
        const isFinalSubMetric = !isFinalMetricGroup && index === metrics.length - 1;
        const roleConfig = roleConfigs[metric];

        const metricName = roleConfig !== undefined
          ? roleConfigs[metric].name
          : metricToDisplayInfo[metric]?.name[userConfig.language];

        const metricDescription = roleConfig !== undefined
          ? undefined
          : metricToDisplayInfo[metric]?.description[userConfig.language];

        const isOrderByMetric = selectedOrderBy === metric;

        const cell = {
          Header:
            <div
              className={'player-table-sub-level-header-cell' + (isFinalSubMetric ? ' player-table-header-cell-with-border' : '')}
              style={{
                color: isOrderByMetric ? secondaryClubColor : undefined,
                fontWeight: isOrderByMetric ? 600 : undefined
              }}
            >
              <div
                className='player-table-sub-level-header-cell-text'
                title={translate('showDescription', userConfig.language)}
                onClick={() => roleConfig
                  ? openRoleInfoModal(roleConfig)
                  : openTextModal(metricName, metricDescription, { infoType: 'metric', titleKey: metric })
                }
              >
                {metricName}
              </div>
            </div>,
          id: metric,
          Cell: renderMetricOrRatingCell(
            metric,
            metricGroupKey,
            seasonStatsToggles[metricGroupKey],
            userConfig.isColorBlind
          ),
          width: getMetricColumnWidth(metricName),
          isFinalSubMetric: isFinalSubMetric,
        };
        return cell;
      }),
    };

    columns.push(topLevelCell);
  });

  return columns;
};
