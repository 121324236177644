import '../tables.css';

import { CellProps } from 'react-table';
import { StringToAnyMap, PlayerOverview } from '../../../types';
import { getDisplayPlayerName, getDisplayPositions } from '../../../utils/playerUtils';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { translate } from '../../../../common/language/translations';
import { ReactNode } from 'react';
import { PlayerImage } from '../../display/images/PlayerImage';
import { CountryFlag } from '../../display/images/CountryFlag';
import { CompetitionFlag } from '../../display/images/CompetitionFlag';
import { ClubLogo } from '../../display/images/ClubLogo';
import { IconButton } from '../../controls/buttons/IconButton';


const PlayerFindingInfoCell: React.FC<{ row: StringToAnyMap }> = ({ row }) => {

  const playerOverview: PlayerOverview = row.original;

  return (
    <div className='player-table-info-cell'>

      <PlayerImage
        src={playerOverview.image_url}
        size={40}
        containerStyle={{ marginLeft: 0, marginRight: 10 }}
        isSearchTableComponent={true}
      />

      <div className='player-table-info-cell-row'>
        {getDisplayPlayerName(row.original.fullname, 25)}

        <CountryFlag
          countryCode={row.original.country_code}
          width={16}
          isWhiteBackground={true}
          containerStyle={{ marginLeft: 9, marginBottom: -1 }}
        />
      </div>

    </div>
  );
};


const CompetitionCell: React.FC<CellProps<StringToAnyMap, StringToAnyMap>> = ({ row }) => {

  return (
    <div className='table-cell'>
      <CompetitionFlag
        competitionId={row.original.club?.current_competition_id}
        width={17}
        showHyphenIfEmpty={true}
        containerStyle={{ gap: 5 }}
        isWhiteBackground={true}
      />
    </div>
  );
};


const ClubCell: React.FC<CellProps<StringToAnyMap, StringToAnyMap>> = ({ row }) => {

  return (
    <div className='table-cell'>
      <ClubLogo
        club={row.original.club}
        size={18}
        showClubName={true}
        showHyphenIfEmpty={true}
        containerStyle={{ gap: 5 }}
        isWhiteBackground={true}
      />
    </div>
  );
};


const TextCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='table-cell'>{value ?? '-'}</div>;
};


const renderPositionCell = (positionGroupKey: string, language: string) => {
  const PositionCellRenderer = ({ row }: StringToAnyMap) => {
    return <PositionCell playerOverview={row.original} positionGroupKey={positionGroupKey} language={language} />;
  };

  PositionCellRenderer.displayName = 'PositionCellRenderer';
  return PositionCellRenderer;
};

const PositionCell: React.FC<{
  playerOverview: PlayerOverview;
  positionGroupKey: string;
  language: string;
}> = ({ playerOverview, positionGroupKey, language }) => {

  let primaryPositions = [];
  let secondaryPositions = [];

  if (playerOverview.event_data_exist) {
    // if positionGroupKey is overall, we use the positions one level above position_group_stats to align with the server
    // these positions might differ from the positions in overall, in case of limited scopes
    primaryPositions = positionGroupKey === 'overall'
      ? playerOverview.primary_positions ?? []
      : playerOverview.position_group_stats?.[positionGroupKey]?.primary_positions ?? [];

    secondaryPositions = positionGroupKey === 'overall'
      ? playerOverview.secondary_positions ?? []
      : playerOverview.position_group_stats?.[positionGroupKey]?.secondary_positions ?? [];
  }

  else {
    // if FAPI positions, we use the first position as primary and the second position as secondary (whether it's primary or secondary)
    const allPositions = (playerOverview.primary_positions ?? []).concat(playerOverview.secondary_positions ?? []);
    primaryPositions = allPositions.slice(0, 1);
    secondaryPositions = allPositions.slice(1);
  }

  const primaryPositionString = getDisplayPositions(primaryPositions, language);
  const secondaryPositionString = getDisplayPositions(secondaryPositions, language);

  return (
    <div className='find-player-table-position-cell'>
      {primaryPositionString ?? '-'}

      {secondaryPositionString && (
        <div style={{ fontSize: 11, color: '#000000dd' }}>
          {secondaryPositionString}
        </div>
      )}
    </div>
  );
};


const HeightCell: React.FC<CellProps<StringToAnyMap, ReactNode>> = ({ value }) => {
  return <div className='table-cell'>{value ? value + ' cm' : '-'}</div>;
};


export const getFindPlayerColumns = (
  widthUnit: number,
  language: string,
  currentChapter: number,
  hitsPerChapter: number,
  totalHits: number,
  isSelectedPlayer: boolean,
  handleChangeCurrentChapter?: (isIncrement: boolean) => void,
) => {
  return [
    {
      Header:
        <div className='find-player-table-sticky-header-cell'>

          {isSelectedPlayer
            ? translate('selectedPlayer', language)
            : (totalHits
              + (totalHits >= 10000 ? '+ ' : ' ')
              + (translate(totalHits === 1 ? 'player' : 'players', language, true)))
          }

          {totalHits > hitsPerChapter && handleChangeCurrentChapter && (
            <div className='table-cell'>
              <div className='player-table-current-chapter-range'>
                {'(' + (hitsPerChapter * currentChapter + 1) + '-' + Math.min(hitsPerChapter * (currentChapter + 1), totalHits) + ')'}
              </div>

              <div className='player-table-pagination-section'>
                <IconButton
                  icon={(
                    <KeyboardArrowLeftIcon
                      style={{ fontSize: 17, color: currentChapter > 0 ? '#ffffffcc' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => currentChapter > 0 ? handleChangeCurrentChapter(false) : null}
                  size={20}
                  isMobile={true}
                  style={{ cursor: currentChapter > 0 ? 'pointer' : 'default' }}
                />

                <div className='player-table-current-chapter'>
                  {currentChapter + 1}
                </div>

                <IconButton
                  icon={(
                    <KeyboardArrowRightIcon
                      style={{ fontSize: 17, color: currentChapter < Math.floor(totalHits / hitsPerChapter) ? '#ffffffcc' : '#ffffff77' }}
                    />
                  )}
                  onClick={() => currentChapter < Math.floor(totalHits / hitsPerChapter) ? handleChangeCurrentChapter(true) : null}
                  size={20}
                  isMobile={true}
                  style={{ cursor: currentChapter < Math.floor(totalHits / hitsPerChapter) ? 'pointer' : 'default' }}
                />
              </div>
            </div>
          )}

        </div>,

      accessor: 'id',
      sticky: 'left',
      Cell: PlayerFindingInfoCell,
      width: 340,
    },
    {
      Header: translate('age', language),
      accessor: 'age',
      Cell: TextCell,
      width: widthUnit * 90,
      minWidth: 90,
    },
    {
      Header: translate('position', language),
      Cell: renderPositionCell('overall', language),
      width: widthUnit * 110,
      minWidth: 110,
    },
    {
      Header: translate('height', language),
      accessor: 'height',
      Cell: HeightCell,
      width: widthUnit * 90,
      minWidth: 90,
    },
    {
      Header: translate('league', language),
      accessor: 'club.competition_id',
      Cell: CompetitionCell,
      width: widthUnit * 170,
      minWidth: 170,
    },
    {
      Header: translate('team', language),
      accessor: 'club.name',
      Cell: ClubCell,
      width: widthUnit * 170,
      minWidth: 170,
    },
  ];
};


export const getFindPlayerTableTotalColumnsMinWidth = () => {
  return 630;
};
