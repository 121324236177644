import { UserConfig } from '../types';


export const userHasEconomyAccess = (userConfig: UserConfig | null) => {
  return userConfig !== null && (
    userConfig.access === 'admin' || userConfig.access === 'full' || userConfig.access === 'economy'
  );
};


export const userHasScoutAccess = (userConfig: UserConfig | null) => {
  return userConfig !== null && (
    userConfig.access === 'admin' || userConfig.access === 'full' || userConfig.access === 'scout'
  );
};


export const userHasNoAccess = (userConfig: UserConfig | null) => {
  return userConfig !== null && (
    userConfig.access === 'none'
  );
};


export const userHasFullAccess = (userConfig: UserConfig | null) => {
  return userConfig !== null && (
    userConfig.access === 'admin' || userConfig.access === 'full'
  );
};


export const userHasMaintenanceAccess = (userConfig: UserConfig | null) => {
  return userConfig !== null && userConfig.hasMaintenanceAccess;
};


export const userHasMobileAccess = (userConfig: UserConfig | null) => {
  return userConfig !== null && (userConfig.club === 'fokus' || userConfig.club === 'demo');
};


export const getShortUserName = (name: string) => {
  if (!name) return '';

  const nameList = name.split(' ');

  if (nameList.length === 1 || name.length < 10) {
    return name;
  }

  const firstName = nameList[0];
  const lastName = nameList[nameList.length - 1];

  if (firstName.length + lastName.length < 14) {
    return `${firstName} ${lastName}`;
  }

  return `${firstName} ${lastName[0]}.`;
};
