import './playerView.css';
import './../documents/documents.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';
import { trackEvent } from '../../services/server/analytics/trackEvent';

import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AddCommentIcon from '@mui/icons-material/AddComment';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { translate } from '../../../common/language/translations';
import { PlayerEntry, PlayerOverview, PlayerReport, ReportType, StringToNumberMap } from '../../types';
import { ViewContainerBackgroundSvg } from '../../../assets/svg/ViewContainerBackgroundSvg';
import { Report } from '../documents/report/Report';
import { getLatestReportVersion, reportVersionToScoreKeys } from '../documents/report/reportVersions';
import { ReportScores } from '../documents/report/ReportScores';
import { TextButton } from '../controls/buttons/TextButton';
import { ExistingReports } from '../documents/existingDocuments/ExistingReports';


interface PlayerViewReportsProps {
  playerOverview: PlayerOverview | undefined;
  playerEntry?: PlayerEntry; // provided for players without data

  reports: PlayerReport[];
  setReports: (newReports: PlayerReport[]) => void;

  playerViewIsExpanding: boolean;
  initialReport: PlayerReport | undefined;
  initialReportHasPrecedence: boolean;
  setInitialReportHasPrecedence: (hasPrecedence: boolean) => void;
}

export const PlayerViewReports: React.FC<PlayerViewReportsProps> = ({
  playerOverview,
  playerEntry,

  reports,
  setReports,

  playerViewIsExpanding,
  initialReport,
  initialReportHasPrecedence,
  setInitialReportHasPrecedence,
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);

  const { openConfirmModal } = useOpenGlobalModal();

  const [newReportTypeSelected, setNewReportTypeSelected] = useState<ReportType | undefined>(undefined);
  const [existingReportSelected, setExistingReportSelected] = useState<PlayerReport | undefined>(undefined);
  const [reportHasUnsavedChanges, setReportHasUnsavedChanges] = useState(false);

  const [averageScoutingReportScores, setAverageScoutingReportScores] = useState<StringToNumberMap>({});
  const [averageMatchReportScores, setAverageMatchReportScores] = useState<StringToNumberMap | undefined>(undefined);


  const openConfirmLeaveReportModal = (action: () => void) => {
    const reportType = newReportTypeSelected ?? existingReportSelected?.reportType;
    openConfirmModal(
      action,
      reportType === 'Comment' ? 'exitComment?' : reportType === 'Attachment' ? 'exitAttachment?' : 'exitReport?',
      reportType === 'Comment' ? 'exitCommentInfo' : reportType === 'Attachment' ? 'exitAttachmentInfo' : 'exitReportInfo'
    );
  };


  const handleCloseIconClick = () => {
    if (reportHasUnsavedChanges) {
      openConfirmLeaveReportModal(handleCloseReport);
    }
    else {
      handleCloseReport();
    }
  };


  const handleCloseReport = () => {
    setNewReportTypeSelected(undefined);
    setExistingReportSelected(undefined);
    setInitialReportHasPrecedence(false);
    setReportHasUnsavedChanges(false);
  };


  const handleExistingReportClicked = (report: PlayerReport | undefined) => {
    if (reportHasUnsavedChanges) {
      openConfirmLeaveReportModal(() => handleSelectReport(report));
    }
    else {
      handleSelectReport(report);
    }
  };


  const handleSelectReport = (report: PlayerReport | undefined, shouldNotResetPrecedence?: boolean) => {
    setNewReportTypeSelected(undefined);
    setExistingReportSelected(report);
    setReportHasUnsavedChanges(false);

    if (!shouldNotResetPrecedence) {
      setInitialReportHasPrecedence(false);
    }
  };


  const addOrUpdateReport = (report: PlayerReport, isNewReport: boolean) => {
    if (isNewReport) {
      setReports([report, ...reports]);
      setNewReportTypeSelected(undefined);
    }
    else {
      let reportWasDeleted = false;

      const updatedReports = reports.map((existingReport: PlayerReport) => {
        if (existingReport.id === report.id) {

          if (!existingReport.isDeleted && report.isDeleted) {
            reportWasDeleted = true;
          }

          return report;
        }

        return existingReport;
      });

      setReports(updatedReports);

      if (reportWasDeleted) {
        setExistingReportSelected(undefined);
      }
      else {
        setExistingReportSelected(report);
      }
    }

    setReportHasUnsavedChanges(false);
  };


  useEffect(() => {
    if (userConfig?.club) {
      const latestReportVersion = getLatestReportVersion(userConfig.club);
      const scoreKeys = reportVersionToScoreKeys[latestReportVersion];

      const scoutingReportScoreSums: StringToNumberMap = {};
      const matchReportScoreSums: StringToNumberMap = {};

      const scoutingReportScoreCounts: StringToNumberMap = {};
      const matchReportScoreCounts: StringToNumberMap = {};

      for (let i = 0; i < reports.length; i++) {
        if (reports[i].reportVersion === latestReportVersion) {
          if (reports[i].reportType === 'ScoutingReport') {
            scoreKeys.forEach(scoreKey => {
              if (!scoutingReportScoreSums[scoreKey]) {
                scoutingReportScoreSums[scoreKey] = 0;
              }

              const score = reports[i].reportFields[scoreKey];
              if (score) {
                scoutingReportScoreSums[scoreKey] += score;

                if (!scoutingReportScoreCounts[scoreKey]) {
                  scoutingReportScoreCounts[scoreKey] = 0;
                }
                scoutingReportScoreCounts[scoreKey]++;
              }
            });
          }
          else if (reports[i].reportType === 'MatchReport') {
            scoreKeys.forEach(scoreKey => {
              if (!matchReportScoreSums[scoreKey]) {
                matchReportScoreSums[scoreKey] = 0;
              }

              const score = reports[i].reportFields[scoreKey];
              if (score) {
                matchReportScoreSums[scoreKey] += score;

                if (!matchReportScoreCounts[scoreKey]) {
                  matchReportScoreCounts[scoreKey] = 0;
                }
                matchReportScoreCounts[scoreKey]++;
              }
            });
          }
        }
      }

      const averageScoutingReportScoresToSet: StringToNumberMap = {};
      const averageMatchReportScoresToSet: StringToNumberMap = {};

      let shouldShowAverageMatchReportTitle = false;
      scoreKeys.forEach(scoreKey => {
        if (scoutingReportScoreCounts[scoreKey]) {
          averageScoutingReportScoresToSet[scoreKey] = scoutingReportScoreSums[scoreKey] / scoutingReportScoreCounts[scoreKey];
        }

        if (matchReportScoreCounts[scoreKey]) {
          shouldShowAverageMatchReportTitle = true;
          averageMatchReportScoresToSet[scoreKey] = matchReportScoreSums[scoreKey] / matchReportScoreCounts[scoreKey];
        }
      });

      setAverageScoutingReportScores(averageScoutingReportScoresToSet);
      setAverageMatchReportScores(shouldShowAverageMatchReportTitle ? averageMatchReportScoresToSet : undefined);
    }
  }, [userConfig?.club, reports]);


  useEffect(() => {
    if (initialReport) {
      trackEvent('ReportViewed', { reportType: initialReport.reportType }, currentUser, 'user');
      handleSelectReport(initialReport, true);
    }
  }, [initialReport]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='full-size-container'>

      <div className='player-view-widget-container player-view-reports-existing-reports-container'>
        {!playerViewIsExpanding && (
          <div className={initialReportHasPrecedence ? 'fade-in' : undefined}>
            <ExistingReports
              reports={reports}
              existingReportSelected={existingReportSelected}
              handleExistingReportClicked={handleExistingReportClicked}
              isPlayerView={true}
            />
          </div>
        )}
      </div>


      <div
        className={
          'player-view-widget-container player-view-reports-right-section' +
          (existingReportSelected ? ' player-view-reports-right-section-existing-report' : '')
        }
      >

        <div className='full-size'>
          <ViewContainerBackgroundSvg />
        </div>

        {!newReportTypeSelected && !existingReportSelected && !initialReportHasPrecedence && (
          <div className='document-content-section'>

            <div className='report-content-left-column'>
              <div className='player-view-reports-new-report-buttons-column'>
                <TextButton
                  onClick={() => setNewReportTypeSelected('ScoutingReport')}
                  text={translate('newScoutingReport', userConfig?.language)}
                  icon={<NoteAddIcon style={{ fontSize: 22 }} />}
                  buttonType={'transparent'}
                  isRound={true}
                  style={{ width: 210, height: 36 }}
                />

                <TextButton
                  onClick={() => setNewReportTypeSelected('MatchReport')}
                  text={translate('newMatchReport', userConfig?.language)}
                  icon={<PostAddIcon style={{ fontSize: 22 }} />}
                  buttonType={'transparent'}
                  isRound={true}
                  style={{ width: 210, height: 36 }}
                />

                <TextButton
                  onClick={() => setNewReportTypeSelected('Comment')}
                  text={translate('newComment', userConfig?.language)}
                  icon={<AddCommentIcon style={{ fontSize: 22 }} />}
                  buttonType={'transparent'}
                  isRound={true}
                  style={{ width: 210, height: 36 }}
                />

                <TextButton
                  onClick={() => setNewReportTypeSelected('Attachment')}
                  text={translate('newAttachment', userConfig?.language)}
                  icon={<AttachFileIcon style={{ fontSize: 22 }} />}
                  buttonType={'transparent'}
                  isRound={true}
                  style={{ width: 210, height: 36 }}
                />
              </div>
            </div>

            <div className='report-content-right-column'>
              <div className='report-content-average-title'>
                {translate('averageRating', userConfig?.language)}
              </div>

              <ReportScores
                scores={averageScoutingReportScores}
              />
            </div>
          </div>
        )}

        {(newReportTypeSelected || existingReportSelected) && (
          <div className={'full-size-container' + (initialReportHasPrecedence ? ' fade-in' : '')}>
            <Report
              newReportTypeSelected={newReportTypeSelected}
              existingReportSelected={existingReportSelected}

              reportHasUnsavedChanges={reportHasUnsavedChanges}
              setReportHasUnsavedChanges={setReportHasUnsavedChanges}

              handleCloseIconClick={handleCloseIconClick}
              addOrUpdateReport={addOrUpdateReport}

              playerOverview={playerOverview}
              playerEntry={playerEntry}

              averageMatchReportScores={averageMatchReportScores}
            />
          </div>
        )}

      </div>

    </div>
  );
};
