import './input.css';


interface ToggleProps {
  isToggled: boolean;
  setIsToggled: (isToggled: boolean, callBackProperty?: string) => void;
  isWhiteBackground?: boolean;
  boxShadow?: string;
  callBackProperty?: string;
  isSmall?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  isToggled,
  setIsToggled,
  isWhiteBackground,
  boxShadow,
  callBackProperty,
  isSmall
}) => {


  const getClassName = () => {
    let className = 'toggle-container';

    if (isSmall) {
      className += ' toggle-container-small';
    }

    if (isWhiteBackground) {
      className += ' toggle-container-white';
    }

    if (isToggled) {
      if (isWhiteBackground) {
        className += ' toggle-container-active-white';
      }
      else {
        className += ' toggle-container-active';
      }
    }
    return className;
  };


  return (
    <div className={getClassName()} onClick={() => setIsToggled(!isToggled, callBackProperty)} style={{ boxShadow: boxShadow }}>

      <div
        className={'toggle-circle' + (isWhiteBackground ? ' toggle-circle-white' : '') + (isSmall ? ' toggle-circle-small' : '')}
        style={{ left: isToggled ? (isSmall ? 10 : 12) : 1 }}
      />

    </div>
  );
};
