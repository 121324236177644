import { contractKeysToMonths } from '../../../static/propertyValues';
import { Alert, RatingRequirement } from '../../../types';
import { PlayerOverviewWithAlertProperties } from './Alerts';


export type AlertsFilter =
  | 'logisticTeams'
  | 'minConfidence'
  | 'maxAge'
  | 'positionsAndRoles'
  | 'leagues'
  | 'orderBy'
  | 'indexes'
  | 'form'
  | 'playingTime'
  | 'nationalities'
  | 'contractStatus';


export interface AlertsState {

  // selected filters
  selectedLogisticTeams: string[];
  selectedMinConfidence: string[];
  selectedMaxAge: string[];
  selectedPositions: string[];
  selectedRoles: string[];
  selectedLeagues: string[];
  selectedOrderBy: string[];

  selectedIndexes: RatingRequirement[];
  selectedForm: string[];
  selectedPlayingTime: string[];
  selectedNationalities: string[];
  selectedContractStatus: string[];
  isContractStatusToggled: boolean;

  // filter expansion state
  currentFilterExpanded: AlertsFilter | undefined,
  previousFilterExpanded: AlertsFilter | undefined;

  // search state
  tableData: PlayerOverviewWithAlertProperties[];
  currentPage: number;
  totalHits: number;
  isLoading: boolean;
  isEmptySearchResult: boolean;
  submitButtonDisabled: boolean;
  errorMessage: string | undefined;

  // alerts state
  newAlertSelected: boolean;
  existingAlertSelected: Alert | undefined;
  existingAlertSelectedWasJustClicked: boolean;
  isEditAlertView: boolean;
  showEditAlertView: boolean;
  isExcludedPlayersView: boolean;
  showExcludedPlayersView: boolean;
  playerWasJustExcluded: boolean;
  playerExclusionWasJustUndone: boolean;
  isResetExcludedPlayersToggled: boolean;
  alertName: string;
  showAlertNameRequired: boolean;
}


export const initialAlertsState: AlertsState = {

  // selected filters
  selectedLogisticTeams: [],
  selectedMinConfidence: [],
  selectedMaxAge: [],
  selectedPositions: [],
  selectedRoles: [],
  selectedLeagues: [],
  selectedOrderBy: ['skillIndex'],

  selectedIndexes: [],
  selectedForm: [],
  selectedPlayingTime: [],
  selectedNationalities: [],
  selectedContractStatus: [],
  isContractStatusToggled: false,

  // filter expansion state
  currentFilterExpanded: undefined,
  previousFilterExpanded: undefined,

  // search state
  tableData: [],
  currentPage: 0,
  totalHits: 0,
  isLoading: false,
  isEmptySearchResult: false,
  submitButtonDisabled: false,
  errorMessage: undefined,

  // alerts state
  newAlertSelected: false,
  existingAlertSelected: undefined,
  existingAlertSelectedWasJustClicked: false,
  isEditAlertView: false,
  showEditAlertView: false,
  isExcludedPlayersView: false,
  showExcludedPlayersView: false,
  playerWasJustExcluded: false,
  playerExclusionWasJustUndone: false,
  isResetExcludedPlayersToggled: false,
  alertName: '',
  showAlertNameRequired: false
};


export const alertHasUnsavedChanges = (
  state: AlertsState,
  onlyConsiderFilters?: boolean // if true, name changes are not considered
) => {

  // if existing alert, check if name or any filters have been changed
  if (state.existingAlertSelected) {
    if (
      !onlyConsiderFilters && state.alertName !== state.existingAlertSelected.name
    ) return true;

    if (
      state.selectedRoles.join(',') !== (state.existingAlertSelected.selectedRoles ?? []).join(',')
    ) return true;

    if (
      state.selectedLogisticTeams.join(',') !== (state.existingAlertSelected.filters.logisticTeamIds ?? []).join(',')
    ) return true;
    if (
      (state.selectedMinConfidence.length > 0 ? parseInt(state.selectedMinConfidence[0]) / 100 : undefined) !==
      state.existingAlertSelected.filters.minConfidence
    ) return true;
    if (
      (state.selectedMaxAge.length > 0 ? parseInt(state.selectedMaxAge[0]) : undefined) !== state.existingAlertSelected.filters.maxAge
    ) return true;
    if (
      state.selectedPositions.join(',') !== (state.existingAlertSelected.filters.primaryPositions ?? []).join(',')
    ) return true;
    if (
      state.selectedLeagues.join(',') !== (state.existingAlertSelected.filters.competitions ?? []).join(',')
    ) return true;
    if (
      state.selectedOrderBy[0] !== state.existingAlertSelected.filters.sortBy
    ) return true;

    if (
      state.selectedIndexes.map(index => index.metric + '_' + index.value).join(',') !==
      (state.existingAlertSelected.filters.indexRequirements ?? []).map((index: RatingRequirement) => index.metric + '_' + index.value).join(',')
    ) return true;
    if (
      state.selectedForm.join(',') !== (state.existingAlertSelected.filters.form ?? []).join(',')
    ) return true;
    if (
      state.selectedPlayingTime.join(',') !== (state.existingAlertSelected.filters.playingTime ?? []).join(',')
    ) return true;
    if (
      state.selectedNationalities.join(',') !== (state.existingAlertSelected.filters.countryCodes ?? []).join(',')
    ) return true;
    if (
      (state.selectedContractStatus.length > 0 ? contractKeysToMonths[state.selectedContractStatus[0]] : undefined) !==
      state.existingAlertSelected.filters.maxContractLength
    ) return true;
    if (
      state.isContractStatusToggled !== (state.existingAlertSelected.filters.includeUnknownContracts ?? false)
    ) return true;

    if (state.isResetExcludedPlayersToggled) return true;
  }

  // if new alert, check if name or any filters are provided
  else {
    if (!onlyConsiderFilters && state.alertName.length > 0) return true;

    if (state.selectedRoles.length > 0) return true;

    if (state.selectedLogisticTeams.length > 0) return true;
    if (state.selectedMinConfidence.length > 0) return true;
    if (state.selectedMaxAge.length > 0) return true;
    if (state.selectedPositions.length > 0) return true;
    if (state.selectedLeagues.length > 0) return true;
    if (state.selectedOrderBy[0] !== 'skillIndex') return true;

    if (state.selectedIndexes.length > 0) return true;
    if (state.selectedForm.length > 0) return true;
    if (state.selectedPlayingTime.length > 0) return true;
    if (state.selectedNationalities.length > 0) return true;
    if (state.selectedContractStatus.length > 0) return true;
    if (state.isContractStatusToggled) return true;
  }

  return false;
};
