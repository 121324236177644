import './history.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import { translate } from '../../../common/language/translations';
import { StringToAnyMap } from '../../types';
import { addTeamHistoryAction, deleteCommentFromTeamHistory } from '../../services/firestore/teamsOrSquads';
import { getDateLocaleFormat, getDisplayPlayerName } from '../../utils/playerUtils';
import { getShortUserName } from '../../utils/userUtils';
import { InputField } from '../controls/input/InputField';


interface TeamHistoryProps {
  teamOrSquadId: string | undefined;
  history: StringToAnyMap[];
  isSquad?: boolean;
}


export const TeamHistory: React.FC<TeamHistoryProps> = ({ teamOrSquadId, history, isSquad }) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);

  const { openConfirmModal } = useOpenGlobalModal();

  const inputRef = useRef<HTMLInputElement>(null);
  const [commentString, setCommentString] = useState('');

  const [showHistory, setShowHistory] = useState(false);


  const onChangeCommentString = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommentString(event.target.value);
  };


  const onKeyDownCommentString = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && commentString.length > 0) {
      submitNewComment();
    }
  };


  const onCommentClick = (index: number, isCurrentUser: boolean) => {
    if (isCurrentUser) {
      openConfirmModal(
        () => confirmDeletion(index),
        'deleteComment',
        'noRegretInfo'
      );
    }
  };


  const submitNewComment = () => {
    if (!userConfig || !teamOrSquadId) return;

    const action: StringToAnyMap = {
      actionType: 'comment',
      comment: commentString,
    };

    addTeamHistoryAction(action, teamOrSquadId, isSquad ?? false, userConfig.email, userConfig.club, currentUser);
    setCommentString('');
    if (inputRef.current) {
      inputRef.current.blur();
    }

    setTimeout(() => {
      scrollToBottom();
    }, 250);
  };


  const confirmDeletion = async (index: number) => {
    if (!teamOrSquadId || !userConfig) return;

    const actionToDelete = history[index];
    await deleteCommentFromTeamHistory(teamOrSquadId, isSquad ?? false, actionToDelete, userConfig?.club, currentUser);
  };


  const scrollToBottom = () => {
    const commentField = document.querySelector('.team-history-action-section');
    if (commentField) {
      commentField.scrollTop = commentField.scrollHeight;
    }
  };


  const shouldShowDate = (index: number) => {
    if (index === 0) return true;

    const previousDate = history[index - 1]['date'].split('T')[0];
    const currentDate = history[index]['date'].split('T')[0];

    const previousUser = history[index - 1]['userEmail'];
    const currentUser = history[index]['userEmail'];

    return previousDate !== currentDate || previousUser !== currentUser;
  };


  const getHistoryInfoText = (teamAction: StringToAnyMap, actionType: string, isCurrentUser: boolean) => {
    if (!userConfig) return '';

    const userName = isCurrentUser
      ? translate('you', userConfig.language)
      : allUsersWithSameClub[teamAction['userEmail']]
        ? getShortUserName(allUsersWithSameClub[teamAction['userEmail']].name)
        : translate('unknownUser', userConfig.language);

    if (actionType === 'archive') {
      return userName + ' ' + translate(isSquad ? '<someone>archivedTheSquad' : '<someone>archivedTheTeam', userConfig.language);
    }

    if (actionType === 'reset') {
      return userName + ' ' + translate(isSquad ? '<someone>resetTheSquad' : '<someone>resetTheTeam', userConfig.language);
    }

    if (actionType === 'create') {
      return userName + ' ' + translate(isSquad ? '<someone>createdTheSquad' : '<someone>createdTheTeam', userConfig.language);
    }

    if (actionType === 'editName') {
      return userName
        + ' '
        + translate('<someone>editedTheNameTo', userConfig.language)
        + ' '
        + teamAction['action']['newName'];
    }

    if (actionType === 'editFormation') {
      return userName
        + ' '
        + translate('<someone>editedTheFormationTo', userConfig.language)
        + ' '
        + teamAction['action']['newFormation'];
    }

    if (actionType === 'playerActivity') {
      return userName
        + ' '
        + translate(teamAction['fromTeamId'] === teamOrSquadId ? '<someone>removed<thePlayer>' : '<someone>added<thePlayer>', userConfig.language)
        + ' '
        + getDisplayPlayerName(teamAction['playerName'], 16);
    }

    if (actionType === 'comment') {
      return userName + ' ' + translate('<someone>commented', userConfig.language) + ':';
    }

    return actionType;

  };


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHistory(true);
    }, 250);

    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    scrollToBottom();
  }, []);


  return (
    <div className='history-container team-history-container'>

      <div className={'history-action-section team-history-action-section' + (showHistory ? ' history-action-section-show' : '')}>
        {history && history.map((teamAction, index) => {

          const actionType = teamAction['action'] ? teamAction['action']['actionType'] : 'playerActivity';

          const isCurrentUser = userConfig !== null && teamAction['userEmail'] === userConfig.email;

          return (
            <div
              key={index}
              className='history-action team-history-action'>

              {shouldShowDate(index) && (
                <div className={'history-date-row team-history-date-row' + (index === 0 ? ' team-history-first-date-row' : '')}>

                  {isCurrentUser && <div className='history-date-small-divider' />}

                  <div className={'history-date-small' + (isCurrentUser ? ' history-date-current-user' : '')}>
                    {getDateLocaleFormat(teamAction['date'].split('T')[0])}
                  </div>

                  {!isCurrentUser && <div className='history-date-small-divider' />}

                </div>
              )}

              {(actionType !== 'comment' || !isCurrentUser) && (
                <div
                  className={
                    'team-history-info-text'
                    + (isCurrentUser ? ' team-history-info-text-current-user' : '')
                    + (actionType === 'comment' ? ' team-history-info-text-comment' : '')
                  }
                >
                  {getHistoryInfoText(teamAction, actionType, isCurrentUser)}
                </div>
              )}

              {actionType === 'comment' && (
                <div
                  className={'team-history-comment' + (isCurrentUser ? ' team-history-comment-current-user' : '')}
                  onClick={() => onCommentClick(index, isCurrentUser)}
                >
                  {teamAction['action']['comment']}
                </div>
              )}

            </div>
          );
        })}
      </div>

      <div className='team-history-input-section'>
        <InputField
          ref={inputRef}
          value={commentString}
          placeholder={translate('provideComment', userConfig?.language)}
          placeholderFontSize={12}
          onChange={onChangeCommentString}
          onKeyDown={onKeyDownCommentString}
          resetValue={() => setCommentString('')}
          onClick={scrollToBottom}
          isSquare={true}
        />
      </div>

    </div>
  );
};
