import { Club, PlayerOverview } from '../../../types';


export type PlayerScoutingFilter =
  | 'minConfidence'
  | 'maxAge'
  | 'positionsAndRoles'
  | 'leagues'
  | 'orderBy'
  | 'clubs'
  | 'form'
  | 'playingTime'
  | 'nationalities'
  | 'contractStatus';


// name strings are handled separately due to debounce logic
export interface PlayerScoutingState {

  // selected filters
  selectedMinConfidence: string[];
  selectedMaxAge: string[];
  selectedPositions: string[];
  selectedRoles: string[];
  selectedLeagues: string[];
  selectedOrderBy: string[];

  selectedClubs: Club[];
  selectedForm: string[];
  selectedPlayingTime: string[];
  selectedNationalities: string[];
  selectedContractStatus: string[];
  isContractStatusToggled: boolean;

  // filters applied to current search result
  currentMinConfidence: string[];
  currentMaxAge: string[];
  currentPositions: string[];
  currentRoles: string[];
  currentLeagues: string[];
  currentOrderBy: string[];

  currentClubs: Club[];
  currentForm: string[];
  currentPlayingTime: string[];
  currentNationalities: string[];
  currentContractStatus: string[];
  currentIsContractStatusToggled: boolean;

  // filter expansion state
  currentFilterExpanded: PlayerScoutingFilter | undefined,
  previousFilterExpanded: PlayerScoutingFilter | undefined;

  // advanced filters
  isAdvancedFiltersExpanded: boolean;
  showAdvancedFilters: boolean;
  scoutingViewResultContainerTop: number; // 85 | 155

  // search state
  tableData: PlayerOverview[];
  currentModuloPage: number; // currentModuloPage (page % pagesPerChapter) is 1-indexed
  currentChapter: number; // chapters are 0-indexed
  totalHits: number;
  isLoading: boolean;
  isEmptySearchResult: boolean;
  searchWasLoaded: boolean;

  // other state
  modalTypeOpen: 'saveSearch' | 'loadSearch' | undefined;
}


export const initialPlayerScoutingState: PlayerScoutingState = {

  // selected filters
  selectedMinConfidence: [],
  selectedMaxAge: [],
  selectedPositions: [],
  selectedRoles: [],
  selectedLeagues: [],
  selectedOrderBy: ['skillIndex'],

  selectedClubs: [],
  selectedForm: [],
  selectedPlayingTime: [],
  selectedNationalities: [],
  selectedContractStatus: [],
  isContractStatusToggled: false,

  // filters applied to current search result
  currentMinConfidence: [],
  currentMaxAge: [],
  currentPositions: [],
  currentRoles: [],
  currentLeagues: [],
  currentOrderBy: ['skillIndex'],

  currentClubs: [],
  currentForm: [],
  currentPlayingTime: [],
  currentNationalities: [],
  currentContractStatus: [],
  currentIsContractStatusToggled: false,

  // filter expansion state
  currentFilterExpanded: undefined,
  previousFilterExpanded: undefined,

  // advanced filters
  isAdvancedFiltersExpanded: false,
  showAdvancedFilters: false,
  scoutingViewResultContainerTop: 85,

  // search state
  tableData: [],
  currentModuloPage: 0,
  currentChapter: 0,
  totalHits: 0,
  isLoading: false,
  isEmptySearchResult: false,
  searchWasLoaded: false,

  // other state
  modalTypeOpen: undefined,
};


export const anyFilterExceptNameAndDefaultOrderBySelected = (state: PlayerScoutingState) => {

  const otherOrderByThanDefaultSelected = state.selectedOrderBy.length > 0 && state.selectedOrderBy[0] !== 'skillIndex';

  return (
    state.selectedMinConfidence.length > 0
    || state.selectedMaxAge.length > 0
    || state.selectedPositions.length > 0
    || state.selectedRoles.length > 0
    || state.selectedLeagues.length > 0
    || otherOrderByThanDefaultSelected

    || state.selectedClubs.length > 0
    || state.selectedForm.length > 0
    || state.selectedPlayingTime.length > 0
    || state.selectedNationalities.length > 0
    || state.selectedContractStatus.length > 0
  );
};
