/* eslint-disable max-len */
import { StringToAnyMap } from '../types';
import { metricToDisplayInfo } from './playerMetrics';
import { translate } from '../../common/language/translations';


export const supportEntries: StringToAnyMap = {


  'faq': {
    'title': {
      'en': 'FAQ',
      'no': 'Ofte stilte spørsmål',
      'se': 'Vanliga frågor',
      'dk': 'Ofte stillede spørgsmål',
    },
    'subsections': [
      {
        'title': {
          'en': 'How do I add a player I can\'t find in the database?',
          'no': 'Hvordan legger jeg til en spiller jeg ikke finner i databasen?',
          'se': 'Hur lägger jag till en spelare som inte finns i databasen?',
          'dk': 'Hvordan tilføjer jeg en spiller, jeg ikke kan finde i databasen?'
        },
        'text': {
          'en': [
            'To track a player without data, you can manually create the player in the name search to the left in all teams and squads.',
            "Click on the Add icon to the left and then 'Add player without data' and enter the name. The player is created once added to the team or squad. After the player is created, you can enter age and nationality, and optionally your reports.",
            'When the player eventually debuts professionally, the player will most likely appear in our database quickly. You can then use the linking functionality within the player view to search for the player in the database and link that player to the manual player.'
          ],
          'no': [
            'For å følge opp juniorspillere og akademispillere, eller spillere som av andre grunner ikke finnes i databasen, kan du opprette spillere manuelt i navnesøket til venstre inne på alle lag og staller.',
            "Klikk på Pluss-ikonet til venstre og deretter 'Legg til spiller uten data' og oppgi navnet. Spilleren opprettes når du legger til spilleren på laget eller stallen. Etter at spilleren er opprettet kan du legge inn alder og nasjonalitet, og eventuelt dine rapporter.",
            'Når spilleren etterhvert debuterer profesjonelt vil han sannsynligvis dukke opp i vår database raskt. Da kan man bruke koblingsfunksjonaliteten inne på spilleren for å søke opp den faktiske spilleren i databasen, og koble den manuelle spilleren opp mot riktig spiller.'
          ],
          'se': [
            'För att följa upp juniorspelare och akademispelare, eller spelare som av andra anledningar inte finns i databasen, kan du manuellt skapa spelare i namnsökningen till vänster i alla lag och stall.',
            "Klicka på Lägg till-ikonen till vänster och sedan 'Lägg till spelare utan data' och ange namnet. Spelaren skapas när du lägger till spelaren i laget eller stallen. Efter att spelaren har skapats kan du ange ålder och nationalitet, och eventuellt dina rapporter.",
            'När spelaren så småningom debuterar professionellt kommer han förmodligen att dyka upp i vår databas snabbt. Då kan du använda länkfunktionaliteten inom spelarvyn för att söka efter den faktiska spelaren i databasen och länka den manuella spelaren till rätt spelare.'
          ],
          'dk': [
            'For at følge op på juniorspillere og akademispillere, eller spillere, der af andre årsager ikke findes i databasen, kan du manuelt oprette spilleren i navnesøgningen til venstre i alle hold og trupper.',
            "Klik på Tilføj-ikonet til venstre og derefter 'Tilføj spiller uden data' og indtast navnet. Spilleren oprettes, når du tilføjer spilleren til holdet eller truppen. Efter at spilleren er oprettet, kan du indtaste alder og nationalitet og eventuelt dine rapporter.",
            'Når spilleren så småt debuterer professionelt, vil han sandsynligvis dukke op i vores database hurtigt. Du kan derefter bruge linkfunktionaliteten inden for spillerens visning til at søge efter den faktiske spiller i databasen og linke den manuelle spiller til den rigtige spiller.'
          ],
        }
      },
      {
        'title': {
          'en': 'Why does the table on the Home page show fewer matches for advanced data?',
          'no': 'Hvorfor viser tabellen på Hjem-siden færre kamper spilt for avansert data?',
          'se': 'Varför visar tabellen på startsidan färre spelade matcher för avancerade data?',
          'dk': 'Hvorfor viser tabellen på startsiden færre spillede kampe for avancerede data?',
        },
        'text': {
          'en': [
            'The advanced data in the table on the Home page are based on event data from IMPECT. The data is collected manually and made available to us within a certain timeframe. Different leagues have different deadlines, ranging from the day after to multiple days. We make the data available in our platform as soon as we receive it.',
            'This means that the table with advanced data also serves as an indicator of which matches we have detailed data from in your league.'
          ],
          'no': [
            'Den avanserte dataen i tabellen på Hjem-siden er basert på event-data fra IMPECT. Dataene samles inn manuelt, og gjøres tilgjengelig for oss innen en bestemt frist. Ulike ligaer har ulike frister, der enkelte kamper samles inn dagen etter, mens noen kamper kommer senere. Vi tilgjengeliggjør dataene inne på plattformen så snart vi får dem.',
            'Dette betyr at tabellen med avansert data også fungerer som en indikator på hvilke kamper vi har detaljert data fra i din nåværende liga.',
          ],
          'se': [
            'Den avancerade datan i tabellen på startsidan är baserad på event-data från IMPECT. Datan samlas in manuellt och görs tillgänglig för oss inom en viss tidsram. Olika ligor har olika tidsfrister, från dagen efter till flera dagar. Vi gör datan tillgänglig i vår plattform så snart vi får den.',
            'Detta betyder att tabellen med avancerade data också fungerar som en indikator på vilka matcher vi har detaljerad data från i din nuvarande liga.'
          ],
          'dk': [
            'Den avancerede data i tabellen på startsiden er baseret på event-data fra IMPECT. Dataene indsamles manuelt og gøres tilgængelige for os inden for en bestemt tidsramme. Forskellige ligaer har forskellige deadlines, der spænder fra dagen efter til flere dage. Vi gør dataene tilgængelige i vores platform, så snart vi modtager dem.',
            'Dette betyder, at tabellen med avancerede data også fungerer som en indikator på, hvilke kampe vi har detaljeret data fra i din nuværende liga.'
          ],
        }
      },
      {
        'title': {
          'en': 'How can I get insight into a locked league?',
          'no': 'Hvordan kan jeg få innsikt i en låst liga?',
          'se': 'Hur kan jag få insyn i en låst liga?',
          'dk': 'Hvordan kan jeg få indsigt i en låst liga?',
        },
        'text': {
          'en': [
            'Your club has chosen leagues and cups with event data. To see the competitions you have access to, you can go to Settings and then Club, and if you click on a competition you will see the available seasons. If you want analyses from more leagues, you can contact us directly, and we will see if we can upgrade your package or update the chosen leagues.'
          ],
          'no': [
            'Din klubb har selv valgt ligaer og cuper med event-data. For å se konkurransene du har tilgang kan du gå til Innstillinger og så Klubb, og dersom du klikker på en liga eller cup vil du se hvilke sesonger som er tilgjengelige. Ønsker dere analyser fra flere ligaer kan dere ta kontakt med oss direkte, så ser vi om vi kan oppgradere pakken deres eller endre valgte ligaer.'
          ],
          'se': [
            'Din klubb har själv valt ligor och cuper med event-data. För att se de tävlingar du har tillgång till kan du gå till Inställningar och sedan Klubb, och om du klickar på en tävling ser du de tillgängliga säsongerna. Om du vill ha analyser från fler ligor kan du kontakta oss direkt, så ser vi om vi kan uppgradera din paket eller uppdatera de valda ligorna.'
          ],
          'dk': [
            'Din klub har selv valgt ligaer og turneringer med event-data. For at se de konkurrencer, du har adgang til, kan du gå til Indstillinger og derefter Klub, og hvis du klikker på en konkurrence, vil du se de tilgængelige sæsoner. Hvis du ønsker analyser fra flere ligaer, kan du kontakte os direkte, og vi vil se, om vi kan opgradere din pakke eller opdatere de valgte ligaer.'
          ],
        }
      },
      {
        'title': {
          'en': 'I want to clean up a logistics team, but I am afraid of losing players, what can I do?',
          'no': 'Jeg ønsker å rydde opp i et logistikklag, men er redd for å miste spillerne, hva kan jeg gjøre?',
          'se': 'Jag vill städa upp ett logistiklag men är rädd för att tappa spelarna, vad kan jag göra?',
          'dk': 'Jeg vil gerne rydde op i et logistikhold, men er bange for at miste spillerne, hvad kan jeg gøre?',
        },
        'text': {
          'en': [
            'To avoid losing valuable work and to maintain good order, you can archive all logistics teams, which in practice means saving a screenshot of previous work. Best practice is to archive the teams after each completed transfer window, and then clean up. You can read more about archived teams further down the page.',
            'Additionally, each player and each team has a log that shows historical movements. If you delete a player from the Shadow Team, for example, you can still see in the log that the player was previously included.'
          ],
          'no': [
            'For å unngå å miste verdifullt arbeid, og samtidig holde god orden kan man arkivere alle logistikklag, som i praksis betyr å lagre et skjermbilde av tidligere arbeid. Typisk bruk vil være å arkivere lagene etter hvert fullførte overgangsvindu, før man rydder opp. Du kan lese mer om arkiverte lag lenger ned på siden.',
            'I tillegg har hver spiller og hvert lag en logg som viser bevegelsen historisk. Om man sletter en spiller fra Skyggelaget for eksempel, vil man fortsatte kunne se i loggen at spilleren lå inne tidligere.'
          ],
          'se': [
            'För att undvika att förlora värdefullt arbete och behålla god ordning kan du arkivera alla logistiklag, vilket i praktiken innebär att spara en skärmbild av tidigare arbete. Bästa praxis är att arkivera lagen efter varje avslutat övergångsfönster och sedan städa upp. Du kan läsa mer om arkiverade lag längre ner på sidan.',
            'Dessutom har varje spelare och varje lag en logg som visar historiska rörelser. Om du tar bort en spelare från Skyggelaget till exempel, kan du fortfarande se i loggen att spelaren tidigare var inkluderad.'
          ],
          'dk': [
            'For at undgå at miste værdifuldt arbejde og for at opretholde god orden kan du arkivere alle logistikhold, hvilket i praksis betyder at gemme et skærmbillede af tidligere arbejde. Bedste praksis er at arkivere holdene efter hvert afsluttet transfervindue og derefter rydde op. Du kan læse mere om arkiverede hold længere nede på siden.',
            'Derudover har hver spiller og hvert hold en log, der viser historiske bevægelser. Hvis du sletter en spiller fra Skyggelaget, for eksempel, kan du stadig se i loggen, at spilleren tidligere var inkluderet.'
          ],
        }
      },
      {
        'title': {
          'en': 'What is the difference between Skill rating and Skill index?',
          'no': 'Hva er forskjellen på Skillrating og Skillindeks?',
          'se': 'Vad är skillnaden mellan Skillrating och Skillindex?',
          'dk': 'Hvad er forskellen på Skillrating og Skillindeks?',
        },
        'text': {
          'en': [
            'Both skill rating and skill index are overarching measures of a player\'s performance level, and the metrics and weighting included in these are the same. The difference is that skill rating describes the performance level of a player in a given season for a given club, while skill index is a weighted average of several skill ratings back in time. Skill rating thus represents a season, but skill index represents a player. You can read more about the difference between ratings and indexes further down the page.'
          ],
          'no': [
            'Både skillrating og skillindeks er overordnede mål på prestasjonsnivået til en spiller, og metrikkene og vektingen som inngår i disse er de samme. Forskjellen er at skillrating beskriver prestasjonsnivået til en spiller i en gitt sesong for en gitt klubb, mens skillindeksen er et vektet snitt av flere skillratinger tilbake i tid. Skillrating representerer altså en sesong, men skillindeks representerer en spiller. Du kan lese mer om forskjellen på ratinger og indekser lenger ned på siden.'
          ],
          'se': [
            'Både skillrating och skillindex är övergripande mått på en spelares prestationsnivå, och de metoder och vikter som ingår i dessa är desamma. Skillnaden är att skillrating beskriver en spelares prestationsnivå under en given säsong för en given klubb, medan skillindex är ett viktat genomsnitt av flera skillratinger bakåt i tiden. Skillrating representerar alltså en säsong, men skillindex representerar en spelare. Du kan läsa mer om skillnaden mellan ratings och index längre ner på sidan.'
          ],
          'dk': [
            'Både skillrating og skillindeks er overordnede mål for en spillers præstationsniveau, og de metrikker og vægtninger, der indgår i disse, er de samme. Forskellen er, at skillrating beskriver en spillers præstationsniveau i en given sæson for en given klub, mens skillindeks er et vægtet gennemsnit af flere skillratings tilbage i tiden. Skillrating repræsenterer således en sæson, men skillindeks repræsenterer en spiller. Du kan læse mere om forskellen mellem ratings og indeks længere nede på siden.'
          ],
        }
      },
      {
        'title': {
          'en': 'How are indexes adjusted for league levels?',
          'no': 'Hvordan justeres indekser for liganivå?',
          'se': 'Hur justeras index för ligor?',
          'dk': 'Hvordan justeres indekser for liganiveauer?',
        },
        'text': {
          'en': [
            'All indexes are adjusted for the level of all competitions, which means that performances in a tougher competition get a small reward when an index is calculated, while performances in a weaker competition get a small penalty.',
            'Fokus has developed its own ranking of the level of all leagues and cups, where one of the most important factors is the average market value of all teams participating in the competition, as this has proven to be a strong indicator of the level of a competition.',
            'Based on this ranking, the teams are placed into different buckets representing different levels. The bucket of the domestic league of your club will be the reference point for the adjustment of the indexes. All competitions at the same level will not be adjusted, while for each level up or down, the indexes will be adjusted up or down respectively.'
          ],
          'no': [
            'Alle indekser justeres for nivået til alle konkurranser, som betyr at prestasjoner i en tøffere konkurranse får en liten belønning når en indeks regnes ut, mens prestasjoner i en svakere konkurranse får en liten straff.',
            'Fokus har utarbeidet en egen rangering av nivået til alle ligaer og cuper, der en av de viktigste faktorene er gjennomsnittlig markedsverdi for alle lagene som deltar i konkurransen, da dette har vist seg å være en sterk indikator på nivået til en konkurranse.',
            'Basert på denne rangeringen blir lagene inndelt i nivåer. Det nivået der den hjemlige ligaen til din klubb havner, vil være referansepunktet for justeringen av indeksene. Alle konkurranser i samme nivå vil ikke justeres, mens for hvert nivå opp eller ned vil indeksene justeres henholdsvis opp eller ned.'
          ],
          'se': [
            'Alla index justeras för nivån på alla tävlingar, vilket innebär att prestationer i en tuffare tävling får en liten belöning när ett index beräknas, medan prestationer i en svagare tävling får en liten straff.',
            'Fokus har utvecklat sin egen ranking av nivån på alla ligor och cuper, där en av de viktigaste faktorerna är det genomsnittliga marknadsvärdet för alla lag som deltar i tävlingen, eftersom detta har visat sig vara en stark indikator på nivån på en tävling.',
            'Baserat på denna ranking placeras lagen i olika nivåer. Nivån på din klubbens inhemska liga kommer att vara referenspunkten för justeringen av indexen. Alla tävlingar på samma nivå kommer inte att justeras, medan för varje nivå upp eller ner kommer indexen att justeras upp eller ner respektive.'
          ],
          'dk': [
            'Alle indekser justeres for niveauet i alle konkurrencer, hvilket betyder, at præstationer i en hårdere konkurrence får en lille belønning, når en indeks beregnes, mens præstationer i en svagere konkurrence får en lille straf.',
            'Fokus har udviklet sin egen rangering af niveauet i alle ligaer og turneringer, hvor en af de vigtigste faktorer er den gennemsnitlige markedsværdi for alle hold, der deltager i konkurrencen, da dette har vist sig at være en stærk indikator for niveauet i en konkurrence.',
            'Baseret på denne rangering placeres holdene i forskellige niveauer. Niveauet for din klubs hjemlige liga vil være referencepunktet for justeringen af indekserne. Alle konkurrencer på samme niveau vil ikke blive justeret, mens for hvert niveau op eller ned vil indekserne blive justeret op eller ned, henholdsvis.'
          ],
        }
      },
      {
        'title': {
          'en': 'What do the values for ratings and indexes actually mean?',
          'no': 'Hva betyr egentlig verdiene for ratinger og indekser?',
          'se': 'Vad betyder egentligen värdena för ratingar och index?',
          'dk': 'Hvad betyder egentlig værdierne for ratings og indekser?',
        },
        'text': {
          'en': [
            'The value for all ratings and indexes goes from 0 to 10, where 10 is best. Simply put, we can say that a value of 9 for a rating or index, for example, means that the player is better than 90% of all other players in the database within a position group, and is thus among the 10% best players.',
            'In practice, it is a bit more complicated than this, as ratings are weighted averages of metrics that are themselves normalized percentiles, and as indexes are weighted averages of ratings that are also adjusted for league level. You can read more about what the values mean further down the page, and you can also contact us for a proper walkthrough together of how the values are calculated and what they mean.'
          ],
          'no': [
            'Verdien for alle ratinger og indekser går fra 0 til 10, der 10 er best. Enkelt forklart kan vi si at en verdi på 9 for en rating eller indeks, for eksempel, betyr at spilleren er bedre enn 90% av alle andre spillere i databasen innenfor en posisjonsgruppe, og er dermed blant de 10% beste spillerne.',
            'I praksis er det litt mer komplisert enn dette, ettersom ratinger er vektede snitt av metrikken som selv er normaliserte persentiler, og ettersom indekser er vektede snitt av ratinger som også justeres for liganivå. Du kan lese mer om hva verdiene betyr lenger ned på siden, og du kan også ta kontakt med oss for en ordentlig gjennomgang sammen av hvordan verdiene regnes ut og hva de betyr.'
          ],
          'se': [
            'Värdet för alla ratingar och index går från 0 till 10, där 10 är bäst. Enkelt uttryckt kan vi säga att ett värde på 9 för en rating eller index, till exempel, betyder att spelaren är bättre än 90% av alla andra spelare i databasen inom en position, och är därmed bland de 10% bästa spelarna.',
            'I praktiken är det lite mer komplicerat än så här, eftersom ratingar är viktade genomsnitt av metriker som i sig är normaliserade percentiler, och eftersom index är viktade genomsnitt av ratingar som också justeras för ligornas nivå. Du kan läsa mer om vad värdena betyder längre ner på sidan, och du kan också kontakta oss för en ordentlig genomgång tillsammans av hur värdena beräknas och vad de betyder.'
          ],
          'dk': [
            'Værdien for alle ratings og indekser går fra 0 til 10, hvor 10 er bedst. Enkelt sagt kan vi sige, at en værdi på 9 for en rating eller indeks, for eksempel, betyder, at spilleren er bedre end 90% af alle andre spillere i databasen inden for en position, og er dermed blandt de 10% bedste spillere.',
            'I praksis er det lidt mere kompliceret end dette, da ratings er vægtede gennemsnit af metrikker, der selv er normaliserede percentiler, og da indekser er vægtede gennemsnit af ratings, der også er justeret for ligakvalitet. Du kan læse mere om, hvad værdierne betyder længere nede på siden, og du kan også kontakte os for en ordentlig gennemgang sammen af, hvordan værdierne beregnes, og hvad de betyder.'
          ],
        }
      },
      {
        'title': {
          'en': 'How do I create my own evaluation criteria for the reports?',
          'no': 'Hvordan lager jeg egne vurderingskriterier for rapportene?',
          'se': 'Hur skapar jag mina egna utvärderingskriterier för rapporterna?',
          'dk': 'Hvordan opretter jeg mine egne evalueringskriterier for rapporterne?',
        },
        'text': {
          'en': [
            'The evaluation criteria in the reports can be customized to your club, but you cannot do this yourself from Fokus. If you know which criteria the club wants, you can just contact us and send us the new criteria, and they will appear in Fokus shortly. If you are unsure which criteria the club wants, you can also just contact us, and we can develop customized criteria together.'
          ],
          'no': [
            'Vurderingskriteriene i rapportene kan tilpasses din klubb, men du kan ikke gjøre dette selv fra Fokus. Dersom du vet hvilke kriteier klubben ønsker, kan du bare ta kontakt med oss og sende oss de nye kriterene, så vil de dukke opp i Fokus innen kort tid. Dersom du er usikker på hvilke kriterer klubben ønsker, kan du også bare ta kontakt, og så kan vi utarbeide tilpassede kriterier sammen.'
          ],
          'se': [
            'Bedömningskriterierna i rapporterna kan anpassas till din klubb, men du kan inte göra detta själv från Fokus. Om du vet vilka kriterier klubben vill ha kan du helt enkelt kontakta oss och skicka oss de nya kriterierna så dyker de upp i Fokus inom kort. Om du är osäker på vilka kriterier klubben vill ha kan du också bara höra av dig, så kan vi arbeta fram skräddarsydda kriterier tillsammans.'
          ],
          'dk': [
            'Vurderingskriterierne i rapporterne kan tilpasses din klub, men det kan du ikke selv fra Fokus. Hvis du ved hvilke kriterier klubben ønsker, kan du blot kontakte os og sende os de nye kriterier, så dukker de op i Fokus inden for kort tid. Er du usikker på, hvilke kriterier klubben ønsker, kan du også bare tage kontakt, så kan vi sammen udarbejde skræddersyede kriterier.'
          ],
        }
      },
      {
        'title': {
          'en': 'How do I add new accounts?',
          'no': 'Hvordan legger jeg til en ny bruker?',
          'se': 'Hur lägger jag till nya konton?',
          'dk': 'Hvordan tilføjer jeg nye konti?',
        },
        'text': {
          'en': [
            'You need admin access to add users and manage the access of existing users. Normally, the sporting director in the club will have this access, but other users can also have this access if desired. To change who has admin access, you need to contact us.',
            'If you have admin access, you can add new users by going to Settings and Club, and then clicking on the plus icon next to Accounts.',
            'When you create an account, you will be able to choose what access the user should have, and you will be able to read more about what the different options entails. If the club wants other accesses, you can contact us.'
          ],
          'no': [
            'Du trenger en egen admin-tilgang for å kunne legge til brukere og styre tilgangen til eksisterende brukere. Normalt vil sportssjefen i klubben ha denne tilgangen, men andre brukere kan også ha denne tilgangen dersom klubben ønsker det. For å endre hvem som har admin-tilgang, må du ta kontakt med oss.',
            'Dersom du har admin-tilgang kan du legge til nye brukere ved å gå til Innstillinger og Klubb, og så trykke på pluss-ikonet ved siden av Kontoer.',
            'Når du oppretter en bruker vil du kunne velge hvilken tilgang brukeren skal ha, og du vil kunne lese mer om hva de ulike tilgangene innebærer. Dersom klubben ønsker andre tilganger, kan dere ta kontakt med oss.'
          ],
          'se': [
            'Du behöver en separat administratörsbehörighet för att kunna lägga till användare och hantera åtkomst till befintliga användare. Normalt kommer sportchefen i klubben att ha denna tillgång, men även andra användare kan ha denna tillgång om klubben så önskar. För att ändra vem som har administratörsbehörighet måste du kontakta oss.',
            'Om du har administratörsbehörighet kan du lägga till nya användare genom att gå till Inställningar och Klubb och sedan klicka på plustecknet bredvid Konton.',
            'När du skapar ett konto kan du välja vilken åtkomst användaren ska ha, och du kan läsa mer om vad de olika alternativen innebär. Om klubben vill ha andra åtkomster kan du kontakta oss.'
          ],
          'dk': [
            'Du skal have en separat administratoradgang for at kunne tilføje brugere og administrere adgang til eksisterende brugere. Normalt vil sportschefen i klubben have denne adgang, men andre brugere kan også få denne adgang, hvis klubben ønsker det. For at ændre, hvem der har administratoradgang, skal du kontakte os.',
            'Hvis du har administratoradgang, kan du tilføje nye brugere ved at gå til Indstillinger og Klub og derefter klikke på plus-ikonet ved siden af Konti.',
            'Når du opretter en konto, kan du vælge, hvilken adgang brugeren skal have, og du kan læse mere om, hvad de forskellige muligheder indebærer. Hvis klubben ønsker andre adgange, kan du kontakte os.'
          ],
        }
      },
      {
        'title': {
          'en': 'How do I display contract lengths, age or other metrics directly next to a player in a logistic team or my Own team?',
          'no': 'Hvordan viser jeg kontraktslengder, alder eller annen informasjon direkte ved siden av en spiller i et logistikklag eller mitt Eget lag?',
          'se': 'Hur visar jag kontraktslängder, ålder eller annan information direkt bredvid en spelare i ett logistiklag eller mitt Eget lag?',
          'dk': 'Hvordan viser jeg kontraktlængder, alder eller anden information direkte ved siden af en spiller i et logistikhold eller mit Eget hold?',
        },
        'text': {
          'en': [
            'To choose to display extra information, click on the Team menu button to the right of the team you are looking at. Read more about the team menu further down the page.',
          ],
          'no': [
            'For å velge å vise ekstra informasjon klikker du på Lagmeny-knappen til høyre for laget du ser på. Les mer om lagmenyen lenger ned på siden.',
          ],
          'se': [
            'För att välja att visa extra information klickar du på Lagmeny-knappen till höger om laget du tittar på. Läs mer om lagmenyn längre ner på sidan.',
          ],
          'dk': [
            'For at vælge at vise ekstra information skal du klikke på Holdmenu-knappen til højre for holdet, du ser på. Læs mere om holdmenuen længere nede på siden.',
          ],
        }
      },

    ]
  },


  'teamsAndSquads': {
    'title': {
      'en': 'Teams and squads',
      'no': 'Lag og staller',
      'se': 'Lag och trupper',
      'dk': 'Hold og trupper',
    },
    'subsections': [
      {
        'title': {
          'en': 'Scout teams, Shadow team and Master team',
          'no': 'Speiderlag, Skyggelag og Masterlag',
          'se': 'Scoutlag, Skyggelag och Masterlag',
          'dk': 'Spejderteam, Skyggelag og Masterlag',
        },
        'text': {
          'en': [
            'The Scout teams, Shadow team and Master team constitute the logistics teams, and are designed to make it easy for the club to keep track of transfer targets and interesting players. To maintain a tidy structure, a player can only exist on one team at a time (with the exception of Squad Builder, which you can read about further down the page).',
            'Scout teams _ The club\'s first filter for interesting players. The club can create its own teams, such as separate teams for each scout in the club, or other categories like specific geographies or age groups.',
            'Shadow team _ When the club determines that a player should be closely monitored, the player is moved to the Shadow Team. These are players the club wants to see live and where it is natural to document matches through reports. Ideally, the club should have at least two alternatives for each position at any time, and preferably even more players in the most critical positions.',
            'Master team _ The Master Team is intended to be filled with players the club has decided to try to acquire in the near future. The players in this team can be viewed as the players that do not require further scouting.',
            'Player flow _ Players can be moved between teams by changing the team status within the player view, or by using the name search to the left in the logistics teams. Each player and each logistics team also has a log, describing who changed the team status and when this happened.',
          ],
          'no': [
            'Speiderlag, Skyggelag og Masterlag utgjør logistikklagene, og er designet for å gjøre det enkelt for klubben å holde oversikt over overgangsmål og interessante spillere. For å opprettholde en ryddig struktur kan en spiller kun eksistere på ett lag om gangen (med unntak av Stallbygger, som du kan lese om litt lenger ned på siden).',
            'Speiderlag _ Kategorisering og strukturering av interessante spillere.\nKlubben kan selv opprette egne lag, som for eksempel dedikerte lag til hver speider i klubben, eller andre kategorier som spesifikke geografier eller aldersgrupper.',
            'Skyggelag _ Spillere klubben følger tett.\nKlubben ser gjerne spillerne live, og det er naturlig å dokumentere kamper gjennom rapporter. Ideelt sett har klubben til enhver tid minst to alternativer for hver posisjon, og gjerne flere spillere på posisjoner som er mer prekære.',
            'Masterlag _ Spillere klubben har bestemt seg for å hente.\nMasterlaget er tiltenkt å fylles med spillere klubben forsøker å hente i nærmeste fremtid. Masterlaget kan ses på som spillerne klubben er ferdig med å speide.',
            'Flyt av spillere _ Spillere kan flyttes mellom lag ved å endre lagstatus inne på spilleren, eller ved navnesøket til venstre inne på lagene. Hver spiller og hvert lag har også en logg, med informasjon om hvem som endret lagstatus og når dette skjedde.',
          ],
          'se': [
            'Scoutlag, Skyggelag och Masterlag utgör logistiklagen och är utformade för att göra det enkelt för klubben att hålla koll på övergångsmål och intressanta spelare. För att bibehålla en snygg struktur kan en spelare endast existera på ett lag åt gången (med undantag för Stallbyggare, som du kan läsa om längre ner på sidan).',
            'Scoutlag _ Klubbens första filter för intressanta spelare.\nKlubben kan skapa egna lag, som separata lag för varje scout i klubben, eller andra kategorier som specifika geografier eller åldersgrupper.',
            'Skyggelag _ När klubben bestämmer att en spelare ska följas noga flyttas spelaren till Skyggelaget. Detta är spelare klubben vill se live och där det är naturligt att dokumentera matcher genom rapporter. Idealt sett bör klubben ha minst två alternativ för varje position vid varje tidpunkt, och helst ännu fler spelare på de mest kritiska positionerna.',
            'Masterlag _ Masterlaget är avsett att fyllas med spelare som klubben har bestämt sig för att försöka förvärva inom en snar framtid. Spelarna i detta lag kan ses som spelare som inte kräver ytterligare scoutning.',
            'Spelarflöde _ Spelare kan flyttas mellan lag genom att ändra lagstatus i spelarvyn, eller genom att använda namnsökningen till vänster i logistiklagen. Varje spelare och varje logistiklag har också en logg som beskriver vem som ändrade lagstatus och när detta hände.',
          ],
          'dk': [
            'Spejderteam, Skyggelag og Masterlag udgør logistikholdene og er designet til at gøre det nemt for klubben at holde styr på transfermål og interessante spillere. For at opretholde en ryddig struktur kan en spiller kun eksistere på ét hold ad gangen (med undtagelse af Holdbygger, som du kan læse mere om længere nede på siden).',
            'Spejderteam _ Klubbens første filter for interessante spillere.\nKlubben kan oprette sine egne hold, såsom separate hold til hver spejder i klubben eller andre kategorier som specifikke geografier eller aldersgrupper.',
            'Skyggelag _ Når klubben beslutter, at en spiller skal overvåges tæt, flyttes spilleren til Skyggelaget. Disse er spillere, klubben ønsker at se live, og hvor det er naturligt at dokumentere kampe gennem rapporter. Ideelt set bør klubben have mindst to alternativer for hver position på ethvert tidspunkt og helst endnu flere spillere på de mest kritiske positioner.',
            'Masterlag _ Masterlaget er beregnet til at blive fyldt med spillere, klubben har besluttet at forsøge at erhverve i den nærmeste fremtid. Spillerne i dette hold kan ses som spillere, der ikke kræver yderligere scouting.',
            'Spillerflow _ Spillere kan flyttes mellem hold ved at ændre holdstatus i spilleroversigten eller ved hjælp af navnesøgningen til venstre i logistikholdene. Hver spiller og hvert logistikhold har også en log, der beskriver, hvem der ændrede holdstatus og hvornår dette skete.',
          ],
        }
      },
      {
        'title': {
          'en': 'Own team',
          'no': 'Eget lag',
          'se': 'Eget lag',
          'dk': 'Eget hold',
        },
        'text': {
          'en': [
            'The Own team should contain all players in the club, and is designed to provide an overview of the club\'s players and current squad composition. The team will not be updated automatically with new signings or sales, and must therefore be updated manually. Only users with full access can add and remove players.',
            'Table _ The table is a flexible tool that provides a clear summary of the squad, and that lets you easily input data on your players and sort the squad by different criteria. You can also filter players in the table by clicking on the filter icon in the top left corner, and thus list and summarize players that meet different criteria.',
            'Matrix _ The matrix provides an overview of the player\'s age distribution and playing time, and how these are related. You will also be able to see other information from the table up against age and playing time. The matrix lets you visualize the entire squad, and can be used to diagnose and communicate strengths and weaknesses in the squad composition.',
            'Academy _ The Academy team is intended to contain players from the club\'s academy, so you can keep track of the talents in the club and involve academy players when planning the squad. You can choose whether you want to add all players from the academy, but our recommendation is to add the biggest talents that you think have the potential to take the next step. Most academy players will probably not be in the database, but it is possible to create your own players without data (you can read more about how to do this further down the page).',
          ],
          'no': [
            'Eget lag skal inneholde alle spillerne i klubben, og er designet for å gi en oversikt over egne spillere og nåværende stallsammensetning. Laget vil ikke oppdateres automatisk ved nye signeringer eller salg, og må derfor oppdateres manuelt. Det er kun brukere med full tilgang som kan legge til og fjerne spillere.',
            'Tabell _ Tabellen er et fleksibelt verktøy som gir deg en oversiktlig oppsummering av stallen, og som enkelt lar deg legge inn data på egne spillere og sortere stallen etter ulike kriterier. Du kan også filtrere spillere i tabellen ved å klikke på filter-ikonet øverst til venstre, og dermed liste opp og oppsummere spillere som tilfredsstiller ulike kriterier.',
            'Matrise _ Matrisen gir en oversikt over aldersfordelingen og spilletiden til spillerne, og hvordan disse henger sammen. Du vil også kunne se annen informasjon fra tabellen opp mot alder og spilletid. Matrisen lar deg visualisere hele stallen, og kan brukes til å diagnostisere og formidle styrker og svakheter ved stallsammensetningen.',
            'Akademi _ Akademi-laget er tiltenkt å inneholde spillere fra klubbens akademi, slik at dere kan holde oversikt talentene i klubben og involvere spillere fra akademiet når dere planlegger stallen. Dere velger selv om dere ønsker å legge inn alle spillere fra akademiet, men vår anbefaling er å legge inn de største talentene som dere tror kan ha potensialet til å ta steget opp. De fleste spillerne fra akademiet vil trolig ikke finnes i databasen, men det er mulig å opprette egne spillere uten data (du kan lese mer om hvordan du gjør dette lenger ned på siden).',
          ],
          'se': [
            'Eget lag ska innehålla alla spelare i klubben och är utformat för att ge en översikt över klubbens spelare och nuvarande truppsammansättning. Laget kommer inte att uppdateras automatiskt med nya värvningar eller försäljningar och måste därför uppdateras manuellt. Endast användare med full åtkomst kan lägga till och ta bort spelare.',
            'Tabell _ Tabellen är ett flexibelt verktyg som ger en tydlig sammanfattning av truppen och som låter dig enkelt mata in data om dina spelare och sortera truppen efter olika kriterier. Du kan också filtrera spelare i tabellen genom att klicka på filterikonen i det övre vänstra hörnet och därmed lista och summera spelare som uppfyller olika kriterier.',
            'Matris _ Matrisen ger en översikt över spelarnas åldersfördelning och speltid och hur dessa hänger samman. Du kommer också att kunna se annan information från tabellen mot ålder och speltid. Matrisen låter dig visualisera hela truppen och kan användas för att diagnostisera och kommunicera styrkor och svagheter i truppsammansättningen.',
            'Akademi _ Akademilaget är avsett att innehålla spelare från klubbens akademi, så att du kan hålla koll på talangerna i klubben och involvera akademispelare när du planerar truppen. Du kan välja om du vill lägga till alla spelare från akademin, men vår rekommendation är att lägga till de största talangerna som du tror har potential att ta nästa steg. De flesta akademispelare kommer förmodligen inte att finnas i databasen, men det är möjligt att skapa egna spelare utan data (du kan läsa mer om hur du gör detta längre ner på sidan).',
          ],
          'dk': [
            'Eget hold skal indeholde alle spillere i klubben og er designet til at give et overblik over klubbens spillere og nuværende trup. Holdet vil ikke blive opdateret automatisk med nye signeringer eller salg og skal derfor opdateres manuelt. Kun brugere med fuld adgang kan tilføje og fjerne spillere.',
            'Tabel _ Tabellen er et fleksibelt værktøj, der giver et klart overblik over truppen og lader dig nemt indtaste data om dine spillere og sortere truppen efter forskellige kriterier. Du kan også filtrere spillere i tabellen ved at klikke på filterikonet i øverste venstre hjørne og dermed liste og opsummere spillere, der opfylder forskellige kriterier.',
            'Matrix _ Matricen giver et overblik over spillerens aldersfordeling og spilletid og hvordan disse hænger sammen. Du vil også kunne se anden information fra tabellen op mod alder og spilletid. Matricen lader dig visualisere hele truppen og kan bruges til at diagnosticere og kommunikere styrker og svagheder i trupsammensætningen.',
            'Akademi _ Akademiholdet er beregnet til at indeholde spillere fra klubbens akademi, så du kan holde styr på talenterne i klubben og involvere akademispillere, når du planlægger truppen. Du kan vælge, om du vil tilføje alle spillere fra akademiet, men vores anbefaling er at tilføje de største talenter, som du tror har potentiale til at tage det næste skridt. De fleste akademispillere vil sandsynligvis ikke være i databasen, men det er muligt at oprette dine egne spillere uden data (du kan læse mere om, hvordan du gør dette længere nede på siden).',
          ],
        }
      },
      {
        'title': {
          'en': 'Squad Builder',
          'no': 'Stallbygger',
          'se': 'Truppbyggare',
          'dk': 'Truppbygger',
        },
        'text': {
          'en': [
            'Squad Builder is a flexible tool that allows the club to create squads where players can be added and removed without affecting other parts of the platform, like Own team or other logistics teams, or the player logs.',
            'One intended use case for Squad Builder is to use this to plan your own squad, by creating expected future squads, like Squad 2025 and Squad 2026. This way the club can safely and easily add and remove players from these squads without affecting the Own team, and thus plan based on how you expect the squad to develop. When you create a squad, you will have the option to load players from existing teams, like Own team.',
            'Other use cases include creating draft teams, for instance in preparation for a meeting with the coaching staff, or keeping track of your own players currently in contract negotiations.',
          ],
          'no': [
            'Stallbygger er et fleksibelt verktøy som lar klubben opprette staller hvor spillere kan legges til og fjerens uten at det påvirker resten av plattformen, som Eget lag eller andre logistikklag, eller historikken til spillerne.',
            'Et tiltenkt bruksområde for Stallbygger er å bruke dette til å planlegge egen stall, ved å opprette forventede staller frem i tid, som for eksempel Stall 2025 og Stall 2026. Da kan klubben trygt og enkelt legge til og fjerne spillere fra disse stallene uten at det påvirker Eget lag, og dermed planlegge utifra hvordan man forventer at stallen vil utvikle seg. Når du oppretter en stall, vil du ha muligheten til å laste inn spillere fra eksisterende lag, som Eget lag.',
            'Andre bruksområder er å opprette kladdelag, for eksempel før et møte med trenerteamet, eller å holde oversikt over egne spillere i kontraktsforhandlinger.',
          ],
          'se': [
            'Truppbyggaren är ett flexibelt verktyg som låter klubben skapa trupper där spelare kan läggas till och tas bort utan att påverka andra delar av plattformen, som Eget lag eller andra logistiklag, eller spelarloggar.',
            'Ett avsedd användningsområde för Truppbyggaren är att använda den för att planera din egen trupp genom att skapa förväntade framtida trupper, som Trupp 2025 och Trupp 2026. På så sätt kan klubben säkert och enkelt lägga till och ta bort spelare från dessa trupper utan att påverka Eget lag och därmed planera utifrån hur du förväntar dig att truppen ska utvecklas. När du skapar en trupp har du möjlighet att ladda in spelare från befintliga lag, som Eget lag.',
            'Andra användningsområden inkluderar att skapa draftlag, till exempel inför ett möte med tränarstaben, eller hålla koll på dina egna spelare som för närvarande förhandlar om kontrakt.',
          ],
          'dk': [
            'Truppbygger er et fleksibelt værktøj, der giver klubben mulighed for at oprette trupper, hvor spillere kan tilføjes og fjernes uden at påvirke andre dele af platformen, som Eget hold eller andre logistikhold, eller spillernes log.',
            'Et tænkt anvendelsesområde for Truppbygger er at bruge det til at planlægge din egen trup ved at oprette forventede fremtidige trupper, som f.eks. Trup 2025 og Trup 2026. På den måde kan klubben sikkert og nemt tilføje og fjerne spillere fra disse trupper uden at påvirke Eget hold og dermed planlægge ud fra, hvordan du forventer, at truppen vil udvikle sig. Når du opretter en trup, har du mulighed for at indlæse spillere fra eksisterende hold, som Eget hold.',
            'Andre anvendelsesområder inkluderer at oprette draft-hold, f.eks. i forberedelse til et møde med trænerstaben, eller holde styr på dine egne spillere, der i øjeblikket er i kontraktforhandlinger.',
          ],
        }
      },
      {
        'title': {
          'en': 'Team menu',
          'no': 'Lagmeny',
          'se': 'Lagmeny',
          'dk': 'Holdmenu',
        },
        'text': {
          'en': [
            'All teams and squads will have a team menu to the right that can be opened and closed. If you open the team menu, you will have the opportunity to control which information is displayed for each player in the team or squad. Some of the options also give you the opportunity to color or sort the players based on the selected information, by clicking on the icons to the right. You can choose to display multiple options at the same time, but you can only color and sort on one option at a time.',
            'The options selected from the team menu are stored for your user, and will not affect other users in the club. All logistics teams will share the same team menu, while the Own team and the Academy team will have their own team menu.',
          ],
          'no': [
            'Alle lag og staller vil ha en lagmeny til høyre som kan åpnes og lukkes. Dersom du åpner lagmenyen, vil du få muligheten til å kontrollere hvilken informasjon som vises for hver spiller inne på laget eller stallen. Noen av alternativene gir deg også muligheten til å fargelegge eller sortere spillerne på den valgte informasjonen, ved å klikke på ikonene til høyre. Du kan velge å vise flere alternativer samtidig, men du kan kun fargelegge og sortere på ett alternativ om gangen.',
            'Alternativene som velges fra lagmenyen lagres for din bruker, og vil ikke påvirke andre brukere i klubben. Alle logistikklag og staller vil dele sammme lagmeny, mens Eget lag og Akademi-laget har hver sin egen lagmeny.',
          ],
          'se': [
            'Alla lag och trupper kommer att ha en lagmeny till höger som kan öppnas och stängas. Om du öppnar lagmenyn får du möjlighet att styra vilken information som visas för varje spelare i laget eller truppen. Några av alternativen ger dig också möjlighet att färglägga eller sortera spelarna baserat på den valda informationen genom att klicka på ikonerna till höger. Du kan välja att visa flera alternativ samtidigt, men du kan bara färglägga och sortera på ett alternativ i taget.',
            'De alternativ som väljs från lagmenyn sparas för din användare och kommer inte att påverka andra användare i klubben. Alla logistiklag kommer att dela samma lagmeny, medan Eget lag och Akademilaget kommer att ha sin egen lagmeny.',
          ],
          'dk': [
            'Alle hold og trupper vil have en holdmenu til højre, som kan åbnes og lukkes. Hvis du åbner holdmenuen, får du mulighed for at styre, hvilken information der vises for hver spiller på holdet eller truppen. Nogle af mulighederne giver dig også mulighed for at farve eller sortere spillerne baseret på den valgte information ved at klikke på ikonerne til højre. Du kan vælge at vise flere muligheder på samme tid, men du kan kun farvelægge og sortere på én mulighed ad gangen.',
            'De valg, der er foretaget fra holdmenuen, gemmes for din bruger og vil ikke påvirke andre brugere i klubben. Alle logistikhold vil dele den samme holdmenu, mens Eget hold og Akademiholdet vil have deres egen holdmenu.',
          ],
        }
      },
      {
        'title': {
          'en': 'Archived teams',
          'no': 'Arkiverte lag',
          'se': 'Arkiverade lag',
          'dk': 'Arkiverede hold',
        },
        'text': {
          'en': [
            'In preparation for a transfer window, many players can accumulate. To avoid losing valuable work and to maintain a good overview, you can archive all teams and squads from the team menu, which in practice means saving a screenshot of previous work.',
            'Our recommendation is to carry out a cleanup after each completed transfer window by:',
            '1. Archiving all your relevant teams.',
            '2. Conducting a cleanup by either resetting the teams or removing individual players.',
            'If you want to look back at targets of previous transfer windows, you can browse through the club\'s archived teams in the Archive.'
          ],
          'no': [
            'Frem mot et overgangsvindu fyller det seg gjerne opp med mange spillere på ulike lag. For å unngå å miste verdifullt arbeid og samtidig holde god orden, kan du fra lagmenyen arkivere lag og staller og se tilbake på dem senere.',
            'Vår anbefaling er å gjennomføre en opprydning etter hvert fullførte overgangsvindu ved å:',
            '1. Arkivere alle relevante lag.',
            '2. Gjennomføre en opprydning, enten ved å nullstille lagene eller fjerne enkelspillere',
            'Hvis du ønsker å se tilbake på tidligere logistikkarbeid og gamle overgangsmål, blar du deg enkelte gjennom de daterte arkiverte lagene til klubben under Arkiv.'
          ],
          'se': [
            'Inför ett transferfönster kan många spelare ackumuleras. För att undvika att förlora värdefullt arbete och för att behålla en bra översikt kan du arkivera alla lag och trupper från lagmenyn, vilket i praktiken innebär att spara en skärmbild av tidigare arbete.',
            'Vår rekommendation är att genomföra en upprydningsåtgärd efter varje avslutat transferfönster genom att:',
            '1. Arkivera alla relevanta lag.',
            '2. Genomföra en upprydningsåtgärd genom att antingen återställa lagen eller ta bort enskilda spelare.',
            'Om du vill titta tillbaka på mål från tidigare transferfönster kan du bläddra igenom klubbens arkiverade lag i Arkivet.',
          ],
          'dk': [
            'I forberedelse til et transfervindue kan mange spillere akkumuleres. For at undgå at miste værdifuldt arbejde og for at opretholde et godt overblik kan du arkivere alle hold og trupper fra holdmenuen, hvilket i praksis betyder at gemme et skærmbillede af tidligere arbejde.',
            'Vores anbefaling er at gennemføre en oprydning efter hvert afsluttet transfervindue ved at:',
            '1. Arkivere alle relevante hold.',
            '2. Gennemføre en oprydning ved enten at nulstille holdene eller fjerne enkelte spillere.',
            'Hvis du ønsker at se tilbage på mål fra tidligere transfervinduer, kan du gennemse klubbens arkiverede hold i Arkivet.',
          ],
        }
      },
    ]
  },


  'scouting': {
    'title': {
      'en': 'Scouting',
      'no': 'Scouting',
      'se': 'Scouting',
      'dk': 'Scouting',
    },
    'subsections': [
      {
        'title': {
          'en': 'Player search',
          'no': 'Spillersøk',
          'se': 'Spelarsök',
          'dk': 'Spillersøgning',
        },
        'text': {
          'en': [
            'The player search is designed to provide an overview of the most interesting players in a market, and to search for specific names or clubs. You can easily sort players based on overall metrics of their underlying statistics, and you can quickly assess whether an incoming tip is worth following up. We like to call this the sports director search.',
            'If you click on the filter icon to the right, you will have the opportunity to choose even more filters, and you will also have the opportunity to save the search. To save the search, you must first configure the filters you want for the search, and then click on the save icon to the right.',
          ],
          'no': [
            'Spillersøket er designet for å gi en enkel oversikt over de mest interessante spillerne i et marked, og til å søke opp konkrete navn eller klubber. Du kan enkelt sortere spillere på overordnede mål basert på deres underliggende statistikk, og du kan raskt vurdere om et innkommende tips er verdt å følge videre. Vi liker å kalle dette for sportssjef-søket.',
            'Dersom du klikker på filter-ikonet til høyre vil du få muligheten til å velge enda flere filtre, og du vil også få muligheten til å lagre søket. For å lagre søket må du først konfigurere filtrene som du ønsker for søket, og deretter klikke på lagre-ikonet til høyre.',
          ],
          'se': [
            'Spelarsök är utformat för att ge en översikt över de mest intressanta spelarna på en marknad och för att söka efter specifika namn eller klubbar. Du kan enkelt sortera spelare baserat på övergripande mått på deras underliggande statistik och snabbt bedöma om ett inkommande tips är värt att följa upp. Vi kallar detta för sportchefssök.',
            'Om du klickar på filterikonen till höger får du möjlighet att välja ännu fler filter och du får också möjlighet att spara söket. För att spara söket måste du först konfigurera de filter du vill ha för söket och sedan klicka på sparaikonen till höger.',
          ],
          'dk': [
            'Spillersøgningen er designet til at give et overblik over de mest interessante spillere på et marked og til at søge efter specifikke navne eller klubber. Du kan nemt sortere spillere baseret på overordnede mål for deres underliggende statistik og hurtigt vurdere, om et indkommende tip er værd at følge op på. Vi kalder dette for sportsdirektørsøgningen.',
            'Hvis du klikker på filterikonet til højre, får du mulighed for at vælge endnu flere filtre, og du får også mulighed for at gemme søgningen. For at gemme søgningen skal du først konfigurere de filtre, du ønsker for søgningen, og derefter klikke på gemikonet til højre.',
          ],
        }
      },
      {
        'title': {
          'en': 'Season search',
          'no': 'Sesongsøk',
          'se': 'Säsongssök',
          'dk': 'Sæsonssøgning',
        },
        'text': {
          'en': [
            'The season search can be used to dive deeper into the statistics of players in selected seasons, and to filter and sort on specific metrics. Are you curious about which midfielders have been the best passers in a given season, or do you want to exclude fullbacks that are below average at dribbling, this is the search for you. We like to call this the scout search.',
            'If you click on the filter icon to the right, you will have the opportunity to choose even more filters, and you will also have the opportunity to save the search. To save the search, you must first configure the filters you want for the search, and then click on the save icon to the right.'
          ],
          'no': [
            'Sesongsøket kan brukes til å dykke dypere i statistikken til spillere i valgte sesonger, og til å filtrere og sortere på spesifikke metrikker. Er du nysgjerrige på hvilke midtbanespillere som har vært de beste pasningsspillerne i en gitt sesong, eller ønsker du å utelukke backer som er under middels til å drible, er dette søket for deg. Vi liker å kalle dette for speider-søket.',
            'Dersom du klikker på filter-ikonet til høyre vil du få muligheten til å velge enda flere filtre, og du vil også få muligheten til å lagre søket. For å lagre søket må du først konfigurere filtrene som du ønsker for søket, og deretter klikke på lagre-ikonet til høyre.'
          ],
          'se': [
            'Säsongssök kan användas för att fördjupa sig i spelarnas statistik i valda säsonger och för att filtrera och sortera på specifika mått. Är du nyfiken på vilka mittfältare som har varit de bästa passningsspelarna under en given säsong eller vill du utesluta ytterbackar som är under genomsnittet i dribbling, är detta söket för dig. Vi kallar detta för scout-sök.',
            'Om du klickar på filterikonen till höger får du möjlighet att välja ännu fler filter och du får också möjlighet att spara söket. För att spara söket måste du först konfigurera de filter du vill ha för söket och sedan klicka på sparaikonen till höger.'
          ],
          'dk': [
            'Sæsonssøgningen kan bruges til at dykke dybere ned i spillernes statistik i valgte sæsoner og til at filtrere og sortere på specifikke mål. Er du nysgerrig på, hvilke midtbanespillere der har været de bedste pasningsspillere i en given sæson, eller vil du udelukke backs, der er under gennemsnittet i driblinger, er dette søgningen for dig. Vi kalder dette for spejdersøgningen.',
            'Hvis du klikker på filterikonet til højre, får du mulighed for at vælge endnu flere filtre, og du får også mulighed for at gemme søgningen. For at gemme søgningen skal du først konfigurere de filtre, du ønsker for søgningen, og derefter klikke på gemikonet til højre.'
          ],
        }
      },
      {
        'title': {
          'en': 'Alerts',
          'no': 'Varsler',
          'se': 'Aviseringar',
          'dk': 'Alarmer',
        },
        'text': {
          'en': [
            'Alerts allow you to set up automatic player searches that notify you when new players meet your search criteria. When you create an alert search, all players who meet the criteria will immediately be added to the search. You can then remove players from the search, so that you can search through different markets and segments without seeing the same players at the top every time.',
            'To make the most of the alerts, we recommend that you create some smaller searches with stricter criteria, rather than one large search. We also recommend that you take the time in the beginning to go through your new searches and remove players who are not relevant, which will give you a better overview when new players start showing up in your alerts.',
          ],
          'no': [
            'Varlser lar deg sette opp automatiske spillersøk som varsler deg når nye spillere oppfyller søkekriteriene dine. Når du oppretter et varsel-søk, vil alle spillere som oppfyller kriteriene umiddelbart bli lagt til i søket. Deretter kan du fjerne spillere fra søket, slik at du får søkt gjennom ulike markeder og segmenter uten å få opp de samme spillerne øverst hver gang.',
            'For å få utnyttet varslene best mulig, anbefaler vi at du heller oppretter flere mindre søk med strengere kriterier, enn ett stort søk. Vi anbefaler også at du tar deg tiden i starten til å gå gjennom de nye søkene dine og fjerne spillere som ikke er aktuelle, slik at du får bedre oversikt når nye spillere begynner å dukke opp i varslene dine.',
          ],
          'se': [
            'Aviseringar låter dig ställa in automatiska spelarsökningar som meddelar dig när nya spelare uppfyller dina sökkriterier. När du skapar en aviseringssökning kommer alla spelare som uppfyller kriterierna omedelbart att läggas till i sökningen. Du kan sedan ta bort spelare från sökningen så att du kan söka igenom olika marknader och segment utan att se samma spelare högst upp varje gång.',
            'För att få ut mesta möjliga av aviseringarna rekommenderar vi att du skapar några mindre sökningar med strängare kriterier istället för en stor sökning. Vi rekommenderar också att du tar dig tid i början att gå igenom dina nya sökningar och ta bort spelare som inte är relevanta, vilket ger dig bättre översikt när nya spelare börjar dyka upp i dina aviseringar.',
          ],
          'dk': [
            'Alarmer giver dig mulighed for at oprette automatiske spillersøgninger, der giver dig besked, når nye spillere opfylder dine søgekriterier. Når du opretter en alarm-søgning, vil alle spillere, der opfylder kriterierne, straks blive tilføjet til søgningen. Du kan derefter fjerne spillere fra søgningen, så du kan søge gennem forskellige markeder og segmenter uden at se de samme spillere øverst hver gang.',
            'For at få mest muligt ud af alarmerne anbefaler vi, at du opretter nogle mindre søgninger med strammere kriterier i stedet for én stor søgning. Vi anbefaler også, at du tager dig tid i begyndelsen til at gennemgå dine nye søgninger og fjerne spillere, der ikke er relevante, hvilket giver dig et bedre overblik, når nye spillere begynder at dukke op i dine alarmer.',
          ],
        }
      },
      {
        'title': {
          'en': 'Reports',
          'no': 'Rapporter',
          'se': 'Rapporter',
          'dk': 'Rapporter',
        },
        'text': {
          'en': [
            'Reports are an important part of the club\'s scouting work, and are used to document information and evaluations. By submitting reports on players, the club can easily share observations, and build up a databaseof qualitative evaluations that can be used in future transfer windows. The format of the reports can be adapted to your club, so please contact us if you want other evaluation criteria than what is standard in Fokus.',
            'Match report _ The purpose of the match report is to document a player in a single match, through free text and evaluation criteria. We naturally encourage the club to watch several matches live to form the best possible decision base.',
            'Scouting report _ The scouting report is a more general report used to document a player over time. This report should be a scout\'s composite assessment of a player in a decision-making process. It is the evaluations in these reports that form the basis for the average stars shown in the player profile. It is also possible to upload PDF attachments as part of the scout report.',
            'Comment _ Comments can be used to add information that does not fit into the predefined evaluation criteria. For example, the club may want to add information about agent situations, impressions of personality or other things that are important for the club\'s evaluation of the player.',
            'Attachment _ It is also possible to upload your own PDF attachments in a separate report. This can be useful if you have existing information that you want to keep, or if you have your own forms that you want to upload and that do not naturally fit as part of a scouting report.',
          ],
          'no': [
            'Rapporter er en viktig del av klubbens speiderarbeid og brukes til å dokumentere informasjon og vurderinger. Ved å legge inn rapporter på spillere kan klubben enkelt dele observasjoner og bygge opp en database av kvalitative vurderinger som kan brukes i fremtidige overgangsvinduer. Formatet på rapportene kan tilpasses din klubb, så det er bare å ta kontakt dersom dere ønsker andre vurderingskriterier enn de som er standard i Fokus.',
            'Kamprapport _ Hensikten med kamprapporten er å dokumentere en spiller i en enkeltkamp, gjennom fritekst og vurderingskriterier. Vi oppfordrer naturligvis klubben til å se flere kamper live for å danne et best mulig beslutningsgrunnlag.',
            'Speiderrapport _ Speiderrapporten er en mer overordnet rapport som brukes til å dokumentere en spiller over tid. Speiderrapporten skal være en speiders sammensatte vurdering av en spiller i en beslutningsprosess. Det er vurderingene i disse rapportene som danner grunnlaget for de gjennomsnittlige stjernene som vises i spillerprofilen. Det er også mulig å laste opp PDF-vedlegg som en del av speiderrapporten.',
            'Kommentar _ Kommentarer kan brukes til å legge til informasjon som ikke passer inn i de forhåndsdefinerte vurderingskriteriene. For eksempel ønsker klubben kanskje å legge til informasjon om agentsituasjon, inntrykk av personlighet eller andre ting som er viktig for klubbens vurdering av spilleren.',
            'Vedlegg _ Det er også mulig å laste opp ett eller flere PDF-vedlegg som en egen rapport. Dette kan være nyttig om dere har eksisterende informasjon som dere ønsker å ta vare på, eller om dere har egne skjemaer som dere ønsker å laste opp og som ikke faller naturlig inn under en speiderrapport.',
          ],
          'se': [
            'Rapporter är en viktig del av klubbens scoutingsarbete och används för att dokumentera information och utvärderingar. Genom att lämna in rapporter om spelare kan klubben enkelt dela observationer och bygga upp en databas av kvalitativa utvärderingar som kan användas i framtida transferfönster. Formatet på rapporterna kan anpassas till din klubb, så kontakta oss om du vill ha andra utvärderingskriterier än vad som är standard i Fokus.',
            'Matchrapport _ Syftet med matchrapporten är att dokumentera en spelare i en enskild match, genom fritext och utvärderingskriterier. Vi uppmuntrar naturligtvis klubben att se flera matcher live för att bilda det bästa möjliga beslutsunderlaget.',
            'Scoutrapport _ Scoutrapporten är en mer generell rapport som används för att dokumentera en spelare över tid. Denna rapport ska vara en scouts sammansatta bedömning av en spelare i en beslutsprocess. Det är utvärderingarna i dessa rapporter som utgör grunden för de genomsnittliga stjärnorna som visas i spelarprofilen. Det är också möjligt att ladda upp PDF-bilagor som en del av scoutrapporten.',
            'Kommentar _ Kommentarer kan användas för att lägga till information som inte passar in i de fördefinierade utvärderingskriterierna. Till exempel kan klubben vilja lägga till information om agentsituationer, intryck av personlighet eller andra saker som är viktiga för klubbens utvärdering av spelaren.',
            'Bilaga _ Det är också möjligt att ladda upp egna PDF-bilagor i en separat rapport. Detta kan vara användbart om du har befintlig information som du vill behålla eller om du har egna formulär som du vill ladda upp och som inte naturligt passar som en del av en scoutrapport.',
          ],
          'dk': [
            'Rapporter er en vigtig del af klubbens scoutingarbejde og bruges til at dokumentere information og evalueringer. Ved at indsende rapporter om spillere kan klubben nemt dele observationer og opbygge en database af kvalitative evalueringer, der kan bruges i fremtidige transfervinduer. Formatet på rapporterne kan tilpasses din klub, så kontakt os, hvis du ønsker andre evalueringskriterier end dem, der er standard i Fokus.',
            'Kamp rapport _ Formålet med kamp rapporten er at dokumentere en spiller i en enkelt kamp, gennem fri tekst og evalueringskriterier. Vi opfordrer naturligvis klubben til at se flere kampe live for at danne det bedst mulige beslutningsgrundlag.',
            'Scouting rapport _ Scouting rapporten er en mere generel rapport, der bruges til at dokumentere en spiller over tid. Denne rapport bør være en scouts sammensatte vurdering af en spiller i en beslutningsproces. Det er evalueringerne i disse rapporter, der danner grundlaget for de gennemsnitlige stjerner, der vises i spillerprofilen. Det er også muligt at uploade PDF-vedhæftninger som en del af scouting rapporten.',
            'Kommentar _ Kommentarer kan bruges til at tilføje information, der ikke passer ind i de foruddefinerede evalueringskriterier. For eksempel kan klubben ønske at tilføje information om agent situationer, indtryk af personlighed eller andre ting, der er vigtige for klubbens evaluering af spilleren.',
            'Vedhæftning _ Det er også muligt at uploade dine egne PDF-vedhæftninger i en separat rapport. Dette kan være nyttigt, hvis du har eksisterende information, du ønsker at beholde, eller hvis du har dine egne formularer, du ønsker at uploade, og som ikke naturligt passer som en del af en scouting rapport.',
          ],
        }
      },
    ]
  },


  'models': {
    'title': {
      'en': 'Models',
      'no': 'Modeller',
      'se': 'Modeller',
      'dk': 'Modeller',
    },
    'subsections': [
      {
        'title': {
          'en': 'On-ball-value (OBV)',
          'no': 'On-ball-value (OBV)',
          'se': 'On-ball-value (OBV)',
          'dk': 'On-ball-value (OBV)',
        },
        'text': {
          'en': [
            'What is OBV _ OBV is a Possession-Value model that uses machine learning techniques (AI) to evaluate all actions, and assigns each action a value based on how the action affects the team\'s chances of scoring and conceding.',
            'Common metrics like Expected Goals and Expected Assists are centered around finishes, which make up less than 2% of actions in a match. To evaluate individual skills of players, we have developed a model that considers ~2500 actions in each match, from passes to dribbles to tackles.',
            'Imagine a game of chess. If a chess player makes a brilliant move, he has put himself in a better position to win the game, even though he did not necessarily win the game with that move. We use the same logic to evaluate actions on the football field. Consider the following situation: A center-back makes a perfect thourgh pass to a forward on the run, who dribbles past the last defender and then finishes the shot out wide alone with the goalkeeper. The pass and the dribble naturally increased the probability of scoring, and the model assigns positive values to these actions regardless of whether the shot went in. The shot, however, will likely receive a negative value.',
            'Data quality _ A model is never better than the quality of the data, and our model is based on event data from German IMPECT. Unlike Wyscout, for example, IMPECT tags Bypassed Opponents in data collection, representing the differance in number of opponents between the ball and the opposition goal before and after every action. This gives the model valuable context to actions that are crucial for accuracy.',
            'Literature _ Our model has drawn inspiration from the general VAEP framework, which was published as part of a PhD thesis in 2020.\nA standalone publication of VAEP from the preceeding year can be read here: <link>https://www.janvanhaaren.be/assets/papers/kdd-2019-vaep.pdf\nA more high-level explanation with animations can be read her: <link>https://dtai.cs.kuleuven.be/sports/vaep'
          ],
          'no': [
            'Hva er OBV? _ OBV er en Possession-Value modell som bruker maskinlæringsteknikker (AI) til å vurdere alle handlinger, og tildeler hver handling en verdi basert på hvordan handlingen påvirker lagets sjanser for å score og slippe inn mål.',
            'Utbredte metrikker som Expected Goals og Expected Assists er sentrert rundt avslutninger, som utgjør mindre enn 2% av handlingene i en kamp. For å evaluere individuelle ferdigheter hos spillere har vi utviklet en modell som ser på ~2500 handlinger i hver kamp, fra pasninger til driblinger til taklinger.',
            'Se for deg et sjakkparti. Om en sjakkspiller gjennomfører et genialt trekk har han satt seg selv i en bedre posisjon til å vinne partiet, uten at han nødvendigvis vant partiet med dette trekket. Vi bruker samme logikk for å evaluere handlinger på fotballbanen, ved å vurdere om handlingene setter laget i bedre posisjoner til å score mål i nær fremtid, uavhenging av om det faktisk blir scoret mål eller ikke. Vi kan se for oss følgende situasjon: En midtstopper slår en perfekt gjennombruddspasning til en spiss på løp, som så dribler seg forbi siste forsvarer før spissen setter ballen skyhøyt over mål alene med keeper. Modellen vil da tilegne en høy positiv verdi til midtstopperen for pasningen, og en høy positiv verdi til driblingen, selv om sjansen ikke resulterte i scoring. Avslutningen, derimot, vil trolig bli tilegnet en negativ verdi.',
            'Hva bruker vi OBV til? _ OBV gir oss en ny måte å evaluere individuelle ferdigheter på. I Fokus bruker vi den for eksempel aktivt til å si noe om hvilke midstoppere som skaper mest verdi i pasningsspillet, eller hvilke backer som er best til å dra av mann.',
            'Datakvalitet _ En modell blir aldri bedre enn kvaliteten på dataen, og vår modell er basert på event-data fra tyske IMPECT. I motsetning til for eksempel Wyscout, tagger IMPECT Bypassed Opponents i datainnsamlingen, som representerer differansen mellom antall motspillere mellom ballen og motstanderens mål før og etter alle handlinger. Dette gir modellen verdifull kontekst til handlingene som er avgjørende for treffsikkerheten.',
            'Litteratur _ Modellen vår har tatt inspirasjon fra det generelle rammverket VAEP, som ble ble publisert som en del av en doktorgrad i 2020.\nEn egen publikasjon om VAEP fra året før kan leses her: <link>https://www.janvanhaaren.be/assets/papers/kdd-2019-vaep.pdf\nEn mer overordnet forklaring med animasjoner kan leses her: <link>https://dtai.cs.kuleuven.be/sports/vaep'
          ],
          'se': [
            'Vad är OBV? _ OBV är en Possession-Value modell som använder maskininlärningstekniker (AI) för att utvärdera alla handlingar och tilldela varje handling ett värde baserat på hur handlingen påverkar lagets chanser att göra mål och släppa in mål.',
            'Vanliga metriker som Expected Goals och Expected Assists är centrerade kring avslut, som utgör mindre än 2% av handlingarna i en match. För att utvärdera individuella färdigheter hos spelare har vi utvecklat en modell som ser på ~2500 handlingar i varje match, från passningar till dribblingar till tacklingar.',
            'Tänk dig ett schackparti. Om en schackspelare gör ett briljant drag har han satt sig själv i en bättre position att vinna partiet, även om han inte nödvändigtvis vann partiet med det draget. Vi använder samma logik för att utvärdera handlingar på fotbollsplanen, genom att bedöma om handlingarna sätter laget i bättre positioner att göra mål i nära framtid, oavsett om det faktiskt görs mål eller inte. Vi kan tänka oss följande situation: En mittback slår en perfekt genomskärare till en forward på löp, som sedan dribblar förbi sista försvararen innan forwarden skjuter bollen högt över mål ensam med målvakten. Modellen kommer då att tilldela ett högt positivt värde till mittbacken för passningen och ett högt positivt värde för dribblingen, även om chansen inte resulterade i mål. Skottet kommer dock troligen att tilldelas ett negativt värde.',
            'Datakvalitet _ En modell är aldrig bättre än kvaliteten på data, och vår modell är baserad på event-data från tyska IMPECT. Till skillnad från till exempel Wyscout, taggar IMPECT Bypassed Opponents i datainsamlingen, vilket representerar skillnaden i antal motståndare mellan bollen och motståndarmålet före och efter varje handling. Detta ger modellen värdefull kontext till handlingar som är avgörande för träffsäkerheten.',
            'Litteratur _ Vår modell har hämtat inspiration från det generella ramverket VAEP, som publicerades som en del av en doktorsavhandling 2020.\nEn fristående publikation om VAEP från föregående år kan läsas här: <link>https://www.janvanhaaren.be/assets/papers/kdd-2019-vaep.pdf\nEn mer övergripande förklaring med animationer kan läsas här: <link>https://dtai.cs.kuleuven.be/sports/vaep'
          ],
          'dk': [
            'Hvad er OBV? _ OBV er en Possession-Value model, der bruger maskinlæringsteknikker (AI) til at evaluere alle handlinger og tildele hver handling en værdi baseret på, hvordan handlingen påvirker holdets chancer for at score og indkassere mål.',
            'Almindelige metrikker som Expected Goals og Expected Assists er centreret omkring afslutninger, som udgør mindre end 2% af handlingerne i en kamp. For at evaluere individuelle færdigheder hos spillere har vi udviklet en model, der ser på ~2500 handlinger i hver kamp, fra afleveringer til driblinger til tacklinger.',
            'Forestil dig et skakparti. Hvis en skakspiller laver et genialt træk, har han sat sig selv i en bedre position til at vinde partiet, selvom han ikke nødvendigvis vandt partiet med det træk. Vi bruger samme logik til at evaluere handlinger på fodboldbanen. Overvej følgende situation: En midterforsvarer laver en perfekt gennemskærende pasning til en angriber på løb, som dribler forbi den sidste forsvarer og derefter skyder bolden langt over mål alene med målmanden. Pasningen og driblingen øgede naturligt sandsynligheden for at score, og modellen tildeler positive værdier til disse handlinger, uanset om skuddet gik i mål. Skuddet vil dog sandsynligvis modtage en negativ værdi.',
            'Datakvalitet _ En model er aldrig bedre end datakvaliteten, og vores model er baseret på eventdata fra tyske IMPECT. I modsætning til Wyscout tagger IMPECT Bypassed Opponents i dataindsamlingen, hvilket repræsenterer forskellen i antallet af modstandere mellem bolden og modstandermålet før og efter hver handling. Dette giver modellen værdifuld kontekst til handlinger, der er afgørende for nøjagtigheden.',
            'Litteratur _ Vores model har hentet inspiration fra det generelle VAEP-rammeværk, som blev offentliggjort som en del af en ph.d.-afhandling i 2020.\nEn selvstændig publikation af VAEP fra det foregående år kan læses her: <link>https://www.janvanhaaren.be/assets/papers/kdd-2019-vaep.pdf\nEn mere overordnet forklaring med animationer kan læses her: <link>https://dtai.cs.kuleuven.be/sports/vaep'
          ],
        }
      },
      {
        'title': {
          'en': 'Position groups',
          'no': 'Posisjonsgrupper',
          'se': 'Positiongrupper',
          'dk': 'Positiongrupper',
        },
        'text': {
          'en': [
            'All data for a player is first calculated for each position group based on what the player has done in the position or positions that are part of the group.',
            'In football, there are small nuances that determine which position a player should be classified as. For example, a player can be on the field for 90 minutes as a left winger, 90 minutes as a right winger, and 90 minutes as a left attacking midfielder. In this case, the data basis is minimal per position, even though the player has played three full matches in similar roles. To handle this issue, we therefore combine some positions into selected position groups, which in this case means that the player will have one set of data based on the 270 minutes he has played in similar roles.',
            'Another positive consequence of the position groups is that more players will have a sufficient data basis to be included in the pool of players that all players are compared against. This makes the comparison, and thus the rankings and the final numbers, more stable and reliable.',
            'Players who have played in multiple position groups will also have a set of overall data based on everything the player has done. This data will then be a weighted average of the data from the different position groups based on how much the player has played in the different positions.',
            '<PositionGroupsTable/>',
          ],
          'no': [
            'All data for en spiller beregnes først for hver posisjonsgruppe basert på hva spilleren har gjort i den eller de posisjonene som inngår i gruppen.',
            'I fotballen er det små nyanser som avgjør hvilken posisjon en spiller teknisk sett bør klassifiseres som. For eksempel kan en spiller være på banen i 90 minutter som venstreving, 90 minutter som høyreving og 90 minutter som venstre offensiv midtbane. I dette tilfellet blir datagrunnlaget minimalt per posisjon, selv om spilleren har spilt tre fulle kamper i lignende roller. For å håndtere denne problemstillingen, slår vi derfor sammen noen posisjoner til utvalgte posisjonsgrupper, som i dette tilfellet betyr at spilleren vil ha ett sett med data basert på de 270 minuttene han har spilt i lignende roller.',
            'En annen positiv konsekvens av posisjonsgruppene er at flere spillere vil ha et tilstrekkelig datagrunnlag til å bli inkludert i settet av spillere som alle spillere sammenlignes opp mot. Dette gjør at sammenligningen, og dermed rangeringene og de endelige tallene, blir mer stabile og pålitelige.',
            'Spillere som har spilt i flere posisjonsgrupper vil også få et sett med data basert på alt spilleren har gjort. Denne dataen blir da et vektet snitt av dataen fra de ulike posisjonsgruppene, vektet ut ifra hvor mye spilleren har spilt i de ulike posisjonene.',
            '<PositionGroupsTable/>',
          ],
          'se': [
            'All data för en spelare beräknas först för varje positiongrupp baserat på vad spelaren har gjort i den eller de positioner som ingår i gruppen.',
            'I fotbollen finns små nyanser som avgör vilken position en spelare tekniskt sett bör klassificeras som. Till exempel kan en spelare vara på planen i 90 minuter som vänsterforward, 90 minuter som högerforward och 90 minuter som vänster offensiv mittfältare. I detta fall blir datagrunden minimal per position, även om spelaren har spelat tre fulla matcher i liknande roller. För att hantera detta problem slår vi därför samman vissa positioner till utvalda positiongrupper, vilket i detta fall innebär att spelaren kommer att ha en uppsättning data baserad på de 270 minuterna han har spelat i liknande roller.',
            'En annan positiv konsekvens av positiongrupperna är att fler spelare kommer att ha en tillräcklig datagrund för att inkluderas i gruppen av spelare som alla spelare jämförs mot. Detta gör att jämförelsen, och därmed rankingarna och de slutliga siffrorna, blir mer stabila och pålitliga.',
            'Spelare som har spelat i flera positiongrupper kommer också att ha en uppsättning övergripande data baserad på allt spelaren har gjort. Denna data blir sedan ett vägt genomsnitt av datan från de olika positiongrupperna baserat på hur mycket spelaren har spelat på de olika positionerna.',
            '<PositionGroupsTable/>',
          ],
          'dk': [
            'Alle data for en spiller beregnes først for hver positiongruppe baseret på, hvad spilleren har gjort i den eller de positioner, der indgår i gruppen.',
            'I fodbold er der små nuancer, der afgør, hvilken position en spiller teknisk set bør klassificeres som. For eksempel kan en spiller være på banen i 90 minutter som venstre kant, 90 minutter som højre kant og 90 minutter som venstre offensiv midtbanespiller. I dette tilfælde er datagrundlaget minimalt per position, selvom spilleren har spillet tre hele kampe i lignende roller. For at håndtere dette problem slår vi derfor nogle positioner sammen til udvalgte positiongrupper, hvilket i dette tilfælde betyder, at spilleren vil have et sæt data baseret på de 270 minutter, han har spillet i lignende roller.',
            'En anden positiv konsekvens af positiongrupperne er, at flere spillere vil have et tilstrækkeligt datagrundlag til at blive inkluderet i gruppen af spillere, som alle spillere sammenlignes mod. Dette gør sammenligningen, og dermed rangeringerne og de endelige tal, mere stabile og pålidelige.',
            'Spillere, der har spillet i flere positiongrupper, vil også have et sæt overordnet data baseret på alt, hvad spilleren har gjort. Disse data vil derefter være et vægtet gennemsnit af data fra de forskellige positiongrupper baseret på, hvor meget spilleren har spillet på de forskellige positioner.',
            '<PositionGroupsTable/>',
          ],
        }
      },
      {
        'title': {
          'en': 'Percentiles and normalisation',
          'no': 'Persentiler og normalisering',
          'se': 'Percentiler och normalisering',
          'dk': 'Percentiler og normalisering',
        },
        'text': {
          'en': [
            'Player metrics are compared against all players in our database within the same position group. The greener the color scale, the higher the ranking. All metrics are also normalized per 90 minutes played, except for percentage values. You can click on all metrics to get more information on what the metric represents.',
            'Percentile calculation _ Percentile values can be interpreted as follows: A value of 9 for a metric means that the player for a given season has performed better than 90% of all seasons for all players in the database within a position group, and thus top 10%.',
            'Comparison across leagues _ We always compare players with all other players in the same position group, also across leagues, to make the percentiles more stable. We can imagine a season where the top scoring striker in the league scores 25 goals, while the second on the list scores 10 goals. The player in second place is in practice among the top 2% of strikers in the league if we assume hundred strikers. We would rather have a scale that captures the big difference in the number of goals for the two strikers. This is one of several reasons why we always compare thousands of players.',
            'Though players are compared across leagues, this does not mean that a winger in the Eliteserien who scores 8 in Dribble OBV is necessarily as good as a winger in the Premier League with the same value. The same percentile, however, describes that the two players dominate their respective leagues to a similar extent. Our indexes are the ony values that adjust for competition levels.'
          ],
          'no': [
            'Dataen til spillere sammenlignes med alle spillere i vår database innenfor samme posisjonsgruppe. Jo grønnere fargeskala, jo høyere rangering. Alle metrikker er også normalisert per 90 spilte minutter, med unntak av prosentverdier. Du kan klikke på alle metrikker for å få opp mer informasjon om hva metrikken betyr.',
            'Persentilberegning _ Persentilverdiene kan tolkes som følger: En verdi på 9 for en metrikk betyr at spilleren for en gitt sesong har prestert bedre enn 90% av alle sesonger til alle spillere i databasen innenfor en posisjonsgruppe, og dermed er topp 10%.',
            'Sammenligning på tvers av ligaer _ Vi sammenligner alltid spillerne med alle spillere i samme posisjonsgruppe som har tilstrekkelig datagrunnlag, også på tvers av ligaer, for å gjøre sammenligningen mer stabil. Vi kan se for oss en sesong hvor den mestscorende spissen i ligaen scorer 25 mål, mens nummer to på listen scorer 10 mål. Spilleren på andreplass er i praksis blant de 2% beste spissene i ligaen om vi forutsetter hundre spisser. Vi ønsker oss derimot heller en skala som fanger opp at det er stor forskjell på antall mål scoret for disse to spissene. Dette er en av flere grunner til at vi alltid sammenligner tusenvis av spillere.',
            'Selv om spillere sammenlignes på tvers av ligaer, betyr ikke dette at en ving i Eliteserien som scorer 8 i Dribble OBV nødvendigvis er like god som en ving i Premier League med samme verdi. Samme persentil beskriver derimot at de to spillerne dominerer ligaene sine i omtrent like stor grad. Det er kun indekser som inkluderer en ligavekting.'
          ],
          'se': [
            'Spelardata jämförs med alla spelare i vår databas inom samma positiongrupp. Ju grönare färgskala, desto högre ranking. Alla metriker är också normaliserade per 90 spelade minuter, förutom procentvärden. Du kan klicka på alla metriker för att få mer information om vad metriken representerar.',
            'Percentilberäkning _ Percentilvärden kan tolkas enligt följande: Ett värde på 9 för en metrik betyder att spelaren för en given säsong har presterat bättre än 90% av alla säsonger för alla spelare i databasen inom en positiongrupp, och därmed topp 10%.',
            'Jämförelse över ligor _ Vi jämför alltid spelare med alla andra spelare i samma positiongrupp, även över ligor, för att göra percentilerna mer stabila. Vi kan tänka oss en säsong där den mest målskyttande anfallaren i ligan gör 25 mål, medan tvåan på listan gör 10 mål. Spelaren på andra plats är i praktiken bland de 2% bästa anfallarna i ligan om vi antar hundra anfallare. Vi föredrar dock en skala som fångar upp den stora skillnaden i antalet mål för de två anfallarna. Detta är en av flera anledningar till att vi alltid jämför tusentals spelare.',
            'Även om spelare jämförs över ligor betyder det inte att en ytter i Allsvenskan som får 8 i Dribble OBV nödvändigtvis är lika bra som en ytter i Premier League med samma värde. Samma percentil beskriver dock att de två spelarna dominerar sina respektive ligor i liknande grad. Våra index är de enda värdena som justerar för konkurrensnivåer.'
          ],
          'dk': [
            'Spillerdata sammenlignes med alle spillere i vores database inden for samme positiongruppe. Jo grønnere farveskala, jo højere rangering. Alle metrikker er også normaliseret per 90 spillede minutter, undtagen procentværdier. Du kan klikke på alle metrikker for at få mere information om, hvad metrikken repræsenterer.',
            'Percentilberegning _ Percentilværdier kan tolkes som følger: En værdi på 9 for en metrik betyder, at spilleren for en given sæson har præsteret bedre end 90% af alle sæsoner for alle spillere i databasen inden for en positiongruppe, og dermed top 10%.',
            'Sammenligning på tværs af ligaer _ Vi sammenligner altid spillere med alle andre spillere i samme positiongruppe, også på tværs af ligaer, for at gøre percentilerne mere stabile. Vi kan forestille os en sæson, hvor den mest scorende angriber i ligaen scorer 25 mål, mens nummer to på listen scorer 10 mål. Spilleren på andenpladsen er i praksis blandt de 2% bedste angribere i ligaen, hvis vi antager hundrede angribere. Vi foretrækker dog en skala, der fanger den store forskel i antallet af mål for de to angribere. Dette er en af flere grunde til, at vi altid sammenligner tusindvis af spillere.',
            'Selvom spillere sammenlignes på tværs af ligaer, betyder det ikke, at en kantspiller i Superligaen, der scorer 8 i Dribble OBV, nødvendigvis er lige så god som en kantspiller i Premier League med samme værdi. Den samme percentil beskriver dog, at de to spillere dominerer deres respektive ligaer i en lignende grad. Vores indekser er de eneste værdier, der justerer for konkurrenceniveauer.'
          ],
        }
      },
      {
        'title': {
          'en': 'Ratings and Indexes',
          'no': 'Ratinger og Indekser',
          'se': 'Ratingar och Index',
          'dk': 'Ratinger og Indekser',
        },
        'text': {
          'en': [
            'Our vision is to help clubs filter out the most relevant players for the club, so that you can use resources to follow up the players that give the most value. To do this, we have, also in collaboration with your club, developed various ratings and indexes that help you compare players. The difference between the two is that ratings are based on a single season, while indexes are based on ratings from multiple seasons.',
            'Ratings _ Each player in the database with event data is first evaluated for a given season in a given club. The rating is a weighted sum of selected metrics from this season, where the selection and weighting are based on which position group the player has played in, and what you want to capture with the rating. You can click on the ratings of a player to see a more detailed explanation of what the rating contains.',
            'Indexes _ Indexes are a weighted average of a player\'s ratings over multiple seasons, where recent seasons and seasons with more playing time are weighted more. Performances in tougher leagues are also weighted higher. The main use of the indexes is to compress a player\'s performances into a single value, so that you can easily filter out and compare players across seasons and leagues. You can click on the indexes of a player to see a more detailed explanation of what the index contains.',
            'The value of all ratings and indexes ranges from 0 to 10, and represents a ranking among all players in the database within a position group.',
          ],
          'no': [
            'Fokus sin visjon er å hjelpe klubber med å filtrere ut de mest aktuelle spillerne for klubben, slik at dere kan bruke ressurser på å følge opp de spillerne som gir mest verdi. For å gjøre dette har vi, også i samarbeid med din klubb, utviklet ulike ratinger og indekser som hjelper dere med å sammenligne spillere. Forskjellen på de to er at ratinger er basert på en enkelt sesong, mens indekser er basert på ratinger fra flere sesonger.',
            'Ratinger _ Hver spiller i databasen med event-data vurderes først for en gitt sesong i en gitt klubb. Ratingen er en vektet sum av utvalgte metrikker fra denne sesongen, der utvalget og vektingen er basert på hvilken posisjonsgruppe spilleren har spilt i, og hva man ønsker å fange opp med ratingen. Du kan klikke på ratingene til en spiller for å se en mer detaljert forklaring på hva ratingen inneholder.',
            'Indekser _ Indekser er et vektet snitt av ratingene til en spiller over flere sesonger, der nylige sesonger og sesonger med mer spilletid vektes mer. Prestasjoner i tøffere ligaer vektlegges også høyere. Det viktigste bruksområdet for indeksene er å samle en spillers prestasjoner til en enkelt verdi, slik at dere enkelt kan filtrere ut og sammenligne spillere på tvers av sesonger og ligaer. Du kan klikke på indeksene til en spiller for å se en mer detaljert forklaring på hva indeksen inneholder.',
            'Verdien på alle ratinger og indekser går fra 0 til 10, og representerer en rangering blant alle spillere i databasen innenfor en posisjonsgruppe.',
          ],
          'se': [
            'Vår vision är att hjälpa klubbar att filtrera ut de mest relevanta spelarna för klubben, så att ni kan använda resurser för att följa upp de spelare som ger mest värde. För att göra detta har vi, även i samarbete med er klubb, utvecklat olika ratingar och index som hjälper er att jämföra spelare. Skillnaden mellan de två är att ratingar är baserade på en enskild säsong, medan index är baserade på ratingar från flera säsonger.',
            'Ratingar _ Varje spelare i databasen med event-data utvärderas först för en given säsong i en given klubb. Ratingen är en viktad summa av utvalda metriker från denna säsong, där urvalet och vikten baseras på vilken positiongrupp spelaren har spelat i, och vad ni vill fånga upp med ratingen. Du kan klicka på en spelares ratingar för att få en mer detaljerad förklaring av vad ratingen innehåller.',
            'Index _ Index är ett viktat genomsnitt av en spelares ratingar över flera säsonger, där nyligen säsonger och säsonger med mer speltid viktas mer. Prestandor i tuffare ligor viktas också högre. Huvudanvändningen av index är att komprimera en spelares prestationer till ett enda värde, så att ni enkelt kan filtrera ut och jämföra spelare över säsonger och ligor. Du kan klicka på en spelares index för att få en mer detaljerad förklaring av vad indexet innehåller.',
            'Värdet på alla ratingar och index går från 0 till 10, och representerar en ranking bland alla spelare i databasen inom en positiongrupp.',
          ],
          'dk': [
            'Vores vision er at hjælpe klubber med at filtrere de mest relevante spillere for klubben, så I kan bruge ressourcer til at følge op på de spillere, der giver mest værdi. For at gøre dette har vi, også i samarbejde med jeres klub, udviklet forskellige ratings og indekser, der hjælper jer med at sammenligne spillere. Forskellen mellem de to er, at ratings er baseret på en enkelt sæson, mens indekser er baseret på ratings fra flere sæsoner.',
            'Ratinger _ Hver spiller i databasen med event-data evalueres først for en given sæson i en given klub. Ratingen er en vægtet sum af udvalgte metrikker fra denne sæson, hvor udvælgelsen og vægtningen er baseret på, hvilken positiongruppe spilleren har spillet i, og hvad I ønsker at fange med ratingen. Du kan klikke på en spillers ratinger for at få en mere detaljeret forklaring af, hvad ratingen indeholder.',
            'Indekser _ Indekser er et vægtet gennemsnit af en spillers ratinger over flere sæsoner, hvor nylige sæsoner og sæsoner med mere spilletid vægtes mere. Præstationer i hårdere ligaer vægtes også højere. Hovedformålet med indekserne er at komprimere en spillers præstationer til en enkelt værdi, så I nemt kan filtrere og sammenligne spillere på tværs af sæsoner og ligaer. Du kan klikke på en spillers indekser for at få en mere detaljeret forklaring af, hvad indekset indeholder.',
            'Værdien af alle ratinger og indekser går fra 0 til 10 og repræsenterer en rangering blandt alle spillere i databasen inden for en positiongruppe.',
          ],
        }
      },
      {
        'title': {
          'en': 'Skill rating and Skill index',
          'no': 'Skillrating og Skillindeks',
          'se': 'Skillrating och Skillindex',
          'dk': 'Skillrating og Skillindeks',
        },
        'text': {
          'en': [
            'Skill rating and Skill index are general measures of a player\'s performance level, where the metrics and weights are chosen by Fokus. The difference between the two is that skill rating is based on a single season, while skill index is based on multiple seasons and includes a league weighting.',
            'Skill rating _ Skill rating is an overall measure of a player\'s performance level in a given season in a given club. The rating is a weighted sum of selected metrics, where the selection and weighting are based on which position group the player has played in. You can click on a player\'s skill rating to see a more detailed explanation of what the rating contains.',
            'Skill index _ Skill index is a weighted average of a player\'s skill ratings over multiple seasons, where recent seasons and seasons with more playing time are weighted more. Performances in tougher leagues are also weighted higher. If a player has played in multiple position groups, the overall skill index will be a weighted average of the index for the different position groups. The main use of the skill index is to compress a player\'s performances into a single value, so that you can easily filter out and compare players across seasons and leagues.',
            'The value of all ratings and indexes ranges from 0 to 10, and represents a ranking among all players in the database within a position group.',
          ],
          'no': [
            'Skillrating og skillindeks er generelle mål på prestasjonsnivået til en spiller, der utvalget og vektingen av metrikker er valgt av Fokus. Forskjellen på de to er at skillrating er basert på en enkelt sesong, mens skillindeks er basert på flere sesonger og inkluderer en ligavekting.',
            'Skillrating _ Skillrating er et overordnet mål på prestasjonsnivået til spilleren i en gitt sesong i en gitt klubb. Ratingen er en vektet sum av utvalgte metrikker, der utvalget og vektingen er basert på hvilken posisjonsgruppe spilleren har spilt i. Du kan klikke på skillratingen til en spiller for å se en mer detaljert forklaring på hva ratingen inneholder.',
            'Skillindeks _ Skillindeks er et vektet snitt av skillratingene til en spiller over flere sesonger, der nylige sesonger og sesonger med mer spilletid vektes mer. Prestasjoner i tøffere ligaer vektlegges også høyere. Om en spiller har spilt i flere posisjonsgrupper, vil den overordnede skillindeksen være et vektet snitt av indeksen for de ulike posisjonsgruppene. Det viktigste bruksområdet for skillindeksen er å samle en spillers prestasjoner til en enkelt verdi, slik at dere enkelt kan filtrere ut og sammenligne spillere på tvers av sesonger og ligaer.',
            'Verdien på alle ratinger og indekser går fra 0 til 10, og representerer en rangering blant alle spillere i databasen innenfor en posisjonsgruppe.',
          ],
          'se': [
            'Skillrating och skillindex är generella mått på en spelares prestandanivå, där metrikerna och vikterna väljs av Fokus. Skillnaden mellan de två är att skillrating är baserad på en enskild säsong, medan skillindex är baserad på flera säsonger och inkluderar en ligaviktning.',
            'Skillrating _ Skillrating är ett övergripande mått på en spelares prestandanivå under en given säsong i en given klubb. Ratingen är en viktad summa av utvalda metriker, där urvalet och vikten baseras på vilken positiongrupp spelaren har spelat i. Du kan klicka på en spelares skillrating för att få en mer detaljerad förklaring av vad ratingen innehåller.',
            'Skillindex _ Skillindex är ett viktat genomsnitt av en spelares skillratingar över flera säsonger, där nyligen säsonger och säsonger med mer speltid viktas mer. Prestandor i tuffare ligor viktas också högre. Om en spelare har spelat i flera positiongrupper kommer det övergripande skillindexet att vara ett viktat genomsnitt av indexet för de olika positiongrupperna. Huvudanvändningen av skillindexet är att komprimera en spelares prestationer till ett enda värde, så att ni enkelt kan filtrera ut och jämföra spelare över säsonger och ligor.',
            'Värdet på alla ratingar och index går från 0 till 10, och representerar en ranking bland alla spelare i databasen inom en positiongrupp.',
          ],
          'dk': [
            'Skillrating og skillindeks er generelle mål på en spillers præstationsniveau, hvor metrikkerne og vægtene er valgt af Fokus. Forskellen mellem de to er, at skillrating er baseret på en enkelt sæson, mens skillindeks er baseret på flere sæsoner og inkluderer en ligavægtning.',
            'Skillrating _ Skillrating er et overordnet mål på en spillers præstationsniveau i en given sæson i en given klub. Ratingen er en vægtet sum af udvalgte metrikker, hvor udvælgelsen og vægtningen er baseret på, hvilken positiongruppe spilleren har spillet i. Du kan klikke på en spillers skillrating for at få en mere detaljeret forklaring af, hvad ratingen indeholder.',
            'Skillindeks _ Skillindeks er et vægtet gennemsnit af en spillers skillratinger over flere sæsoner, hvor nylige sæsoner og sæsoner med mere spilletid vægtes mere. Præstationer i hårdere ligaer vægtes også højere. Hvis en spiller har spillet i flere positiongrupper, vil det overordnede skillindeks være et vægtet gennemsnit af indexet for de forskellige positiongrupper. Hovedformålet med skillindekset er at komprimere en spillers præstationer til en enkelt værdi, så I nemt kan filtrere og sammenligne spillere på tværs af sæsoner og ligaer.',
            'Værdien af alle ratinger og indekser går fra 0 til 10 og repræsenterer en rangering blandt alle spillere i databasen inden for en positiongruppe.',
          ],
        }
      },
      {
        'title': {
          'en': 'Roles',
          'no': 'Roller',
          'se': 'Roller',
          'dk': 'Roller',
        },
        'text': {
          'en': [
            'In Fokus, your club will have the opportunity to define its own roles for the different position groups, where a role consists of a selection of metrics and a weighting among these. The roles that the club defines will be the basis for all role ratings and role indexes in Fokus.',
            'Role ratings and role indexes are calculated in the same way as skill rating and skill index, with the exception of the metrics and weighting, which are developed in collaboration with your club. The roles allow the club to compare and sort players based on its own criteria and which skills the club emphasizes, and can also be compared to skill rating and skill index to assess whether players fit particularly well with your club.',
            'Under Settings and Roles you will find an overview of all the roles defined for your club. You can click on each role to get a detailed overview of which metrics are included in the role, and how they are weighted. You can also click on all role ratings and role indexes inside a player or in a player table to see a detailed overview of what the role contains.'
          ],
          'no': [
            'I Fokus vil klubben din ha muligheten til å definere egne roller for de ulike posisjonsgruppene, der en rolle består av et utvalg metrikker og en vekting blant disse. Rollene som klubben definerer vil være utgangspunktet for alle rolleratinger og rolleindekser i Fokus.',
            'Rolleratinger og rolleindekser beregnes på samme måte som skillrating og skillindeks, med unntak av metrikkene og vektigen, som er utarbeidet i samarbeid med din klubb. Rollene lar klubben sammenligne og sortere spillere spillere basert på egne kriterier og hvilke ferdigheter klubben vektlegger, og kan også sammenlignes opp mot skillrating og skilllindeks for å vurdere om spillere passer spesielt godt til klubben din.',
            'Under Innstillinger og Roller vil du finne en oversikt over alle rollene som er definert for klubben din. Du kan klikke på hver rolle for å få opp en detajlert oversikt over hvilke metrikker som inngår i rollen, og hvordan de er vektet. Du kan også klikke på alle rolleratinger og rolleindekser inne på en spiller eller i en spillertabell for å se en detaljert oversikt over hva rollen inneholder.'
          ],
          'se': [
            'I Fokus kommer erbjuda din klubb möjligheten att definiera sina egna roller för de olika positiongrupperna, där en roll består av ett urval metriker och en viktning bland dessa. Rollerna som klubben definierar kommer att vara grunden för alla rollratingar och rollindex i Fokus.',
            'Rollratingar och rollindex beräknas på samma sätt som skillrating och skillindex, med undantag för metrikerna och vikten, som utvecklas i samarbete med din klubb. Rollerna låter klubben jämföra och sortera spelare baserat på sina egna kriterier och vilka färdigheter klubben betonar, och kan också jämföras med skillrating och skillindex för att bedöma om spelare passar särskilt bra med din klubb.',
            'Under Inställningar och Roller hittar du en översikt över alla roller som är definierade för din klubb. Du kan klicka på varje roll för att få en detaljerad översikt över vilka metriker som ingår i rollen, och hur de är viktade. Du kan också klicka på alla rolleratingar och rollindex i en spelare eller i en spelartabell för att se en detaljerad översikt över vad rollen innehåller.'
          ],
          'dk': [
            'I Fokus vil din klub have mulighed for at definere egne roller for de forskellige positiongrupper, hvor en rolle består af et udvalg metrikker og en vægtning blandt disse. Rollerne, som klubben definerer, vil være udgangspunktet for alle rolleratings og rolleindekser i Fokus.',
            'Rolleratings og rolleindekser beregnes på samme måde som skillrating og skillindeks, med undtagelse af metrikkerne og vægtningen, som er udviklet i samarbejde med din klub. Rollerne giver klubben mulighed for at sammenligne og sortere spillere baseret på egne kriterier og hvilke færdigheder klubben vægter, og kan også sammenlignes med skillrating og skillindeks for at vurdere, om spillere passer særligt godt til din klub.',
            'Under Indstillinger og Roller finder du et overblik over alle roller, der er defineret for din klub. Du kan klikke på hver rolle for at få et detaljeret overblik over, hvilke metrikker, der indgår i rollen, og hvordan de er vægtet. Du kan også klikke på alle rolleratings og rolleindekser i en spiller eller i en spiller tabel for at se et detaljeret overblik over, hvad rollen indeholder.'
          ],
        }
      },
      {
        'title': {
          'en': metricToDisplayInfo['confidence'].name.en,
          'no': metricToDisplayInfo['confidence'].name.no,
          'se': metricToDisplayInfo['confidence'].name.se,
          'dk': metricToDisplayInfo['confidence'].name.dk,
        },
        'text': {
          'en': [
            metricToDisplayInfo['confidence'].description.en
          ],
          'no': [
            metricToDisplayInfo['confidence'].description.no
          ],
          'se': [
            metricToDisplayInfo['confidence'].description.se
          ],
          'dk': [
            metricToDisplayInfo['confidence'].description.dk
          ],
        }
      },
      {
        'title': {
          'en': 'Form and Playing time',
          'no': 'Form og Spilletid',
          'se': 'Form och Speltid',
          'dk': 'Form og Spilletid',
        },
        'text': {
          'en': [
            'Form is a measure of the player\'s overall performance level recently, while playing time represents the recent playing time in the domestic league. Both form and playing time belong to the player\'s overall set of data, and not to each position group. For players who have only played one position group, this group will reflect the player\'s overall data. For players with multiple position groups, the combination of these will constitute the player\'s overall set of data.',
            'Form _ ' + translate('formInfo', 'en'),
            'Spilletid _ ' + translate('playingTimeInfo', 'en'),
          ],
          'no': [
            'Form er et mål på spillerens overordnede prestasjonsnivå i nyere tid, mens spilletid representerer spilletiden i hjemlig liga i nyere tid. Både form og spilletid hører til spillerens overordnede sett med data, og ikke til hver posisjonsgruppe. For spillere som kun har spilt en posisjonsgruppe, vil denne gruppen gjenspeile spillerens overordnede data. For spillere med flere posisjonsgrupper, vil kombinasjonen av disse utgjøre spillerens overordnede sett med data.',
            'Form _ ' + translate('formInfo', 'no'),
            'Spilletid _ ' + translate('playingTimeInfo', 'no'),
          ],
          'se': [
            'Form är ett mått på spelarens övergripande prestandanivå nyligen, medan speltid representerar den senaste speltiden i den inhemska ligan. Både form och speltid tillhör spelarens övergripande uppsättning data, och inte varje positiongrupp. För spelare som endast har spelat en positiongrupp kommer denna grupp att återspegla spelarens övergripande data. För spelare med flera positiongrupper kommer kombinationen av dessa att utgöra spelarens övergripande uppsättning data.',
            'Form _ ' + translate('formInfo', 'se'),
            'Speltid _ ' + translate('playingTimeInfo', 'se'),
          ],
          'dk': [
            'Form er et mål for spillerens generelle præstationsniveau for nylig, mens spilletid repræsenterer den seneste spilletid i den hjemlige liga. Både form og spilletid hører til spillerens generelle sæt data og ikke til hver positiongruppe. For spillere, der kun har spillet en positiongruppe, vil denne gruppe afspejle spillerens generelle data. For spillere med flere positiongrupper vil kombinationen af disse udgøre spillerens generelle sæt data.',
            'Form _ ' + translate('formInfo', 'dk'),
            'Spilletid _ ' + translate('playingTimeInfo', 'dk'),
          ],
        }
      },
    ]
  },


  'stats': {
    'title': {
      'en': 'Stats',
      'no': 'Stats',
      'se': 'Statistik',
      'dk': 'Statistik',
    },
    'subsections': [
      // This section will be automatically generated based on playerMetrics.ts
      // Every metric that is part of either the outfieldSeasonTable or goalkeeperSeasonTable will be described here
      // Support.tsx will construct the subsections and provide the correct text to SupportAccordion.tsx
    ],
  },


  'contact': {
    'title': {
      'en': 'Contact us',
      'no': 'Kontakt oss',
      'se': 'Kontakta oss',
      'dk': 'Kontakt os',
    },
    'subsections': [
      {
        'title': {
          'en': 'Questions, suggestions, or feedback?',
          'no': 'Spørsmål, forslag eller tilbakemeldinger?',
          'se': 'Frågor, förslag eller feedback?',
          'dk': 'Spørgsmål, forslag eller feedback?',
        },
        'text': {
          'en': [
            'If you have any questions or feedback, please reach out to us at lars.gundersen@fokus.ing, markus.kim@fokus.ing or eliot.strobel@fokus.ing.'
          ],
          'no': [
            'Vi er takknemlige for tilbakemeldinger, og du kan når som helst kontakte oss på lars.gundersen@fokus.ing, markus.kim@fokus.ing eller eliot.strobel@fokus.ing.'
          ],
          'se': [
            'Vi är tacksamma för feedback och du kan kontakta oss när som helst på lars.gundersen@fokus.ing, markus.kim@fokus.ing eller eliot.strobel@fokus.ing.'
          ],
          'dk': [
            'Vi er taknemmelige for feedback, og du kan altid kontakte os på lars.gundersen@fokus.ing, markus.kim@fokus.ing eller eliot.strobel@fokus.ing.'
          ],
        }
      }
    ]
  },
};
