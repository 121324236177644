import './mobilePlayerView.css';

import { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { getClubColor } from '../../../static/clubConfigs';
import { translate } from '../../../../common/language/translations';
import { tabIcons, tabOptions } from './MobilePlayerView';


export type MobilePlayerViewTab =
  | 'overview'
  | 'career'
  | 'reports'


interface MobilePlayerViewTabsProps {
  activeTab: MobilePlayerViewTab;
  setActiveTab: (tab: MobilePlayerViewTab) => void;
  tabLineOffset: number;
}

export const MobilePlayerViewTabs: React.FC<MobilePlayerViewTabsProps> = ({
  activeTab,
  setActiveTab,
  tabLineOffset,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const clubColor = getClubColor(userConfig?.club ?? '');

  const tabRefs = useRef<(HTMLDivElement | null)[]>([]);


  return (
    <div className='mobile-player-view-tabs-container'>

      <div
        className='mobile-player-view-tab-line-container'
        style={{ left: tabLineOffset + '%' }}
      >
        <div
          className='mobile-player-view-tab-line'
          style={{ backgroundColor: clubColor }}
        >
          &nbsp;
        </div>
      </div>

      {tabOptions.map((tab, index) => {

        // the tab is either 1) enabled, 2) blocked (and disabled), or 3) locked (and disabled)
        // const tabIsEnabled = !playerViewTabOptionToStatus || !isStringTab || playerViewTabOptionToStatus[tabOption] === 'enabled';
        // const tabIsBlocked = playerViewTabOptionToStatus && isStringTab && playerViewTabOptionToStatus[tabOption] === 'blocked';
        // const tabIsLocked = playerViewTabOptionToStatus && isStringTab && playerViewTabOptionToStatus[tabOption] === 'locked';

        return (
          <div
            key={'mobilePlayerView-' + tab}
            ref={element => (tabRefs.current[index] = element)}
            className='mobile-player-view-tab'
            style={{ color: activeTab === tab ? clubColor : '#ffffffaa' }}
            onClick={() => setActiveTab(tab)}
          >
            <div className='mobile-player-view-tab-icon'>
              {tabIcons[index]}
            </div>
            <div className='mobile-player-view-tab-text'>
              {translate(tab, userConfig?.language)}
            </div>
          </div>
        );
      })}

    </div>
  );
};
