import './images.css';

import React, { useEffect, useRef, useState } from 'react';
import { playerImageLoader } from './playerImageLoader';


type PlayerImageProps = {
  src: string | undefined | null;
  alt?: string;
  size?: number;
  containerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  isSingleImage?: boolean; // If provided, we skip the queueing and load the image immediately
  isSearchTableComponent?: boolean;
};

export const PlayerImage: React.FC<PlayerImageProps> = ({
  src,
  alt,
  size,
  containerStyle,
  imageStyle,
  isSingleImage,
  isSearchTableComponent,
}) => {

  const srcToRender = src ?? 'https://media.api-sports.io/football/players/0.png';
  const treatAsSingleImage = isSingleImage || !src;

  const [isInView, setIsInView] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef<HTMLDivElement>(null);


  useEffect(() => {
    if (!treatAsSingleImage) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target);
          }
        });
      });

      if (imgRef.current) {
        observer.observe(imgRef.current);
      }

      return () => observer.disconnect();
    }
  }, [treatAsSingleImage]);


  useEffect(() => {
    if (isInView && !treatAsSingleImage) {
      playerImageLoader
        .addToQueue(srcToRender, isSearchTableComponent)
        .then(() => {
          setIsLoaded(true);
        })
        .catch(() => {
          setIsLoaded(false);
        });
    }
  }, [isInView, srcToRender, treatAsSingleImage, isSearchTableComponent]);


  return (
    <div
      ref={imgRef}
      className='player-image-container'
      style={{
        width: size,
        height: size,
        ...containerStyle,
      }}
    >
      {(isLoaded || treatAsSingleImage) && (
        <img
          src={srcToRender}
          alt={alt}
          className='player-image'
          style={{
            width: size,
            height: size,
            ...imageStyle,
          }}
          draggable={false}
        />
      )}
    </div>
  );
};
