import axios from 'axios';
import { getBearerToken } from '../token';
import { ANALYTICS_API_URL } from '../constants';
import { FirebaseUser } from '../../../../firebase';
import { EventName, EventPropertiesMap } from './events';


const currentEventVersion = 1;


export const trackEvent = async<T extends EventName>(
  eventName: T,
  eventProperties: EventPropertiesMap[T],
  currentUser: FirebaseUser,
  eventSource: 'user' | 'system',
  currentReactVersion?: string,
) => {

  // console.log('trackEvent', eventName, eventProperties, eventSource);

  const extendedEventProperties = {
    ...eventProperties,
    currentEventVersion: currentEventVersion,
    eventSource: eventSource,
    currentReactVersion: currentReactVersion ?? 'notSet',
  };

  const data = JSON.stringify({
    'name': eventName,
    properties: extendedEventProperties,
  });

  const token = await getBearerToken(currentUser);

  try {
    await axios.post(
      `${ANALYTICS_API_URL}/trackEvent`,
      data,
      {
        headers: {
          Authorization: token,
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    console.log(error); // eslint-disable-line no-console
  }
};
