import './../modals.css';

import { Dispatch, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import UpdateIcon from '@mui/icons-material/Update';
import CheckIcon from '@mui/icons-material/Check';

import { translate } from '../../../../common/language/translations';
import { SeasonCondition, SeasonConditionOverallStatus, SeasonConditionStatus, SeasonOrCondition } from '../../../types';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ClauseAction } from '../../documents/clause/clauseReducer';
import { ClauseState } from '../../documents/clause/clauseState';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { DateInputField } from '../../controls/input/DateInputField';


interface SeasonConditionStatusModalProps {
  state: ClauseState;
  isResolveSeasonConditionModalOpen: boolean;
  seasonCondition: SeasonCondition;
  seasonString: string;
  dispatch: Dispatch<ClauseAction>;
  closeModal: () => void;
}

export const SeasonConditionStatusModal: React.FC<SeasonConditionStatusModalProps> = ({
  state,
  isResolveSeasonConditionModalOpen,
  seasonCondition,
  seasonString,
  closeModal,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [isResolveConditionView, setIsResolveConditionView] = useState<boolean>(false);

  const [paymentDate, setPaymentDate] = useState<Date | null>(null);

  const seasonStatus: SeasonConditionStatus = seasonCondition.seasonToCurrentStatuses[seasonString];
  const alreadyAchievedOrConditionIndex: number = seasonStatus?.orConditionStatuses?.findIndex((status: string | null) => status === 'achieved') ?? -1;

  const [achievedOrConditionIndex, setAchievedOrConditionIndex] = useState<number | undefined>(
    alreadyAchievedOrConditionIndex !== -1 ? alreadyAchievedOrConditionIndex : undefined
  );


  const handleSetPaymentDate = (date: Date | null) => {
    if (!date) {
      setPaymentDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setPaymentDate(adjustedDate);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleResolveCondition('resolved');
    }
  };


  const handleResolveCondition = (newStatus: SeasonConditionOverallStatus) => {
    const payload = {
      conditionId: seasonCondition.conditionId,
      seasonString: seasonString,
      newStatus: newStatus,
      achievedOrConditionIndex: achievedOrConditionIndex,
      date: paymentDate
    };

    dispatch({ type: 'UPDATE_SEASON_CONDITION_STATUS', payload: payload });
    closeModal();
  };


  const getDisplayAmount = (amount: number) => {
    let currency = state.currency;
    if (state.subClauses && state.additionalClauseState.activeSubClauseTab !== undefined) {
      currency = state.subClauses[state.additionalClauseState.activeSubClauseTab].currency;
    }

    return currency
      ? getDisplayEconomicValue(amount) + ' ' + currency
      : getDisplayEconomicValue(amount, true);
  };


  const getYourPaymentDescription = () => {
    if (!userConfig
      || !seasonCondition
      || !state.subClauses
      || state.additionalClauseState.activeSubClauseTab === undefined
    ) return;

    const isMultipleAmountsAbove = !seasonCondition.amount && alreadyAchievedOrConditionIndex === -1;
    const totalPercentage = state.subClauses[state.additionalClauseState.activeSubClauseTab].totalSellOnPercentage;

    let description = translate('youWillReceive', userConfig.language) + ' ';
    description += totalPercentage + '% ';

    description += translate(isMultipleAmountsAbove ? 'ofTheSumsAbove' : 'ofTheSumAbove', userConfig.language, true);

    if (!isMultipleAmountsAbove) {
      const conditionAmount = seasonCondition.amount ?? seasonCondition.orConditions?.[alreadyAchievedOrConditionIndex].amount;
      if (conditionAmount !== undefined) {
        const currency = state.subClauses[state.additionalClauseState.activeSubClauseTab].currency;
        const discountedAmount = Math.round(conditionAmount * (totalPercentage / 100));
        const amount = currency
          ? getDisplayEconomicValue(discountedAmount) + ' ' + currency
          : getDisplayEconomicValue(discountedAmount, true);

        description += ': ' + amount;
      }
    }

    return description;
  };


  // If condition is already resolved, it may be discarded or reactivated
  // If condition is discarded, it may be resolved or reactivated
  // If condition does not have a status, it may be resolved or discarded

  const overallStatus = seasonStatus?.overallStatus;

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const isMaxConditionalFeeReached = stateAccessor.maxConditionalFees !== null &&
    (stateAccessor.conditionalFees ?? 0) >= stateAccessor.maxConditionalFees;

  const shouldDisableModal = isMaxConditionalFeeReached && overallStatus !== 'resolved';


  return (
    <div
      className='modal-root-container'
      style={{ width: 500, height: state.additionalClauseState.activeSubClauseTab !== undefined ? 550 : 510 }}
    >

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      {isResolveConditionView && (
        <IconButton
          icon={<ArrowBackIcon style={{ fontSize: 24 }} />}
          onClick={() => setIsResolveConditionView(false)}
          size={30}
          style={{ position: 'absolute', top: 12, left: 12 }}
          startWhite={true}
        />
      )}

      <div className='modal-root-title'>
        {userConfig && isResolveSeasonConditionModalOpen
          ? (
            (
              isResolveConditionView
                ? translate('resolveCondition', userConfig.language)
                : overallStatus === 'resolved'
                  ? translate('reactivateOrDiscardCondition', userConfig.language)
                  : overallStatus === 'discarded'
                    ? translate('resolveOrReactivateCondition', userConfig.language)
                    : translate('resolveOrDiscardCondition', userConfig.language)
            ) +
            ' ' + translate('for<season>', userConfig.language) + ' ' + seasonString
          )
          : ''}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      {shouldDisableModal && (
        <div className='modal-content-container'>
          <div style={{ margin: 'auto', paddingBottom: 15 }}>
            {translate('maxAmountReachedMessage', userConfig?.language)}
          </div>
        </div>
      )}

      {!shouldDisableModal && (
        <div className='modal-content-container'>
          {!isResolveConditionView && (
            <div className='modal-content-section' style={{ margin: 'auto', minHeight: 120, maxHeight: 340 }}>
              {overallStatus !== 'resolved' && (
                <TextButton
                  onClick={() => setIsResolveConditionView(true)}
                  text={translate('resolveCondition', userConfig?.language)}
                  icon={<TaskAltIcon style={{ fontSize: 22 }} />}
                  buttonType='transparent'
                  isRound={true}
                  style={{ width: 220 }}
                />
              )}
              {overallStatus && (
                <TextButton
                  onClick={() => handleResolveCondition(null)}
                  text={translate('reactivateCondition', userConfig?.language)}
                  icon={<UpdateIcon style={{ fontSize: 22 }} />}
                  buttonType='transparent'
                  isRound={true}
                  style={{ width: 220 }}
                />
              )}
              {overallStatus !== 'discarded' && (
                <TextButton
                  onClick={() => handleResolveCondition('discarded')}
                  text={translate('discardCondition', userConfig?.language)}
                  icon={<CancelOutlinedIcon style={{ fontSize: 22 }} />}
                  buttonType='transparent'
                  isRound={true}
                  style={{ width: 220 }}
                />
              )}
            </div>
          )}
        </div>
      )}

      {isResolveConditionView && (
        <div className='modal-content-container'>

          {seasonCondition.amount && (
            <div className='resolve-condition-modal-info-row'>
              {getDisplayAmount(seasonCondition.amount)}
            </div>
          )}

          {!seasonCondition.amount && seasonCondition.orConditions && alreadyAchievedOrConditionIndex !== -1 && (
            <div className='resolve-condition-modal-info-row'>
              {getDisplayAmount(seasonCondition.orConditions[alreadyAchievedOrConditionIndex].amount)}
            </div>
          )}

          {!seasonCondition.amount && alreadyAchievedOrConditionIndex === -1 && (
            <div className='resolve-condition-modal-amount-row'>
              {seasonCondition.orConditions && seasonCondition.orConditions.map((orCondition: SeasonOrCondition, index: number) => {
                return (
                  <div
                    key={index}
                    className={'resolve-condition-modal-amount' + (index === achievedOrConditionIndex ? ' resolve-condition-modal-amount-selected' : '')}
                    onClick={() => setAchievedOrConditionIndex(index)}
                  >
                    {getDisplayAmount(orCondition.amount)}
                  </div>
                );
              })}
            </div>
          )}

          {state.additionalClauseState.activeSubClauseTab !== undefined && (
            <div className='resolve-condition-modal-info-row' style={{ fontSize: 14, color: '#ffffffcc' }}>
              {getYourPaymentDescription()}
            </div>
          )}

          <div className='margin-auto' style={{ marginTop: 25 }}>
            <DateInputField
              value={paymentDate}
              placeholder={translate('paymentDate', userConfig?.language) + '?'}
              onChange={handleSetPaymentDate}
              onKeyDown={onKeyDownInputField}
              minDate='today-10y'
              maxDate='today+10y'
            />
          </div>
        </div>
      )}

      {isResolveConditionView && (
        <TextButton
          onClick={() => handleResolveCondition('resolved')}
          text={translate('resolveCondition', userConfig?.language)}
          icon={<CheckIcon style={{ fontSize: 22 }} />}
          style={{ position: 'absolute', bottom: 30 }}
          disabled={!seasonCondition.amount && achievedOrConditionIndex === undefined}
        />
      )}

    </div>
  );
};
