import { Club, PlayerOverview } from '../../../types';


export type PlayerFindingFilter =
  | 'minAge'
  | 'maxAge'
  | 'nationalities'
  | 'leagues'
  | 'clubs'


// name strings are handled separately due to debounce logic
export interface PlayerFindingState {

  // selected filters
  selectedMinAge: string[];
  selectedMaxAge: string[];
  selectedNationalities: string[];
  selectedLeagues: string[];
  selectedClubs: Club[];

  // filters applied to current search result
  currentMinAge: string[];
  currentMaxAge: string[];
  currentNationalities: string[];
  currentLeagues: string[];
  currentClubs: Club[];

  // filter expansion state
  currentFilterExpanded: PlayerFindingFilter | undefined,
  previousFilterExpanded: PlayerFindingFilter | undefined;

  // search state
  tableData: PlayerOverview[];
  currentModuloPage: number; // currentModuloPage (page % pagesPerChapter) is 1-indexed
  currentChapter: number; // chapters are 0-indexed
  totalHits: number;
  isLoading: boolean;
  isEmptySearchResult: boolean;
}


export const initialPlayerFindingState: PlayerFindingState = {

  // selected filters
  selectedMinAge: [],
  selectedMaxAge: [],
  selectedNationalities: [],
  selectedLeagues: [],
  selectedClubs: [],

  // filters applied to current search result
  currentMinAge: [],
  currentMaxAge: [],
  currentNationalities: [],
  currentLeagues: [],
  currentClubs: [],

  // filter expansion state
  currentFilterExpanded: undefined,
  previousFilterExpanded: undefined,

  // search state
  tableData: [],
  currentModuloPage: 0,
  currentChapter: 0,
  totalHits: 0,
  isLoading: false,
  isEmptySearchResult: false,
};
