import axios from 'axios';

import { FirebaseUser } from '../../../../firebase';

import { APPLICATION_API_URL } from '../constants';
import { getBearerToken } from '../token';
import { positionOptionsPlatform } from '../../../static/propertyValues';
import { getCountryCodeSearchString } from '../utils';

import {
  StringToAnyMap,
  PlayerOverviews,
  PlayerEntry,
  PlayerOverview,
  PlayerOverviewsListQueryOptions,
  PlayerOverviewsQueryOptions
} from '../../../types';
import { trackEvent } from '../analytics/trackEvent';
import { addNameToNameSearchHistory } from '../../firestore/scouting';


export interface SearchPlayerOverviewsResponse {
  total_hits: number;
  current_page: number;
  players: PlayerOverview[];
}


// Get players oveviews for all players on a team or squad
export const getPlayerOverviewsForAllTeamsAndSquads = async (
  teams: StringToAnyMap,
  squads: StringToAnyMap,
  currentUser: FirebaseUser,
): Promise<PlayerOverviews> => {

  // Get id of all players
  const playerIds = new Set<number>();

  positionOptionsPlatform.forEach(positionKey => {

    Object.keys(teams).forEach(teamKey => {
      const teamData = teams[teamKey];
      if (positionKey in teamData) {
        const positionArray = teamData[positionKey];
        positionArray.forEach((player: PlayerEntry) => {
          const numberId = Number(player.id);
          if (!isNaN(numberId)) {
            playerIds.add(numberId);
          }
        });
      }
    });

    Object.keys(squads).forEach(squadKey => {
      const squadData = squads[squadKey];
      if (positionKey in squadData) {
        const positionArray = squadData[positionKey];
        positionArray.forEach((player: PlayerEntry) => {
          const numberId = Number(player.id);
          if (!isNaN(numberId)) {
            playerIds.add(numberId);
          }
        });
      }
    });
  });

  if (playerIds.size === 0) {
    return {};
  }

  const playersOnTeams = await getPlayerOverviews(Array.from(playerIds), currentUser);

  return playersOnTeams ?? {};
};


// Get players oveviews for all players in the given archivedTeams that are not already in playerOverviews
export const getNewPlayerOverviewsForArchivedTeams = async (
  archivedTeams: StringToAnyMap[],
  playerOverviews: PlayerOverviews,
  currentUser: FirebaseUser,
): Promise<PlayerOverviews> => {

  // Get id of all players
  const newPlayerIds = new Set<number>();

  archivedTeams.forEach(teamOrSquad => {
    positionOptionsPlatform.forEach(positionKey => {
      if (positionKey in teamOrSquad) {
        const positionArray = teamOrSquad[positionKey];
        positionArray.forEach((player: PlayerEntry) => {
          const numberId = Number(player.id);
          if (!isNaN(numberId) && !(numberId in playerOverviews)) {
            newPlayerIds.add(numberId);
          }
        });
      }
    });
  });

  if (newPlayerIds.size === 0) {
    return {};
  }

  const playersOnTeams = await getPlayerOverviews(Array.from(newPlayerIds), currentUser);

  return playersOnTeams ?? {};
};


// Get players_oveview for all given playerIds
export const getPlayerOverviews = async (
  playerIds: number[],
  currentUser: FirebaseUser,
): Promise<PlayerOverviews | undefined> => {

  try {
    if (playerIds.length === 0) return undefined;

    const token = await getBearerToken(currentUser);
    const playerIdsString = playerIds.join(',');

    const result = await axios.get(`${APPLICATION_API_URL}/playerOverviews/${playerIdsString}`, {
      headers: { Authorization: token },
    });

    if (result === undefined) return undefined;

    return result.data;
  }

  catch (error) {
    trackEvent('Error', { api: 'server', function: 'getPlayerOverviews', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// Get players_oveview for all given playerIds
export const getPlayerOverviewsList = async (
  queryOptions: PlayerOverviewsListQueryOptions,
  currentUser: FirebaseUser,
): Promise<SearchPlayerOverviewsResponse | undefined> => {

  // if no player ids are given, we return an empty result without calling the server
  if (!queryOptions.playerIds || queryOptions.playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  try {
    const token = await getBearerToken(currentUser);

    const result = await axios.get(`${APPLICATION_API_URL}/playerOverviews/list/`, {
      headers: { Authorization: token },
      params: {
        player_ids: queryOptions.playerIds.join(','),
        page: queryOptions.page,
        page_size: queryOptions.pageSize,
      },
    });

    if (!result) return undefined;

    return result.data;
  }

  catch (error) {
    trackEvent('Error', { api: 'server', function: 'getPlayerOverviewsList', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};


// Search for player overviews
export const searchPlayerOverviews = async (
  queryOptions: PlayerOverviewsQueryOptions,
  currentUser: FirebaseUser,
  nameSearchHistory?: string[],
  userEmail?: string,
  club?: string,
): Promise<SearchPlayerOverviewsResponse | undefined> => {

  // if the array is undefined, we don't concern with any player ids, but if the array is empty, we attempt to search for an empty set of players
  if (queryOptions.playerIds && queryOptions.playerIds.length === 0) {
    return {
      total_hits: 0,
      current_page: 1,
      players: [],
    };
  }

  try {
    const token = await getBearerToken(currentUser);

    const primaryPositionsString = queryOptions.primaryPositions ? queryOptions.primaryPositions.join(',') : undefined;

    const competitionsString = queryOptions.competitions ? queryOptions.competitions.join(',') : undefined;

    const clubsString = queryOptions.clubs ? queryOptions.clubs.join(',') : undefined;

    const formString = queryOptions.form ? queryOptions.form.join(',') : undefined;

    const playingTimeString = queryOptions.playingTime ? queryOptions.playingTime.join(',') : undefined;

    const countryCodesString = queryOptions.countryCodes ? getCountryCodeSearchString(queryOptions.countryCodes) : undefined;

    const playerIdsString = queryOptions.playerIds ? queryOptions.playerIds.join(',') : undefined;

    let nameString = queryOptions.name ? queryOptions.name : undefined;
    if (nameString && nameSearchHistory && userEmail && club) {
      addNameToNameSearchHistory(nameString, nameSearchHistory, userEmail, club, currentUser);
      nameString = nameString.toLowerCase();
    }

    const result = await axios.get(`${APPLICATION_API_URL}/search/playerOverviews/`, {
      headers: { Authorization: token },
      params: {
        name: nameString,
        min_age: queryOptions.minAge,
        max_age: queryOptions.maxAge,
        max_contract_length: queryOptions.maxContractLength,
        include_unknown_contracts: queryOptions.includeUnknownContracts,
        primary_positions: primaryPositionsString,
        position_group: queryOptions.positionGroup,
        competitions: competitionsString,
        club_ids: clubsString,
        min_confidence: queryOptions.minConfidence,
        max_confidence: queryOptions.maxConfidence,
        form_status: formString,
        availability_status: playingTimeString,
        country_codes: countryCodesString,
        player_ids: playerIdsString,
        sort_by: queryOptions.sortBy,
        page: queryOptions.page,
        page_size: queryOptions.pageSize,
      },
    });

    if (!result) return undefined;

    return result.data;
  }

  catch (error) {
    trackEvent('Error', { api: 'server', function: 'searchPlayerOverviews', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};
