import '../tables.css';

import { useEffect, useMemo, useRef } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { StringToAnyMap, PlayerOverview } from '../../../types';
import { getFindPlayerColumns } from './findPlayerColumns';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../controls/navigation/Navigation';
import { debounce } from '../../../utils/utils';
import { playerTableChapterSize, playerTablePageSize } from '../../../views/scouting/Scouting';
import { getFindPlayerTableTotalColumnsMinWidth } from './findPlayerColumns';


interface FindPlayerTableProps {
  data: PlayerOverview[];

  isLoading?: boolean;
  handleSearchButtonPressed?: (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => Promise<void>;
  currentModuloPage: number;
  currentChapter: number;
  totalHits: number;
  handleChangeCurrentChapter?: (isIncrement: boolean) => void;

  isSelectedPlayer?: boolean;
  handlePlayerClick?: (player: PlayerOverview) => void;
}

export const FindPlayerTable: React.FC<FindPlayerTableProps> = ({
  data,

  isLoading,
  handleSearchButtonPressed,
  currentModuloPage,
  currentChapter,
  totalHits,
  handleChangeCurrentChapter,

  handlePlayerClick,
  isSelectedPlayer,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize();
  const getTableWidth = (currentWidth: number) => {
    return currentWidth - 24 - getNavigationWidth(userConfig);
  };

  const remainingWidth = getTableWidth(width) - 340;
  const totalColumnsMinWidth = getFindPlayerTableTotalColumnsMinWidth();
  const widthUnit = remainingWidth / totalColumnsMinWidth;


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getFindPlayerColumns(
      widthUnit,
      userConfig?.language ?? 'en',
      currentChapter,
      playerTablePageSize * playerTableChapterSize,
      totalHits,
      isSelectedPlayer ?? false,
      handleChangeCurrentChapter,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      width,
      userConfig,
      currentChapter,
      totalHits,
      isSelectedPlayer,
      isLoading,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );


  const handleRowClick = (playerOverview: PlayerOverview) => {
    if (isSelectedPlayer) return;

    if (handlePlayerClick) {
      handlePlayerClick(playerOverview);
    }
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !handleSearchButtonPressed) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentModuloPage * 0.1;

    const isMoreDataToFetch = ((currentChapter * playerTablePageSize * playerTableChapterSize) + data.length) < totalHits;

    if (scrollPosition > threshold && !isLoading && currentModuloPage < 4 && isMoreDataToFetch) {
      await handleSearchButtonPressed(false, false);
    }
  };


  useEffect(() => {
    if (playerTableChapterSize > 1) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 150);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div
      ref={tableContainerRef}
      {...getTableProps()}
      className='table-container player-table'
      style={{ overflowX: getTableWidth(width) < (totalColumnsMinWidth + 350 + 20) ? 'auto' : 'hidden' }}
    >

      {headerGroups.map(headerGroup => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <div
            key={key}
            {...restHeaderGroupProps}
            className='find-player-table-header-group'
          >
            {headerGroup.headers.map((column: StringToAnyMap) => {
              const { key: columnKey, ...restColumnProps } = column.getHeaderProps();
              return (
                <div
                  key={columnKey}
                  {...restColumnProps}
                  className='find-player-table-header-cell'
                >
                  {column.render('Header')}
                </div>
              );
            })}
          </div>
        );
      })}

      <div {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={i % 2 === 0 ? ('player-table-body-row-even' + (isSelectedPlayer ? '-hover-disabled' : '')) : 'player-table-body-row-odd'}
              onClick={() => handleRowClick(row.original)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={'find-player-table-body-cell' + (i % 2 === 0 ? ' player-table-body-cell-even' : ' player-table-body-cell-odd')}
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
