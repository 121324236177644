import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { translate } from '../../../../common/language/translations';
import { ConditionDropDown } from '../../controls/dropDowns/ConditionDropDown';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { ConditionOption, ConditionType } from '../../../types';
import { InputField } from '../../controls/input/InputField';


interface SubConditionModalProps {
  newConditionThreshold: string;
  setNewConditionThreshold: (newThreshold: string) => void;

  minuteRequirementPerMatch: string | undefined;
  setMinuteRequirementPerMatch: (value: string) => void;

  addOrEditSeasonSubCondition: (index: number) => void;
  closeModal: () => void;

  seasonOrConditionIndex: number | undefined;
  isEdit: boolean;
  messageKey: string | undefined;

  seasonConditionOptions: ConditionOption[];
  selectedConditionOption: ConditionOption[];
  setSelectedConditionOption: (selectedOptions: ConditionOption[]) => void;
  isConditionDropDownExpanded: boolean;
  setIsConditionDropDownExpanded: (isExpanded: boolean) => void;

  onKeyDownInputField: (
    event: React.KeyboardEvent<HTMLInputElement>,
    conditionType: ConditionType,
    seasonOrConditionIndex?: number,
    isDynamicSellOn?: boolean
  ) => void;
  getSeasonThresholdInputPlaceholder: () => string;
}

export const SubConditionModal: React.FC<SubConditionModalProps> = ({
  newConditionThreshold,
  setNewConditionThreshold,

  minuteRequirementPerMatch,
  setMinuteRequirementPerMatch,

  addOrEditSeasonSubCondition,
  closeModal,

  seasonOrConditionIndex,
  isEdit,
  messageKey,

  seasonConditionOptions,
  selectedConditionOption,
  setSelectedConditionOption,
  isConditionDropDownExpanded,
  setIsConditionDropDownExpanded,

  onKeyDownInputField,
  getSeasonThresholdInputPlaceholder,
}) => {

  const userConfig = useRecoilValue(userConfigState);


  return (
    <div className='modal-root-container' style={{ width: 550, height: 370 }}>

      {isConditionDropDownExpanded &&
        <div className='modal-empty-background' style={{ zIndex: 10 }} onClick={() => setIsConditionDropDownExpanded(false)} />
      }

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate(isEdit ? 'editCondition' : 'addCondition', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container'>
        <div className='condition-modal-new-total-condition-section'>
          <div className='modal-content-section'>
            <div className='condition-modal-season-condition-input-row' style={{ width: 500 }}>
              <div style={{ width: 240 }}>
                <ConditionDropDown
                  id={'clauses-modal-condition-select'}
                  dropDownOptions={seasonConditionOptions}
                  selectedOptions={selectedConditionOption}
                  setSelectedOptions={setSelectedConditionOption}
                  isDropDownExpanded={isConditionDropDownExpanded}
                  setIsDropDownExpanded={setIsConditionDropDownExpanded}
                  defaultDropDownText={'selectCondition'}
                  defaultDropDownTextColor={'#000000'}
                  marginBetweenOptions={4}
                  maxHeight={'60vh'}
                  height={26}
                  minuteRequirementPerMatch={minuteRequirementPerMatch}
                  setMinuteRequirementPerMatch={setMinuteRequirementPerMatch}
                />
              </div>

              <InputField
                value={newConditionThreshold}
                placeholder={getSeasonThresholdInputPlaceholder()}
                onChange={(event) => setNewConditionThreshold(event.target.value)}
                onKeyDown={(event) => onKeyDownInputField(event, 'season', seasonOrConditionIndex)}
                disabled={selectedConditionOption.length === 0}
                showDisabled={true}
                isSquare={true}
                height={26}
                width={240}
              />
            </div>

            <div className='payment-modal-divider' style={{ marginTop: 46 }} />

            <TextButton
              onClick={() => seasonOrConditionIndex !== undefined ? addOrEditSeasonSubCondition(seasonOrConditionIndex) : null}
              text={translate(isEdit ? 'editCondition' : 'addCondition', userConfig?.language)}
              icon={<CheckIcon style={{ fontSize: 20 }} />}
              buttonType={'transparent'}
              style={{ height: 26 }}
            />
          </div>
        </div>

        {messageKey && (
          <div className='condition-modal-message' style={{ position: 'relative', margin: 'auto' }}>
            {translate(messageKey, userConfig?.language)}
          </div>
        )}
      </div>

    </div>
  );
};
