import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { countryCodeToCountryInfo } from '../../../static/countries';


interface CountryFlagProps {
  countryCode: string | undefined;
  width: number;

  showCountryName?: boolean;
  tierToShow?: number | string | undefined;
  textToShow?: string;
  iconToShow?: JSX.Element;
  showHyphenIfEmpty?: boolean;

  containerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  isWhiteBackground?: boolean;
}


export const CountryFlag: React.FC<CountryFlagProps> = ({
  countryCode,
  width,

  showCountryName,
  tierToShow,
  textToShow,
  iconToShow,
  showHyphenIfEmpty,

  containerStyle,
  imageStyle,
  isWhiteBackground,
}) => {


  const userConfig = useRecoilValue(userConfigState);

  const countryInfo = countryCode ? countryCodeToCountryInfo[countryCode] : undefined;

  return (
    <div
      className='image-row-container'
      style={{
        ...containerStyle,
      }}
    >
      {countryInfo && (
        <img
          src={countryInfo.flagUrl}
          alt={''}
          className={'flag-image' + (isWhiteBackground ? '-whiteBackground' : '')}
          style={{
            width: width,
            ...imageStyle,
          }}
          draggable={false}
        />
      )}

      {showCountryName && countryInfo && (
        <div className='flex-row' style={{ gap: 3 }}>
          {countryInfo.name[userConfig?.language ?? 'en']}

          {tierToShow !== undefined && <div>{tierToShow}</div>}
        </div>
      )}

      {textToShow && <div>{textToShow}</div>}

      {iconToShow}

      {showHyphenIfEmpty && !countryCode && !iconToShow && <div>-</div>}
    </div>
  );
};
