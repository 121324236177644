import { StringToStringMap } from '../types';


export interface CountryInfo {
  name: StringToStringMap;
  flagUrl: string;
  excludeFromSearch?: boolean;
}


export interface EnhancedCountryInfo extends CountryInfo {
  countryCode: string;
}


export const countryCodeToCountryInfo: { [key: string]: CountryInfo } = {
  'AD': {
    name: {
      'en': 'Andorra',
      'no': 'Andorra',
      'se': 'Andorra',
      'dk': 'Andorra',
    },
    flagUrl: 'flags/ad.svg',
  },
  'AE': {
    name: {
      'en': 'Arab Emirates',
      'no': 'Arabiske Emirater',
      'se': 'Förenade Arabemiraten',
      'dk': 'Arabiske Emirater',
    },
    flagUrl: 'flags/ae.svg',
  },
  'AF': {
    name: {
      'en': 'Afghanistan',
      'no': 'Afghanistan',
      'se': 'Afghanistan',
      'dk': 'Afghanistan',
    },
    flagUrl: 'flags/af.svg',
  },
  // 'AG'
  // 'AI'
  'AL': {
    name: {
      'en': 'Albania',
      'no': 'Albania',
      'se': 'Albánia',
      'dk': 'Albanien',
    },
    flagUrl: 'flags/al.svg',
  },
  'AM': {
    name: {
      'en': 'Armenia',
      'no': 'Armenia',
      'se': 'Armenia',
      'dk': 'Armenien',
    },
    flagUrl: 'flags/am.svg',
  },
  'AO': {
    name: {
      'en': 'Angola',
      'no': 'Angola',
      'se': 'Angola',
      'dk': 'Angola',
    },
    flagUrl: 'flags/ao.svg',
  },
  // 'AQ'
  'AR': {
    name: {
      'en': 'Argentina',
      'no': 'Argentina',
      'se': 'Argentina',
      'dk': 'Argentina',
    },
    flagUrl: 'flags/ar.svg',
  },
  // 'AS'
  'AT': {
    name: {
      'en': 'Austria',
      'no': 'Østerrike',
      'se': 'Østerrike',
      'dk': 'Østrig',
    },
    flagUrl: 'flags/at.svg',
  },
  'AU': {
    name: {
      'en': 'Australia',
      'no': 'Australia',
      'se': 'Australien',
      'dk': 'Australien',
    },
    flagUrl: 'flags/au.svg',
  },
  // 'AW'
  // 'AX'
  'AZ': {
    name: {
      'en': 'Azerbaijan',
      'no': 'Aserbajdsjan',
      'se': 'Aserbajdsjan',
      'dk': 'Aserbajdsjan',
    },
    flagUrl: 'flags/az.svg',
  },
  'BA': {
    name: {
      'en': 'Bosnia', // Bosnia and Herzegovina
      'no': 'Bosnia', // Bosnia-Hercegovina
      'se': 'Bosnien', // Bosnien och Hercegovina
      'dk': 'Bosnien', // Bosnien-Hercegovina
    },
    flagUrl: 'flags/ba.svg',
  },
  'BB': {
    name: {
      'en': 'Barbados',
      'no': 'Barbados',
      'se': 'Barbados',
      'dk': 'Barbados',
    },
    flagUrl: 'flags/bb.svg',
  },
  'BD': {
    name: {
      'en': 'Bangladesh',
      'no': 'Bangladesh',
      'se': 'Bangladesh',
      'dk': 'Bangladesh',
    },
    flagUrl: 'flags/bd.svg',
  },
  'BE': {
    name: {
      'en': 'Belgium',
      'no': 'Belgia',
      'se': 'Belgien',
      'dk': 'Belgien',
    },
    flagUrl: 'flags/be.svg',
  },
  'BF': {
    name: {
      'en': 'Burkina Faso',
      'no': 'Burkina Faso',
      'se': 'Burkina Faso',
      'dk': 'Burkina Faso',
    },
    flagUrl: 'flags/bf.svg',
  },
  'BG': {
    name: {
      'en': 'Bulgaria',
      'no': 'Bulgaria',
      'se': 'Bulgarien',
      'dk': 'Bulgarien',
    },
    flagUrl: 'flags/bg.svg',
  },
  'BH': {
    name: {
      'en': 'Bahrain',
      'no': 'Bahrain',
      'se': 'Bahrain',
      'dk': 'Bahrain',
    },
    flagUrl: 'flags/bh.svg',
  },
  'BI': {
    name: {
      'en': 'Burundi',
      'no': 'Burundi',
      'se': 'Burundi',
      'dk': 'Burundi',
    },
    flagUrl: 'flags/bi.svg',
  },
  'BJ': {
    name: {
      'en': 'Benin',
      'no': 'Benin',
      'se': 'Benin',
      'dk': 'Benin',
    },
    flagUrl: 'flags/bj.svg',
  },
  // 'BL'
  'BM': {
    name: {
      'en': 'Bermuda',
      'no': 'Bermuda',
      'se': 'Bermuda',
      'dk': 'Bermuda',
    },
    flagUrl: 'flags/bm.svg',
  },
  'BN': {
    name: {
      'en': 'Brunei',
      'no': 'Brunei',
      'se': 'Brunei',
      'dk': 'Brunei',
    },
    flagUrl: 'flags/bn.svg',
  },
  'BO': {
    name: {
      'en': 'Bolivia',
      'no': 'Bolivia',
      'se': 'Bolivia',
      'dk': 'Bolivia',
    },
    flagUrl: 'flags/bo.svg',
  },
  // 'BQ'
  'BR': {
    name: {
      'en': 'Brazil',
      'no': 'Brasil',
      'se': 'Brasilien',
      'dk': 'Brasilien',
    },
    flagUrl: 'flags/br.svg',
  },
  'BS': {
    name: {
      'en': 'Bahamas',
      'no': 'Bahamas',
      'se': 'Bahamas',
      'dk': 'Bahamas',
    },
    flagUrl: 'flags/bs.svg',
  },
  'BT': {
    name: {
      'en': 'Bhutan',
      'no': 'Bhutan',
      'se': 'Bhutan',
      'dk': 'Bhutan',
    },
    flagUrl: 'flags/bt.svg',
  },
  // 'BV'
  'BW': {
    name: {
      'en': 'Botswana',
      'no': 'Botswana',
      'se': 'Botswana',
      'dk': 'Botswana',
    },
    flagUrl: 'flags/bw.svg',
  },
  'BY': {
    name: {
      'en': 'Belarus',
      'no': 'Hviterussland',
      'se': 'Vitryssland',
      'dk': 'Hviderusland',
    },
    flagUrl: 'flags/by.svg',
  },
  'BZ': {
    name: {
      'en': 'Belize',
      'no': 'Belize',
      'se': 'Belize',
      'dk': 'Belize',
    },
    flagUrl: 'flags/bz.svg',
  },
  'CA': {
    name: {
      'en': 'Canada',
      'no': 'Canada',
      'se': 'Kanada',
      'dk': 'Canada',
    },
    flagUrl: 'flags/ca.svg',
  },
  // 'CC'
  'CD': {
    name: {
      'en': 'Congo (DR)', // Democratic Republic of the Congo
      'no': 'Kongo (DR)', // Den demokratiske republikken Kongo
      'se': 'Kongo (DR)', // Demokratiska republiken Kongo
      'dk': 'Congo (DR)', // Den Demokratiske Republik Congo
    },
    flagUrl: 'flags/cd.svg',
  },
  'CF': {
    name: {
      'en': 'Central African Republic',
      'no': 'Sentralafrikanske republikk', // Den sentralafrikanske republikk
      'se': 'Centralafrikanska republiken',
      'dk': 'Centralafrikanske Republik',
    },
    flagUrl: 'flags/cf.svg',
  },
  'CG': {
    name: {
      'en': 'Congo',
      'no': 'Kongo',
      'se': 'Kongo',
      'dk': 'Congo',
    },
    flagUrl: 'flags/cg.svg',
  },
  'CH': {
    name: {
      'en': 'Switzerland',
      'no': 'Sveits',
      'se': 'Schweiz',
      'dk': 'Schweiz',
    },
    flagUrl: 'flags/ch.svg',
  },
  'CI': {
    name: {
      'en': 'Ivory Coast',
      'no': 'Elfenbenskysten',
      'se': 'Elfenbenskusten',
      'dk': 'Elfenbenskysten',
    },
    flagUrl: 'flags/ci.svg',
  },
  // 'CK'
  'CL': {
    name: {
      'en': 'Chile',
      'no': 'Chile',
      'se': 'Chile',
      'dk': 'Chile',
    },
    flagUrl: 'flags/cl.svg',
  },
  'CM': {
    name: {
      'en': 'Cameroon',
      'no': 'Kamerun',
      'se': 'Kamerun',
      'dk': 'Cameroun',
    },
    flagUrl: 'flags/cm.svg',
  },
  'CN': {
    name: {
      'en': 'China',
      'no': 'Kina',
      'se': 'Kina',
      'dk': 'Kina',
    },
    flagUrl: 'flags/cn.svg',
  },
  'CO': {
    name: {
      'en': 'Colombia',
      'no': 'Colombia',
      'se': 'Colombia',
      'dk': 'Colombia',
    },
    flagUrl: 'flags/co.svg',
  },
  // 'CP'
  'CR': {
    name: {
      'en': 'Costa Rica',
      'no': 'Costa Rica',
      'se': 'Costa Rica',
      'dk': 'Costa Rica',
    },
    flagUrl: 'flags/cr.svg',
  },
  'CU': {
    name: {
      'en': 'Cuba',
      'no': 'Cuba',
      'se': 'Kuba',
      'dk': 'Cuba',
    },
    flagUrl: 'flags/cu.svg',
  },
  'CV': {
    name: {
      'en': 'Cape Verde',
      'no': 'Kapp Verde',
      'se': 'Kap Verde',
      'dk': 'Kap Verde',
    },
    flagUrl: 'flags/cv.svg',
  },
  'CW': {
    name: {
      'en': 'Curacao',
      'no': 'Curacao',
      'se': 'Curacao',
      'dk': 'Curacao',
    },
    flagUrl: 'flags/cw.svg',
  },
  // 'CX'
  'CY': {
    name: {
      'en': 'Cyprus',
      'no': 'Kypros',
      'se': 'Cypern',
      'dk': 'Cypern',
    },
    flagUrl: 'flags/cy.svg',
  },
  'CZ': {
    name: {
      'en': 'Czech Republic',
      'no': 'Tsjekkia',
      'se': 'Tjeckien',
      'dk': 'Tjekkiet',
    },
    flagUrl: 'flags/cz.svg',
  },
  'DE': {
    name: {
      'en': 'Germany',
      'no': 'Tyskland',
      'se': 'Tyskland',
      'dk': 'Tyskland',
    },
    flagUrl: 'flags/de.svg',
  },
  // 'DG'
  'DJ': {
    name: {
      'en': 'Djibouti',
      'no': 'Djibouti',
      'se': 'Djibouti',
      'dk': 'Djibouti',
    },
    flagUrl: 'flags/dj.svg',
  },
  'DK': {
    name: {
      'en': 'Denmark',
      'no': 'Danmark',
      'se': 'Danmark',
      'dk': 'Danmark',
    },
    flagUrl: 'flags/dk.svg',
  },
  'DM': {
    name: {
      'en': 'Dominica',
      'no': 'Dominica',
      'se': 'Dominica',
      'dk': 'Dominica',
    },
    flagUrl: 'flags/dm.svg',
  },
  'DO': {
    name: {
      'en': 'Dominican Republic',
      'no': 'Den dominikanske republikk',
      'se': 'Dominikanska republiken',
      'dk': 'Den Dominikanske Republik',
    },
    flagUrl: 'flags/do.svg',
  },
  'DZ': {
    name: {
      'en': 'Algeria',
      'no': 'Algerie',
      'se': 'Algeria',
      'dk': 'Algeriet',
    },
    flagUrl: 'flags/dz.svg',
  },
  'EC': {
    name: {
      'en': 'Ecuador',
      'no': 'Ecuador',
      'se': 'Ecuador',
      'dk': 'Ecuador',
    },
    flagUrl: 'flags/ec.svg',
  },
  'EE': {
    name: {
      'en': 'Estonia',
      'no': 'Estland',
      'se': 'Estland',
      'dk': 'Estland',
    },
    flagUrl: 'flags/ee.svg',
  },
  'EG': {
    name: {
      'en': 'Egypt',
      'no': 'Egypt',
      'se': 'Egypten',
      'dk': 'Egypten',
    },
    flagUrl: 'flags/eg.svg',
  },
  'EH': {
    name: {
      'en': 'Western Sahara',
      'no': 'Vest-Sahara',
      'se': 'Västsahara',
      'dk': 'Vest-Sahara',
    },
    flagUrl: 'flags/eh.svg',
  },
  'ER': {
    name: {
      'en': 'Eritrea',
      'no': 'Eritrea',
      'se': 'Eritrea',
      'dk': 'Eritrea',
    },
    flagUrl: 'flags/er.svg',
  },
  // 'ES-CT' / 'ES-GA' / 'ES-PV'
  'ES': {
    name: {
      'en': 'Spain',
      'no': 'Spania',
      'se': 'Spanien',
      'dk': 'Spanien',
    },
    flagUrl: 'flags/es.svg',
  },
  'ET': {
    name: {
      'en': 'Ethiopia',
      'no': 'Etiopia',
      'se': 'Etiopien',
      'dk': 'Etiopien',
    },
    flagUrl: 'flags/et.svg',
  },
  'EU': {
    name: {
      'en': 'Europe',
      'no': 'Europa',
      'se': 'Europa',
      'dk': 'Europa',
    },
    flagUrl: 'flags/eu.svg',
    excludeFromSearch: true,
  },
  'FI': {
    name: {
      'en': 'Finland',
      'no': 'Finland',
      'se': 'Finland',
      'dk': 'Finland',
    },
    flagUrl: 'flags/fi.svg',
  },
  'FJ': {
    name: {
      'en': 'Fiji',
      'no': 'Fiji',
      'se': 'Fiji',
      'dk': 'Fiji',
    },
    flagUrl: 'flags/fj.svg',
  },
  // 'FK'
  'FM': {
    name: {
      'en': 'Micronesia',
      'no': 'Mikronesia',
      'se': 'Mikronesien',
      'dk': 'Mikronesien',
    },
    flagUrl: 'flags/fm.svg',
  },
  'FO': {
    name: {
      'en': 'Faroe Islands',
      'no': 'Færøyene',
      'se': 'Färöarna',
      'dk': 'Færøerne',
    },
    flagUrl: 'flags/fo.svg',
  },
  'FR': {
    name: {
      'en': 'France',
      'no': 'Frankrike',
      'se': 'Frankrike',
      'dk': 'Frankrig',
    },
    flagUrl: 'flags/fr.svg',
  },
  'GA': {
    name: {
      'en': 'Gabon',
      'no': 'Gabon',
      'se': 'Gabon',
      'dk': 'Gabon',
    },
    flagUrl: 'flags/ga.svg',
  },
  'GB-ENG': {
    name: {
      'en': 'England',
      'no': 'England',
      'se': 'England',
      'dk': 'England',
    },
    flagUrl: 'flags/gb-eng.svg',
  },
  'GB-NIR': {
    name: {
      'en': 'Northern Ireland',
      'no': 'Nord-Irland',
      'se': 'Nordirland',
      'dk': 'Nordirland',
    },
    flagUrl: 'flags/gb-nir.svg',
  },
  'GB-SCT': {
    name: {
      'en': 'Scotland',
      'no': 'Skottland',
      'se': 'Skottland',
      'dk': 'Skotland',
    },
    flagUrl: 'flags/gb-sct.svg',
  },
  'GB-WLS': {
    name: {
      'en': 'Wales',
      'no': 'Wales',
      'se': 'Wales',
      'dk': 'Wales',
    },
    flagUrl: 'flags/gb-wls.svg',
  },
  'GB': {
    name: {
      'en': 'United Kingdom',
      'no': 'Storbritannia',
      'se': 'Storbritannien',
      'dk': 'Storbritannien',
    },
    flagUrl: 'flags/gb.svg',
  },
  'GD': {
    name: {
      'en': 'Grenada',
      'no': 'Grenada',
      'se': 'Grenada',
      'dk': 'Grenada',
    },
    flagUrl: 'flags/gd.svg',
  },
  'GE': {
    name: {
      'en': 'Georgia',
      'no': 'Georgia',
      'se': 'Georgien',
      'dk': 'Georgien',
    },
    flagUrl: 'flags/ge.svg',
  },
  'GF': {
    name: {
      'en': 'French Guiana',
      'no': 'Fransk Guyana',
      'se': 'Franska Guyana',
      'dk': 'Fransk Guyana',
    },
    flagUrl: 'flags/gf.svg',
  },
  // 'GG'
  'GH': {
    name: {
      'en': 'Ghana',
      'no': 'Ghana',
      'se': 'Ghana',
      'dk': 'Ghana',
    },
    flagUrl: 'flags/gh.svg',
  },
  'GI': {
    name: {
      'en': 'Gibraltar',
      'no': 'Gibraltar',
      'se': 'Gibraltar',
      'dk': 'Gibraltar',
    },
    flagUrl: 'flags/gi.svg',
  },
  'GL': {
    name: {
      'en': 'Greenland',
      'no': 'Grønland',
      'se': 'Grönland',
      'dk': 'Grønland',
    },
    flagUrl: 'flags/gl.svg',
  },
  'GM': {
    name: {
      'en': 'Gambia',
      'no': 'Gambia',
      'se': 'Gambia',
      'dk': 'Gambia',
    },
    flagUrl: 'flags/gm.svg',
  },
  'GN': {
    name: {
      'en': 'Guinea',
      'no': 'Guinea',
      'se': 'Guinea',
      'dk': 'Guinea',
    },
    flagUrl: 'flags/gn.svg',
  },
  'GP': {
    name: {
      'en': 'Guadeloupe',
      'no': 'Guadeloupe',
      'se': 'Guadeloupe',
      'dk': 'Guadeloupe',
    },
    flagUrl: 'flags/gp.svg',
  },
  'GQ': {
    name: {
      'en': 'Equatorial Guinea',
      'no': 'Ekvatorial-Guinea',
      'se': 'Ekvatorialguinea',
      'dk': 'Ækvatorialguinea',
    },
    flagUrl: 'flags/gq.svg',
  },
  'GR': {
    name: {
      'en': 'Greece',
      'no': 'Hellas',
      'se': 'Grekland',
      'dk': 'Grækenland',
    },
    flagUrl: 'flags/gr.svg',
  },
  // 'GS'
  'GT': {
    name: {
      'en': 'Guatemala',
      'no': 'Guatemala',
      'se': 'Guatemala',
      'dk': 'Guatemala',
    },
    flagUrl: 'flags/gt.svg',
  },
  'GU': {
    name: {
      'en': 'Guam',
      'no': 'Guam',
      'se': 'Guam',
      'dk': 'Guam',
    },
    flagUrl: 'flags/gu.svg',
  },
  'GW': {
    name: {
      'en': 'Guinea-Bissau',
      'no': 'Guinea-Bissau',
      'se': 'Guinea-Bissau',
      'dk': 'Guinea-Bissau',
    },
    flagUrl: 'flags/gw.svg',
  },
  'GY': {
    name: {
      'en': 'Guyana',
      'no': 'Guyana',
      'se': 'Guyana',
      'dk': 'Guyana',
    },
    flagUrl: 'flags/gy.svg',
  },
  'HK': {
    name: {
      'en': 'Hong Kong',
      'no': 'Hong Kong',
      'se': 'Hongkong',
      'dk': 'Hong Kong',
    },
    flagUrl: 'flags/hk.svg',
  },
  // 'HM'
  'HN': {
    name: {
      'en': 'Honduras',
      'no': 'Honduras',
      'se': 'Honduras',
      'dk': 'Honduras',
    },
    flagUrl: 'flags/hn.svg',
  },
  'HR': {
    name: {
      'en': 'Croatia',
      'no': 'Kroatia',
      'se': 'Kroatien',
      'dk': 'Kroatien',
    },
    flagUrl: 'flags/hr.svg',
  },
  'HT': {
    name: {
      'en': 'Haiti',
      'no': 'Haiti',
      'se': 'Haiti',
      'dk': 'Haiti',
    },
    flagUrl: 'flags/ht.svg',
  },
  'HU': {
    name: {
      'en': 'Hungary',
      'no': 'Ungarn',
      'se': 'Ungern',
      'dk': 'Ungarn',
    },
    flagUrl: 'flags/hu.svg',
  },
  'IC': {
    name: {
      'en': 'Canary Islands',
      'no': 'Kanariøyene',
      'se': 'Kanarieöarna',
      'dk': 'Kanariske Øer',
    },
    flagUrl: 'flags/ic.svg',
  },
  'ID': {
    name: {
      'en': 'Indonesia',
      'no': 'Indonesia',
      'se': 'Indonesien',
      'dk': 'Indonesien',
    },
    flagUrl: 'flags/id.svg',
  },
  'IE': {
    name: {
      'en': 'Ireland',
      'no': 'Irland',
      'se': 'Irland',
      'dk': 'Irland',
    },
    flagUrl: 'flags/ie.svg',
  },
  'IL': {
    name: {
      'en': 'Israel',
      'no': 'Israel',
      'se': 'Israel',
      'dk': 'Israel',
    },
    flagUrl: 'flags/il.svg',
  },
  // 'IM'
  'IN': {
    name: {
      'en': 'India',
      'no': 'India',
      'se': 'Indien',
      'dk': 'Indien',
    },
    flagUrl: 'flags/in.svg',
  },
  // 'IO'
  'IQ': {
    name: {
      'en': 'Iraq',
      'no': 'Irak',
      'se': 'Irak',
      'dk': 'Irak',
    },
    flagUrl: 'flags/iq.svg',
  },
  'IR': {
    name: {
      'en': 'Iran',
      'no': 'Iran',
      'se': 'Iran',
      'dk': 'Iran',
    },
    flagUrl: 'flags/ir.svg',
  },
  'IS': {
    name: {
      'en': 'Iceland',
      'no': 'Island',
      'se': 'Island',
      'dk': 'Island',
    },
    flagUrl: 'flags/is.svg',
  },
  'IT': {
    name: {
      'en': 'Italy',
      'no': 'Italia',
      'se': 'Italien',
      'dk': 'Italien',
    },
    flagUrl: 'flags/it.svg',
  },
  'JE': {
    name: {
      'en': 'Jersey',
      'no': 'Jersey',
      'se': 'Jersey',
      'dk': 'Jersey',
    },
    flagUrl: 'flags/je.svg',
  },
  'JM': {
    name: {
      'en': 'Jamaica',
      'no': 'Jamaica',
      'se': 'Jamaica',
      'dk': 'Jamaica',
    },
    flagUrl: 'flags/jm.svg',
  },
  'JO': {
    name: {
      'en': 'Jordan',
      'no': 'Jordan',
      'se': 'Jordanien',
      'dk': 'Jordan',
    },
    flagUrl: 'flags/jo.svg',
  },
  'JP': {
    name: {
      'en': 'Japan',
      'no': 'Japan',
      'se': 'Japan',
      'dk': 'Japan',
    },
    flagUrl: 'flags/jp.svg',
  },
  'KE': {
    name: {
      'en': 'Kenya',
      'no': 'Kenya',
      'se': 'Kenya',
      'dk': 'Kenya',
    },
    flagUrl: 'flags/ke.svg',
  },
  'KG': {
    name: {
      'en': 'Kyrgyzstan',
      'no': 'Kirgisistan',
      'se': 'Kirgizistan',
      'dk': 'Kirgisistan',
    },
    flagUrl: 'flags/kg.svg',
  },
  'KH': {
    name: {
      'en': 'Cambodia',
      'no': 'Kambodsja',
      'se': 'Kambodja',
      'dk': 'Cambodja',
    },
    flagUrl: 'flags/kh.svg',
  },
  'KI': {
    name: {
      'en': 'Kiribati',
      'no': 'Kiribati',
      'se': 'Kiribati',
      'dk': 'Kiribati',
    },
    flagUrl: 'flags/ki.svg',
  },
  'KM': {
    name: {
      'en': 'Comoros',
      'no': 'Komorene',
      'se': 'Komorerna',
      'dk': 'Comorerne',
    },
    flagUrl: 'flags/km.svg',
  },
  // 'KN'
  'KP': {
    name: {
      'en': 'North Korea',
      'no': 'Nord-Korea',
      'se': 'Nordkorea',
      'dk': 'Nordkorea',
    },
    flagUrl: 'flags/kp.svg',
  },
  'KR': {
    name: {
      'en': 'South Korea',
      'no': 'Sør-Korea',
      'se': 'Sydkorea',
      'dk': 'Sydkorea',
    },
    flagUrl: 'flags/kr.svg',
  },
  'KW': {
    name: {
      'en': 'Kuwait',
      'no': 'Kuwait',
      'se': 'Kuwait',
      'dk': 'Kuwait',
    },
    flagUrl: 'flags/kw.svg',
  },
  'KY': {
    name: {
      'en': 'Cayman Islands',
      'no': 'Caymanøyene',
      'se': 'Caymanöarna',
      'dk': 'Caymanøerne',
    },
    flagUrl: 'flags/ky.svg',
  },
  'KZ': {
    name: {
      'en': 'Kazakhstan',
      'no': 'Kasakhstan',
      'se': 'Kazakstan',
      'dk': 'Kasakhstan',
    },
    flagUrl: 'flags/kz.svg',
  },
  'LA': {
    name: {
      'en': 'Laos',
      'no': 'Laos',
      'se': 'Laos',
      'dk': 'Laos',
    },
    flagUrl: 'flags/la.svg',
  },
  'LB': {
    name: {
      'en': 'Lebanon',
      'no': 'Libanon',
      'se': 'Libanon',
      'dk': 'Libanon',
    },
    flagUrl: 'flags/lb.svg',
  },
  // 'LC'
  'LI': {
    name: {
      'en': 'Liechtenstein',
      'no': 'Liechtenstein',
      'se': 'Liechtenstein',
      'dk': 'Liechtenstein',
    },
    flagUrl: 'flags/li.svg',
  },
  'LK': {
    name: {
      'en': 'Sri Lanka',
      'no': 'Sri Lanka',
      'se': 'Sri Lanka',
      'dk': 'Sri Lanka',
    },
    flagUrl: 'flags/lk.svg',
  },
  'LR': {
    name: {
      'en': 'Liberia',
      'no': 'Liberia',
      'se': 'Liberia',
      'dk': 'Liberia',
    },
    flagUrl: 'flags/lr.svg',
  },
  'LS': {
    name: {
      'en': 'Lesotho',
      'no': 'Lesotho',
      'se': 'Lesotho',
      'dk': 'Lesotho',
    },
    flagUrl: 'flags/ls.svg',
  },
  'LT': {
    name: {
      'en': 'Lithuania',
      'no': 'Litauen',
      'se': 'Litauen',
      'dk': 'Litauen',
    },
    flagUrl: 'flags/lt.svg',
  },
  'LU': {
    name: {
      'en': 'Luxembourg',
      'no': 'Luxembourg',
      'se': 'Luxemburg',
      'dk': 'Luxembourg',
    },
    flagUrl: 'flags/lu.svg',
  },
  'LV': {
    name: {
      'en': 'Latvia',
      'no': 'Latvia',
      'se': 'Lettland',
      'dk': 'Letland',
    },
    flagUrl: 'flags/lv.svg',
  },
  'LY': {
    name: {
      'en': 'Libya',
      'no': 'Libya',
      'se': 'Libyen',
      'dk': 'Libyen',
    },
    flagUrl: 'flags/ly.svg',
  },
  'MA': {
    name: {
      'en': 'Morocco',
      'no': 'Marokko',
      'se': 'Marocko',
      'dk': 'Marokko',
    },
    flagUrl: 'flags/ma.svg',
  },
  'MC': {
    name: {
      'en': 'Monaco',
      'no': 'Monaco',
      'se': 'Monaco',
      'dk': 'Monaco',
    },
    flagUrl: 'flags/mc.svg',
  },
  'MD': {
    name: {
      'en': 'Moldova',
      'no': 'Moldova',
      'se': 'Moldavien',
      'dk': 'Moldova',
    },
    flagUrl: 'flags/md.svg',
  },
  'ME': {
    name: {
      'en': 'Montenegro',
      'no': 'Montenegro',
      'se': 'Montenegro',
      'dk': 'Montenegro',
    },
    flagUrl: 'flags/me.svg',
  },
  // 'MF'
  'MG': {
    name: {
      'en': 'Madagascar',
      'no': 'Madagaskar',
      'se': 'Madagaskar',
      'dk': 'Madagaskar',
    },
    flagUrl: 'flags/mg.svg',
  },
  // 'MH'
  'MK': {
    name: {
      'en': 'North Macedonia',
      'no': 'Nord-Makedonia',
      'se': 'Nordmakedonien',
      'dk': 'Nordmakedonien',
    },
    flagUrl: 'flags/mk.svg',
  },
  'ML': {
    name: {
      'en': 'Mali',
      'no': 'Mali',
      'se': 'Mali',
      'dk': 'Mali',
    },
    flagUrl: 'flags/ml.svg',
  },
  'MM': {
    name: {
      'en': 'Myanmar',
      'no': 'Myanmar',
      'se': 'Myanmar',
      'dk': 'Myanmar',
    },
    flagUrl: 'flags/mm.svg',
  },
  'MN': {
    name: {
      'en': 'Mongolia',
      'no': 'Mongolia',
      'se': 'Mongoliet',
      'dk': 'Mongoliet',
    },
    flagUrl: 'flags/mn.svg',
  },
  'MO': {
    name: {
      'en': 'Macau',
      'no': 'Macao',
      'se': 'Macao',
      'dk': 'Macao',
    },
    flagUrl: 'flags/mo.svg',
  },
  // 'MP'
  'MQ': {
    name: {
      'en': 'Martinique',
      'no': 'Martinique',
      'se': 'Martinique',
      'dk': 'Martinique',
    },
    flagUrl: 'flags/mq.svg',
  },
  'MR': {
    name: {
      'en': 'Mauritania',
      'no': 'Mauritania',
      'se': 'Mauretanien',
      'dk': 'Mauretanien',
    },
    flagUrl: 'flags/mr.svg',
  },
  'MS': {
    name: {
      'en': 'Montserrat',
      'no': 'Montserrat',
      'se': 'Montserrat',
      'dk': 'Montserrat',
    },
    flagUrl: 'flags/ms.svg',
  },
  'MT': {
    name: {
      'en': 'Malta',
      'no': 'Malta',
      'se': 'Malta',
      'dk': 'Malta',
    },
    flagUrl: 'flags/mt.svg',
  },
  'MU': {
    name: {
      'en': 'Mauritius',
      'no': 'Mauritius',
      'se': 'Mauritius',
      'dk': 'Mauritius',
    },
    flagUrl: 'flags/mu.svg',
  },
  'MV': {
    name: {
      'en': 'Maldives',
      'no': 'Maldivene',
      'se': 'Maldiverna',
      'dk': 'Maldiverne',
    },
    flagUrl: 'flags/mv.svg',
  },
  'MW': {
    name: {
      'en': 'Malawi',
      'no': 'Malawi',
      'se': 'Malawi',
      'dk': 'Malawi',
    },
    flagUrl: 'flags/mw.svg',
  },
  'MX': {
    name: {
      'en': 'Mexico',
      'no': 'Mexico',
      'se': 'Mexiko',
      'dk': 'Mexico',
    },
    flagUrl: 'flags/mx.svg',
  },
  'MY': {
    name: {
      'en': 'Malaysia',
      'no': 'Malaysia',
      'se': 'Malaysia',
      'dk': 'Malaysia',
    },
    flagUrl: 'flags/my.svg',
  },
  'MZ': {
    name: {
      'en': 'Mozambique',
      'no': 'Mosambik',
      'se': 'Moçambique',
      'dk': 'Mozambique',
    },
    flagUrl: 'flags/mz.svg',
  },
  'NA': {
    name: {
      'en': 'Namibia',
      'no': 'Namibia',
      'se': 'Namibia',
      'dk': 'Namibia',
    },
    flagUrl: 'flags/na.svg',
  },
  // 'NC'
  'NE': {
    name: {
      'en': 'Niger',
      'no': 'Niger',
      'se': 'Niger',
      'dk': 'Niger',
    },
    flagUrl: 'flags/ne.svg',
  },
  // 'NF'
  'NG': {
    name: {
      'en': 'Nigeria',
      'no': 'Nigeria',
      'se': 'Nigeria',
      'dk': 'Nigeria',
    },
    flagUrl: 'flags/ng.svg',
  },
  'NI': {
    name: {
      'en': 'Nicaragua',
      'no': 'Nicaragua',
      'se': 'Nicaragua',
      'dk': 'Nicaragua',
    },
    flagUrl: 'flags/ni.svg',
  },
  'NL': {
    name: {
      'en': 'Netherlands',
      'no': 'Nederland',
      'se': 'Nederländerna',
      'dk': 'Holland',
    },
    flagUrl: 'flags/nl.svg',
  },
  'NO': {
    name: {
      'en': 'Norway',
      'no': 'Norge',
      'se': 'Norge',
      'dk': 'Norge',
    },
    flagUrl: 'flags/no.svg',
  },
  'NP': {
    name: {
      'en': 'Nepal',
      'no': 'Nepal',
      'se': 'Nepal',
      'dk': 'Nepal',
    },
    flagUrl: 'flags/np.svg',
  },
  // 'NR'
  // 'NU'
  'NZ': {
    name: {
      'en': 'New Zealand',
      'no': 'New Zealand',
      'se': 'Nya Zeeland',
      'dk': 'New Zealand',
    },
    flagUrl: 'flags/nz.svg',
  },
  'OM': {
    name: {
      'en': 'Oman',
      'no': 'Oman',
      'se': 'Oman',
      'dk': 'Oman',
    },
    flagUrl: 'flags/om.svg',
  },
  'PA': {
    name: {
      'en': 'Panama',
      'no': 'Panama',
      'se': 'Panama',
      'dk': 'Panama',
    },
    flagUrl: 'flags/pa.svg',
  },
  // PC
  'PE': {
    name: {
      'en': 'Peru',
      'no': 'Peru',
      'se': 'Peru',
      'dk': 'Peru',
    },
    flagUrl: 'flags/pe.svg',
  },
  'PF': {
    name: {
      'en': 'French Polynesia',
      'no': 'Fransk Polynesia',
      'se': 'Franska Polynesien',
      'dk': 'Fransk Polynesien',
    },
    flagUrl: 'flags/pf.svg',
  },
  'PG': {
    name: {
      'en': 'Papua New Guinea',
      'no': 'Papua Ny-Guinea',
      'se': 'Papua Nya Guinea',
      'dk': 'Papua Ny Guinea',
    },
    flagUrl: 'flags/pg.svg',
  },
  'PH': {
    name: {
      'en': 'Philippines',
      'no': 'Filippinene',
      'se': 'Filippinerna',
      'dk': 'Filippinerne',
    },
    flagUrl: 'flags/ph.svg',
  },
  'PK': {
    name: {
      'en': 'Pakistan',
      'no': 'Pakistan',
      'se': 'Pakistan',
      'dk': 'Pakistan',
    },
    flagUrl: 'flags/pk.svg',
  },
  'PL': {
    name: {
      'en': 'Poland',
      'no': 'Polen',
      'se': 'Polen',
      'dk': 'Polen',
    },
    flagUrl: 'flags/pl.svg',
  },
  // 'PM'
  // 'PN'
  'PR': {
    name: {
      'en': 'Puerto Rico',
      'no': 'Puerto Rico',
      'se': 'Puerto Rico',
      'dk': 'Puerto Rico',
    },
    flagUrl: 'flags/pr.svg',
  },
  'PS': {
    name: {
      'en': 'Palestine',
      'no': 'Palestina',
      'se': 'Palestina',
      'dk': 'Palæstina',
    },
    flagUrl: 'flags/ps.svg',
  },
  'PT': {
    name: {
      'en': 'Portugal',
      'no': 'Portugal',
      'se': 'Portugal',
      'dk': 'Portugal',
    },
    flagUrl: 'flags/pt.svg',
  },
  // 'PW'
  'PY': {
    name: {
      'en': 'Paraguay',
      'no': 'Paraguay',
      'se': 'Paraguay',
      'dk': 'Paraguay',
    },
    flagUrl: 'flags/py.svg',
  },
  'QA': {
    name: {
      'en': 'Qatar',
      'no': 'Qatar',
      'se': 'Qatar',
      'dk': 'Qatar',
    },
    flagUrl: 'flags/qa.svg',
  },
  'RE': {
    name: {
      'en': 'Réunion',
      'no': 'Réunion',
      'se': 'Réunion',
      'dk': 'Réunion',
    },
    flagUrl: 'flags/re.svg',
  },
  'RO': {
    name: {
      'en': 'Romania',
      'no': 'Romania',
      'se': 'Rumänien',
      'dk': 'Rumænien',
    },
    flagUrl: 'flags/ro.svg',
  },
  'RS': {
    name: {
      'en': 'Serbia',
      'no': 'Serbia',
      'se': 'Serbien',
      'dk': 'Serbien',
    },
    flagUrl: 'flags/rs.svg',
  },
  'RU': {
    name: {
      'en': 'Russia',
      'no': 'Russland',
      'se': 'Ryssland',
      'dk': 'Rusland',
    },
    flagUrl: 'flags/ru.svg',
  },
  'RW': {
    name: {
      'en': 'Rwanda',
      'no': 'Rwanda',
      'se': 'Rwanda',
      'dk': 'Rwanda',
    },
    flagUrl: 'flags/rw.svg',
  },
  'SA': {
    name: {
      'en': 'Saudi Arabia',
      'no': 'Saudi-Arabia',
      'se': 'Saudiarabien',
      'dk': 'Saudi-Arabien',
    },
    flagUrl: 'flags/sa.svg',
  },
  'SB': {
    name: {
      'en': 'Solomon Islands',
      'no': 'Salomonøyene',
      'se': 'Salomonöarna',
      'dk': 'Salomonøerne',
    },
    flagUrl: 'flags/sb.svg',
  },
  'SC': {
    name: {
      'en': 'Seychelles',
      'no': 'Seychellene',
      'se': 'Seychellerna',
      'dk': 'Seychellerne',
    },
    flagUrl: 'flags/sc.svg',
  },
  'SD': {
    name: {
      'en': 'Sudan',
      'no': 'Sudan',
      'se': 'Sudan',
      'dk': 'Sudan',
    },
    flagUrl: 'flags/sd.svg',
  },
  'SE': {
    name: {
      'en': 'Sweden',
      'no': 'Sverige',
      'se': 'Sverige',
      'dk': 'Sverige',
    },
    flagUrl: 'flags/se.svg',
  },
  'SG': {
    name: {
      'en': 'Singapore',
      'no': 'Singapore',
      'se': 'Singapore',
      'dk': 'Singapore',
    },
    flagUrl: 'flags/sg.svg',
  },
  // 'SH-AC' / 'SH-HL' / 'SH-TA' / 'SH'
  'SI': {
    name: {
      'en': 'Slovenia',
      'no': 'Slovenia',
      'se': 'Slovenien',
      'dk': 'Slovenien',
    },
    flagUrl: 'flags/si.svg',
  },
  // 'SJ'
  'SK': {
    name: {
      'en': 'Slovakia',
      'no': 'Slovakia',
      'se': 'Slovakien',
      'dk': 'Slovakiet',
    },
    flagUrl: 'flags/sk.svg',
  },
  'SL': {
    name: {
      'en': 'Sierra Leone',
      'no': 'Sierra Leone',
      'se': 'Sierra Leone',
      'dk': 'Sierra Leone',
    },
    flagUrl: 'flags/sl.svg',
  },
  'SM': {
    name: {
      'en': 'San Marino',
      'no': 'San Marino',
      'se': 'San Marino',
      'dk': 'San Marino',
    },
    flagUrl: 'flags/sm.svg',
  },
  'SN': {
    name: {
      'en': 'Senegal',
      'no': 'Senegal',
      'se': 'Senegal',
      'dk': 'Senegal',
    },
    flagUrl: 'flags/sn.svg',
  },
  'SO': {
    name: {
      'en': 'Somalia',
      'no': 'Somalia',
      'se': 'Somalia',
      'dk': 'Somalia',
    },
    flagUrl: 'flags/so.svg',
  },
  'SR': {
    name: {
      'en': 'Suriname',
      'no': 'Surinam',
      'se': 'Surinam',
      'dk': 'Surinam',
    },
    flagUrl: 'flags/sr.svg',
  },
  'SS': {
    name: {
      'en': 'South Sudan',
      'no': 'Sør-Sudan',
      'se': 'Sydsudan',
      'dk': 'Sydsudan',
    },
    flagUrl: 'flags/ss.svg',
  },
  // 'ST'
  'SV': {
    name: {
      'en': 'El Salvador',
      'no': 'El Salvador',
      'se': 'El Salvador',
      'dk': 'El Salvador',
    },
    flagUrl: 'flags/sv.svg',
  },
  // 'SX'
  'SY': {
    name: {
      'en': 'Syria',
      'no': 'Syria',
      'se': 'Syrien',
      'dk': 'Syrien',
    },
    flagUrl: 'flags/sy.svg',
  },
  'SZ': {
    name: {
      'en': 'Swaziland',
      'no': 'Swaziland',
      'se': 'Swaziland',
      'dk': 'Swaziland',
    },
    flagUrl: 'flags/sz.svg',
  },
  // 'TC'
  'TD': {
    name: {
      'en': 'Chad',
      'no': 'Tsjad',
      'se': 'Tchad',
      'dk': 'Tchad',
    },
    flagUrl: 'flags/td.svg',
  },
  // 'TF'
  'TG': {
    name: {
      'en': 'Togo',
      'no': 'Togo',
      'se': 'Togo',
      'dk': 'Togo',
    },
    flagUrl: 'flags/tg.svg',
  },
  'TH': {
    name: {
      'en': 'Thailand',
      'no': 'Thailand',
      'se': 'Thailand',
      'dk': 'Thailand',
    },
    flagUrl: 'flags/th.svg',
  },
  'TJ': {
    name: {
      'en': 'Tajikistan',
      'no': 'Tadsjikistan',
      'se': 'Tadzjikistan',
      'dk': 'Tadsjikistan',
    },
    flagUrl: 'flags/tj.svg',
  },
  // 'TK'
  'TL': {
    name: {
      'en': 'Timor-Leste',
      'no': 'Øst-Timor',
      'se': 'Östtimor',
      'dk': 'Østtimor',
    },
    flagUrl: 'flags/tl.svg',
  },
  'TM': {
    name: {
      'en': 'Turkmenistan',
      'no': 'Turkmenistan',
      'se': 'Turkmenistan',
      'dk': 'Turkmenistan',
    },
    flagUrl: 'flags/tm.svg',
  },
  'TN': {
    name: {
      'en': 'Tunisia',
      'no': 'Tunisia',
      'se': 'Tunisien',
      'dk': 'Tunesien',
    },
    flagUrl: 'flags/tn.svg',
  },
  'TO': {
    name: {
      'en': 'Tonga',
      'no': 'Tonga',
      'se': 'Tonga',
      'dk': 'Tonga',
    },
    flagUrl: 'flags/to.svg',
  },
  'TR': {
    name: {
      'en': 'Turkey',
      'no': 'Tyrkia',
      'se': 'Turkiet',
      'dk': 'Tyrkiet',
    },
    flagUrl: 'flags/tr.svg',
  },
  'TT': {
    name: {
      'en': 'Trinidad and Tobago',
      'no': 'Trinidad og Tobago',
      'se': 'Trinidad och Tobago',
      'dk': 'Trinidad og Tobago',
    },
    flagUrl: 'flags/tt.svg',
  },
  // 'TV'
  'TW': {
    name: {
      'en': 'Taiwan',
      'no': 'Taiwan',
      'se': 'Taiwan',
      'dk': 'Taiwan',
    },
    flagUrl: 'flags/tw.svg',
  },
  'TZ': {
    name: {
      'en': 'Tanzania',
      'no': 'Tanzania',
      'se': 'Tanzania',
      'dk': 'Tanzania',
    },
    flagUrl: 'flags/tz.svg',
  },
  'UA': {
    name: {
      'en': 'Ukraine',
      'no': 'Ukraina',
      'se': 'Ukraina',
      'dk': 'Ukraine',
    },
    flagUrl: 'flags/ua.svg',
  },
  'UG': {
    name: {
      'en': 'Uganda',
      'no': 'Uganda',
      'se': 'Uganda',
      'dk': 'Uganda',
    },
    flagUrl: 'flags/ug.svg',
  },
  // 'UM'
  'US': {
    name: {
      'en': 'United States',
      'no': 'USA',
      'se': 'USA',
      'dk': 'USA',
    },
    flagUrl: 'flags/us.svg',
  },
  'UY': {
    name: {
      'en': 'Uruguay',
      'no': 'Uruguay',
      'se': 'Uruguay',
      'dk': 'Uruguay',
    },
    flagUrl: 'flags/uy.svg',
  },
  'UZ': {
    name: {
      'en': 'Uzbekistan',
      'no': 'Usbekistan',
      'se': 'Uzbekistan',
      'dk': 'Usbekistan',
    },
    flagUrl: 'flags/uz.svg',
  },
  // 'VA'
  // 'VC'
  'VE': {
    name: {
      'en': 'Venezuela',
      'no': 'Venezuela',
      'se': 'Venezuela',
      'dk': 'Venezuela',
    },
    flagUrl: 'flags/ve.svg',
  },
  // 'VG'
  // 'VI'
  'VN': {
    name: {
      'en': 'Vietnam',
      'no': 'Vietnam',
      'se': 'Vietnam',
      'dk': 'Vietnam',
    },
    flagUrl: 'flags/vn.svg',
  },
  'VU': {
    name: {
      'en': 'Vanuatu',
      'no': 'Vanuatu',
      'se': 'Vanuatu',
      'dk': 'Vanuatu',
    },
    flagUrl: 'flags/vu.svg',
  },
  // 'WF'
  'WS': {
    name: {
      'en': 'Samoa',
      'no': 'Samoa',
      'se': 'Samoa',
      'dk': 'Samoa',
    },
    flagUrl: 'flags/ws.svg',
  },
  'XK': {
    name: {
      'en': 'Kosovo',
      'no': 'Kosovo',
      'se': 'Kosovo',
      'dk': 'Kosovo',
    },
    flagUrl: 'flags/xk.svg',
  },
  'XX': {
    name: {
      'en': 'World',
      'no': 'Verden',
      'se': 'Världen',
      'dk': 'Verden',
    },
    flagUrl: 'flags/xx.svg',
    excludeFromSearch: true,
  },
  'YE': {
    name: {
      'en': 'Yemen',
      'no': 'Jemen',
      'se': 'Jemen',
      'dk': 'Jemen',
    },
    flagUrl: 'flags/ye.svg',
  },
  'YT': {
    name: {
      'en': 'Mayotte',
      'no': 'Mayotte',
      'se': 'Mayotte',
      'dk': 'Mayotte',
    },
    flagUrl: 'flags/yt.svg',
  },
  'ZA': {
    name: {
      'en': 'South Africa',
      'no': 'Sør-Afrika',
      'se': 'Sydafrika',
      'dk': 'Sydafrika',
    },
    flagUrl: 'flags/za.svg',
  },
  'ZM': {
    name: {
      'en': 'Zambia',
      'no': 'Zambia',
      'se': 'Zambia',
      'dk': 'Zambia',
    },
    flagUrl: 'flags/zm.svg',
  },
  'ZW': {
    name: {
      'en': 'Zimbabwe',
      'no': 'Zimbabwe',
      'se': 'Zimbabwe',
      'dk': 'Zimbabwe',
    },
    flagUrl: 'flags/zw.svg',
  },
};


export const enhancedCountryList: EnhancedCountryInfo[] = Object.entries(countryCodeToCountryInfo).map(([key, value]) => ({
  countryCode: key,
  ...value,
}));


export const countryCodeListSubset: string[] = [
  'NO',
  'SE',
  'DK',
  'FI',
  'IS',
  'DE',
  'NL',
  'BE',
  'GB-ENG',
  'GB-SCT',
  'GB-NIR',
  'GB-WLS',
  'FR',
  'ES',
  'PT',
  'IT',
  'GR',
  'AT',
  'CH',
  'IE',
  'LU',
  'EE',
  'LV',
  'LT',
  'PL',
  'CZ',
  'SK',
  'HU',
  'SI',
  'HR',
  'BA',
  'ME',
  'AL',
  'MK',
  'RS',
  'BG',
  'RO',
  'MD',
  'UA',
  'BY',
  'RU',
  'TR',
  'CY',
];
