import { useRecoilValue } from 'recoil';
import { competitionsState } from '../../../recoil/atoms/competitionsState';

import { countryCodeToCountryInfo } from '../../../static/countries';


interface CompetitionFlagProps {
  competitionId: number | string | undefined;
  width: number;

  doNotShowFlag?: boolean;
  seasonToShow?: string;
  showHyphenIfEmpty?: boolean;

  containerStyle?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  isWhiteBackground?: boolean;
  smallFontSize?: number;
  smallFontSizeThreshold?: number; // if competition name is longer than this, the font size will be smallFontSize
}


export const CompetitionFlag: React.FC<CompetitionFlagProps> = ({
  competitionId,
  width,

  doNotShowFlag,
  seasonToShow,
  showHyphenIfEmpty,

  containerStyle,
  imageStyle,
  isWhiteBackground,
  smallFontSize,
  smallFontSizeThreshold,
}) => {


  const competitions = useRecoilValue(competitionsState);
  const competition = competitionId !== undefined && competitionId !== null ? competitions[Number(competitionId)] : undefined;
  const competitionName = competition?.name;

  const countryCode = competition?.countryCode;
  const countryInfo = countryCode ? countryCodeToCountryInfo[countryCode] : undefined;

  const fontSize = smallFontSizeThreshold !== undefined && competitionName && competitionName.length > smallFontSizeThreshold ? smallFontSize : undefined;

  return (
    <div
      className='image-row-container'
      style={{
        fontSize: fontSize,
        ...containerStyle,
      }}
    >
      {!doNotShowFlag && countryInfo && (
        <img
          src={countryInfo.flagUrl}
          alt={''}
          className={'flag-image' + (isWhiteBackground ? '-whiteBackground' : '')}
          style={{
            width: width,
            ...imageStyle,
          }}
          draggable={false}
        />
      )}

      {competitionName && <div>{competitionName}</div>}

      {seasonToShow && <div>{seasonToShow}</div>}

      {showHyphenIfEmpty && (competitionId === undefined || competitionId === null) && !seasonToShow && <div>-</div>}
    </div>
  );
};
