import '../tables.css';

import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../../recoil/atoms/userSettingsState';

import { useMemo } from 'react';
import { useTable, useBlockLayout, Column } from 'react-table';

import { getEmptyPlayerSimpleTableWidth, getPlayerSimpleColumns } from './playerSimpleColumns';
import { StringToAnyMap, PlayerId } from '../../../types';
import { getClubColor } from '../../../static/clubConfigs';
import { debounce } from '../../../utils/utils';
import { playerSimpleTableChapterSize } from '../../../views/scouting/Scouting';


interface PlayerSimpleTableProps {
  tableData: StringToAnyMap[];
  onPlayerClick: (player: StringToAnyMap) => void;
  tableType: string | undefined; // either a specific table type or a teamOrSquadId (undefined -> team)

  positionKey?: string;
  draggedPlayer?: StringToAnyMap | undefined;
  setDraggedPlayer?: (player: StringToAnyMap | undefined) => void;
  handlePlayerDrop?: (newPosition: string, positionIndex?: number) => void;
  tableHoverDisabled?: boolean;
  droppedPlayer?: StringToAnyMap | undefined;

  isSearchResultTable?: boolean;
  forceLoadPlayerImages?: boolean;
  isLoading?: boolean;
  handleSearchForPlayers?: (isUserInitiated: boolean, isNewSearch: boolean) => Promise<void>;
  currentPage?: number;
  totalHits?: number;

  disablePlayersWithoutEventData?: boolean;
  isGoalkeeper?: boolean; // if provided -> if true, players who are not goalkeepers will be disabled, and vice versa

  maxHeight?: string; // if provided, scroll will be enabled
  highlightedPlayerId?: PlayerId | undefined;
}

export const PlayerSimpleTable: React.FC<PlayerSimpleTableProps> = ({
  tableData,
  onPlayerClick,
  tableType,

  positionKey,
  draggedPlayer,
  setDraggedPlayer,
  handlePlayerDrop,
  tableHoverDisabled,
  droppedPlayer,

  isSearchResultTable,
  forceLoadPlayerImages,
  isLoading,
  handleSearchForPlayers,
  currentPage,
  totalHits,

  disablePlayersWithoutEventData,
  isGoalkeeper,

  maxHeight,
  highlightedPlayerId,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);

  const [draggedPlayerIdWithDelay, setDraggedPlayerIdWithDelay] = useState<string | undefined>(undefined);
  const [originalDraggedPlayerHasMoved, setOriginalDraggedPlayerHasMoved] = useState<boolean>(false);
  const [originalPlayerHasLeftOriginalTable, setOriginalPlayerHasLeftOriginalTable] = useState<boolean>(false);

  const [tableIsDraggedOver, setTableIsDraggedOver] = useState(false);

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const overlayContainerRef = useRef<HTMLDivElement>(null);
  const [tableHasOverflow, setTableHasOverflow] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);


  const [data, setData] = useState<StringToAnyMap[]>(tableData ?? []);
  useEffect(() => {
    setData(tableData ?? []);
  }, [tableData]);


  const teamMenu = useMemo(() => {
    return (
      (tableType === 'ownTeam' || tableType === 'bench')
        ? userSettings?.ownTeamMenu
        : tableType === 'academyTeam'
          ? userSettings?.academyTeamMenu
          : userSettings?.teamMenu
    ) ?? {};
  }, [tableType, userSettings?.ownTeamMenu, userSettings?.academyTeamMenu, userSettings?.teamMenu]);


  // if fetching images dynamically, the images will re-render and flicker whenever the table is re-rendered
  // therefore, we allow dynamic fetching until the first re-render, and then we disable it
  const componentHasMounted = useRef(false);
  const [anyPlayerHasBeenDragged, setAnyPlayerHasBeenDragged] = useState<boolean>(false);
  useEffect(() => {
    if (!anyPlayerHasBeenDragged && draggedPlayer && draggedPlayer.positionKey === positionKey && !isSearchResultTable) {
      setAnyPlayerHasBeenDragged(true);
    }
  }, [anyPlayerHasBeenDragged, draggedPlayer, positionKey, isSearchResultTable]);


  const columns: Column<StringToAnyMap>[] = useMemo(
    () => {
      const forceLoadImages = forceLoadPlayerImages ?? (anyPlayerHasBeenDragged || componentHasMounted.current);
      componentHasMounted.current = true;
      return getPlayerSimpleColumns(
        userConfig?.club ?? '',
        tableType,
        teamMenu,
        disablePlayersWithoutEventData,
        isGoalkeeper,
        forceLoadImages,
      );
    },
    [userConfig?.club, tableType, teamMenu, disablePlayersWithoutEventData, isGoalkeeper, forceLoadPlayerImages, anyPlayerHasBeenDragged]
  );

  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useBlockLayout,
  );


  useEffect(() => {
    if (draggedPlayer) {
      setTimeout(() => {
        setDraggedPlayerIdWithDelay(draggedPlayer.id);
      }, 1);
    }
    else {
      setDraggedPlayerIdWithDelay(undefined);
    }
  }, [draggedPlayer]);


  // get the index of the dragged player copy shown in the table, assuming the original dragged player does not exist
  const getCopyIndex = () => {
    if (!draggedPlayer) return -1;

    let copyIndex = data.findIndex(player => player.id === draggedPlayer.id + '-copy');

    const originalIndex = data.findIndex(player => player.id === draggedPlayer.id);

    if (originalDraggedPlayerHasMoved && originalIndex !== -1) {
      if (copyIndex > originalIndex) copyIndex--;
    }

    return copyIndex;
  };


  // get the next index of the dragged player copy shown in the table when entering drop zone with index adjusetdDropZoneIndex
  const getNewDropIndex = (adjusetdDropZoneIndex: number) => {
    if (!draggedPlayer) return;

    const copyIndex = getCopyIndex();

    // if the copy is already in the table, the two drop zones around the copy (dropIndex and dropIndex + 1) both belong to the copy
    // when entering a new drop zone with a higher index than the copy, the adjusetdDropZoneIndex must be 2 higher to trigger 1 move downwards
    // when entering a new drop zone with a lower index than the copy, the copy just moves upwards to the new index
    const adjustedCopyIndex = copyIndex !== -1 && adjusetdDropZoneIndex > copyIndex ? adjusetdDropZoneIndex - 1 : adjusetdDropZoneIndex;
    if (adjustedCopyIndex === copyIndex) return;

    // the index logic so far assumes the original dragged player is not in the table, but when inserting here, we must adjust for the original row
    const originalIndex = data.findIndex(player => player.id === draggedPlayer.id);
    if (originalDraggedPlayerHasMoved && originalIndex !== -1) {
      if (adjusetdDropZoneIndex >= originalIndex) return adjustedCopyIndex + 1;
    }

    return adjustedCopyIndex;
  };


  const handleDragStart = (player: StringToAnyMap) => {
    if (positionKey && setDraggedPlayer) {
      setDraggedPlayer({
        ...player,
        positionKey: positionKey,
      });
    }
  };


  const handleDragEnd = () => {
    if (setDraggedPlayer) {
      setDraggedPlayer(undefined);
    }
    setTableIsDraggedOver(false);
    setOriginalDraggedPlayerHasMoved(false);
    setOriginalPlayerHasLeftOriginalTable(false);
  };


  const handleDragDrop = (event: React.DragEvent<HTMLDivElement>) => {
    if (handlePlayerDrop) {
      event.preventDefault();
      if (positionKey && draggedPlayer) {
        let dropIndex: number | undefined = getCopyIndex();
        dropIndex = dropIndex === -1 ? undefined : dropIndex;

        // the drop should not trigger an update if the player is dropped in the same position and index
        // if no update should be triggered, we must abort the drop and reset the data
        const tableIndexOfOriginalDraggedPlayer = tableData.findIndex(player => player.id === draggedPlayer.id);
        const dropShouldTriggerUpdate = tableIndexOfOriginalDraggedPlayer === -1 || tableIndexOfOriginalDraggedPlayer !== dropIndex;

        if (dropShouldTriggerUpdate) {
          handlePlayerDrop(positionKey, dropIndex === -1 ? undefined : dropIndex);
          const newData = [...data].filter(player => player.id !== draggedPlayer.id);
          setData(newData);
        }

        else {
          setData(tableData);
          handleDragEnd();
        }
      }
    }
    setTableIsDraggedOver(false);
    setOriginalDraggedPlayerHasMoved(false);
    setOriginalPlayerHasLeftOriginalTable(false);
  };


  const handleTableScroll = () => {
    if (tableContainerRef.current) {
      setScrollOffset(tableContainerRef.current.scrollTop);
    }
  };


  const handleDragOverRow = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };


  const calculateDynamicSpeed = (
    distance: number,
    edgeZone: number,
    flatZone: number,
    maxSpeed: number,
  ) => {
    const baseSpeed = 5;
    if (distance <= flatZone) {
      return baseSpeed;
    }
    else {
      const exponentialFactor = 12;
      const excessDistance = distance - flatZone;
      const exponentialSpeed = baseSpeed * Math.pow(exponentialFactor, excessDistance / edgeZone);
      return Math.min(exponentialSpeed, maxSpeed);
    }
  };


  const handleDragOverContainer = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    const container = tableContainerRef.current;
    if (!container) return;

    const { top, bottom } = container.getBoundingClientRect();
    const edgeZone = 30;
    const flatZone = 15;
    const maxSpeed = 100;

    if (event.clientY < top + edgeZone) {
      const distanceToTopEdge = top + edgeZone - event.clientY;
      const speed = calculateDynamicSpeed(distanceToTopEdge, edgeZone, flatZone, maxSpeed);
      container.scrollBy({ top: -speed, behavior: 'smooth' });
    }
    else if (event.clientY > bottom - edgeZone) {
      const distanceToBottomEdge = event.clientY - (bottom - edgeZone);
      const speed = calculateDynamicSpeed(distanceToBottomEdge, edgeZone, flatZone, maxSpeed);
      container.scrollBy({ top: speed, behavior: 'smooth' });
    }
  };


  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    const relatedTarget = event.relatedTarget as Node | null;
    if (overlayContainerRef?.current && !overlayContainerRef.current.contains(relatedTarget)) {
      setTableIsDraggedOver(false);
      setOriginalDraggedPlayerHasMoved(true);
      setOriginalPlayerHasLeftOriginalTable(true);
      if (draggedPlayer) {
        const newData = [...data].filter(player => player.id !== draggedPlayer.id + '-copy');
        setData(newData);
      }
    }
  };


  const handleDragEnter = (index: number) => {
    setTableIsDraggedOver(true);
    if (draggedPlayer) {
      const newDropIndex = getNewDropIndex(index);
      if (newDropIndex === undefined) return;

      const newData = [...data].filter(player => (player.id !== draggedPlayer.id + '-copy'));
      newData.splice(newDropIndex, 0, { ...draggedPlayer, 'id': draggedPlayer.id + '-copy' });

      setData(newData);
      setOriginalDraggedPlayerHasMoved(true);
    }
  };

  // players that we know are goalkeepers can not be dropped on outfield positions
  // players that we know are outfield players can not be dropped on the goalkeeper position if there are no goalkeepers in the team
  // players without a position can not be dropped on the goalkeeper position if they come from an outfield position and there are no goalkeepers in the team
  const playerIsGoalkeeper = draggedPlayer?.primary_positions?.includes('GK') || draggedPlayer?.primary_positions?.includes('G');
  const playerIsOutfielder = draggedPlayer?.primary_positions && !playerIsGoalkeeper;

  const isInvalidOutfieldDropZone = playerIsGoalkeeper && positionKey !== 'GK';
  const isInvalidGoalkeeperDropZone = playerIsOutfielder && positionKey === 'GK' && data.length === 0;
  const isInvalidGoalkeeperDropZoneForPlayersWithoutPosition = !draggedPlayer?.primary_positions
    && positionKey === 'GK'
    && !draggedPlayer?.positionKey?.startsWith('addPlayerTable')
    && data.length === 0;

  const isSingleDropZone = positionKey === 'bench' || teamMenu.orderBy;

  const isValidDropZone = handlePlayerDrop !== undefined
    && !isInvalidOutfieldDropZone
    && !isInvalidGoalkeeperDropZone
    && !isInvalidGoalkeeperDropZoneForPlayersWithoutPosition
    && draggedPlayerIdWithDelay !== undefined
    && (!isSingleDropZone || draggedPlayer?.positionKey !== positionKey);


  const getBoxShadow = () => {
    if (isValidDropZone) {
      if (tableIsDraggedOver) {
        return '0px 0px 0px 3px ' + clubColor;
      }
      return '0px 0px 0px 3px #cdd0e773';
    }
    return 'none';
  };


  const checkScrollPosition = async () => {
    if (!tableContainerRef.current || !isSearchResultTable || !handleSearchForPlayers || currentPage === undefined || totalHits === undefined) return;

    const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;
    const scrollPosition = scrollTop / (scrollHeight - clientHeight);
    const threshold = 0.4 + currentPage * 0.1;

    const isMoreDataToFetch = currentPage < playerSimpleTableChapterSize && data.length < totalHits;

    if (scrollPosition > threshold && !isLoading && isMoreDataToFetch) {
      await handleSearchForPlayers(true, false);
    }
  };


  useEffect(() => {
    if (isSearchResultTable && currentPage !== undefined && currentPage < playerSimpleTableChapterSize) {
      const [handleScroll, cancelHandleScroll] = debounce(() => checkScrollPosition(), 150);

      const tableContainer = tableContainerRef.current;
      if (tableContainer) {
        tableContainer.addEventListener('scroll', handleScroll);
      }

      return () => {
        if (tableContainer) {
          tableContainer.removeEventListener('scroll', handleScroll);
        }
        cancelHandleScroll();
      };
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (!isSearchResultTable) {
      const tableElement = tableContainerRef.current;
      if (tableElement) {
        tableElement.addEventListener('scroll', handleTableScroll);
      }
      return () => {
        if (tableElement) {
          tableElement.removeEventListener('scroll', handleTableScroll);
        }
      };
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const checkOverflow = () => {
      const element = tableContainerRef.current;
      const isOverflowing = element !== null && element.scrollHeight > element.clientHeight;
      setTableHasOverflow(isOverflowing);
    };

    checkOverflow();

    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [data]);


  const getTableRowClassName = (player: StringToAnyMap, index: number) => {

    // if this is the original dragged player, we hide it once the drag has started, unless the drag starts in a addPlayerTable
    if (player.id === draggedPlayerIdWithDelay
      && (originalDraggedPlayerHasMoved || (draggedPlayer?.positionKey?.startsWith('addPlayerTable') && !positionKey?.startsWith('addPlayerTable')))) {
      return 'player-simple-table-row-dragged-original';
    }

    if (droppedPlayer && player.id === droppedPlayer.id) {
      if (droppedPlayer.droppedPositionKey !== positionKey) {
        return 'player-simple-table-row-dropped-old';
      }
      if (teamMenu.colored !== 'role') {
        return 'player-simple-table-row-dropped-new';
      }
    }

    if (teamMenu.colored === 'role') {
      const baseClassName = 'player-simple-table-row player-simple-table-row-' + player.role;
      const hoverClassName = !tableHoverDisabled ? (' player-simple-table-row-hover player-simple-table-row-hover-' + player.role) : '';
      return baseClassName + hoverClassName;
    }

    else if (player.id === draggedPlayerIdWithDelay || player.id === (draggedPlayerIdWithDelay + '-copy')) {
      // if the original dragged player has moved, we must account for the offset of one invisible row
      let adjustedIndex = index;
      let adjusetdTableLength = tableData.length;

      const originalIndex = tableData.findIndex(player => player.id === draggedPlayerIdWithDelay);
      if (originalDraggedPlayerHasMoved && originalIndex !== -1) {
        if (index > originalIndex) adjustedIndex--;
        adjusetdTableLength--;
      }

      if (adjustedIndex === 0) {
        return 'player-simple-table-row-dragged-copy-top';
      }
      if (adjustedIndex === adjusetdTableLength) {
        return 'player-simple-table-row-dragged-copy-bottom';
      }
      return 'player-simple-table-row-dragged-copy-middle';
    }

    if (
      (disablePlayersWithoutEventData && !player['event_data_available']) ||
      (isGoalkeeper !== undefined && (isGoalkeeper === (!player['primary_positions'].includes('GK'))))
    ) {
      return 'player-simple-table-row-disabled';
    }

    if (player.id === highlightedPlayerId) {
      return 'player-simple-table-row-highlighted';
    }

    return 'player-simple-table-row' + (!tableHoverDisabled ? ' player-simple-table-row-hover' : '');
  };


  const showEmptyTableDropZone = !tableData || tableData.length === 0 || (
    !isSingleDropZone && data.every(player => (player.id === draggedPlayerIdWithDelay || player.id === (draggedPlayerIdWithDelay + '-copy')))
  );


  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div>

      {data.length > 0 && (
        <div
          className={
            showEmptyTableDropZone
              ? 'player-simple-table-container-hidden'
              : ('player-simple-table-container' + (!maxHeight ? ' player-simple-table-container-with-shadow' : ''))
          }
        >

          {isValidDropZone && !isSingleDropZone && (
            <div
              ref={overlayContainerRef}
              className='player-simple-table-overlay-container'
              style={{ maxHeight: maxHeight + '33px' }}
              onDragOver={handleDragOverContainer}
              onDragLeave={handleDragLeave}
            >
              {(Array.from({ length: rows.length + 1 }, (_, i) => i)).map(index => {

                // if the original dragged player is in this table and has moved, we must account for the offset of one invisible row
                let adjustedIndex = index;
                if (draggedPlayer && draggedPlayer.positionKey === positionKey) {
                  if (index === draggedPlayer.positionIndex) return null;
                  if (index > draggedPlayer.positionIndex) adjustedIndex--;
                }

                return (
                  <div
                    key={index}
                    className='player-simple-table-overlay'
                    style={{ top: (adjustedIndex * 33) - scrollOffset }}
                    onDragOver={handleDragOverRow}
                    onDragEnter={() => handleDragEnter(adjustedIndex)}
                    onDrop={(event) => handleDragDrop(event)}
                  />
                );
              })}
            </div>
          )}

          {isValidDropZone && isSingleDropZone && (
            <div
              ref={overlayContainerRef}
              className='player-simple-table-overlay-container-single-drop-zone'
              onDragOver={(event) => event.preventDefault()}
              onDragEnter={() => setTableIsDraggedOver(true)}
              onDragLeave={() => setTableIsDraggedOver(false)}
              onDrop={(event) => handleDragDrop(event)}
            />
          )}

          <div
            {...getTableProps()}
            ref={tableContainerRef}
            className={'player-simple-table' + (tableHasOverflow ? ' player-simple-table-overflowed' : '')}
            style={{ maxHeight: maxHeight, boxShadow: getBoxShadow() }}
          >
            <div
              {...getTableBodyProps()}
              className='player-simple-table-body'
            >
              {rows.map((row, index) => {
                prepareRow(row);
                const { key, ...restRowProps } = row.getRowProps();

                return (
                  <div
                    key={key}
                    {...restRowProps}
                    className={getTableRowClassName(row.original, index) + ''}
                    onClick={() => onPlayerClick(row.original)}
                    draggable={positionKey !== undefined && setDraggedPlayer !== undefined && row.original.id !== droppedPlayer?.id}
                    onDragStart={() => handleDragStart(row.original)}
                    onDragEnd={() => handleDragEnd()}
                  >
                    {row.cells.map(cell => {
                      const { key: cellKey, ...restCellProps } = cell.getCellProps();
                      return (
                        <div
                          key={cellKey}
                          {...restCellProps}
                        >
                          {cell.render('Cell')}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      )}

      {data.length === 0 && !isValidDropZone && positionKey !== 'GK' &&
        <div className='player-simple-table-empty-container' />
      }

      {showEmptyTableDropZone && isValidDropZone && (
        <div
          className={'player-simple-table-empty-container player-simple-table-empty-container-drag-active'}
          style={{
            boxShadow: tableIsDraggedOver && (draggedPlayer?.positionKey !== positionKey || originalPlayerHasLeftOriginalTable)
              ? '0px 0px 0px 3px ' + clubColor
              : 'none',
            width: getEmptyPlayerSimpleTableWidth(tableType, teamMenu)
          }}
          onDragOver={handleDragOverRow}
          onDragLeave={handleDragLeave}
          onDragEnter={() => handleDragEnter(0)}
          onDrop={(event) => handleDragDrop(event)}
        />
      )}

    </div>
  );
};
