import './../modals.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import BuildIcon from '@mui/icons-material/Build';

import { translate } from '../../../../common/language/translations';
import { RoleConfig } from '../../../types';
import { IconButton } from '../../controls/buttons/IconButton';
import { RoleConfigWeightBar } from '../../display/visualization/RoleConfigWeightBar';


interface RoleInfoModalProps {
  closeModal: () => void;
  roleConfig: RoleConfig;
}

export const RoleInfoModal: React.FC<RoleInfoModalProps> = ({ closeModal, roleConfig }) => {

  const userConfig = useRecoilValue(userConfigState);

  const roleConfigIsReady = roleConfig
    && roleConfig.weightsLastUpdated
    && roleConfig.dataLastUpdated
    && roleConfig.dataLastUpdated >= roleConfig.weightsLastUpdated;

  return (
    <div className='modal-root-container role-info-modal-root'>

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {roleConfig.name}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='role-info-modal-text' style={{ marginTop: '4vh' }}>
        <b>
          {roleConfig.name}
        </b>
        {translate('roleInfoModalText1', userConfig?.language)}
      </div>

      <div className='role-info-modal-text' style={{ marginTop: '2vh' }}>
        {translate('roleInfoModalText2', userConfig?.language)}
      </div>

      <div className='role-info-modal-text' style={{ marginTop: '2vh' }}>
        {translate('roleInfoModalText3', userConfig?.language)}
      </div>

      {!roleConfigIsReady && (
        <div className='role-info-modal-being-computed-section'>
          <BuildIcon style={{ fontSize: 18, marginTop: 1 }} />
          {translate('roleInfoModalText4', userConfig?.language)}
        </div>
      )}

      <div className='role-info-modal-role-config-weights'>
        <RoleConfigWeightBar
          roleConfig={roleConfig}
        />
      </div>

    </div>
  );
};
