

// some colors relate to the global css variables defined in src/index.css


export const svgColors = {
  'fokusLogoWhite': ' #ffffff',
  'fokusLogoDark': ' #090c11',

  'platformDarkColor': ' #171c25', // '#1f222c',
  'platformLightColor': ' #242a37', // '#2b2f3b',

  'svgBackgroundLightColor': ' #353c4c', // '#3d4357',

  'footballFieldDarkGrassLeft': ' #272d38', // '#2b2f3b',
  'footballFieldDarkGrassRight': ' #262b36', // '#272b36',
  'footballFieldLightGrassLeft': ' #3a404d', // '#3e4250',
  'footballFieldLightGrassRight': ' #3a404d', // '#3b3f4d',

  'footballFieldLineColor': ' #efefef',
  'footballFieldLineColorSemiTransparent': ' #efefef22',
};
