import './dropDowns.css';

import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import Fuse from 'fuse.js';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import CloseIcon from '@mui/icons-material/Close';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../input/InputField';
import { EnhancedCountryInfo, countryCodeListSubset, countryCodeToCountryInfo, enhancedCountryList } from '../../../static/countries';
import { IconButton } from '../buttons/IconButton';
import { CountryFlag } from '../../display/images/CountryFlag';


interface CountryDropDownProps {
  id: string;

  selectedCountryCodes: string[];
  setSelectedCountryCodes: (value: string[]) => void;
  isDropDownExpanded: boolean;
  setIsDropDownExpanded: (value: boolean) => void;

  defaultDropDownText: string; // static language key
  defaultDropDownTextColor: string;
  marginBetweenOptions: number;
  maxHeight?: string | number; // controls scrolling
  singleSelect?: boolean;
}


export const CountryDropDown: React.FC<CountryDropDownProps> = ({
  id,

  selectedCountryCodes,
  setSelectedCountryCodes,
  isDropDownExpanded,
  setIsDropDownExpanded,

  defaultDropDownText,
  defaultDropDownTextColor,
  marginBetweenOptions,
  maxHeight,
  singleSelect,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [searchString, setSearchString] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const [fuse, setFuse] = useState<Fuse<EnhancedCountryInfo> | null>(null);

  const [countriesToShow, setCountriesToShow] = useState<string[]>([]);


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setCountriesToShow(countryCodeListSubset);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForCountries(searchString);
    }
  };


  const handleSearchForCountries = useCallback((currentSearchString: string) => {
    if (!fuse) return [];

    const searchResult = fuse.search(currentSearchString).map(result => result.item);
    setCountriesToShow(searchResult.map(item => item.countryCode));
  }, [fuse]);


  const handleDropDownClick = () => {
    if (isDropDownExpanded) {
      removeDropDownExpansion();
    }

    else {
      expandDropDown();
    }
  };


  const handleOptionSelect = (countryCode: string, isOptionAlreadySelected: boolean) => {
    if (singleSelect) {
      setSelectedCountryCodes(isOptionAlreadySelected ? [] : [countryCode]);
      if (!isOptionAlreadySelected) removeDropDownExpansion();
      return;
    }

    let newSelectedCountryCodes = selectedCountryCodes.slice();

    if (isOptionAlreadySelected) {
      newSelectedCountryCodes = newSelectedCountryCodes.filter(item => item !== countryCode);
    }
    else {
      newSelectedCountryCodes.push(countryCode);
    }

    setSelectedCountryCodes(newSelectedCountryCodes);
  };


  const expandDropDown = () => {
    // zIndex handling is not ideal, but must be handlded carefully due to interactions between multiple drop downs
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = 'height 150ms';
      element.style.height = 'auto';
      element.style.zIndex = '100';
    }
    setIsDropDownExpanded(true);
  };


  const removeDropDownExpansion = () => {
    const element = document.getElementById(id);
    if (element) {
      element.style.transition = '75ms';
      element.style.height = '28px';
      element.style.zIndex = '1';
    }
    setIsDropDownExpanded(false);
  };


  const showSelectedCountries = () => {

    if (!userConfig) return '';

    let display = countryCodeToCountryInfo[selectedCountryCodes[0]].name[userConfig.language];

    for (let i = 1; i < selectedCountryCodes.length; i++) {
      display += ', ' + countryCodeToCountryInfo[selectedCountryCodes[i]].name[userConfig.language];
    }

    return display;
  };


  useEffect(() => {
    if (!isDropDownExpanded) {
      removeDropDownExpansion();
      setSearchString('');
      setCountriesToShow(countryCodeListSubset);
    }
    else {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }, [isDropDownExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (searchString.length === 0) {
      setCountriesToShow(countryCodeListSubset);
    }
    else {
      handleSearchForCountries(searchString);
    }
  }, [handleSearchForCountries, searchString]);


  useEffect(() => {
    const searchKey = 'name.' + (userConfig?.language ?? 'en');

    const options = {
      keys: [searchKey],
      threshold: 0.2,
    };

    const fuseInstance = new Fuse(enhancedCountryList, options);
    setFuse(fuseInstance);
  }, [userConfig?.language]);


  return (
    <div
      className='drop-down-container'
      id={id}
      style={{ maxHeight: maxHeight }}
    >

      <div className='drop-down-button' onClick={() => handleDropDownClick()}>

        {selectedCountryCodes.length > 0 && <div className='drop-down-button-overlay fast-fade-in' />}

        <div className='drop-down-button-text'>

          {selectedCountryCodes.length === 0 && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(defaultDropDownText, userConfig?.language)}
            </div>
          )}

          {selectedCountryCodes.length > 0 && isDropDownExpanded && (
            <div style={{ color: defaultDropDownTextColor }}>
              {translate(singleSelect ? 'selectedCountry' : 'selectedCountries', userConfig?.language)}
            </div>
          )}

          {selectedCountryCodes.length > 0 && !isDropDownExpanded && (
            <div style={{ color: '#000000' }}>
              {showSelectedCountries()}
            </div>
          )}

        </div>

        {!isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropDownIcon style={{ fontSize: 24 }} />
          </div>
        )}

        {isDropDownExpanded && (
          <div className='drop-down-icon'>
            <ArrowDropUpIcon style={{ fontSize: 24 }} />
          </div>
        )}
      </div>

      <div className='drop-down-section-with-search' style={{ maxHeight: maxHeight }}>

        <div className='drop-down-with-search-selected-options'>
          {selectedCountryCodes.map((countryCode: string) => {
            return (
              <div
                className='drop-down-with-search-selected-option'
                key={countryCode + '-selected'}
              >
                <IconButton
                  icon={<CloseIcon style={{ fontSize: 16 }} />}
                  onClick={() => handleOptionSelect(countryCode, true)}
                  size={18}
                  backgroundColorBehindIcon='white'
                  style={{ marginLeft: -2 }}
                />

                <CountryFlag
                  countryCode={countryCode}
                  width={15}
                  showCountryName={true}
                  containerStyle={{ marginLeft: 3, fontSize: 11, gap: 5 }}
                  isWhiteBackground={true}
                />
              </div>
            );
          })}
        </div>

        <div
          className='drop-down-with-search-input-container'
          style={{ marginTop: singleSelect ? (34) : (12 + (22 * Math.max(selectedCountryCodes.length, 2))) }}
        >
          <InputField
            ref={inputRef}
            value={searchString}
            placeholder={translate('searchForCountry', userConfig?.language)}
            onChange={onChangeSearchField}
            onKeyDown={onKeyDownSearchField}
            resetValue={() => setSearchString('')}
            isWhiteBackground={true}
          />
        </div>

        {countriesToShow.length > 0 &&
          countriesToShow.map((countryCode: string) => {
            const isSelected = selectedCountryCodes.some(item => item === countryCode);
            return (
              <div
                className={'drop-down-option' + (isSelected ? ' drop-down-option-selected' : '')}
                style={{ marginTop: marginBetweenOptions }}
                key={countryCode}
                onClick={() => handleOptionSelect(countryCode, isSelected)}
              >
                <CountryFlag
                  countryCode={countryCode}
                  width={15}
                  showCountryName={true}
                  containerStyle={{ marginLeft: 3, gap: 5 }}
                  isWhiteBackground={true}
                />
              </div>
            );
          })}

        {countriesToShow.length === 0 && (
          <div className='drop-down-with-search-no-search-result'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

      </div>

    </div>
  );
};
