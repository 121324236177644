import './visualization.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { positionGroupToPositions } from '../../../static/propertyValues';
import { translate } from '../../../../common/language/translations';


export const PositionGroupsTable: React.FC = () => {

  const userConfig = useRecoilValue(userConfigState);


  return (
    <div className='position-groups-table-container'>

      <div className='position-groups-table-divider' />

      {Object.entries(positionGroupToPositions).map(([positionGroup, positions]: [string, string[]], positionGroupIndex) => {

        return (
          <div
            key={positionGroup}
            className={
              'position-groups-table-row' +
              (positionGroupIndex === 0 ? '' : ' position-groups-table-row-border-top')
            }
          >

            <div className='position-groups-table-positions'>
              {positions.map(position => {
                return (
                  <div
                    key={position}
                    className=''
                  >
                    {translate(position, userConfig?.language)}
                  </div>
                );
              })}
            </div>

            <div className='position-groups-table-description'>
              {translate(positionGroup + '_info', userConfig?.language)}
            </div>

          </div>
        );
      })}

    </div>
  );
};
