import '../../static/clubConfigs.css';
import '../mobilePlatform.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';

import ReplayIcon from '@mui/icons-material/Replay';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

import {
  getClubLogo,
  getClubLogoBackgroundColor,
  getClubLogoBorderRadius,
  getClubLogoShadow,
  getClubLogoSize
} from '../../static/clubConfigs';

import { translate } from '../../../common/language/translations';
import { MobileNavigationTab } from './MobileNavigation';
import { IconButton } from '../../components/controls/buttons/IconButton';


interface MobileHeaderProps {
  tab: MobileNavigationTab;
  isMobileScoutingFilterSectionExpanded?: boolean;
  setIsMobileScoutingFilterSectionExpanded?: (value: boolean) => void;
  resetMobileScoutingState?: () => void;
}

export const MobileHeader: React.FC<MobileHeaderProps> = ({
  tab,
  isMobileScoutingFilterSectionExpanded,
  setIsMobileScoutingFilterSectionExpanded,
  resetMobileScoutingState,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const logoShadow = getClubLogoShadow(userConfig?.club ?? '');
  const logoSize = getClubLogoSize(userConfig?.club ?? '');
  const logoBackgroundColor = getClubLogoBackgroundColor(userConfig?.club ?? '');
  const logoBorderRadius = getClubLogoBorderRadius(userConfig?.club ?? '');


  return (
    <div className='mobile-header-container'>
      <div className='mobile-header-logo-container'>
        <div className={'mobile-header-logo-shadow logo-shadow-' + logoShadow} />

        <img
          src={getClubLogo(userConfig?.club ?? '')}
          alt=''
          className='mobile-header-logo'
          draggable={false}
          style={{
            maxWidth: logoSize ?? '100%',
            maxHeight: logoSize ?? '100%',
            backgroundColor: logoBackgroundColor,
            borderRadius: logoBorderRadius ?? (logoBackgroundColor ? 8 : undefined),
            overflow: logoBorderRadius !== undefined ? 'hidden' : undefined,
            padding: logoBackgroundColor ? '5%' : undefined,
          }}
        />
      </div>

      {translate(tab, userConfig?.language)}

      {tab === 'scouting' && resetMobileScoutingState && (
        <IconButton
          onClick={() => resetMobileScoutingState()}
          icon={<ReplayIcon style={{ fontSize: 22 }} />}
          size={26}
          style={{ position: 'absolute', right: 60 }}
          isMobile={true}
        />
      )}

      {tab === 'scouting' && setIsMobileScoutingFilterSectionExpanded && (
        <IconButton
          onClick={() => setIsMobileScoutingFilterSectionExpanded(!isMobileScoutingFilterSectionExpanded)}
          icon={!isMobileScoutingFilterSectionExpanded ? <FilterAltIcon style={{ fontSize: 22, marginTop: 1 }} /> : <CloseIcon style={{ fontSize: 22 }} />}
          size={26}
          style={{ position: 'absolute', right: 20, marginBottom: -1 }}
          isMobile={true}
          noMobileFeedback={true}
        />
      )}
    </div>
  );
};
