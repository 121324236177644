import './../scouting.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import {
  ageKeys,
  contractKeys,
  formKeys,
  minConfidenceKeys,
  playingTimeKeys,
} from '../../../static/propertyValues';

import { translate } from '../../../../common/language/translations';
import { DropDown } from '../../../components/controls/dropDowns/DropDown';
import { LeagueDropDown } from '../../../components/controls/dropDowns/LeagueDropDown';
import { CountryDropDown } from '../../../components/controls/dropDowns/CountryDropDown';
import { PositionAndRoleDropDown } from '../../../components/controls/dropDowns/PositionAndRoleDropDown';
import { RatingDropDown } from '../../../components/controls/dropDowns/RatingDropDown';
import { DropDownWithIcons } from '../../../components/controls/dropDowns/DropDownWithIcons';
import { LogisticTeamDropDown } from '../../../components/controls/dropDowns/LogisticTeamDropDown';
import { AlertsFilter, AlertsState } from './alertsState';
import { AlertsAction } from './alertsReducer';
import { RatingRequirement } from '../../../types';


interface AlertFiltersProps {
  state: AlertsState;
  dispatch: React.Dispatch<AlertsAction>;
}

export const AlertFilters: React.FC<AlertFiltersProps> = ({
  state,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);


  const handleSetIsDropDownExpanded = (filter: AlertsFilter, value: boolean) => {
    if (value) {
      dispatch({ type: 'SET_FILTER_EXPANSION', payload: filter });
    }
    else {
      dispatch({ type: 'RESET_FILTER_EXPANSION' });
    }
  };


  return (
    <div className='alert-filters-container'>

      <div className='alert-filters-filters-top-row'>
        <div className='filter-section-drop-down-title alerts-filter-section-logistic-teams'>
          {translate('logisticTeams', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-logistic-teams'>
          <LogisticTeamDropDown
            id={'alerts-filter-section-logistic-teams-drop-down'}
            selectedOptions={state.selectedLogisticTeams}
            setSelectedOptions={(selectedLogisticTeams: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedLogisticTeams } })}
            isDropDownExpanded={state.currentFilterExpanded === 'logisticTeams'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('logisticTeams', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'60vh'}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-confidence'>
          {translate('minConfidence', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-confidence'>
          <DropDown
            id={'alerts-filter-section-confidence-drop-down'}
            dropDownOptions={minConfidenceKeys}
            selectedOptions={state.selectedMinConfidence}
            setSelectedOptions={(selectedMinConfidence: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMinConfidence } })}
            isDropDownExpanded={state.currentFilterExpanded === 'minConfidence'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('minConfidence', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'60vh'}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-age'>
          {translate('maxAge', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-age'>
          <DropDown
            id={'alerts-filter-section-age-drop-down'}
            dropDownOptions={ageKeys}
            selectedOptions={state.selectedMaxAge}
            setSelectedOptions={(selectedMaxAge: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedMaxAge } })}
            isDropDownExpanded={state.currentFilterExpanded === 'maxAge'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('maxAge', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'69vh'}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-positions-and-roles'>
          {translate('positionsAndRoles', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-positions-and-roles'>
          <PositionAndRoleDropDown
            id={'alerts-filter-section-positions-and-roles-drop-down'}
            selectedPositions={state.selectedPositions}
            setSelectedPositions={(selectedPositions: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedPositions } })}
            selectedRoles={state.selectedRoles}
            setSelectedRoles={(selectedRoles: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedRoles } })}
            isDropDownExpanded={state.currentFilterExpanded === 'positionsAndRoles'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('positionsAndRoles', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            maxHeight={'69vh'}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-leagues'>
          {translate('leagues', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-leagues'>
          <LeagueDropDown
            id={'alerts-filter-section-leagues-drop-down'}
            selectedLeagues={state.selectedLeagues}
            setSelectedLeagues={(selectedLeagues: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedLeagues } })}
            isDropDownExpanded={state.currentFilterExpanded === 'leagues'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('leagues', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'69vh'}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-order-by'>
          {translate('orderBy', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-order-by'>
          <DropDown
            id={'alerts-filter-section-ordering-drop-down'}
            dropDownOptions={[...state.selectedRoles, 'skillIndex', 'age']}
            selectedOptions={state.selectedOrderBy}
            setSelectedOptions={(selectedOrderBy: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedOrderBy } })}
            isDropDownExpanded={state.currentFilterExpanded === 'orderBy'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('orderBy', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'69vh'}
            forceSelection={true}
          />
        </div>
      </div>

      <div className='alert-filters-filters-bottom-row'>

        <div className='filter-section-drop-down-title alerts-filter-section-indexes'>
          {translate('indexRequirements', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-indexes'>
          <RatingDropDown
            id={'alerts-filter-section-indexes-drop-down'}
            selectedOptions={state.selectedIndexes}
            setSelectedOptions={(selectedIndexes: RatingRequirement[]) => dispatch({ type: 'SET_FILTER', payload: { selectedIndexes } })}
            selectedRoles={state.selectedRoles}
            isDropDownExpanded={state.currentFilterExpanded === 'indexes'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('indexes', value)}
            showIndexes={true}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'75vh'}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-form'>
          {translate('form', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-form'>
          <DropDownWithIcons
            id={'alerts-filter-section-form-drop-down'}
            dropDownOptions={formKeys}
            selectedOptions={state.selectedForm}
            setSelectedOptions={(selectedForm: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedForm } })}
            isDropDownExpanded={state.currentFilterExpanded === 'form'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('form', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'75vh'}
            multiSelect={true}
            useFormIcons={true}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-playing-time'>
          {translate('playingTime', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-playing-time'>
          <DropDownWithIcons
            id={'alerts-filter-section-playing-time-drop-down'}
            dropDownOptions={playingTimeKeys}
            selectedOptions={state.selectedPlayingTime}
            setSelectedOptions={(selectedPlayingTime: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedPlayingTime } })}
            isDropDownExpanded={state.currentFilterExpanded === 'playingTime'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('playingTime', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'75vh'}
            multiSelect={true}
            usePlayingTimeIcons={true}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-nationalities'>
          {translate('nationalities', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-nationalities'>
          <CountryDropDown
            id={'alerts-filter-section-nationalities-drop-down'}
            selectedCountryCodes={state.selectedNationalities}
            setSelectedCountryCodes={(selectedNationalities: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedNationalities } })}
            isDropDownExpanded={state.currentFilterExpanded === 'nationalities'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('nationalities', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'60vh'}
          />
        </div>

        <div className='filter-section-drop-down-title alerts-filter-section-contract'>
          {translate('contractStatus', userConfig?.language)}
        </div>
        <div className='filter-section-drop-down alerts-filter-section-contract'>
          <DropDown
            id={'alerts-filter-section-contract-drop-down'}
            dropDownOptions={contractKeys}
            selectedOptions={state.selectedContractStatus}
            setSelectedOptions={(selectedContractStatus: string[]) => dispatch({ type: 'SET_FILTER', payload: { selectedContractStatus } })}
            isDropDownExpanded={state.currentFilterExpanded === 'contractStatus'}
            setIsDropDownExpanded={(value: boolean) => handleSetIsDropDownExpanded('contractStatus', value)}
            defaultDropDownText={'select'}
            defaultDropDownTextColor={'#00000080'}
            marginBetweenOptions={3}
            maxHeight={'60vh'}
            toggleText={'includeUnknownContractStatus'}
            isToggled={state.isContractStatusToggled}
            setIsToggled={(value: boolean) => dispatch({ type: 'SET_FILTER', payload: { isContractStatusToggled: value } })}
          />
        </div>
      </div>
    </div>
  );
};
