import '../../platform.css';
import './settings.css';

import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { allUserConfigsState } from '../../recoil/atoms/allUserConfigsState';
import { clubScopesState } from '../../recoil/atoms/clubScopesState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { translate } from '../../../common/language/translations';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import Fuse from 'fuse.js';

import UpdateIcon from '@mui/icons-material/Update';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { DropDown } from '../../components/controls/dropDowns/DropDown';
import { formationOptions } from '../../static/propertyValues';
import { Dialog } from '@mui/material';
import { updateClubSettingsFormation } from '../../services/firestore/clubSettings';
import { Competition, StringToStringMap, UserConfig } from '../../types';
import { InputField } from '../../components/controls/input/InputField';
import { getSortDistanceAccess } from '../../utils/playerUtils';
import { AccountModal } from '../../components/modals/accounModals/AccountModal';
import { getAccessIcon } from '../../utils/iconUtils';
import { AddAccountModal } from '../../components/modals/accounModals/AddAccountModal';
import { userHasFullAccess } from '../../utils/userUtils';
import { IconButton } from '../../components/controls/buttons/IconButton';
import { TextButton } from '../../components/controls/buttons/TextButton';
import { CountryFlag } from '../../components/display/images/CountryFlag';
import { modalPaperProps } from '../../components/modals/globalModals/GlobalModals';


const supportedLanguagesToCountryCode: StringToStringMap = {
  'no': 'NO',
  'se': 'SE',
  'dk': 'DK',
  'en': 'GB',
};


export const ClubSettings: React.FC = () => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const hasFullAccess = userHasFullAccess(userConfig);

  const allUsersWithSameClub = useRecoilValue(allUserConfigsState);
  const clubScopes = useRecoilValue(clubScopesState);
  const competitions = useRecoilValue(competitionsState);

  const { openConfirmModal } = useOpenGlobalModal();

  const clubSettings = useRecoilValue(clubSettingsState);
  const clubFormation = clubSettings?.formation;

  const [allUsersListSorted, setAllUsersListSorted] = useState<UserConfig[]>([]);

  const [selectedFormation, setSelectedFormation] = useState<string[]>(clubFormation ? [clubFormation] : []);
  const [isFormationDropDownExpanded, setIsFormationDropDownExpanded] = useState(false);

  const [accountToEdit, setAccountToEdit] = useState<UserConfig | undefined>(undefined);
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);

  const [isAddAccountModalOpen, setIsAddAccountModalOpen] = useState(false);

  const [searchString, setSearchString] = useState('');
  const [sortedStaticCompetitions, setSortedStaticCompetitions] = useState<Competition[]>([]);
  const [yourLeaguesAndCups, setYourLeaguesAndCups] = useState<Competition[]>([]);
  const [leaguesAndCupsToShow, setLeaguesAndCupsToShow] = useState<Competition[]>([]);
  const [fuse, setFuse] = useState<Fuse<Competition> | null>(null);

  const [expandedCompetitionIds, setExpandedCompetitionIds] = useState<string[]>([]);
  const [yourExpandedCompetitionIds, setYourExpandedCompetitionIds] = useState<string[]>([]);


  const handleChangeFormation = () => {
    if (userConfig && clubSettings && clubFormation && selectedFormation.length > 0 && selectedFormation[0] !== clubFormation && hasFullAccess) {

      const newFormation = selectedFormation[0];

      updateClubSettingsFormation(
        newFormation,
        clubFormation,
        clubSettings.positionToRoleIndexConfig,
        userConfig.email,
        userConfig.club,
        currentUser,
      );
    }
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value);
    if (event.target.value === '') {
      setLeaguesAndCupsToShow(sortedStaticCompetitions);
    }
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchForCompetitions(searchString);
    }
  };


  const handleSearchForCompetitions = useCallback((currentSearchString: string) => {
    if (!fuse) return [];

    const searchResult = fuse.search(currentSearchString).map(result => result.item);
    setLeaguesAndCupsToShow(searchResult);
  }, [fuse]);


  const handleEditAccountClick = (user: UserConfig) => {
    setAccountToEdit(user);
    setIsEditAccountModalOpen(true);
  };


  useEffect(() => {
    if (!isEditAccountModalOpen) {
      const timer = setTimeout(() => {
        setAccountToEdit(undefined);
      }, 250);

      return () => clearTimeout(timer);
    }
  }, [isEditAccountModalOpen]);


  useEffect(() => {
    if (allUsersWithSameClub) {
      const usersList: UserConfig[] = [];

      Object.entries(allUsersWithSameClub).map(([email, user]) => {
        if (email.split('@')[0] !== 'fokus') {
          const userWithEmail: UserConfig = { ...user, email: email };
          usersList.push(userWithEmail);
        }
      });

      usersList.sort((a, b) => getSortDistanceAccess(a, b));
      setAllUsersListSorted(usersList);
    }
  }, [userConfig, allUsersWithSameClub]);


  useEffect(() => {
    setExpandedCompetitionIds([]);
    if (searchString.length === 0) {
      setLeaguesAndCupsToShow(sortedStaticCompetitions);
    }
    else {
      handleSearchForCompetitions(searchString);
    }
  }, [searchString, handleSearchForCompetitions]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const searchKeys = [
      'name',
      'countryName_' + (userConfig?.language ?? 'en'),
    ];

    const options = {
      keys: searchKeys,
      threshold: 0.2,
    };

    const fuseInstance = new Fuse(sortedStaticCompetitions, options);
    setFuse(fuseInstance);
  }, [sortedStaticCompetitions, userConfig?.language]);


  useEffect(() => {
    if (!clubScopes) return;

    const competitionsList: Competition[] = Object.values(competitions)
      .filter(competition => competition.iterationsWithEventData && competition.iterationsWithEventData.length > 0);

    competitionsList.sort((a, b) => a.orderIndex - b.orderIndex);

    // if club does not have full access, we want to sort the leagues such that leagues with access come first
    if (clubScopes && !clubScopes.hasFullCoverage) {
      competitionsList.sort((a, b) => {
        const aIsInScope = clubScopes.competitionIds.includes(a.id);
        const bIsInScope = clubScopes.competitionIds.includes(b.id);

        if (aIsInScope && !bIsInScope) return -1;
        if (!aIsInScope && bIsInScope) return 1;
        return 0;
      });
    }

    const competitionsInScope: Competition[] = clubScopes.hasFullCoverage
      ? competitionsList
        .filter(
          competition =>
            competition.iterationsWithEventData && competition.iterationsWithEventData.length > 0
        )
      : competitionsList
        .filter(
          competition =>
            competition.iterationsWithEventData && competition.iterationsWithEventData.length > 0 && clubScopes.competitionIds.includes(competition.id)
        );

    setSortedStaticCompetitions(competitionsList);
    setLeaguesAndCupsToShow(competitionsList);
    setYourLeaguesAndCups(competitionsInScope);
  }, [clubScopes, competitions]);


  const getCompetitions = (isYourCompetitions: boolean) => {

    const leaguesAndCups = isYourCompetitions ? yourLeaguesAndCups : leaguesAndCupsToShow;

    return (
      <div className={'club-settings-view-league-or-cup-column' + (isYourCompetitions ? ' club-settings-view-your-league-or-cup-column' : '')}>

        {leaguesAndCups.length > 0 &&
          leaguesAndCups.map((competition: Competition) => {

            const seasons = competition.iterationsWithEventData
              ? competition.iterationsWithEventData.map(iteration => iteration.season)
              : undefined;

            const isExpanded = isYourCompetitions
              ? yourExpandedCompetitionIds.includes(competition.id)
              : expandedCompetitionIds.includes(competition.id);

            return (
              <div
                className={'club-settings-view-league-or-cup' + (isExpanded ? ' club-settings-view-league-or-cup-expanded' : '')}
                key={competition.id + '-all-column'}
                style={{
                  paddingTop: competition.countryCode ? 5 : 14,
                  paddingBottom: competition.countryCode ? 5 : 14,
                  height: 35 + (isExpanded && seasons ? (6 + (seasons.length * 22)) : 0),
                }}
                onClick={() => {
                  if (isYourCompetitions) {
                    setYourExpandedCompetitionIds(isExpanded
                      ? yourExpandedCompetitionIds.filter(id => id !== competition.id)
                      : [...yourExpandedCompetitionIds, competition.id]);
                  }
                  else {
                    setExpandedCompetitionIds(isExpanded
                      ? expandedCompetitionIds.filter(id => id !== competition.id)
                      : [...expandedCompetitionIds, competition.id]);
                  }
                }}
              >
                {competition.name}

                {competition.countryCode && (
                  <CountryFlag
                    countryCode={competition.countryCode}
                    width={15}
                    showCountryName={true}
                    tierToShow={competition.tier}
                    containerStyle={{ fontSize: 12, gap: 6, color: '#ffffffc4', marginTop: 5 }}
                    isWhiteBackground={true}
                  />
                )}

                {isExpanded && seasons && seasons.length > 0 && (
                  <div className='fade-in' style={{ marginTop: 8 }}>
                    {seasons.map((season, index) => {
                      return (
                        <div key={index} style={{ fontSize: 14, marginBottom: 4, color: '#ffffffdd' }}>
                          {season}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    );
  };


  return (
    <div className='full-size-container club-settings-view-container'>

      <Dialog
        open={isEditAccountModalOpen}
        onClose={() => setIsEditAccountModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <AccountModal
          user={accountToEdit}
          closeModal={() => setIsEditAccountModalOpen(false)}
        />
      </Dialog>

      <Dialog
        open={isAddAccountModalOpen}
        onClose={() => setIsAddAccountModalOpen(false)}
        PaperProps={modalPaperProps}
      >
        <AddAccountModal
          closeModal={() => setIsAddAccountModalOpen(false)}
        />
      </Dialog>

      {isFormationDropDownExpanded && <div className='filter-section-empty-background' onClick={() => setIsFormationDropDownExpanded(false)} />}

      <div className='club-settings-view-column club-settings-view-left-section'>
        <div className='club-settings-view-formation-section'>
          <div className='club-settings-view-section-title'>
            {translate('formation', userConfig?.language)}
          </div>

          <div className='club-settings-view-formation-drop-down-container'>
            <div className='club-settings-view-formation-drop-down'>
              <DropDown
                id={'club-settings-view-formation-drop-down'}
                dropDownOptions={formationOptions}
                selectedOptions={selectedFormation}
                setSelectedOptions={setSelectedFormation}
                isDropDownExpanded={isFormationDropDownExpanded}
                setIsDropDownExpanded={setIsFormationDropDownExpanded}
                defaultDropDownText={'selectFormation'}
                defaultDropDownTextColor={'#000000'}
                marginBetweenOptions={0}
              />
            </div>
          </div>

          {selectedFormation.length > 0 && selectedFormation[0] !== clubFormation && (
            <TextButton
              onClick={() => openConfirmModal(handleChangeFormation, 'editFormation', 'editFormationInfo')}
              text={translate('editFormation', userConfig?.language)}
              icon={<UpdateIcon style={{ fontSize: 22 }} />}
              style={{ width: 220, marginTop: '8vh' }}
              disabled={!hasFullAccess}
            />
          )}
        </div>

        <div className='club-settings-view-divider club-settings-view-horizontal-divider' />

        <div className='club-settings-view-accounts-section'>
          <div className='club-settings-view-section-title' style={{ position: 'relative' }}>
            {translate('accounts', userConfig?.language)}

            {userConfig && userConfig.access === 'admin' && (
              <IconButton
                icon={<AddCircleOutlineIcon style={{ fontSize: 20 }} />}
                onClick={() => setIsAddAccountModalOpen(true)}
                size={28}
                style={{ position: 'absolute', top: -4, right: -33 }}
                hoverText={translate('addNewAccount', userConfig.language)}
              />
            )}
          </div>

          <div className='club-settings-view-accounts-column'>
            {allUsersListSorted.map((user, index) => {

              const accountIcon = getAccessIcon(user['access'], 24);

              return (
                <div key={index} className='club-settings-view-account' style={{ paddingRight: userConfig && userConfig.access === 'admin' ? 46 : undefined }}>
                  <div className='club-settings-view-account-icon'>
                    {accountIcon}
                  </div>
                  <div className='club-settings-view-account-info-rows'>
                    <div className='club-settings-view-account-name-row'>
                      {user['name']}

                      <CountryFlag
                        countryCode={supportedLanguagesToCountryCode[user.language ?? 'en']}
                        width={17}
                        containerStyle={{ marginLeft: 8 }}
                      />
                    </div>
                    <div className='club-settings-view-account-email'>
                      {user.email}
                    </div>
                  </div>
                  {userConfig && userConfig.access === 'admin' && user.access !== 'admin' && (
                    <IconButton
                      icon={<MoreVertIcon style={{ fontSize: 22 }} />}
                      onClick={() => handleEditAccountClick(user)}
                      size={28}
                      style={{ position: 'absolute', top: 18, right: 5 }}
                      title={translate('editAccess', userConfig.language)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className='club-settings-view-divider club-settings-view-vertical-divider' />

      <div className='club-settings-view-column club-settings-view-right-section'>
        <div className='club-settings-view-section-title'>
          {translate('leaguesAndCupsWithEventData', userConfig?.language)}
        </div>

        <div className='club-settings-view-divider club-settings-view-horizontal-divider club-settings-view-horizontal-divider-short' />

        <div className='club-settings-view-league-coverage-section'>
          <div className='club-settings-view-league-coverage-column'>
            <div className='club-settings-view-league-coverage-column-title'>
              {translate('yourLeaguesAndCups', userConfig?.language)}
            </div>

            <div className='club-settings-view-league-or-cup-column-container club-settings-view-your-league-or-cup-column-container'>
              {getCompetitions(true)}
            </div>
          </div>

          <div className='club-settings-view-league-coverage-column'>
            <div className='club-settings-view-league-coverage-column-title'>
              {translate('allLeaguesAndCups', userConfig?.language)}
            </div>

            <div className='club-settings-view-input-container'>
              <div className='club-settings-view-input'>
                <InputField
                  value={searchString}
                  placeholder={translate('searchForLeagueOrCup', userConfig?.language)}
                  onChange={onChangeSearchField}
                  onKeyDown={onKeyDownSearchField}
                  resetValue={() => setSearchString('')}
                />
              </div>
            </div>

            <div className='club-settings-view-league-or-cup-column-container'>
              {getCompetitions(false)}
            </div>

            {leaguesAndCupsToShow.length === 0 && (
              <div className='club-settings-view-no-search-result'>
                {translate('noResult', userConfig?.language)}
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  );
};
