import { StringToNumberMap } from '../types';


export const clauseCurrencyKeys: string[] = [
  'NOK',
  'SEK',
  'DKK',
  'EUR',
  'GBP',
  'USD',
];


export const countryCodeToCurrencyEuroMultiplier: StringToNumberMap = {
  'JP': 161,
  'NO': 11.76,
  'DK': 7.46,
  'SE': 11.49,
};


export const currencyToEuroMultiplier: StringToNumberMap = {
  'EUR': 1,
  'NOK': 11.76,
  'DKK': 7.46,
  'SEK': 11.49,
  'GBP': 0.83,
  'USD': 1.05,
};
