import '../mobilePlatform.css';

import { ViewContainerBackgroundSvg } from '../../../assets/svg/ViewContainerBackgroundSvg';
import { MobileHeader } from '../mobileComponents/MobileHeader';


export const MobileHome: React.FC = () => {

  return (
    <div className='full-size-container'>

      <div className='mobile-home-view-background'>
        <ViewContainerBackgroundSvg />
      </div>

      <MobileHeader tab={'home'} />

      <div className='mobile-platform-view-section'>
        <div className='mobile-platform-view-content-container'>
          <div className='mobile-home-view-content-box' style={{ height: 500 }}>
            Aktivitet siste 14 dager
          </div>

          <div className='mobile-home-view-content-box' style={{ height: 1000 }}>
            Tabell
          </div>
        </div>
      </div>
    </div>
  );
};
