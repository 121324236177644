import '../tables.css';

import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { clubSettingsState } from '../../../recoil/atoms/clubSettingsState';
import { useSetAndTrackSelectedPlayerState } from '../../../recoil/hooks/useSetAndTrackSelectedPlayerState';
import { useOpenGlobalModal } from '../../../recoil/hooks/useOpenGlobalModal';

import { useMemo } from 'react';
import { useTable, useBlockLayout, useFilters } from 'react-table';
import { useSticky } from 'react-table-sticky';

import { PlayerOverview, StringToAnyMap } from '../../../types';
import { getAlertColumns, getAlertTableTotalColumnsMinWidth } from './alertColumns';
import { useWindowSize } from '../../../../common/hooks/WindowSize';
import { getNavigationWidth } from '../../controls/navigation/Navigation';
import { getClubSecondaryColor } from '../../../static/clubConfigs';
import { PlayerOverviewWithAlertProperties } from '../../../views/scouting/alerts/Alerts';


interface AlertTableProps {
  data: PlayerOverviewWithAlertProperties[];
  isExcludedPlayersView: boolean;
  excludePlayer: (playerId: number, allAlerts: boolean, monthsExcluded: number) => void,
  undoExcludePlayer: (playerOverview: PlayerOverview, allAlerts: boolean) => void,
  excludeAllPlayers: () => void,
  undoAllExcludedPlayers: () => void,
  selectedOrderBy: string | undefined;
  selectedRoles: string[];
  currentPage: number;
  totalHits: number;
  handleChangeCurrentPage: (increment: boolean) => void;
}

export const AlertTable: React.FC<AlertTableProps> = ({
  data,
  isExcludedPlayersView,
  excludePlayer,
  undoExcludePlayer,
  excludeAllPlayers,
  undoAllExcludedPlayers,
  selectedOrderBy,
  selectedRoles,
  currentPage,
  totalHits,
  handleChangeCurrentPage,
}) => {

  const userConfig = useRecoilValue(userConfigState);
  const clubSettings = useRecoilValue(clubSettingsState);
  const setAndTrackSelectedPlayerState = useSetAndTrackSelectedPlayerState();

  const { openTextModal, openConfirmModal, openRoleInfoModal } = useOpenGlobalModal();

  const width = useWindowSize().width;
  const getTableWidth = (currentWidth: number) => {
    return currentWidth - 24 - getNavigationWidth(userConfig);
  };

  const remainingWidth = getTableWidth(width) - 300;
  const totalColumnsMinWidth = getAlertTableTotalColumnsMinWidth(selectedRoles, clubSettings?.roleConfigs ?? {});
  const widthUnit = remainingWidth / totalColumnsMinWidth;


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any[] = useMemo(
    () => getAlertColumns(
      widthUnit,
      userConfig?.language ?? 'en',
      excludePlayer,
      undoExcludePlayer,
      excludeAllPlayers,
      undoAllExcludedPlayers,
      isExcludedPlayersView,
      currentPage,
      totalHits,
      handleChangeCurrentPage,
      selectedRoles,
      clubSettings?.roleConfigs ?? {},
      selectedOrderBy,
      getClubSecondaryColor(userConfig?.club ?? ''),
      openTextModal,
      openConfirmModal,
      openRoleInfoModal,
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      width,
      userConfig,
      isExcludedPlayersView,
      currentPage,
      totalHits,
      clubSettings?.roleConfigs,
    ]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useBlockLayout,
    useSticky
  );


  const handleOnPlayerClick = (playerOverview: PlayerOverviewWithAlertProperties) => {
    const initialPositionGroup = selectedRoles && selectedRoles.length > 0 && clubSettings && clubSettings.roleConfigs[selectedRoles[0]]
      ? clubSettings.roleConfigs[selectedRoles[0]].positionGroup
      : undefined;

    setAndTrackSelectedPlayerState({
      id: playerOverview.id,
      fullname: playerOverview.fullname,
      playerOverview: playerOverview,
      initialPositionGroup: initialPositionGroup,
    });
  };


  const getExtendedCellClassName = (cell: StringToAnyMap) => {
    if (cell.column.isFinalSubMetric) {
      return ' player-table-body-cell-with-border';
    }
    return '';
  };


  const clubColor = getClubSecondaryColor(userConfig?.club ?? '');
  document.documentElement.style.setProperty('--club-color', clubColor);

  return (
    <div
      {...getTableProps()}
      className='table-container player-table'
      style={{ overflowX: getTableWidth(width) < (totalColumnsMinWidth + 300 + 20) ? 'auto' : 'hidden' }}
    >

      <div className='table-top-level-header-group'>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <div
              key={key}
              {...restHeaderGroupProps}
            >
              {headerGroup.headers.map((column: StringToAnyMap) => {
                const { key: columnKey, ...restColumnProps } = column.getHeaderProps();
                return (
                  <div
                    key={columnKey}
                    {...restColumnProps}
                  >
                    {column.render('Header')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

      <div {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <div
              key={key}
              {...restRowProps}
              className={i % 2 === 0 ? 'player-table-body-row-even' : 'player-table-body-row-odd'}
              onClick={() => handleOnPlayerClick(row.original)}
            >
              {row.cells.map(cell => {
                const { key: cellKey, ...restCellProps } = cell.getCellProps();
                return (
                  <div
                    key={cellKey}
                    {...restCellProps}
                    className={
                      'player-table-body-cell'
                      + (i % 2 === 0 ? ' player-table-body-cell-even' : ' player-table-body-cell-odd')
                      + getExtendedCellClassName(cell)
                    }
                  >
                    {cell.render('Cell')}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>

    </div>
  );
};
