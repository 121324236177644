import axios from 'axios';

import { FirebaseUser } from '../../../../firebase';
import { StringToAnyMap, PlayerCareers, PlayerEntry } from '../../../types';

import { APPLICATION_API_URL } from './../constants';
import { getBearerToken } from './../token';
import { positionOptionsPlatform } from '../../../static/propertyValues';
import { trackEvent } from '../analytics/trackEvent';


// Get player careers for players in the given team
export const getPlayerCareersForTeam = async (
  team: StringToAnyMap,
  currentUser: FirebaseUser,
): Promise<PlayerCareers> => {

  const playerIds: number[] = [];

  positionOptionsPlatform.forEach(positionKey => {
    if (positionKey in team) {
      const positionArray = team[positionKey];
      positionArray.forEach((player: PlayerEntry) => {
        const numberId = Number(player.id);
        if (!isNaN(numberId)) {
          playerIds.push(numberId);
        }
      });
    }
  });

  if (playerIds.length === 0) return {};

  const playersOnTeams = await getPlayerCareers(playerIds, currentUser);

  return playersOnTeams ?? {};
};


// Get player careers for all given playerIds
export const getPlayerCareers = async (
  playerIds: number[],
  currentUser: FirebaseUser,
): Promise<PlayerCareers | undefined> => {

  try {
    if (playerIds.length === 0) return undefined;

    const token = await getBearerToken(currentUser);

    const playerIdsString = playerIds.join(',');

    const result = await axios.get(`${APPLICATION_API_URL}/playerCareers/${playerIdsString}`, {
      headers: { Authorization: token },
    });

    if (result === undefined) return undefined;

    return result.data;
  }

  catch (error) {
    trackEvent('Error', { api: 'server', function: 'getPlayerCareers', errorDetails: { error } }, currentUser, 'system');
    return undefined;
  }
};
