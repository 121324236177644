import { FirebaseUser, storage } from '../../../firebase';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { trackEvent } from '../server/analytics/trackEvent';


// uploads the given files to storage and returns their urls
export const uploadFiles = async (
  files: File[],
  documentType: 'reports' | 'clauses',
  documentId: string,
  club: string,
  currentUser: FirebaseUser,
) => {
  try {
    const uploadPromises = files.map(async file => {
      const storageRef = ref(storage, `${club}/${documentType}/${documentId}/${file.name}`);
      const uploadTask = uploadBytes(storageRef, file);
      await uploadTask;
      return await getDownloadURL(storageRef);
    });

    const result = await Promise.all(uploadPromises);

    trackEvent('FilesUploaded', { documentType: documentType, numberOfFiles: files.length }, currentUser, 'user');

    return result;
  }
  catch (error) {
    trackEvent('Error', { api: 'storage', function: 'uploadFiles', errorDetails: { error } }, currentUser, 'system');
    return [];
  }
};


// deletes the files with the given file names from storage
export const deleteFiles = async (
  fileNames: string[],
  documentType: 'reports' | 'clauses',
  documentId: string,
  club: string,
  currentUser: FirebaseUser,
) => {
  try {
    const deletePromises = fileNames.map(fileName => {
      const fileRef = ref(storage, `${club}/${documentType}/${documentId}/${fileName}`);
      return deleteObject(fileRef);
    });

    await Promise.all(deletePromises);

    trackEvent('FilesDeleted', { documentType: documentType, numberOfFiles: fileNames.length }, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'storage', function: 'uploadFiles', errorDetails: { error } }, currentUser, 'system');
  }
};
