import '../../../platform.css';
import './../scouting.css';

import { useEffect, useReducer, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';
import { nameSearchHistorySelector } from '../../../recoil/selectors/scoutingConfigSelectors';

import SearchIcon from '@mui/icons-material/Search';

import { translate } from '../../../../common/language/translations';
import { searchPlayerOverviews, SearchPlayerOverviewsResponse } from '../../../services/server/application/playerOverviews';
import { PlayerOverview, PlayerOverviewsQueryOptions } from '../../../types';
import { AuthContextType, useAuthContext } from '../../../../common/contexts/AuthContext';
import { debounce, stringArraysContainSameElements } from '../../../utils/utils';
import { PlayerFindingFilters } from './PlayerFindingFilters';
import { playerTableChapterSize, playerTablePageSize } from '../Scouting';
import { FindPlayerTable } from '../../../components/tables/findPlayerTable/FindPlayerTable';
import { initialPlayerFindingState } from './playerFindingState';
import playerFindingReducer from './playerFindingReducer';
import { TextButton } from '../../../components/controls/buttons/TextButton';


interface PlayerFindingProps {
  isAnyFilterExpanded: boolean;
  setIsAnyFilterExpanded: (value: boolean) => void;
  handlePlayerClick: (player: PlayerOverview) => void;
}


export const PlayerFinding: React.FC<PlayerFindingProps> = ({
  isAnyFilterExpanded,
  setIsAnyFilterExpanded,
  handlePlayerClick
}) => {

  const { currentUser } = useAuthContext() as AuthContextType;

  const userConfig = useRecoilValue(userConfigState);
  const nameSearchHistory = useRecoilValue(nameSearchHistorySelector);

  const [nameSearchString, setNameSearchString] = useState('');
  const [currentNameSearchString, setCurrentNameSearchString] = useState<string>('');
  const [debouncedNameSearchString, setDebouncedNameSearchString] = useState('');
  const clearNameDebounceRef = useRef<() => void>(() => null);

  const [state, dispatch] = useReducer(
    playerFindingReducer,
    initialPlayerFindingState,
  );


  const anyFilterSelected = () => {
    return (
      nameSearchString.length !== 0
      || state.selectedMaxAge.length !== 0
      || state.selectedMinAge.length !== 0
      || state.selectedLeagues.length !== 0
      || state.selectedNationalities.length !== 0
      || state.selectedClubs.length !== 0
    );
  };


  const onKeyDownNameSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && nameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(true, true);
    }
  };


  const handleSearchButtonPressed = async (isUserInitiated: boolean, isNewSearch: boolean, nextChapter?: number) => {

    dispatch({ type: 'SET_IS_LOADING' });

    if (isUserInitiated) {
      clearNameDebounceRef.current();
    }

    const nextPageToQuery = isNewSearch
      ? 1
      : (nextChapter !== undefined
        ? ((nextChapter * playerTableChapterSize) + 1)
        : ((state.currentChapter * playerTableChapterSize) + (state.currentModuloPage + 1)));

    const queryOptions: PlayerOverviewsQueryOptions = {
      name: nameSearchString,
      maxAge: state.selectedMaxAge.length > 0 ? parseInt(state.selectedMaxAge[0]) : undefined,
      minAge: state.selectedMinAge.length > 0 ? parseInt(state.selectedMinAge[0]) : undefined,
      countryCodes: state.selectedNationalities.length > 0 ? state.selectedNationalities : undefined,
      competitions: state.selectedLeagues.length > 0 ? state.selectedLeagues.map(league => parseInt(league)) : undefined,
      clubs: state.selectedClubs.length > 0 ? state.selectedClubs.map(club => club.id) : undefined,

      page: nextPageToQuery,
      pageSize: playerTablePageSize,
    };

    const result: SearchPlayerOverviewsResponse | undefined = await searchPlayerOverviews(
      queryOptions,
      currentUser,
      nameSearchHistory,
      userConfig?.email,
      userConfig?.club,
    );

    if (!result) {
      // todo: handle error?
      dispatch({ type: 'RESET_STATE' });
      return;
    }

    dispatch({
      type: 'SET_SEARCH_RESULT',
      payload: {
        result,
        isNewSearch,
        nextChapter,
      },
    });
  };


  const handleChangeCurrentChapter = async (isIncrement: boolean) => {
    dispatch({ type: 'SET_EMPTY_TABLE_DATA' });
    const nextChapter = isIncrement ? state.currentChapter + 1 : state.currentChapter - 1;
    handleSearchButtonPressed(false, false, nextChapter);
  };


  // when a filter is de-expanded, we check if the selected filters have changed, and if so, trigger a new search
  useEffect(() => {
    // only trigger a new search if the filter is de-expanded and search is active
    const searchIsActive = state.tableData.length > 0 || state.isEmptySearchResult;
    if (!state.currentFilterExpanded && state.previousFilterExpanded && searchIsActive) {

      let shouldTriggerSearch = false;

      switch (state.previousFilterExpanded) {
        case 'maxAge':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMaxAge, state.selectedMaxAge);
          break;

        case 'minAge':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentMinAge, state.selectedMinAge);
          break;

        case 'leagues':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentLeagues, state.selectedLeagues);
          break;

        case 'nationalities':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentNationalities, state.selectedNationalities);
          break;

        case 'clubs':
          shouldTriggerSearch = !stringArraysContainSameElements(state.currentClubs.map(c => String(c.id)), state.selectedClubs.map(c => String(c.id)));
          break;
      }

      if (shouldTriggerSearch) {
        handleSearchButtonPressed(false, true);
      }
    }
  }, [state.currentFilterExpanded]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (nameSearchString.length === 0) {
      if (anyFilterSelected() && nameSearchString !== currentNameSearchString) {
        handleSearchButtonPressed(false, true);
      }
      else {
        setCurrentNameSearchString('');
        setDebouncedNameSearchString('');
        dispatch({ type: 'RESET_STATE' });
      }
    }

    const [debounceFilter, clearDebounce] = debounce(() => {
      if (nameSearchString !== debouncedNameSearchString) {
        setDebouncedNameSearchString(nameSearchString);
      }
    }, 600);

    debounceFilter();

    clearNameDebounceRef.current = clearDebounce;

    return () => {
      clearDebounce();
    };
  }, [nameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (debouncedNameSearchString !== '' && debouncedNameSearchString !== currentNameSearchString) {
      handleSearchButtonPressed(false, true);
    }
  }, [debouncedNameSearchString]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div className='full-size-container'>

      <PlayerFindingFilters
        state={state}
        dispatch={dispatch}
        nameSearchString={nameSearchString}
        setNameSearchString={setNameSearchString}
        onKeyDownNameSearchField={onKeyDownNameSearchField}
        onNameSearchHistoryClick={(name: string) => { setNameSearchString(name); setDebouncedNameSearchString(name); }}
        isAnyFilterExpanded={isAnyFilterExpanded}
        setIsAnyFilterExpanded={setIsAnyFilterExpanded}
      />

      <div
        className='scouting-view-result-container player-finding-view-result-container'
        style={{ top: 85 }}
      >
        {state.tableData.length > 0 && (
          <FindPlayerTable
            data={state.tableData}
            isLoading={state.isLoading}
            handleSearchButtonPressed={handleSearchButtonPressed}
            currentModuloPage={state.currentModuloPage}
            currentChapter={state.currentChapter}
            totalHits={state.totalHits}
            handleChangeCurrentChapter={handleChangeCurrentChapter}
            handlePlayerClick={handlePlayerClick}
          />
        )}

        {state.tableData.length === 0 && state.isEmptySearchResult && !state.isLoading && (
          <div className='player-finding-info-title fade-in'>
            {translate('noResult', userConfig?.language)}
          </div>
        )}

        {state.tableData.length === 0 && !state.isEmptySearchResult && !state.isLoading && (
          <TextButton
            onClick={() => handleSearchButtonPressed(true, true)}
            text={translate('searchForPlayers', userConfig?.language)}
            icon={<SearchIcon style={{ fontSize: 22 }} />}
            buttonType={'dark'}
            isRound={true}
          />
        )}

      </div>
    </div>
  );
};
