import './../modals.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { translate } from '../../../../common/language/translations';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ClauseState } from '../../documents/clause/clauseState';
import { ClauseAction } from '../../documents/clause/clauseReducer';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';
import { DateInputField } from '../../controls/input/DateInputField';


interface ResolveTotalConditionModalProps {
  closeModal: () => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
  totalConditionIndex: number | undefined;
  totalSubConditionIndex: number | undefined;
}

export const ResolveTotalConditionModal: React.FC<ResolveTotalConditionModalProps> = ({
  closeModal,
  state,
  dispatch,
  totalConditionIndex,
  totalSubConditionIndex,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [paymentDate, setPaymentDate] = useState<Date | null>(null);

  const handleSetPaymentDate = (date: Date | null) => {
    if (!date) {
      setPaymentDate(null);
      return;
    }
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12);
    setPaymentDate(adjustedDate);
  };


  const onKeyDownInputField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      resolveCondition(paymentDate);
    }
  };


  const resolveCondition = (date?: Date | null) => {
    if (totalConditionIndex !== undefined && totalSubConditionIndex !== undefined) {
      dispatch({ type: 'RESOLVE_TOTAL_CONDITION', payload: { totalConditionIndex, totalSubConditionIndex, date } });
    }
    closeModal();
  };


  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const isMaxConditionalFeeReached = stateAccessor.maxConditionalFees !== null &&
    (stateAccessor.conditionalFees ?? 0) >= stateAccessor.maxConditionalFees;


  const getPaymentDescription = () => {
    if (!userConfig || totalConditionIndex === undefined || totalSubConditionIndex === undefined) return;

    const totalCondition = stateAccessor.totalConditions[totalConditionIndex];

    let description = '';
    description += totalCondition.subConditions[totalSubConditionIndex].threshold;
    description += ' ' + translate(totalCondition.condition, userConfig.language, true);
    description += ' ' + translate('in<season>', userConfig.language);
    description += ' ' + translate(totalCondition.competitionString, userConfig.language, true);

    const conditionAmount = totalCondition.subConditions[totalSubConditionIndex].amount;
    const amount = stateAccessor.currency
      ? getDisplayEconomicValue(conditionAmount) + ' ' + stateAccessor.currency
      : getDisplayEconomicValue(conditionAmount, true);

    description += ' ' + amount;

    return description;
  };


  const getYourPaymentDescription = () => {
    if (!userConfig
      || totalConditionIndex === undefined
      || totalSubConditionIndex === undefined
      || !state.subClauses
      || state.additionalClauseState.activeSubClauseTab === undefined
    ) return;

    const totalCondition = state.subClauses[state.additionalClauseState.activeSubClauseTab].totalConditions[totalConditionIndex];
    const currency = state.subClauses[state.additionalClauseState.activeSubClauseTab].currency;
    const totalPercentage = state.subClauses[state.additionalClauseState.activeSubClauseTab].totalSellOnPercentage;
    const discountedAmount = Math.round(totalCondition.subConditions[totalSubConditionIndex].amount * (totalPercentage / 100));
    const amount = currency
      ? getDisplayEconomicValue(discountedAmount) + ' ' + currency
      : getDisplayEconomicValue(discountedAmount, true);

    let description = translate('youWillReceive', userConfig.language);
    description += ' ' + totalPercentage + '% ';
    description += translate('ofTheSumAbove', userConfig?.language, true) + ': ';
    description += amount;

    return description;
  };


  return (
    <div
      className='modal-root-container'
      style={{ width: 500, height: state.additionalClauseState.activeSubClauseTab !== undefined ? 550 : 510 }}
    >

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('resolveCondition', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      {isMaxConditionalFeeReached && (
        <div className='modal-content-container'>
          <div style={{ margin: 'auto', paddingBottom: 15 }}>
            {translate('maxAmountReachedMessage', userConfig?.language)}
          </div>
        </div>
      )}

      {!isMaxConditionalFeeReached && (
        <div className='modal-content-container'>

          <div className='resolve-condition-modal-info-row'>
            {getPaymentDescription()}
          </div>

          {state.additionalClauseState.activeSubClauseTab !== undefined && (
            <div className='resolve-condition-modal-info-row' style={{ fontSize: 14, color: '#ffffffcc' }}>
              {getYourPaymentDescription()}
            </div>
          )}

          <div className='margin-auto' style={{ marginTop: 25 }}>
            <DateInputField
              value={paymentDate}
              placeholder={translate('paymentDate', userConfig?.language) + '?'}
              onChange={handleSetPaymentDate}
              onKeyDown={onKeyDownInputField}
              minDate='today-10y'
              maxDate='today+10y'
            />
          </div>
        </div>
      )}

      {!isMaxConditionalFeeReached && (
        <TextButton
          onClick={() => resolveCondition(paymentDate)}
          text={translate('resolveCondition', userConfig?.language)}
          icon={<CheckIcon style={{ fontSize: 22 }} />}
          style={{ position: 'absolute', bottom: 30 }}
        />
      )}

    </div>
  );
};
