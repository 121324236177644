import './playerView.css';

import React from 'react';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../recoil/atoms/userConfigState';
import { userSettingsState } from '../../recoil/atoms/userSettingsState';
import { selectedPlayerState } from '../../recoil/atoms/selectedPlayerState';
import { playerCareersState } from '../../recoil/atoms/playerCareersState';
import { competitionsState } from '../../recoil/atoms/competitionsState';
import { clubSettingsState } from '../../recoil/atoms/clubSettingsState';
import { useWindowSize } from '../../../common/hooks/WindowSize';
import { AuthContextType, useAuthContext } from '../../../common/contexts/AuthContext';

import { Dialog } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BuildIcon from '@mui/icons-material/Build';

import { translate } from '../../../common/language/translations';
import { PlayerClubIteration, PlayerOverview, PositionData } from '../../types';
import { getClubColor } from '../../static/clubConfigs';
import { SeasonTable } from '../tables/seasonTable/SeasonTable';
import { PlayerLinePlot } from '../plots/PlayerLinePlot';
import { PlayerRadarPlot } from '../plots/PlayerRadarPlot';
import { useOpenGlobalModal } from '../../recoil/hooks/useOpenGlobalModal';
import { getIndexColor } from '../../utils/colorUtils';
import { PositionGroupsInfoModal } from '../modals/infoModals/PositionGroupsInfoModal';
import { getFormIcon, getPlayingTimeIcon } from '../../utils/iconUtils';
import { trackEvent } from '../../services/server/analytics/trackEvent';
import { IconButton } from '../controls/buttons/IconButton';
import { ProgressCircle } from '../display/visualization/ProgressCircle';
import { modalPaperProps } from '../modals/globalModals/GlobalModals';


interface PlayerViewOverviewProps {
  playerOverview: PlayerOverview;
}


export const PlayerViewOverview: React.FC<PlayerViewOverviewProps> = ({ playerOverview }) => {

  const { currentUser } = useAuthContext() as AuthContextType;
  const { width } = useWindowSize();

  const userConfig = useRecoilValue(userConfigState);
  const userSettings = useRecoilValue(userSettingsState);
  const selectedPlayer = useRecoilValue(selectedPlayerState);
  const competitions = useRecoilValue(competitionsState);

  const clubSettings = useRecoilValue(clubSettingsState);
  const positionGroupToRoleIds = clubSettings?.positionGroupToRoleIds ?? {};
  const roleConfigs = clubSettings?.roleConfigs ?? {};

  const [isPositionGroupsInfoModalOpen, setIsPositionGroupsInfoModalOpen] = useState(false);
  const { openTextModal, openRoleInfoModal } = useOpenGlobalModal();

  const playerCareers = useRecoilValue(playerCareersState);
  const [playerClubIterationArray, setPlayerClubIterationArray] = useState<PlayerClubIteration[] | undefined>(undefined);
  const [selectedClubIterationId, setSelectedClubIterationId] = useState<string | undefined>(undefined);
  const [selectedClubIterationIdIsInitialized, setSelectedClubIterationIdIsInitialized] = useState(false);
  const [shouldShowSeasonTable, setShouldShowSeasonTable] = useState(false);

  const positionGroupsPlayed = playerOverview.position_groups.length === 1 ? playerOverview.position_groups : ['overall', ...playerOverview.position_groups];
  const [selectedPositionGroupKey, setSelectedPositionGroupKey] = useState<string>(
    selectedPlayer?.initialPositionGroup ?? (playerOverview.position_groups.length === 1 ? playerOverview.position_groups[0] : 'overall')
  );

  const [showConfidence, setShowConfidence] = useState(false);


  const getSkillIndex = (positionGroup: string) => {
    const positionGroupData = playerOverview.position_group_stats?.[positionGroup];
    return positionGroupData?.skill_index !== undefined
      ? Math.round(positionGroupData.skill_index * 10) / 10
      : undefined;
  };


  const getRoleIndex = (positionGroup: string, roleId: string): number | 'beingComputed' | undefined => {
    const roleConfig = roleConfigs[roleId];
    const roleConfigIsReady = roleConfig
      && roleConfig.weightsLastUpdated
      && roleConfig.dataLastUpdated
      && roleConfig.dataLastUpdated >= roleConfig.weightsLastUpdated;

    if (!roleConfigIsReady) return 'beingComputed';

    const customIndexes = playerOverview.position_group_stats?.[positionGroup]?.custom_indexes;
    return customIndexes?.[roleId + '_index'] !== undefined
      ? Math.round((customIndexes[roleId + '_index'] ?? 0) * 10) / 10
      : undefined;
  };


  useEffect(() => {
    if (playerCareers && selectedPlayer && selectedPlayer.id in playerCareers && userSettings) {

      const playerCareer = playerCareers[Number(selectedPlayer.id)];
      const clubIterationArray: PlayerClubIteration[] = [];

      Object.keys(playerCareer).forEach(clubIterationId => {
        if (playerCareer[clubIterationId].event_data_exist) {
          clubIterationArray.push({ ...playerCareer[clubIterationId], 'club_iteration_id': clubIterationId });
        }
      });

      if (clubIterationArray.length > 0) {
        setShouldShowSeasonTable(true);
      }

      // console.log('playerCareer: ', playerCareer);
      // console.log('clubIterationArray: ', clubIterationArray);

      clubIterationArray.sort(
        (a, b) => b.basic_stats?.plot_date && a.basic_stats?.plot_date
          ? b.basic_stats.plot_date.localeCompare(a.basic_stats.plot_date)
          : 0
      );

      const clubIterationArrayWithToggleConstraintsArray: PlayerClubIteration[] = [];
      let anyLeagueWithMinutes = false;

      // we only want to set a new selectedClubIterationId if none is selected or if the selected one is no longer available or blocked
      const rejectedClubIterationIds: string[] = [];
      let newSelectedClubIterationId: string | undefined = undefined;

      clubIterationArray.forEach(clubIteration => {
        if (clubIteration.event_data_exist) {

          const eventDataAvailable = clubIteration.event_data_available;

          const competitionIsLeague = clubIteration.competition_id in competitions && competitions[clubIteration.competition_id].type === 'League';

          // if selectedPositionGroupKey is 'overall', the key for iterations without 'overall' will be the primary position group for that iteration
          const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
            ? clubIteration.position_groups[0]
            : selectedPositionGroupKey;

          const minutesPlayed = clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration]?.minutes_played ?? 0;

          const seasonIsConstrainedByMinutes = userSettings.seasonStatsToggles
            && userSettings.seasonStatsToggles['showOnlySeasonsWith180Minutes']
            && minutesPlayed < 180;

          const seasonIsConstrainedByLeague = userSettings.seasonStatsToggles
            && userSettings.seasonStatsToggles['showOnlyDomesticLeagues']
            && !competitionIsLeague;

          const seasonIsBlocked = minutesPlayed === 0;

          if (!seasonIsConstrainedByMinutes && !seasonIsConstrainedByLeague) {
            if (
              eventDataAvailable &&
              !anyLeagueWithMinutes &&
              competitionIsLeague &&
              minutesPlayed > 0
            ) {
              newSelectedClubIterationId = clubIteration.club_iteration_id;
              anyLeagueWithMinutes = true;
            }
            else if (
              eventDataAvailable &&
              !anyLeagueWithMinutes &&
              !competitionIsLeague &&
              minutesPlayed > 0
            ) {
              newSelectedClubIterationId = clubIteration.club_iteration_id;
            }

            clubIterationArrayWithToggleConstraintsArray.push(clubIteration);
          }

          if (clubIteration.club_iteration_id && (seasonIsConstrainedByMinutes || seasonIsConstrainedByLeague || seasonIsBlocked)) {
            rejectedClubIterationIds.push(clubIteration.club_iteration_id);
          }
        }
      });

      setPlayerClubIterationArray(clubIterationArrayWithToggleConstraintsArray);

      if (
        !selectedClubIterationIdIsInitialized
        && selectedPlayer.initialClubIteration
        && clubIterationArrayWithToggleConstraintsArray.some(clubIteration => clubIteration.club_iteration_id === selectedPlayer.initialClubIteration)
      ) {
        setSelectedClubIterationId(String(selectedPlayer.initialClubIteration));
      }

      else if (selectedClubIterationId === undefined || rejectedClubIterationIds.includes(selectedClubIterationId)) {
        setSelectedClubIterationId(newSelectedClubIterationId);
      }

      setSelectedClubIterationIdIsInitialized(true);
    }
  }, [competitions, playerCareers, selectedPlayer, selectedPositionGroupKey, userSettings]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfidence(true);
    }, 200);

    return () => clearTimeout(timer);
  }, [playerOverview, selectedPositionGroupKey]);


  const getTopRowTitleSection = (
    widgetType: 'positionGroups' | 'formAndPlayingTime' | 'roleIndexes' | 'playerLinePlot' | 'playerRadarPlot',
    titleType: 'small' | 'large'
  ) => {

    if (!playerOverview.event_data_available && (widgetType === 'playerLinePlot' || widgetType === 'playerRadarPlot')) {
      return (
        <div className={'player-view-overview-widget-title-container player-view-overview-widget-title-container-' + titleType}>
          <LockIcon style={{ fontSize: 20, color: '#ffffffaa' }} />
        </div>
      );
    }

    let title = '';
    let minutesPlayed: number | undefined = undefined;
    let minutesPlayedIndex: number | undefined = undefined;
    let minutesColor: string | undefined = undefined;

    if (widgetType === 'playerRadarPlot') {
      if (selectedPlayer && selectedPlayer.id in playerCareers && selectedClubIterationId !== undefined && competitions) {
        const clubIteration = playerCareers[Number(selectedPlayer.id)][selectedClubIterationId];
        const competition = competitions[clubIteration.competition_id];

        if (competition) {
          title = competition.name + ' ' + clubIteration.season;
        }

        const selectedPositionGroupKeyForIteration = selectedPositionGroupKey === 'overall' && !clubIteration.position_group_stats?.overall
          ? clubIteration.position_groups[0]
          : selectedPositionGroupKey;

        minutesPlayed = clubIteration.position_group_stats?.[selectedPositionGroupKeyForIteration]?.minutes_played ?? 0;
        minutesPlayedIndex = Math.min(minutesPlayed / 150, 10);
        minutesColor = getIndexColor(minutesPlayedIndex, 65);
      }
      else {
        title = translate('noSeasonSelected', userConfig?.language);
      }
    }

    else {
      title = translate(widgetType, userConfig?.language);
    }

    const infoKey = widgetType === 'playerRadarPlot' && selectedClubIterationId === undefined
      ? 'noSeasonSelectedInfo'
      : widgetType + 'Info';

    const handleOpenInfoModal = widgetType === 'positionGroups'
      ? () => {
        trackEvent('InfoModalOpened', { infoType: 'infoIcon', titleKey: 'positionGroups' }, currentUser, 'user');
        setIsPositionGroupsInfoModalOpen(true);
      }
      : () => openTextModal(
        title,
        translate(infoKey, userConfig?.language),
        { infoType: 'infoIcon', titleKey: widgetType }
      );

    return (
      <div className={'player-view-overview-widget-title-container player-view-overview-widget-title-container-' + titleType}>
        {title}

        {minutesPlayed !== undefined && minutesPlayedIndex !== undefined && minutesColor && (
          <div style={{ marginTop: -4, marginLeft: 3, marginRight: 3 }}>
            <ProgressCircle
              size={14}
              progress={minutesPlayedIndex * 10}
              color={minutesColor}
              strokeWidth={9}
            />
          </div>
        )}

        {minutesPlayed !== undefined && (
          <div>
            {minutesPlayed} min
          </div>
        )}

        <IconButton
          icon={<InfoOutlinedIcon style={{ fontSize: 14 }} />}
          onClick={handleOpenInfoModal}
          size={18}
        />
      </div >
    );
  };


  const clubColor = getClubColor(userConfig?.club ?? '');

  // the design of role indexes will depend on the number of indexes and screen width
  const numberOfRolesForSelectedPositionGroup = (positionGroupToRoleIds[selectedPositionGroupKey] ?? []).length;
  const roleIndexDesgin: '' | 'manyRoles' | 'tooManyRoles' =
    ((numberOfRolesForSelectedPositionGroup > 3 && width < 1800) || (numberOfRolesForSelectedPositionGroup > 2 && width < 1000))
      ? 'tooManyRoles'
      : (numberOfRolesForSelectedPositionGroup > 2 && width < 1350)
        ? 'manyRoles'
        : '';

  const dataIsLimitedForAtLeastOnePositionGroup = (playerOverview.position_group_stats?.overall?.confidence ?? 0) <
    (playerOverview.position_group_stats?.overall?.possible_confidence ?? 0);


  return (
    <div className='full-size-container'>

      <Dialog
        open={isPositionGroupsInfoModalOpen}
        onClose={() => setIsPositionGroupsInfoModalOpen(false)}
        maxWidth='md'
        PaperProps={modalPaperProps}
      >
        <PositionGroupsInfoModal
          closeModal={() => setIsPositionGroupsInfoModalOpen(false)}
        />
      </Dialog>

      <div className='player-view-overview-top-row'>

        <div className='player-view-overview-position-and-index-container'>
          <div
            className='player-view-widget-container player-view-overview-widget-container player-view-overview-position-and-index-sub-container'
            style={{ height: (100 + ((positionGroupsPlayed.length - 1) * 70)) + '%' }}
          >
            {getTopRowTitleSection('positionGroups', 'small')}

            <div className='player-view-overview-widget-content-container player-view-overview-widget-content-container-small-title'>
              <div className='player-view-overview-position-group-section'>
                {positionGroupsPlayed.map((positionGroup, positionGroupIndex) => {

                  const isSelected = positionGroup === selectedPositionGroupKey;
                  const positions: PositionData[] = playerOverview.position_group_stats?.[positionGroup]?.positions ?? [];
                  const positionGroupConfidence = playerOverview.position_group_stats?.[positionGroup]?.confidence ?? 0;
                  const positionGroupPossibleConfidence = playerOverview.position_group_stats?.[positionGroup]?.possible_confidence ?? 0;

                  const dataIsAvailableForThisPositionGroup = positionGroupConfidence > 0;
                  const dataIsLimitedForThisPositionGroup = positionGroupConfidence < positionGroupPossibleConfidence;

                  return (
                    <React.Fragment key={positionGroup}>

                      <div
                        className={
                          'player-view-overview-position-group'
                          + (isSelected ? ' player-view-overview-position-group-selected' : '')
                        }
                        style={{ borderColor: isSelected ? clubColor : 'transparent' }}
                        onClick={() => !isSelected ? setSelectedPositionGroupKey(positionGroup) : null}
                      >

                        <div
                          className='player-view-overview-positions-cell'
                          style={{ fontSize: 13 - (positionGroup === 'overall' ? 0 : positions.length) }}
                        >
                          {positionGroup === 'overall' && (
                            <div>
                              {translate('all', userConfig?.language)}
                            </div>
                          )}

                          {positionGroup !== 'overall' && positions.map(position => {
                            return (
                              <div key={position.position}>
                                {translate(position.position, userConfig?.language)}
                              </div>
                            );
                          })}
                        </div>

                        {dataIsAvailableForThisPositionGroup && (
                          <div className='player-view-overview-skill-index-cell'>
                            {translate('skillIndex', userConfig?.language)}

                            <div
                              className={'player-view-overview-skill-index' + (isSelected ? ' player-view-overview-skill-index-selected' : '')}
                              style={{ color: isSelected ? getIndexColor(getSkillIndex(positionGroup) ?? 0, 60) : undefined }}
                            >
                              {getSkillIndex(positionGroup) ?? '-'}
                            </div>
                          </div>
                        )}

                        {!dataIsAvailableForThisPositionGroup && (
                          <div className='player-view-overview-skill-index-cell'>
                            <LockIcon style={{ fontSize: 20, color: '#ffffffaa' }} />
                          </div>
                        )}

                        <div className='player-view-overview-precision-cell' style={{ width: dataIsLimitedForAtLeastOnePositionGroup ? '41%' : undefined }}>
                          {translate('confidence', userConfig?.language)}

                          <div
                            className='player-view-overview-precision-circles'
                            style={{ paddingRight: dataIsLimitedForAtLeastOnePositionGroup && !dataIsLimitedForThisPositionGroup ? 35 : undefined }}
                          >
                            <ProgressCircle
                              size={dataIsLimitedForAtLeastOnePositionGroup ? 22 : 25}
                              progress={isSelected && showConfidence ? Math.round(positionGroupConfidence * 100) : 0}
                              color={isSelected ? getIndexColor(positionGroupConfidence * 10, 65) : 'transparent'}
                              duration={0.6}
                              value={Math.round(positionGroupConfidence * 100)}
                              fontSize={dataIsLimitedForAtLeastOnePositionGroup ? 10 : 11}
                            />

                            {dataIsLimitedForThisPositionGroup && (
                              <div className='player-view-overview-precision-circles-divider'>
                                /
                              </div>
                            )}

                            {dataIsLimitedForThisPositionGroup && (
                              <div style={{ color: isSelected ? '#ffffffbb' : undefined }}>
                                <ProgressCircle
                                  size={22}
                                  progress={isSelected ? Math.round(positionGroupPossibleConfidence * 100) : 0}
                                  color={isSelected ? '#ffffff33' : 'transparent'}
                                  value={Math.round(positionGroupPossibleConfidence * 100)}
                                  fontSize={10}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      {positionGroupIndex < positionGroupsPlayed.length - 1 &&
                        <div className='player-view-overview-position-group-divider' />
                      }

                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>

          {(selectedPositionGroupKey === 'overall' || positionGroupsPlayed.length === 1) && (
            <div className='player-view-widget-container player-view-overview-widget-container player-view-overview-position-and-index-sub-container'>
              {getTopRowTitleSection('formAndPlayingTime', 'small')}

              <div className='player-view-overview-widget-content-container player-view-overview-widget-content-container-small-title'>

                <div className='player-view-overview-index-row'>
                  <div className='player-view-overview-index-cell'>
                    <div
                      className='player-view-metric-label'
                      title={translate('showDescription', userConfig?.language)}
                      onClick={() => openTextModal(
                        translate('form', userConfig?.language),
                        translate('formInfo', userConfig?.language),
                        { infoType: 'metric', titleKey: 'form' }
                      )}
                    >
                      {translate('form', userConfig?.language)}
                    </div>

                    {playerOverview.event_data_available && (getFormIcon(playerOverview.form_status, 20, undefined, 65) ?? '-')}

                    {!playerOverview.event_data_available && <LockIcon style={{ fontSize: 20, color: '#ffffffaa' }} />}
                  </div>

                  <div className='player-view-overview-index-cell'>
                    <div
                      className='player-view-metric-label'
                      title={translate('showDescription', userConfig?.language)}
                      onClick={() => openTextModal(
                        translate('playingTime', userConfig?.language),
                        translate('playingTimeInfo', userConfig?.language),
                        { infoType: 'metric', titleKey: 'playingTime' }
                      )}
                    >
                      {translate('playingTime', userConfig?.language)}
                    </div>

                    {playerOverview.event_data_available && (getPlayingTimeIcon(playerOverview.availability_status, 20) ?? '-')}

                    {!playerOverview.event_data_available && <LockIcon style={{ fontSize: 20, color: '#ffffffaa' }} />}
                  </div>
                </div>
              </div>
            </div>
          )}

          {selectedPositionGroupKey !== 'overall' && (
            <div className='player-view-widget-container player-view-overview-widget-container player-view-overview-position-and-index-sub-container'>
              {getTopRowTitleSection('roleIndexes', 'small')}

              <div className='player-view-overview-widget-content-container player-view-overview-widget-content-container-small-title'>

                <div className={'player-view-overview-index-row' + ' player-view-overview-index-row-' + roleIndexDesgin}>
                  {(positionGroupToRoleIds[selectedPositionGroupKey] ?? []).map(roleId => {

                    if (!(roleId in roleConfigs)) return null;

                    const roleIndex = getRoleIndex(selectedPositionGroupKey, roleId);

                    // display options are roleIndex, BuildIcon, LockIcon
                    let value = undefined;
                    let color = undefined;
                    if (roleIndex === 'beingComputed') {
                      value = <BuildIcon style={{ fontSize: 18, color: '#ffffffaa' }} />;
                    }
                    else if (roleIndex !== undefined) {
                      value = <div>{roleIndex}</div>;
                      color = getIndexColor(roleIndex, 60);
                    }
                    else {
                      value = <LockIcon style={{ fontSize: 20, color: '#ffffffaa' }} />;
                    }

                    return (
                      <div
                        key={roleId}
                        className={'player-view-overview-index-cell' + ' player-view-overview-index-cell-' + roleIndexDesgin}
                      >
                        <div
                          className='player-view-metric-label'
                          title={translate('showDescription', userConfig?.language)}
                          onClick={() => openRoleInfoModal(roleConfigs[roleId])}
                        >
                          {roleConfigs[roleId].name}
                        </div>

                        <div
                          className='player-view-overview-role-index'
                          style={{ color: color }}
                        >
                          {value}
                        </div>
                      </div>
                    );
                  })}

                  {(positionGroupToRoleIds[selectedPositionGroupKey] ?? []).length === 0 && (
                    <div className='player-view-overview-index-cell' style={{ color: '#ffffffbb' }}>
                      {translate('noRolesForPositionGroup', userConfig?.language)}
                    </div>
                  )}
                </div>

              </div>
            </div>
          )}
        </div>

        <div className='player-view-widget-container player-view-overview-widget-container player-view-overview-line-plot-container'>
          {getTopRowTitleSection('playerLinePlot', 'large')}

          <div className='player-view-overview-widget-content-container player-view-overview-widget-content-container-large-title'>
            <div className='player-view-line-plot-container'>
              <PlayerLinePlot
                playerClubIterations={playerClubIterationArray ?? []}
                selectedClubIterationId={selectedClubIterationId}
                selectedPositionGroupKey={selectedPositionGroupKey}
                clubColor={clubColor}
                competitions={competitions}
              />
            </div>
          </div>
        </div>

        <div className='player-view-widget-container player-view-overview-widget-container player-view-overview-radar-plot-container'>
          {getTopRowTitleSection('playerRadarPlot', 'large')}

          <div className='player-view-overview-widget-content-container player-view-overview-widget-content-container-large-title'>
            <div className='player-view-radar-plot-container'>
              <PlayerRadarPlot
                playerCareer={playerCareers && selectedPlayer && selectedPlayer.id in playerCareers ? playerCareers[Number(selectedPlayer.id)] : {}}
                selectedClubIterationId={selectedClubIterationId}
                selectedPositionGroupKey={selectedPositionGroupKey}
                clubColor={clubColor}
                language={userConfig?.language ?? 'en'}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='player-view-overview-bottom-row'>
        {playerClubIterationArray && shouldShowSeasonTable && (
          <div className='player-view-widget-container player-view-overview-widget-container player-view-overview-table-container'>
            <div key={selectedPositionGroupKey}>
              <SeasonTable
                data={playerClubIterationArray}
                selectedClubIterationId={selectedClubIterationId}
                selectedPositionGroupKey={selectedPositionGroupKey}
                setSelectedClubIterationId={setSelectedClubIterationId}
              />
            </div>
          </div>
        )}
      </div>
    </div >
  );
};
