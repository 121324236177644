import { db, FirebaseUser } from '../../../firebase';

import {
  deleteField,
  doc,
  onSnapshot,
  updateDoc,
} from 'firebase/firestore';

import { ClubSettings, RoleConfig, RoleConfigMap, RoleIndexConfigMap, StringToStringArrayMap } from '../../types';
import { updateTeamsWithNewFormation } from './teamsOrSquads';
import { formationToPositionOptions } from '../../static/propertyValues';
import { trackEvent } from '../server/analytics/trackEvent';


// get and set settings for club and
export const getClubSettings = (setClubSettings: (clubSettings: ClubSettings) => void, club: string) => {

  const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');

  const unsubscribe = onSnapshot(clubSettingsDocRef, (doc) => {
    if (doc.exists()) {
      const clubSettingsObject = doc.data();

      const roleConfigs: RoleConfigMap = clubSettingsObject.roleConfigs ?? {};
      const positionGroupToRoleIds: StringToStringArrayMap = {};

      const tempPositionGroupMap: { [key: string]: { roleId: string; orderIndex: number }[] } = {};
      Object.entries(roleConfigs).forEach(([roleId, config]) => {
        const { positionGroup, positionGroupIndex } = config;
        if (!tempPositionGroupMap[positionGroup]) tempPositionGroupMap[positionGroup] = [];
        tempPositionGroupMap[positionGroup].push({
          roleId,
          orderIndex: positionGroupIndex
        });
      });
      Object.keys(tempPositionGroupMap).forEach(positionGroup => {
        tempPositionGroupMap[positionGroup].sort((a, b) => a.orderIndex - b.orderIndex);
        positionGroupToRoleIds[positionGroup] = tempPositionGroupMap[positionGroup].map(entry => entry.roleId);
      });

      const clubSettings: ClubSettings = {
        clubId: clubSettingsObject.clubId,
        clubName: clubSettingsObject.clubName,
        formation: clubSettingsObject.formation ?? '433',
        countryCode: clubSettingsObject.countryCode ?? 'NO',
        iterations: clubSettingsObject.iterations ?? [],
        roleConfigs: roleConfigs,
        positionGroupToRoleIds: positionGroupToRoleIds,
        positionToRoleIndexConfig: clubSettingsObject.positionToRoleIndexConfig ?? {},
      };

      setClubSettings(clubSettings);
    }
  });

  return unsubscribe;
};


// update club settings formation and positionToRoleIndexConfig (no re-distribution, just cleanup of positions that are not in the new formation)
export const updateClubSettingsFormation = async (
  newFormation: string,
  currentFormation: string,
  currentPositionToRoleIndexConfig: RoleIndexConfigMap,
  userEmail: string,
  club: string,
  currentUser: FirebaseUser,
) => {

  // This will only be called with a new formation
  if (newFormation !== currentFormation) {
    try {
      await updateTeamsWithNewFormation(newFormation, currentFormation, userEmail, club, currentUser);

      const newPositions = formationToPositionOptions[newFormation];
      const newPositionToRoleIndexConfig: RoleIndexConfigMap = {};
      newPositions.forEach(position => {
        if (currentPositionToRoleIndexConfig[position]) {
          newPositionToRoleIndexConfig[position] = currentPositionToRoleIndexConfig[position];
        }
      });

      // Update settings
      const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');
      await updateDoc(clubSettingsDocRef, {
        formation: newFormation,
        positionToRoleIndexConfig: newPositionToRoleIndexConfig,
      });

      trackEvent('FormationUpdated', {}, currentUser, 'user');
    }
    catch (error) {
      trackEvent('Error', { api: 'firestore', function: 'updateClubSettingsFormation', errorDetails: { error } }, currentUser, 'system');
    }
  }
};


// update positionToRoleIndexConfig
export const updatePositionToRoleIndexConfig = async (
  newPositionToRoleIndexConfig: RoleIndexConfigMap,
  club: string,
  eventName: 'RoleConfigMoved' | 'RoleConfigAggregationRuleEdited',
  currentUser: FirebaseUser,
) => {

  // temporary tracking of potential issue
  let copyDetected = false;
  Object.values(newPositionToRoleIndexConfig).forEach(roleIndexConfig => {
    roleIndexConfig.roleIds.forEach(roleId => {
      if (roleId.endsWith('copy')) {
        copyDetected = true;
      }
    });
  });
  if (copyDetected) {
    trackEvent(
      'UnexpectedBehavior',
      { file: 'clubSettings.ts', behavior: 'Dragged role copy detected (updatePositionToRoleIndexConfig)' },
      currentUser,
      'user'
    );
  }

  const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');

  try {
    await updateDoc(clubSettingsDocRef, {
      positionToRoleIndexConfig: newPositionToRoleIndexConfig
    });

    trackEvent(eventName, {}, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updatePositionToRoleIndexConfig', errorDetails: { error } }, currentUser, 'system');
  }
};


// update the given role config
export const updateRoleConfig = async (
  roleId: string,
  newRoleConfig: RoleConfig,
  club: string,
  eventName: 'RoleConfigCreated' | 'RoleConfigEdited' | 'RoleConfigNameEdited',
  currentUser: FirebaseUser,
) => {

  const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');

  try {
    await updateDoc(clubSettingsDocRef, {
      [`roleConfigs.${roleId}`]: newRoleConfig
    });

    if (eventName === 'RoleConfigCreated') {
      trackEvent(eventName, { positionGroup: newRoleConfig.positionGroup, numberOfMetrics: Object.keys(newRoleConfig.weights).length }, currentUser, 'user');
    }
    else if (eventName === 'RoleConfigEdited') {
      trackEvent(eventName, { numberOfMetrics: Object.keys(newRoleConfig.weights).length }, currentUser, 'user');
    }
    else {
      trackEvent(eventName, {}, currentUser, 'user');
    }
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'updateRoleConfig', errorDetails: { error } }, currentUser, 'system');
  }
};


// delete the given role config
export const deleteRoleConfig = async (
  roleId: string,
  newPositionToRoleIndexConfig: RoleIndexConfigMap,
  club: string,
  currentUser: FirebaseUser,
) => {

  const clubSettingsDocRef = doc(db, 'configs', club, 'settings', 'clubSettings');

  try {
    await updateDoc(clubSettingsDocRef, {
      [`roleConfigs.${roleId}`]: deleteField(),
      positionToRoleIndexConfig: newPositionToRoleIndexConfig,
    });

    trackEvent('RoleConfigDeleted', {}, currentUser, 'user');
  }
  catch (error) {
    trackEvent('Error', { api: 'firestore', function: 'deleteRoleConfig', errorDetails: { error } }, currentUser, 'system');
  }
};
