import { SearchPlayerOverviewsResponse } from '../../../services/server/application/playerOverviews';
import { mobilePlayerTableChapterSize } from './MobileScouting';
import { initialMobileScoutingState, MobileScoutingFilter, MobileScoutingState } from './mobileScoutingState';


export type MobileScoutingAction =
  | {
    type: 'SET_SEARCH_RESULT';
    payload: {
      result: SearchPlayerOverviewsResponse,
      isNewSearch: boolean,
      nextChapter: number | undefined,
      newOverriddenOrderBy: string | undefined
    }
  }

  | { type: 'RESET_STATE'; }
  | { type: 'RESET_SEARCH_STATE'; }
  | { type: 'SET_IS_LOADING'; }
  | { type: 'SET_EMPTY_TABLE_DATA'; }

  | { type: 'SET_FILTER'; payload: Partial<MobileScoutingState> }
  | { type: 'SET_FILTER_EXPANSION'; payload: MobileScoutingFilter; }
  | { type: 'RESET_FILTER_EXPANSION'; }
  | { type: 'SET_IS_FILTER_SECTION_EXPANDED'; payload: boolean; }
  | { type: 'SET_SHOW_FILTER_SECTION'; payload: boolean; }
  | { type: 'SET_SELECTED_ORDER_BY'; payload: string[]; }


const mobileScoutingReducer = (state: MobileScoutingState, action: MobileScoutingAction): MobileScoutingState => {

  switch (action.type) {

    case 'SET_SEARCH_RESULT':
      return getState_SET_SEARCH_RESULT(action.payload, state);

    case 'RESET_STATE':
      return getState_RESET_STATE(state);

    case 'RESET_SEARCH_STATE':
      return getState_RESET_SEARCH_STATE(state);

    case 'SET_IS_LOADING':
      return { ...state, isLoading: true };

    case 'SET_EMPTY_TABLE_DATA':
      return { ...state, tableData: [] };


    case 'SET_FILTER':
      return { ...state, ...action.payload };

    case 'SET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: action.payload, previousFilterExpanded: action.payload };

    case 'RESET_FILTER_EXPANSION':
      return { ...state, currentFilterExpanded: undefined };

    case 'SET_IS_FILTER_SECTION_EXPANDED':
      return { ...state, isFilterSectionExpanded: action.payload };

    case 'SET_SHOW_FILTER_SECTION':
      return { ...state, showFilterSection: action.payload };

    case 'SET_SELECTED_ORDER_BY':
      return { ...state, selectedOrderBy: action.payload };


    default:
      return state;
  }
};


const getState_SET_SEARCH_RESULT = (
  payload: {
    result: SearchPlayerOverviewsResponse,
    isNewSearch: boolean,
    nextChapter: number | undefined,
    newOverriddenOrderBy: string | undefined,
  },
  state: MobileScoutingState
) => {

  const isEmptySearchResult = payload.result.total_hits === 0 || (payload.isNewSearch && payload.result.players.length === 0);
  const isFirstPageOfChapter = payload.isNewSearch || payload.nextChapter !== undefined;

  return {
    ...state,

    tableData: isEmptySearchResult
      ? []
      : isFirstPageOfChapter ? payload.result.players : [...state.tableData, ...payload.result.players],

    totalHits: isEmptySearchResult
      ? 0
      : payload.result.total_hits,

    currentModuloPage: isEmptySearchResult
      ? 0
      : isFirstPageOfChapter ? 1 : (payload.result.current_page - (state.currentChapter * mobilePlayerTableChapterSize)),

    currentChapter: isEmptySearchResult
      ? 0
      : payload.isNewSearch ? 0 : (payload.nextChapter !== undefined ? payload.nextChapter : state.currentChapter),

    isEmptySearchResult: isEmptySearchResult,
    isLoading: false,

    // set current filters applied to the search result to the ones currenlty selected
    currentNameSearchString: state.nameSearchString,
    currentMinConfidence: state.selectedMinConfidence,
    currentMaxAge: state.selectedMaxAge,
    currentPositions: state.selectedPositions,
    currentRoles: state.selectedRoles,
    currentLeagues: state.selectedLeagues,
    currentOrderBy: payload.newOverriddenOrderBy ? [payload.newOverriddenOrderBy] : state.selectedOrderBy,
  };
};


const getState_RESET_SEARCH_STATE = (state: MobileScoutingState) => {
  return {
    ...state,
    tableData: [],
    currentChapter: 0,
    totalHits: 0,
    currentModuloPage: 0,
    isEmptySearchResult: false,
    isLoading: false,
  };
};


const getState_RESET_STATE = (state: MobileScoutingState) => {
  return {
    ...initialMobileScoutingState,
    isFilterSectionExpanded: state.isFilterSectionExpanded,
    showFilterSection: state.showFilterSection,
  };
};


export default mobileScoutingReducer;
