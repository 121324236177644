import { svgColors } from './colors';


interface FokusLogoProps {
  isDarkLogo?: boolean;
}

export const FokusLogo: React.FC<FokusLogoProps> = ({ isDarkLogo }) => {

  const strokeColor = isDarkLogo ? svgColors.fokusLogoDark : svgColors.fokusLogoWhite;

  return (
    <div className='full-size'>
      <svg width='100%' height='100%' viewBox='0 0 100 100' preserveAspectRatio='none'>

        <line x1="0" y1="50" x2="15" y2="50" style={{ stroke: strokeColor, strokeWidth: 6 }} />
        <line x1="85" y1="50" x2="100" y2="50" style={{ stroke: strokeColor, strokeWidth: 6 }} />

        <path d="M 11 50 C 27 78.5, 73 78.5, 89 50 " style={{ stroke: strokeColor, strokeWidth: isDarkLogo ? 5.8 : 5, fill: 'none' }} />

        <path d="M 11 50 C 27 21.5, 73 21.5, 89 50 " style={{ stroke: strokeColor, strokeWidth: isDarkLogo ? 5.8 : 5, fill: 'none' }} />

        <path d="M 23.5 37.5 C 11 47, 11 53, 23.5 62.5 " style={{ stroke: strokeColor, strokeWidth: 3, fill: 'none' }} />

        <path d="M 76.5 37.5 C 89 47, 89 53, 76.5 62.5 " style={{ stroke: strokeColor, strokeWidth: 3, fill: 'none' }} />

        <circle cx="50" cy="50" r="5" style={{ fill: strokeColor }} />

      </svg>
    </div>
  );
};
