import './../modals.css';

import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ReplayIcon from '@mui/icons-material/Replay';

import { translate } from '../../../../common/language/translations';
import { InputField } from '../../controls/input/InputField';
import { getDisplayEconomicValue } from '../../../utils/currencyUtils';
import { ClauseState } from '../../documents/clause/clauseState';
import { ClauseAction } from '../../documents/clause/clauseReducer';
import { IconButton } from '../../controls/buttons/IconButton';
import { TextButton } from '../../controls/buttons/TextButton';


interface MaxConditionalFeesModalProps {
  closeModal: () => void;
  state: ClauseState;
  dispatch: React.Dispatch<ClauseAction>;
}

export const MaxConditionalFeesModal: React.FC<MaxConditionalFeesModalProps> = ({
  closeModal,
  state,
  dispatch,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const stateAccessor = state.additionalClauseState.activeSubClauseTab !== undefined && state.subClauses
    ? state.subClauses[state.additionalClauseState.activeSubClauseTab]
    : state;

  const [maxAmount, setMaxAmount] = useState(stateAccessor.maxConditionalFees ? stateAccessor.maxConditionalFees.toString() : '');

  const [messageKey, setMessageKey] = useState<string | undefined>(undefined);


  const isValidMaxAmount = (maxAmount: string) => {
    const maxAmountNumber = Number(maxAmount);
    if (isNaN(maxAmountNumber) || maxAmountNumber < 0) {
      setMessageKey('invalidMaxAmount');
      return false;
    }
    return true;
  };


  const handleConfirmMaxConditionalFees = () => {
    if (isValidMaxAmount(maxAmount)) {
      closeModal();
      dispatch({ type: 'SET_MAX_CONDITIONAL_FEES', payload: Number(maxAmount) });
    }
  };


  const handleResetMaxConditionalFees = () => {
    closeModal();
    dispatch({ type: 'SET_MAX_CONDITIONAL_FEES', payload: null });
  };


  const onChangeSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessageKey(undefined);
    setMaxAmount(event.target.value.replaceAll('.', ''));
  };


  const onKeyDownSearchField = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleConfirmMaxConditionalFees();
    }
  };

  const resetSearchString = () => {
    setMessageKey(undefined);
    setMaxAmount('');
  };


  const [shouldShowResetButton, setShouldShowResetButton] = useState(false);
  useEffect(() => {
    if (stateAccessor.maxConditionalFees !== null) {
      setShouldShowResetButton(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const isButtonDisabled = maxAmount === '' || (state.maxConditionalFees !== null && maxAmount === state.maxConditionalFees.toString());


  return (
    <div className='modal-root-container' style={{ width: 500, height: 400 }}>

      {shouldShowResetButton && (
        <IconButton
          icon={<ReplayIcon style={{ fontSize: 24 }} />}
          onClick={handleResetMaxConditionalFees}
          size={30}
          style={{ position: 'absolute', top: 12, left: 12 }}
          startWhite={true}
        />
      )}

      <IconButton
        icon={<CloseIcon style={{ fontSize: 24 }} />}
        onClick={closeModal}
        size={30}
        style={{ position: 'absolute', top: 12, right: 12 }}
        startWhite={true}
      />

      <div className='modal-root-title'>
        {translate('provideMaxAmountForConditions', userConfig?.language)}
      </div>

      <div className='modal-divider modal-root-title-divider' />

      <div className='modal-content-container'>
        {messageKey && (
          <div className='condition-modal-message' style={{ top: 10 }}>
            {userConfig ? translate(messageKey, userConfig.language) : ''}
          </div>
        )}

        <div className='modal-content-section' style={{ margin: 'auto', minHeight: 220, justifyContent: 'space-between', padding: 40 }}>
          <div style={{ width: 350, textAlign: 'center', paddingBottom: 10 }}>
            {translate('provideMaxAmountForConditionsInfo', userConfig?.language)}
          </div>

          <div style={{ width: 200, paddingBottom: 20 }}>
            <InputField
              value={getDisplayEconomicValue(maxAmount)}
              placeholder={translate('provideMaxAmount', userConfig?.language)}
              onChange={onChangeSearchField}
              onKeyDown={onKeyDownSearchField}
              resetValue={resetSearchString}
            />
          </div>

          <TextButton
            onClick={handleConfirmMaxConditionalFees}
            text={translate('confirmMaxAmount', userConfig?.language)}
            icon={<CheckIcon style={{ fontSize: 22 }} />}
            disabled={isButtonDisabled}
            style={{ margin: 0 }}
          />
        </div>
      </div>

    </div>
  );
};
