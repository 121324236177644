import { Club, Iteration, PositionGroupClubIteration, RatingRequirement } from '../../../types';


export type SeasonScoutingFilter =
  | 'minutes'
  | 'maxAge'
  | 'positionsAndRoles'
  | 'iterations'
  | 'orderBy'
  | 'clubs'
  | 'ratings'
  | 'nationalities'
  | 'contractStatus';


export interface SeasonScoutingState {

  // selected filters
  selectedMinutes: string[];
  selectedMaxAge: string[];
  selectedPositions: string[];
  selectedRoles: string[];
  selectedIterations: Iteration[];
  selectedOrderBy: string[];

  selectedClubs: Club[];
  selectedRatings: RatingRequirement[];
  selectedNationalities: string[];
  selectedContractStatus: string[];
  isContractStatusToggled: boolean;

  anySkillcornerFiltersSelected: boolean;
  anyIterationWithoutSkillcornerSelected: boolean;

  // filters applied to current search result
  currentMinutes: string[];
  currentMaxAge: string[];
  currentPositions: string[];
  currentRoles: string[];
  currentIterations: Iteration[];
  currentOrderBy: string[];
  currentOrderByToggle: boolean;

  currentClubs: Club[];
  currentRatings: RatingRequirement[];
  currentNationalities: string[];
  currentContractStatus: string[];
  currentIsContractStatusToggled: boolean;

  // filter expansion state
  currentFilterExpanded: SeasonScoutingFilter | undefined,
  previousFilterExpanded: SeasonScoutingFilter | undefined;

  // advanced filters
  isAdvancedFiltersExpanded: boolean;
  showAdvancedFilters: boolean;
  scoutingViewResultContainerTop: number; // 85 | 155

  // search state
  tableData: PositionGroupClubIteration[];
  playerIdsOnCurrentPage: number[];
  currentModuloPage: number; // currentModuloPage (page % pagesPerChapter) is 1-indexed
  currentChapter: number; // chapters are 0-indexed
  totalHits: number;
  isLoading: boolean;
  isEmptySearchResult: boolean;
  searchWasLoaded: boolean;

  // other state
  modalTypeOpen: 'saveSearch' | 'loadSearch' | undefined;
}


export const initialSeasonScoutingState: SeasonScoutingState = {

  // selected filters
  selectedMinutes: [],
  selectedMaxAge: [],
  selectedPositions: [],
  selectedRoles: [],
  selectedIterations: [],
  selectedOrderBy: ['skill_rating'],

  selectedClubs: [],
  selectedRatings: [],
  selectedNationalities: [],
  selectedContractStatus: [],
  isContractStatusToggled: false,

  anySkillcornerFiltersSelected: false,
  anyIterationWithoutSkillcornerSelected: false,

  // filters applied to current search result
  currentMinutes: [],
  currentMaxAge: [],
  currentPositions: [],
  currentRoles: [],
  currentIterations: [],
  currentOrderBy: [],
  currentOrderByToggle: false,

  currentClubs: [],
  currentRatings: [],
  currentNationalities: [],
  currentContractStatus: [],
  currentIsContractStatusToggled: false,

  // filter expansion state
  currentFilterExpanded: undefined,
  previousFilterExpanded: undefined,

  // advanced filters
  isAdvancedFiltersExpanded: false,
  showAdvancedFilters: false,
  scoutingViewResultContainerTop: 85,

  // search state
  tableData: [],
  playerIdsOnCurrentPage: [],
  currentModuloPage: 0,
  currentChapter: 0,
  totalHits: 0,
  isLoading: false,
  isEmptySearchResult: false,
  searchWasLoaded: false,

  // other state
  modalTypeOpen: undefined,
};
