import './../documents.css';

import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userConfigState } from '../../../recoil/atoms/userConfigState';

import { getClubColor } from '../../../static/clubConfigs';
import { Rating } from '@mui/material';

import { reportScoreLanguageMap } from './reportVersions';
import { StringToBooleanMap, StringToNumberMap } from '../../../types';


interface ReportScoresProps {
  scores: StringToNumberMap;
  averageMatchReportScores?: StringToNumberMap;
  handleRatingChange?: (scoreKey: string, value: number) => void;
  requiredScoresToShow?: StringToBooleanMap;
}


export const ReportScores: React.FC<ReportScoresProps> = ({
  scores,
  averageMatchReportScores,
  handleRatingChange,
  requiredScoresToShow,
}) => {

  const userConfig = useRecoilValue(userConfigState);

  const [ratingWasReset, setRatingWasReset] = useState(false);


  const handleRatingChangeInternal = (scoreKey: string, value: number | null) => {
    if (!handleRatingChange) return;

    // if the score is not set, setting it to the same value as the average will be interpreted as a reset
    let valueToSet = value;
    if (!scores[scoreKey] && value === null) {
      valueToSet = averageMatchReportScores?.[scoreKey] ?? 0;
    }

    if (valueToSet === null) {
      setRatingWasReset(true);
      valueToSet = 0;
    }

    handleRatingChange(scoreKey, valueToSet);
  };


  const readOnly = handleRatingChange === undefined;

  const flexRow = Object.keys(scores).length > 4;

  const clubColor = getClubColor(userConfig?.club ?? '');


  return (
    <div className={'report-scores-container' + (flexRow ? ' report-scores-container-flex-row' : ' report-scores-container-flex-column')}>

      {Object.entries(scores).map(([scoreKey, score], index) => {

        const isLargeRating = !flexRow || index <= 1;

        const fontSize = isLargeRating
          ? 25
          : 22;

        const paddingBottom = flexRow && isLargeRating
          ? '1vh'
          : undefined;

        const value = score
          ? score
          : averageMatchReportScores?.[scoreKey] ?? 0;

        const ratingColor = !score && averageMatchReportScores?.[scoreKey]
          ? '#a7a9ad'
          : clubColor;

        const showRequired = requiredScoresToShow && requiredScoresToShow[scoreKey + 'ShowRequired'];

        return (
          <div
            key={scoreKey}
            className='report-rating'
            style={{
              paddingBottom: paddingBottom,
            }}
          >
            <div className='report-rating-title'>
              {reportScoreLanguageMap[scoreKey][userConfig?.language ?? 'en']}
            </div>

            <div className='report-rating-stars'>
              <Rating
                name='overallScore'
                value={value}
                onChange={(_: React.SyntheticEvent, value: number | null) => (readOnly ? null : handleRatingChangeInternal(scoreKey, value))}
                max={5}
                precision={0.5}
                onMouseMove={() => setRatingWasReset(false)}
                onMouseEnter={() => setRatingWasReset(false)}
                onMouseLeave={() => setRatingWasReset(false)}
                sx={{
                  fontSize: fontSize,
                  color: ratingColor,
                  '&:hover': {
                    color: !ratingWasReset ? clubColor : undefined,
                  },
                  '& .MuiRating-iconEmpty': {
                    color: readOnly ? '#9d9fa3' : '#a7a9ad'
                  },
                }}
                readOnly={readOnly}
              />

              {showRequired && (
                <div className={
                  'document-required-star report-field-required'
                  + (isLargeRating ? ' report-large-rating-required' : ' report-rating-required')
                  + ' fast-fade-in'
                }>
                  *
                </div>
              )}
            </div>

          </div>
        );
      })}


    </div>
  );
};
